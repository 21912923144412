import axios from 'axios';
import { API_BASE, getHeaders } from "../_helpers/api";

export const livestreamService = {
  getLivestreams,
  getLivestreamDetail,
  follow,
  unfollow,
  report,
  postReport,
  subscribeLivestream,
  unsubscribeLivestream,
  getLivestreamByStatus,
  getVideosOfUser,
  getCommunities,
  getCommunityById,
  createLivestream,
  singedUrl,
  uploadImageS3,
  createDraftEvent,
  getVideoCategories
};

function getVideosOfUser(id, type, page, limit) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/${id}/videos?type=${type}&page=${page}&limit=${limit}&ids=`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getLivestreams(page, limmit) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams?page=${page}&limit=${limmit}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getLivestreamByStatus(page, limmit, status, option) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams?page=${page}&limit=${limmit}&status=${status}&option=${option}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getLivestreamDetail(id) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/${id}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function follow(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/users/${id}/follow`,  {mode: 'no-cors'}, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function unfollow(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/users/${id}/unfollow`, {mode: 'no-cors'}, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function report() {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/reports/reasons?filter=videos`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function postReport(data) {
  const token = localStorage.getItem('token');

  return axios.post(`${API_BASE}/api/v1/reports`, data, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function subscribeLivestream(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${id}/subscribe-notification`, {}, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function unsubscribeLivestream(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${id}/unsubscribe-notification`, {}, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function getCommunities(keySearch, page, limmit) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/me/communities?key=${keySearch}&type=2&page=${page}&limit=:${limmit}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getCommunityById(id) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/communities/${id}}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getVideoCategories() {
  return axios.get(`${API_BASE}/api/v1/videos/categories`, {
    headers: {
      ...getHeaders()
    }
  })
}

async function createLivestream(dataLivestream) {
  try {
    const token = localStorage.getItem('token');
    if (dataLivestream.is_upload) {
      let data = {
        "extension": "png",
        "type": 2
      }
      const result = await singedUrl(data);
      const { thumbnail, banner } = dataLivestream;
      data = {
        'urlUploadS3': result.data.data.url_upload,
        thumbnail,
      }
      await uploadImageS3(data);
      dataLivestream.thumbnail = result.data.data.endpoint

      let dataBanner = {
        "extension": "png",
        "type": 2
      }
      const resultBanner = await singedUrl(dataBanner);
      dataBanner = {
        'urlUploadS3': resultBanner.data.data.url_upload,
        banner,
      }
      await uploadImageS3Banner(dataBanner)
      dataLivestream.banner = resultBanner.data.data.endpoint

      delete dataLivestream.is_upload
    }
    delete dataLivestream.is_upload;
    return axios.post(`${API_BASE}/api/v1/livestreams`, dataLivestream, {
      headers: {
        ...getHeaders(token)
      }
    })
  } catch (err) {
    return { error: err.message };
  }
}

async function createDraftEvent(draftEvent) {
  try {
    const token = localStorage.getItem('token');
    if (draftEvent.is_upload) {
      let data = {
        "extension": "png",
        "type": 2
      }
      const result = await singedUrl(data);
      const { thumbnail, banner } = draftEvent;
      data = {
        'urlUploadS3': result.data.data.url_upload,
        thumbnail,
      }
      await uploadImageS3(data);
      draftEvent.thumbnail = result.data.data.endpoint

      let dataBanner = {
        "extension": "png",
        "type": 2
      }
      const resultBanner = await singedUrl(dataBanner);
      dataBanner = {
        'urlUploadS3': resultBanner.data.data.url_upload,
        banner,
      }
      await uploadImageS3Banner(dataBanner)
      draftEvent.banner = resultBanner.data.data.endpoint
    }

    delete draftEvent.is_upload
    return axios.post(`${API_BASE}/api/v1/livestreams/draft-livestreams`, draftEvent, {
      headers: {
        ...getHeaders(token)
      }
    })
  } catch (err) {
    return { error: err.message };
  }
}

async function singedUrl(data) {
  try {
    const token = localStorage.getItem('token');
    return axios.post(`${API_BASE}/api/v1/s3/singed-url`, data, {
      headers: {
        ...getHeaders(token)
      }
    })
  } catch (err) {
    return { error: err.message };
  }
}

async function uploadImageS3(data) {
  try {
    const options = {
      headers: {
        'Content-Type': data.thumbnail.type,
      },
    };
    return axios.put(data.urlUploadS3, data.thumbnail, {})
  } catch (err) {
    return { error: err.message };
  }
}

async function uploadImageS3Banner(data) {
  try {
    return axios.put(data.urlUploadS3, data.banner, {})
  } catch (err) {
    return { error: err.message };
  }
}
