import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import * as moment from 'moment';
import { eventActions } from '../../_actions/event.actions';
import { useWindowResize } from '../LiveStream/LiveStreamDetail/useWindowResize';
import "./eventcategory.css";
import Loader from "../Loader/Loader";
import { livestreamActions } from "../../_actions";

export function EventCategory() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowResize();
  const [page, setPage] = useState(1);
  const limit = 8;
  let userLogin = useSelector(state => state.login.user);
  let userLoginId = userLogin ? userLogin.id : 0;
  let selectVideoCategories = useSelector(state => state.videoCategories.videoCategories);
  const [videoCategories, setVideoCategories] = useState([selectVideoCategories]);
  let listEventsLoad = useSelector(state => state.events.listEvents);
  let loadingListEvents = useSelector(state => state.events.loadingListEvents);
  const [listEvents, setListEvents] = useState([]);
  const [displayLoadMore, setDisplayLoadMore] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [numberDisplay, setNumberDisplay] = useState(7);
  const [currentDisplay, setCurrentDisplay] = useState(6);
  const [valueActive, setValueActive] = useState([]);
  const [displayAll, setDisplayAll] = useState(true);
  let height = 300;
  if (width <= 480) {
    height = width * 16 / 19;
  }

  useEffect(() => {
    listEventsLoad = [];
    const getVideoCategories = async () => {
      await dispatch(livestreamActions.getVideoCategories());
    }
    getVideoCategories();
  }, []);

  useEffect(() => {
    if (width > 1024) {
      setNumberDisplay(7);
      setCurrentDisplay(6);
    } else if (width > 768 && width <= 1024) {
      setNumberDisplay(6);
      setCurrentDisplay(5);
    } else if (width > 480 && width <= 768) {
      setNumberDisplay(6);
      setCurrentDisplay(5);
    } else {
      setNumberDisplay(3);
      setCurrentDisplay(2);
    }
  }, [width])

  useEffect(() => {
    const getDataCategory = async () => {
      await dispatch(eventActions.getEvents(page, limit, valueActive));
    }
    getDataCategory();
  }, [valueActive]);

  useEffect(() => {
    if (listEventsLoad && listEventsLoad.length > 0) {
      if (page === 1) {
        setListEvents(listEventsLoad);
      } else {
        const curData = [...listEvents, ...listEventsLoad];
        setListEvents(curData);
      }

      const getUnit = listEventsLoad.length % limit;
      if (getUnit === 0) {
        setDisplayLoadMore(true);
      } else {
        setDisplayLoadMore(false);
      }
    } else {
      setDisplayLoadMore(false);
      if (page === 1) {
        setListEvents([]);
      }
    }
  }, [listEventsLoad]);

  useEffect(() => {
    if (selectVideoCategories && selectVideoCategories.length) {
      const firstCategory = {
        "id": 0,
        "name": "全て"
      };
      setVideoCategories([firstCategory, ...selectVideoCategories]);
    }
  }, [selectVideoCategories]);

  const handleClickPrev = (e) => {
    const resetToVeryBack = currentIdx === 0;
    const index = resetToVeryBack ? 0 : currentIdx - 1;
    setCurrentIdx(index);
  }

  const handleClickNext = async (e) => {
    if (videoCategories.length - currentIdx === numberDisplay) {
      setCurrentIdx(currentIdx + 1);
    } else {
      if (videoCategories.length - currentIdx < numberDisplay) return;
      setCurrentIdx(currentIdx + 1);
    }
  }

  const categories = videoCategories.slice(
    currentIdx,
    currentIdx + currentDisplay
  );

  const handleClickActive = (i, e) => {
    setPage(1);
    setDisplayAll(false);
    const currentActive = valueActive.includes(i);

    if (currentActive) {
      if (valueActive.length === 1) {
        setDisplayAll(true);
      }

      const filteredClick = valueActive.filter(index => {
        return parseInt(i) !== parseInt(index);
      });
      setValueActive(filteredClick)
    } else {
      setValueActive([...valueActive, i])
    }
  }

  const handleClickAllCategories = (i, e) => {
    setPage(1);
    setDisplayAll(true);
    setValueActive([]);
  }

  const handleLoadMoreEvents = async (e) => {
    e.preventDefault();
    await dispatch(eventActions.getEvents(page + 1, limit, valueActive));
    setPage(page + 1);
  }

  const handleToEvent = (id) => {
    localStorage.setItem("goBack", window.location.pathname);
    history.push(`/events/${id}`);
  }

  const renderEventCategoryDefault = (listEvent, i) => {
    if (listEvent) {
      return <div className="item__video iteam__schedule_livestream item__event__category" style={{ height }} key={i}>
        <div className="video video_event__category" style={{ height }}>
          <a onClick={() => handleToEvent(listEvent.livestream.id)} >
            <img className="user" src={`${listEvent.livestream.thumbnail ? listEvent.livestream.thumbnail : "/assets/images/test.jpg"}`} alt="" />
          </a>
        </div>
        <h5 className="video__title_category cursor_pointer text_event_hidden hide-xs" onClick={() => handleToEvent(listEvent.livestream.id)}>{listEvent.livestream.title}</h5>

        <span className="timeLivestream">{moment.unix(listEvent.livestream.start_time / 1000).format('MM/DD HH:mm')} ~</span>
        {listEvent.livestream.user_id !== userLoginId && <button
          onClick={() => handleToEvent(listEvent.livestream.id)}
          className="live notification"
        >
          チケット購入
        </button>}
        <div className="mobile_video_info d-flex justify-content-between align-items-center content__margin-top mobile__category_infor">
          <div className="avatar">
            <img src={listEvent.livestream.user && listEvent.livestream.user.avatar} 
              onError={(event) => event.target.src = '/assets/images/avatar.png'}
              alt="" />
            <span>{listEvent.livestream.user.name}</span>
          </div>
          <div className="video_title video_title_cus"> {listEvent.livestream.title}</div>
        </div>
      </div>
    }
  }

  return (
    <div className="container_event">
      <section
        className="event__category"
      >
        <div className="event__category_slide">
          <button className="btn btn__event_prev" onClick={() => handleClickPrev()}>
            <img src="/assets/images/Left.png" alt="" />
          </button>
          <ul className="event__category_slide_span">
            {categories && categories.length > 0 && categories.map((item, i) => {
              if (item.id === 0) {
                return <li
                  className={`btn event__category_slide_a ${displayAll ? 'active' : ''} `}
                  key={i}
                  onClick={(e) => handleClickAllCategories(e)}
                >{item.name}</li>
              } else {
                return <li
                  className={`btn event__category_slide_a ${valueActive.includes(item.id) ? 'active' : ''} `}
                  key={i}
                  onClick={(e) => handleClickActive(item.id, e)}
                >{item.name}</li>
              }
            })}
          </ul>
          <button className="btn btn__event_next" onClick={() => handleClickNext()}>
            <img src="/assets/images/Right.png" alt="" />
          </button>
        </div>
        <hr className="hr__event" />
        <div className="event__category_content">
          <div className={`list__event_category_related`}>
            {listEvents && listEvents.map((listEvent, i) => {
              return renderEventCategoryDefault(listEvent, i);
            })}
          </div>
          {listEvents && listEvents.length === 0 && loadingListEvents && <div className="video_not_fount">
            <span className="video_span_not_fount">現在、カテゴリーによりイベントはございません。</span>
          </div>}
          {!loadingListEvents && <Loader />}
        </div>
        <div className="pl-4 pr-4 pb-4 event__category_load_more">
          {displayLoadMore && <button className="btn btn_event_load_more" onClick={(e) => handleLoadMoreEvents(e)}>
            もっと見る<i className="fas fa-chevron-right"></i>
          </button>}
        </div>
      </section>
    </div>
  );
}
