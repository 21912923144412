import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Footer } from '../../_components/Footer/Footer';
import { EventEdit } from '../../_components/EventDetail/EventEdit/EventEdit';
import { Header } from '../../_components/Header';

function EventEditPage() {
  const eventId = useParams().eventId;

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  return (
    <>
      <Header />
      <div className="home__page_live">
        <EventEdit eventId={eventId} />
        <Footer />
      </div>
    </>
  );
};

export { EventEditPage };
