import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../_actions";
import "../Ticket/ticket.css";
import "./user_watched.css";

export function UserWatched(props) {
  let selectListUsers = useSelector(state => state.listUsers ? state.listUsers.listUsersWatched : []);
  const dispatch = useDispatch();
  const [listUsers, setListUsers] = useState();
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userIds, setUserIds] = useState([]);
  const [listTagUser, setListTagUser] = useState([]);
  const dropdownRef = useRef(null);
  const refHeightTag = useRef(null)
  const page = 1;
  const limit = 10;

  // click away listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => document.removeEventListener("mousedown", handleClick, false);
  }, []);

  // useEffect(() => {
    // if (props.arrayUsersChoised && props.arrayUsersChoised.length) {
    //   let userChoisee = []

    //   setListTagUser(props.arrayUsersChoised)

    //   props.arrayUsersChoised.map(userId => {
    //     return userChoisee.push(parseInt(userId.id));
    //   });
    //   setUserIds(userChoisee);
    // }
  // }, [props.arrayUsersChoised]);

  useEffect(() => {
    if (props.passListTagUser) {
      setListTagUser(props.passListTagUser)
    }
  }, [props.passListTagUser]);

  useEffect(() => {
    if (searchValue) {
      const getEvents = async () => {
        await dispatch(userActions.getListUserWatched(page, limit, searchValue.trim()));
      }
      getEvents();
    }
  }, [searchValue]);

  useEffect(() => {
    if (selectListUsers) {
      setListUsers(selectListUsers);
    }
  }, [selectListUsers]);

  useEffect(() => {
    if (userIds) {
      setListUsers(selectListUsers)
    }
  }, [userIds]);

  const handleClick = (e) => {
    if (dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  const handleButtonClick = () => {
    setVisible(true);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setListUsers('');
    }
    setSearchValue(value);
    if (!visible) {
      setVisible(true);
    }
  };

  const selectItem = (e) => {
    const checked = e.target.checked;
    const index = e.target.value;
    if (checked) {
      setUserIds([...userIds, parseInt(index)]);

      const user = listUsers.filter((user) => {
        return user.id === parseInt(index)
      })
      const option = {
        id: user && user[0].id,
        name: user && user[0].name
      }
      setListTagUser([...listTagUser, option])
      props.handleCheckboxUserWatched([...userIds, parseInt(index)], [...listTagUser, option])
    } else {
      const filteredIsChecked = userIds.filter(userId => {
        return parseInt(userId) !== parseInt(index);
      });
      setUserIds(filteredIsChecked);

      const filterUser = listTagUser.filter(tagId => {
        return parseInt(tagId.id) !== parseInt(index);
      });
      setListTagUser(filterUser)

      props.handleCheckboxUserWatched(filteredIsChecked, filterUser)
    }
  };

  const handleClickIconClose = (id) => {
    setListUsers('')
    const filteredIsChecked = userIds.filter(userId => {
      return parseInt(userId) !== parseInt(id);
    });
    setUserIds(filteredIsChecked);

    const filterUser = listTagUser.filter(tagId => {
      return parseInt(tagId.id) !== parseInt(id);
    });
    setListTagUser(filterUser)

    props.handleCheckboxUserWatched(filteredIsChecked)
  }

  return (
    <>
      <div className="container_user_watched">
        <div tabIndex="0"
          className={`input_container input_container_user ${props.submitted && listTagUser && !listTagUser.length ? ' schedule-category_invalid' : ''}`}>
          <input
            className="input__search"
            type="text"
            placeholder="テストユーザーを検索して追加"
            value={searchValue}
            onChange={handleChange}
            onFocus={() => {
              setVisible(true);
            }}
          />
          <button className="btn button__search" onClick={handleButtonClick}><i className='fas fa-search'></i></button>
        </div>
        <div
          ref={dropdownRef}
          className={`dropdown__search dropdown_position dropdown__search_user ${visible ? "v" : ""}`}
        >
          <ul className="dropdown__search__ul">
            {listUsers && listUsers.length &&
              listUsers.map((user , idx) => (
                <li
                  key={idx}
                  className="dropdown_item dispay__dropdown no__cursor"
                >
                  <label className="container-checkbox user__watch_checkbox">
                    <input
                      type="checkbox"
                      className="schedule-checkbox"
                      name={user.name}
                      value={user.id}
                      onChange={selectItem}
                      defaultChecked={userIds.find(userId => parseInt(userId) === parseInt(user.id))}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <span className="dispay__dropdown_image">
                    <img src={`${user.avatar}`} onError={(event) => event.target.src = '/assets/images/avatar.png'} />
                  </span>
                  <span>
                    <div className="item_text1">{user.name}</div>
                    <div className="item_text2">{user.email}</div>
                  </span>
                </li>
              )) ||
              <li key="zxc" className="dropdown_item cl__no_result">検索結果はございません。</li>
            }
          </ul>
        </div>
      </div>
      {listTagUser && listTagUser.length && <div ref={refHeightTag} className={`schedule-category box__lists_user`}>
        {listTagUser.map((user, idx) =>
          <span className='tag__lists_user' key={idx}>{user.name}<i className="fa fa-times icon__close" onClick={() => handleClickIconClose(user.id)}></i></span>
        )}
      </div> || ''}
    </>
  );
}
