import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import DatePicker from "react-datepicker";
import moment from "moment";
import { ModalCommunity } from './ModalCommunity';
import { EventDetailAfterCreate } from "../EventDetailAfterCreate/EventDetailAfterCreate";
import { GiveAwayTicket } from "../EventDetailAfterCreate/GiveAwayTicket";
import { setMinutes, setHours, setSeconds } from "date-fns";
import cogoToast from 'cogo-toast';
import "react-datepicker/dist/react-datepicker.css";
import "./create_livestream_schedule.css";
import { ModalCreateScheduleSuccsess } from "../EventDetailAfterCreate/ModalCreateScheduleSuccsess";
import Linkify from 'react-linkify';
import { ModalConfirmSuccess } from "../EventDetailAfterCreate/ModalConfirmSuccess";
import { ModalConfirm } from "../EventDetailAfterCreate/ModalConfirm";
import * as JWT from 'json-web-token';
import { isValidEmail } from "../../_utils/validate";
import { livestreamActions } from "../../_actions";
import { UserWatched } from './UserWatched';
import { useWindowResize } from '../LiveStream/LiveStreamDetail/useWindowResize';

const ImgUpload = ({
  file,
  onChange,
  src,
}) => {
  return (
    <label htmlFor={`photo-upload-${file}`} className="custom-file-upload fas">
      <div className="img-wrap img-upload">
        <img htmlFor={`photo-upload-${file}`} name={file} className="img-upload-sub" src={src} alt="" />
      </div>
      <input id={`photo-upload-${file}`} type="file" accept="image/*" onChange={onChange} />
    </label>
  );
}

const handleMaxLength = (e) => {
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
}

export function CreateLivestreamSchedule() {
  let videoCategories = useSelector(state => state.videoCategories.videoCategories);
  let communities = useSelector(state => state.communities.communities);
  let draftEvent = useSelector(state => state.events.draftEventWithCreate);
  const history = useHistory();
  const { width } = useWindowResize();
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [banner, setBanner] = useState("");
  const buttonConfirmRef = useRef();
  const buttonConfirmSuccessRef = useRef();
  const emailRef = useRef();
  const titleRef = useRef();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("/assets/images/upload.png");
  const [imagePreviewUrlBanner, setImagePreviewUrlBanner] = useState("/assets/images/upload.png");
  const [date, setDate] = useState(setSeconds(new Date(), 600));
  const [startDate, setStartDate] = useState(setSeconds(new Date(), 600));
  const [selectCommunity, setSelectCommunity] = useState('配信するコミュニティを選択');
  const [communityID, setCommunityID] = useState([]);
  const [titleChar, setTitleChar] = useState(191);
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [valEmail, setValEmail] = useState(true);
  const [descriptionChar, setDescriptionChar] = useState(250);
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState(setSeconds(new Date(), 11400));
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [price, setPrice] = useState('');
  const [feeType, setFeeType] = useState(0);
  const [priceType, setPriceType] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [expiredDate, setExpiredDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [purchaseTicket, setPurchaseTicket] = useState(false);
  const [submitTicket, setSubmitTicket] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isHaveFreeTicket, setIsFreeTicket] = useState(false);
  const [isLess10Minutes, setIsLess10Minutes] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [createSchedule, setCreateSchedule] = useState([]);
  const [displayCreateSchedule, setDisplayCreateSchedule] = useState(false);
  const [displayEventDetail, setDisplayEventDetail] = useState(true);
  const [displayGiveAwayTicket, setDisplayGiveAwayTicket] = useState(true);
  const [checkQuantity, setCheckQuantity] = useState(false);
  const [formatImage, setFormatImage] = useState(false);
  const [formatImageBanner, setFormatImageBanner] = useState(false);
  const [disableTicket, setDisableTicket] = useState(true);
  const [maxExpired, setMaxExpired] = useState('');
  const [minExpired, setMinExpired] = useState(setSeconds(new Date(), 600));
  const [checkTicketFree, setCheckTicketFree] = useState(true);
  const [checkNumberTicket, setCheckNumberTicket] = useState(false);
  const [userTickets, setUserTickets] = useState([]);
  const [radioTest, setRadioTest] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [listTagUser, setListTagUser] = useState([]);

  useEffect(() => {
    const getSingedUrl = async () => {
      await dispatch(livestreamActions.singedUrl());
    }

    getSingedUrl();
    const getVideoCategories = async () => {
      await dispatch(livestreamActions.getVideoCategories());
    }

    getVideoCategories();
    const getCommunities = async () => {
      const keySearch = '';
      const page = 1;
      const limmit = 3;
      await dispatch(livestreamActions.getCommunities(keySearch, page, limmit));
    }

    getCommunities();
    if (draftEvent) {
      const creSchedule = {
        "thumbnail": draftEvent.thumbnail,
        "banner": draftEvent?.banner,
        "title": draftEvent.title,
        "description": draftEvent.description,
        "href": draftEvent.href,
        "start_time": draftEvent.start_time,
        "categories": draftEvent.categories.map(category => category.id),
        "tickets": draftEvent.tickets,
        "user_tickets": [],
        "draft_id": draftEvent.id,
        "email": draftEvent.email,
        // "is_test": radioTest,
        // "user_ids" : userIds
      }
      setCreateSchedule(creSchedule);
      setImagePreviewUrl(draftEvent.thumbnail);
      setImagePreviewUrlBanner(draftEvent.banner)
      setCategories(draftEvent.categories.map(category => category.id));

      setTitle(draftEvent.title);
      setTitleChar(191 - draftEvent.title.length);
      setDescription(draftEvent.description);
      setDescriptionChar(250 - draftEvent.description.length);
      setEmail(draftEvent.email);

      const timeNow = new Date().getTime();
      const draftTickets = draftEvent.tickets.filter((ticket) => {
        return ticket.expired_date > timeNow
      })
      if (draftTickets.length) {
        setPurchaseTicket(true);
      }
      setTickets(draftTickets);
      draftEvent.href.map(comm => {
        const handleCommunities = communities && communities.filter(community => {
          return parseInt(community.id) === parseInt(comm);
        });
        if (handleCommunities) {
          setSelectCommunity(handleCommunities[0].title);
          setCommunityID([handleCommunities[0].id])
        }
      })
      setDate(draftEvent.start_time);
      setStartDate(draftEvent.start_time);

    }
  }, []);

  useEffect(() => {
    const changeStartDate = `${moment(date).format('YYYY-MM-DD')} ${moment(startDate).format('HH:mm')}`
    setStartTime(moment(changeStartDate).valueOf());
    setEndTime(setSeconds(new Date(moment(changeStartDate).valueOf()), 600))
    setMaxExpired(setSeconds(new Date(moment(changeStartDate).valueOf()), 600));
    setMinExpired(setSeconds(new Date(), 600));
  }, [date, startDate])

  useEffect(() => {
    setExpiredDate(moment(endTime).valueOf());
  }, [endTime])

  const handleSelectCommunity = data => {
    const handleCommunities = communities.find(community => {
      return parseInt(community.id) === parseInt(data);
    });
    if (handleCommunities) {
      setSelectCommunity(handleCommunities.title);
      setCommunityID([handleCommunities.id])
    } else {
      setSelectCommunity(data);
      setCommunityID([])
    }
  }

  const handleClickBack = () => {
    history.goBack();
  }

  const handleClickConfirm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let minTime = moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm');
    minTime = moment(minTime).valueOf();

    if (minTime > startTime) {
      setIsLess10Minutes(true);
    } else {
      setIsLess10Minutes(false);
    }

    if (!title.trim()) {
      return titleRef.current.focus()
    }

    if (!email || !valEmail) {
      return emailRef.current.focus()
    }

    if (radioTest && !userIds.length) {
      return;
    }

    if ((file || createSchedule.thumbnail) && (banner || createSchedule.banner) && title.trim() && communityID && categories.length
      && startTime >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() && startTime >= minTime && email) {
      const newTime = new Date().getTime();
      if (startTime < newTime) {
        cogoToast.error("配信スケジュールの開始時間は必ず、将来時間を設定ください。", {
          heading: 'エラー',
          position: 'top-center',
          hideAfter: 3,
          bar: { size: '10px' }
        });
        return;
      }
      const creSchedule = {
        "thumbnail": file ? file : createSchedule.thumbnail,
        "banner": banner ? banner : createSchedule.banner,
        "title": title,
        "description": description,
        "href": communityID,
        "start_time": startTime,
        "categories": categories,
        "tickets": tickets,
        "user_tickets": userTickets,
        "draft_id": createSchedule ? createSchedule.draft_id : "",
        "is_upload": !!file,
        "event_date": moment.unix(startTime / 1000).format('YYYY-MM-DD'),
        "email": email,
        "is_test": radioTest,
        "user_ids" : radioTest ? userIds : [],
        "is_still_create": false
      }
      console.log('creSchedule', creSchedule);
      tickets.map(ticket => {
        if (+ticket.price === 0) {
          setIsFreeTicket(true);
        }
      });
      setCreateSchedule(creSchedule);
      setDisplayCreateSchedule(true);
      setDisplayEventDetail(false);
      setDisplayGiveAwayTicket(true);
    }
  }

  const photoUpload = (e, typeImg) => {
    e.preventDefault();
    setFormatImage(false);
    const reader = new FileReader();
    const fileTarget = e.target.files[0];

    if (fileTarget && !fileTarget.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      setSubmitted(false);
      if (typeImg === 'file') {
        setFormatImage(true);
        setFile("");
        setImagePreviewUrl("/assets/images/upload.png");
      } else {
        setFormatImageBanner(true)
        setBanner("")
        setImagePreviewUrlBanner("/assets/images/upload.png")
      }
      return false;
    }

    if (fileTarget) {
      reader.onloadend = () => {
        if (typeImg === 'file') {
          setFile(fileTarget);
          setImagePreviewUrl(reader.result);
        } else {
          setBanner(fileTarget)
          setImagePreviewUrlBanner(reader.result);
        }
      }
      reader.readAsDataURL(fileTarget);
    }
  }

  const handleSubmitTicket = async () => {
    if (checkQuantity) {
      return;
    }
    setSubmitTicket(true);
    if (name && price !== "" && quantity && endTime >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()) {
      buttonConfirmRef.current.click();
      setDisabled(false);
      setCheckTicketFree(true);
    }
  }

  const handleConfirm = () => {
    if (name.trim() && price !== "" && quantity && endTime >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()) {
      const timeDay = new Date().getTime();
      const hash = JWT.encode('n6Vk31A5hRKKBajCDPaV', {
        timestamp: new Date().getTime(),
        expiredDate
      });
      const ticket = {
        "name": name,
        "price": price,
        "description": ticketDescription,
        "expired_date": expiredDate,
        "fee": 15,
        "fee_type": feeType,
        "quantity": quantity,
        "hash": hash.value
      }

      setPurchaseTicket(true);
      setTickets([...tickets, ticket])

      buttonConfirmSuccessRef.current.click();

      setSubmitTicket(false);
      setName('');
      setTicketDescription('');
      setPrice('');
      setPriceType(0);
      setFeeType(0);
      setQuantity('');
      setEndTime(setSeconds(new Date(moment(`${moment(date).format('YYYY-MM-DD')} ${moment(startDate).format('HH:mm')}`).valueOf()), 600));
      if (+price === 0) {
        setIsFreeTicket(true);
      }
    }
  }

  const handleChangeCheckbox = (e) => {
    const checked = e.target.checked;
    const index = e.target.value;
    if (checked) {
      setCategories([...categories, parseInt(index)]);
    } else {
      const filteredIsChecked = categories.filter(checked => {
        return parseInt(checked) !== parseInt(index);
      });
      setCategories(filteredIsChecked);
    }
  }

  const handleChangeTitle = (e) => {
    let { value } = e.target;
    if (191 - value.length < 0) {
      const lengthSub = Math.abs(191 - value.length);
      const newtitle = value.substr(0, value.length - lengthSub);
      setTitle(newtitle);
      setTitleChar(0);
    } else {
      setTitleChar(191 - value.length);
      setTitle(value);
    }

  }

  const handleChangeEmail = (e) => {
    let { value } = e.target;
    const formatEmail = isValidEmail(value);
    setValEmail(formatEmail)
    setEmail(value);
  }

  const handleChangeDescription = async (e) => {
    const { value } = e.target;
    if (250 - value.length < 0) {
      const lengthSub = Math.abs(250 - value.length);
      const newDescription = value.substr(0, value.length - lengthSub);

      await setDescription(newDescription);
      await setDescriptionChar(0)
    } else {
      await setDescription(value);
      await setDescriptionChar(250 - value.length)
    }

  }

  const handleChangeQuantity = (e) => {
    const { value } = e.target;
    if (parseInt(value) > 10000) {
      setCheckNumberTicket(true);
    } else {
      setCheckNumberTicket(false);
    }
    if (e.target.validity.valid) {
      if (value.length === 1) {
        const arrayNumber = Array.from(value);
        if (parseInt(arrayNumber[0]) === 0) {
          // const valueSub = value.substring(1);
          // return setQuantity(valueSub);
          return;
        }
      }
      setQuantity(value);
    }
  }

  const handleChangePrice = (e) => {
    const { value } = e.target;
    if (parseInt(value) < 700) {
      setCheckQuantity(true);
    } else {
      setCheckQuantity(false);
    }
    if (e.target.validity.valid) {
      if (value.length === 2) {
        const arrayNumber = Array.from(value);
        if (parseInt(arrayNumber[0]) === 0) {
          const valueSub = value.substring(1);
          return setPrice(valueSub);
        }
      }
      setPrice(value);
    }
  }

  const handleBackLivestreamSchedule = () => {
    setImagePreviewUrl(imagePreviewUrl);
    setImagePreviewUrlBanner(imagePreviewUrlBanner);
    setDisplayCreateSchedule(false);
    setDisplayEventDetail(true);
    setDisplayGiveAwayTicket(true);
  }

  const handleClickFreeTickets = (afterCreateSchedule) => {
    setCreateSchedule(afterCreateSchedule);
    setDisplayCreateSchedule(true);
    setDisplayEventDetail(true);
    setDisplayGiveAwayTicket(false);
  }

  const handleBackEventDetail = () => {
    setImagePreviewUrl(imagePreviewUrl);
    setImagePreviewUrlBanner(imagePreviewUrlBanner);
    setDisplayCreateSchedule(true);
    setDisplayEventDetail(false);
    setDisplayGiveAwayTicket(true);
  }

  const handleClickSelectPrice = (e) => {
    e.preventDefault();
    setPriceType(e.target.value);
    setDisabled(!disabled);
    if (e.target.value === '0') {
      setPrice('');
      setCheckTicketFree(true);
    } else {
      setCheckQuantity(false);
      setCheckTicketFree(false);
      setPrice(0);
    }
  }

  const handleDisableSubmitTicket = (e) => {
    e.preventDefault();
    setDisableTicket(!disableTicket);
    setTickets([]);
    setPurchaseTicket(false);
  }

  const handleSaveUserTickets = (data) => {
    setUserTickets(data);
  }

  const handleChangeRadioTest = (e) => {
    const valuesTest = e.target.value
    if (valuesTest === "true") {
      setRadioTest(true)
    } else {
      setRadioTest(false)
    }
  }

  const handleCheckboxUserWatched = (listUserIds, listTagUsers) => {
    setUserIds(listUserIds)
    setListTagUser(listTagUsers)
  }

  return (
    <>
      <section className={`${displayCreateSchedule ? 'display__none' : 'container livestream-schedule-content'}`}>
        <span className="livestream-schedule-title">配信スケジュール</span>
        <div className="row">
          {/* <div className="col-lg-10 offset-lg-1 px-3 mb-5"> */}
          <div className="col-lg-6 px-3 mb-5">
            <div className="schedule_box_width_60 margin-auto">
              <div className="form-group schedule-upload-image">
                <span className="schedule-livestream-title">
                  配信のテーマ画像を選択しよう！
                </span>
              </div>
              <div className="form-group schedule-upload-image">
                <ImgUpload file="file" onChange={(e) => photoUpload(e, "file")} src={imagePreviewUrl} />
              </div>
              {submitted && !file && !createSchedule.thumbnail &&
                <div className="invalid__feedback">画像は、必ず指定してください。</div>
              }
              {!submitted && formatImage &&
                <div className="invalid__feedback">利用可能な画像ファイル形式は jpg, png, jpeg, gif の４つです。</div>
              }

              <div className="form-group schedule-upload-image">
                <span className="schedule-livestream-title">
                バナー
                </span>
              </div>
              <div className="form-group schedule-upload-image">
                <ImgUpload file="banner" onChange={(e) => photoUpload(e, "banner")} src={imagePreviewUrlBanner} />
              </div>
              {submitted && !banner && !createSchedule.banner &&
                <div className="invalid__feedback">画像は、必ず指定してください。</div>
              }
              {!submitted && formatImageBanner &&
                <div className="invalid__feedback">利用可能な画像ファイル形式は jpg, png, jpeg, gif の４つです。</div>
              }

              <div className="form-group schedule-font-size">
                <span className="schedule-label">
                  <label htmlFor="community">ライブタイトルを入力</label>
                  <span className="characters_left">残り{titleChar}文字</span>
                </span>
                <input
                  ref={titleRef}
                  autoComplete="off"
                  type="text"
                  name="title"
                  className={`form-control input-schedule ${submitted && !title.trim() ? ' is-invalid' : ''}`}
                  value={title}
                  placeholder="TAQNOの部屋"
                  onChange={handleChangeTitle}
                />
              </div>
              {submitted && !title.trim() &&
                <div className="invalid__feedback">ライブタイトルを入力は、必ず指定してください。</div>
              }

              <div className="form-group schedule-font-size">
                <span className="schedule-label">
                  <label htmlFor="description">ライブの説明を入力</label>
                  <span className="characters_left">残り{descriptionChar}文字</span>
                </span>
                <textarea
                  autoComplete="off"
                  name="description"
                  value={description}
                  className={`form-control input-schedule`}
                  rows={7}
                  // onKeyUp={handleChangeDescription}
                  onChange={handleChangeDescription}
                  placeholder="コミュニティでやっていくこと&#13;&#10;
                                ・会員だけの特別な情報公開&#13;&#10;
                                ・ライブ情報の先行公開&#13;&#10;
                                ・日記の公開"
                />
              </div>

              <div className="form-group schedule-font-size">
                <span className="schedule-label">
                  <label htmlFor="community">メールアドレス</label>
                </span>
                <input
                  ref={emailRef}
                  autoComplete="off"
                  type="email"
                  name="email"
                  className={`form-control input-schedule ${((submitted && !email) || (submitted && !valEmail)) ? ' is-invalid' : ''}`}
                  value={email}
                  placeholder="live@dreamer.com"
                  onChange={handleChangeEmail}
                />
              </div>
              {submitted && !email &&
                <div className="invalid__feedback">メールアドレスは、必ず指定してください。</div>
              }
              {submitted && !valEmail && email !== "" &&
                <div className="invalid__feedback">メールアドレスは間違っています。</div>
              }

              <div className="form-group schedule-font-size">
                <p className="livestream-schedule-text">コミュニティーをご選択お願いします<br />有料コミュニティー内のみ限定で配信希望の方は選択</p>
                <button
                  className={`btn form-control btn-input-schedule`}
                  data-toggle="modal"
                  data-target="#modalCommunity"
                >{selectCommunity}</button>
              </div>

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="">開始時間</label>
                <div className="schedule-date-time">
                  <span className="schedule-date">
                    <DatePicker
                      locale="ja"
                      selected={date}
                      onChange={date => {
                        return setDate(new Date(moment(date).format('YYYY-MM-DD')))
                      }}
                      dateFormat="yyyy-MM-dd"
                      className={`form-control input-schedule ${submitted && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                      minDate={new Date()}
                    // showDisabledMonthNavigation
                    />
                    <img src={'/assets/icons/calendar_schedule.png'} className="schedule-date-img"
                      alt="" />
                  </span>
                  <span className="schedule-time">
                    <DatePicker
                      locale="ja"
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      minDate={new Date()}
                      minTime={setSeconds(new Date(moment(date).valueOf()), -10800) > new Date() ? setHours(setMinutes(startDate, 0), 0) : minExpired}
                      maxTime={startDate > new Date() ? setHours(setMinutes(startDate, 59), 23) : setHours(setMinutes(new Date(), 59), 23)}
                      timeCaption="時間"
                      dateFormat="HH:mm"
                      className={`form-control input-schedule ${submitted && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                    />
                    <img src={'/assets/icons/hour_schedule.png'} className="schedule-date-img" alt="" />
                  </span>
                </div>
              </div>
              {submitted && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() &&
                <div className="invalid__feedback">配信スケジュールの開始時間は必ず、将来時間を指定ください。</div>
              }
              {submitted && isLess10Minutes &&
                <div className="invalid__feedback">配信開始時間は、現在の時間より10分後から設定できます</div>
              }

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="">カテゴリー選択</label>
                <div
                  className={`schedule-category ${submitted && !categories.length ? ' schedule-category_invalid' : ''}`}>
                  {videoCategories && videoCategories.map((item, i) => {
                    return <div className="schedule-category__item" key={i}>
                      <label htmlFor={item.name}>{item.name}</label>
                      <label className="container-checkbox" onChange={handleChangeCheckbox}>
                        <input
                          type="checkbox"
                          className="schedule-checkbox"
                          name={item.name}
                          value={item.id}
                          defaultChecked={categories.find(category => category === item.id)}
                        // checked={categories.find(category => category === item.id) ? 'checked' : ''}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  })}
                </div>
              </div>
              {submitted && !categories.length &&
                <div className="invalid__feedback">カテゴリー選択は、必ず指定してください。</div>
              }

              <div className="form-group schedule-font-size">
                <label className="livestream-schedule-radio" htmlFor="">配信タイプを選ぶ</label>
                <div className="buy__ticket__radio box-livestream__radio">
                  <input
                    type="radio"
                    value={false}
                    name="readioTest"
                    id="livestrean"
                    defaultChecked={true}
                    onChange={handleChangeRadioTest}
                  />
                  <label className="create-livestream__radio" htmlFor="livestrean">本番</label>
                  <input
                    type="radio"
                    value={true}
                    name="readioTest"
                    id="test"
                    onChange={handleChangeRadioTest}
                  />
                  <label htmlFor="test">リハーサル</label>
                </div>

                { radioTest && <UserWatched submitted={submitted}
                  handleCheckboxUserWatched={handleCheckboxUserWatched}
                  arrayUsersChoised={[]}
                  passListTagUser={listTagUser} />}
                {submitted && radioTest && !userIds.length &&
                  <div className="invalid__feedback">テストユーザーを追加してください！</div>
                }
              </div>

              {width > 1024 && <div style={{ display: disableTicket ? "none" : "block" }}>
                <div className="form-group schedule_btn_return_confirm" >
                  <button className="btn schedule__but_return" onClick={handleClickBack}>戻る</button>
                  <button className="btn schedule__but_next" onClick={handleClickConfirm}>確認</button>
                </div>

                <div className="form-group schedule-font-size schedule_note">
                  <span className="schedule_note_2">※ 配信スケジュールは10件まで作成できます。</span>
                  <span>※ 未公開の配信スケジュールは10件まで保存できます。</span>
                </div>
              </div>}

              <div className="form-group schedule-font-size disable_ticket hide-xs">
                <button
                  className="btn btn_disable_ticket"
                  onClick={handleDisableSubmitTicket}
                >{disableTicket ? "チケットなしで無料配信" : "チケット作成"}</button>
              </div>
              

              {/* <div className="form-group schedule_btn_return_confirm">
                <button className="btn schedule__but_return" onClick={handleClickBack}>戻る</button>
                <button className="btn schedule__but_next" onClick={handleClickConfirm}>確認</button>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 px-3 mb-5">
            <div className="schedule_box_width_60 margin-auto">
              <div className={`form-group schedule-font-size ${disableTicket ? "" : "display_ticket_mobile"}`}>
                <span className="ticket-title"
                  style={disableTicket ? { display: 'unset' } : { display: 'none' }}>
                  チケット作成
                </span>
                <button
                  className="btn btn_disable_ticket display-xs"
                  onClick={handleDisableSubmitTicket}
                >{disableTicket ? "チケットなしで無料配信" : "チケット作成"}</button>
              </div>

              <div style={disableTicket ? { display: 'unset' } : { display: 'none' }}>
                <div className="form-group schedule-font-size">
                  <label className="schedule-label_custom" htmlFor="name">チケット名</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    value={name}
                    className={`form-control input-schedule ${submitTicket && !name.trim() ? ' is-invalid' : ''}`}
                    placeholder="VIP"
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                {submitTicket && !name.trim() &&
                  <div className="invalid__feedback">チケット名は、必ず指定してください。</div>
                }

                <div className="form-group schedule-font-size">
                  <label className="schedule-label_custom" htmlFor="ticketDescription">説明</label>
                  <textarea
                    autoComplete="off"
                    name="ticketDescription"
                    className={`form-control input-schedule`}
                    value={ticketDescription}
                    rows={5}
                    placeholder="説明"
                    onChange={e => setTicketDescription(e.target.value)}
                  />
                </div>

                <div className="form-group schedule-font-size input__select_price">
                  <label className="schedule-label_custom" htmlFor="price">価格 (JPY)</label>
                  <div className="price_box_cus">
                    <div className="price_cus">
                      <select
                        id="schedule-input-select_price"
                        name="fee"
                        className="schedule-select-options_price"
                        value={priceType}
                        onChange={e => handleClickSelectPrice(e)}>
                        <option className="schedule-options_list-item" value="0">有料</option>
                        <option className="schedule-options_list-item" value="1">無料</option>
                      </select>
                      <span className="schedule_caret_price" />
                    </div>

                    <div className="price_cus">
                      <input
                        autoComplete="off"
                        type="tel"
                        name="price"
                        className={`form-control input-schedule ${submitTicket && price === "" ? ' is-invalid' : ''}`}
                        value={price}
                        placeholder="700"
                        onChange={handleChangePrice}
                        pattern="[0-9]*"
                        maxLength={15}
                        onInput={handleMaxLength}
                        disabled={disabled}
                      />
                    </div>
                  </div>


                </div>
                {submitTicket && price === "" &&
                  <div className="invalid__feedback">価格は、必ず指定してください。</div>
                }

                {checkQuantity &&
                  <div className="invalid__feedback">価格は700円以上で設定してください</div>
                }

                {checkTicketFree
                  ? <div className="schedule-font-size input__select schedule_input__select">
                    <label className="schedule-label_custom" htmlFor="fee_type">手数料(15%)負担</label>
                    <select
                      id="schedule-input-select"
                      name="fee"
                      className="schedule-select-options"
                      value={feeType}
                      onChange={e => setFeeType(e.target.value)}>
                      <option className="schedule-options_list-item" value="0">購入者</option>
                      <option className="schedule-options_list-item" value="1">販売者</option>
                    </select>
                    <span className="schedule_caret" />
                  </div>
                  : <div className="form-group schedule-font-size no_fees">
                    <span className="schedule-label">
                      <label htmlFor="community">手数料(15%)負担</label>
                    </span>
                    <button className={`btn form-control btn-input-schedule not__cursor`}>手数料は発生しません</button>
                  </div>}

                <div className="form-group schedule-font-size">
                  <label className="schedule-label_custom" htmlFor="quantity">枚数 (枚)</label>
                  <input
                    autoComplete="off"
                    type="tel"
                    name="quantity"
                    className={`form-control input-schedule ${submitTicket && !quantity ? ' is-invalid' : ''}`}
                    value={quantity}
                    placeholder="10"
                    onChange={handleChangeQuantity}
                    pattern="[0-9]*"
                    maxLength={5}
                    onInput={handleMaxLength}
                  />
                </div>
                {submitTicket && !quantity &&
                  <div className="invalid__feedback">枚数は、必ず指定してください。</div>
                }
                {checkNumberTicket &&
                  <div className="invalid__feedback">チケットの枚数は10000枚以下に設定してください</div>
                }

                <div className="form-group schedule-font-size">
                  <label className="schedule-label_custom" htmlFor="expired_date">販売終了</label>
                  <div className="schedule-date-time schedule-date__sale expired-date">
                    <DatePicker
                      locale="ja"
                      selected={endTime}
                      onChange={date => setEndTime(date)}
                      maxDate={setSeconds(new Date(moment(maxExpired).valueOf()), -600) > new Date() ? new Date(moment(maxExpired).valueOf()) : new Date()}
                      showTimeSelect
                      timeIntervals={1}
                      timeCaption="time"
                      minTime={setHours(setMinutes(endTime, 0), 0)}
                      maxTime={endTime < new Date()
                        ? setHours(setMinutes(endTime, 59), 23)
                        : (endTime < maxExpired
                          ? (new Date(moment(maxExpired).subtract(1, 'days').endOf('days').valueOf()) < endTime
                            ? setSeconds(new Date(maxExpired), 0)
                            : setHours(setMinutes(endTime, 59), 23))
                          : setSeconds(new Date(maxExpired), 0))}
                      timeCaption="時間"
                      dateFormat="yyyy-MM-dd HH:mm"
                      className={`form-control input-schedule input__datime ${submitTicket && endTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                    />
                    <img src={'/assets/icons/calendar_schedule.png'} className="schedule-date-img" alt="" />
                  </div>
                </div>
                {submitTicket && endTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() &&
                  <div className="invalid__feedback">チケットの販売終了日時は必ず将来の時間を指定してください。</div>
                }

                <div className="form-group schedule-btn_add_ticket">
                  <button
                    className="btn schedule__but_next"
                    onClick={handleSubmitTicket}
                  >チケット作成
                  </button>
                  <button
                    ref={buttonConfirmRef}
                    data-toggle="modal"
                    data-target="#modalConfirm"
                    hidden="hidden"
                  >チケット作成
                  </button>
                  <button
                    ref={buttonConfirmSuccessRef}
                    data-toggle="modal"
                    data-target="#modalConfirmSuccess"
                    hidden="hidden"
                  >チケット作成
                  </button>
                </div>
                <div className="form-group schedule-font-size schedule_note">
                  <span>※ ¥0 のチケットはスケジュール公開前にフォロー中/フォロワーアカウントへ配布可能です。</span>
                </div>

                <div className={`form-group schedule-create-ticket ${!purchaseTicket ? 'buy__ticket_disable' : ''}`}>
                  <span>作成したチケット</span>
                </div>

                <div
                  className={`buy__ticket ${!purchaseTicket ? 'buy__ticket_disable' : ''} ${tickets && tickets.length > 6 ? 'length_tickets' : ''}`}>
                  {tickets ? tickets.map((ticket, i) => {
                    return (
                      <div className={`apply_purchase__ticket ${(i === tickets.length - 1) ? "bd_line_none" : ""}`}
                        key={i}>
                        <span className="apply_purchase_content">
                          <div className="added-ticket-name added-ticket-name__cus">
                            <img className="ticket-icon" src={'/assets/icons/title.png'} alt="" />
                            <span className="ticket-value">{ticket.name}</span>
                          </div>
                          <div className="added-ticket-description">
                            <img className="ticket-icon ticket-icon_description"
                              src={'/assets/icons/information.png'} alt="" />
                            <span
                              className={`ticket-value ${ticket.price === 0 && communityID.length ? "ticket_text_span" : ""}`}>
                              {ticket.price === 0 && communityID.length &&
                                <span className="ticket_text_community">コミュニティ限定</span> || ''}
                              <span className="ticket__description"><Linkify
                                properties={{ target: '_blank' }}>{ticket.description}</Linkify></span>
                            </span>
                          </div>
                          <div className="ticket__content content_3 ticket__content_custom">
                            <div className="added-ticket-price content_3_price">
                              <img className="ticket-icon" src={'/assets/icons/money.png'}
                                alt="" />
                              <span className="ticket-value">￥{ticket.price}</span>
                            </div>
                            <div className="added-ticket-expired content_3_calendar">
                              <img className="ticket-icon" src={'/assets/icons/calendar.png'}
                                alt="" />
                              <span
                                className="ticket-value">販売終了: {moment(new Date(ticket.expired_date)).format('YYYY/MM/DD HH:mm')}</span>
                            </div>
                            <div className="added-ticket-quantity content_3_ticket">
                              <img className="ticket-icon" src={'/assets/icons/ticket.png'}
                                alt="" />
                              <span
                                className="ticket-value">残り{ticket.quantity && ticket.quantity.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}枚</span>
                            </div>
                          </div>
                        </span>
                      </div>)
                  }) : null}
                </div>
              </div>
              
              <div style={{ display: width > 1024 ? (disableTicket ? "block" : "none") : "block" }}>
                <div className="form-group schedule_btn_return_confirm">
                  <button className="btn schedule__but_return" onClick={handleClickBack}>戻る</button>
                  <button className="btn schedule__but_next" onClick={handleClickConfirm}>確認</button>
                </div>

                <div className="form-group schedule-font-size schedule_note">
                  <span className="schedule_note_2">※ 配信スケジュールは10件まで作成できます。</span>
                  <span>※ 未公開の配信スケジュールは10件まで保存できます。</span>
                </div>
              </div>
                
            </div>
          </div>
        </div>
        <ModalCommunity selectCommunity={handleSelectCommunity} communityID={communityID} />
      </section>

      <section className={`${displayEventDetail ? 'display__none' : ''}`}>
        <EventDetailAfterCreate
          handleBack={handleBackLivestreamSchedule}
          handleClickFreeTickets={handleClickFreeTickets}
          createSchedule={createSchedule}
          // imagePreviewUrl={imagePreviewUrl}
          imagePreviewUrlBanner={imagePreviewUrlBanner}
          isFreeTicket={isHaveFreeTicket}
          isTest={radioTest}
          listTagUser={listTagUser}
        />
      </section>

      <section className={`${displayGiveAwayTicket ? 'display__none' : ''}`}>
        <GiveAwayTicket
          handleBackEventDetail={handleBackEventDetail}
          createSchedule={createSchedule}
          // imagePreviewUrl={imagePreviewUrl}
          imagePreviewUrlBanner={imagePreviewUrlBanner}
          eventId={0}
          editEvenst={false}
          saveUserTickets={handleSaveUserTickets}
        />
      </section>
      <ModalCreateScheduleSuccsess />
      <ModalConfirm handleConfirm={handleConfirm} />
      <ModalConfirmSuccess />
    </>
  );
}
