import React from "react";
import * as moment from "moment";
import { history } from "../../../../_helpers";

export function Step3(props) {

  function handleGoBack() {
    history.push(`/events/${props.event.livestream.id}`);
  }
  return (
    <section className="event-content">
      <div className="row">
        <div className="col-lg-8 col-md-8 offset-lg-2 px-3 mb-5">
          <div className="buy__ticket_payment">
            <div className="apply_purchase buy__ticket_width_60 payment__padding_top_1">クレジットカード決済が完了しました。</div>
            <div className="apply_purchase__buy__ticket buy__ticket_width_60">
              <span className="buy__ticket_img_success">
                <img src={'/assets/icons/success_finish.png'} alt="" />
              </span>
              <span className="buy__ticket_img_success ticket_success_font">
                ご決済ありがとうございます。以下の決済情報を確認してくだい。
              </span>
              <span className="buy__ticket_sp margin__top_2">
                <span style={{ fontWeight: "bold" }}>商品名</span>
                <span style={{ fontWeight: "bold" }}>お支払い内容</span>
              </span>
              <span className="buy__ticket_sp no-bold">
                <span>{moment.unix(props.event.livestream.start_time / 1000).format('YYYY-MM-DD HH:mm')} ~</span>
                <span>チケット料金: ￥{props.ticket.fee_type === 0 ? props.ticket.price : props.ticket.price - parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
              <span className="buy__ticket_sp no-bold">
                <span>チケットNo: {props.ticket.id}</span>
                <span>手数料: ￥{parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
              <span className="buy__ticket_sp margin-bottom-1rem no-bold">
                <span>配信者: {props.event.livestream.user.name}</span>
                <span></span>
              </span>
            </div>
            <div className="buy__ticket_total buy__ticket_width_60">
              <span className="buy__ticket_sp margin-bottom-1rem">
                <span style={{ fontWeight: 'bold' }}>合計価格（税込）</span>
                <span style={{ fontWeight: 'bold' }}>￥{props.ticket.fee_type === 0 ? props.ticket.price + parseInt(props.ticket.price * props.ticket.fee / 100) : props.ticket.price}</span>
              </span>
            </div>
            <div className="event-payment-desc__but">
              <button className="btn buy__ticket_btn_success" onClick={handleGoBack}>
                イベント詳細
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}