import React, { useEffect, useState } from 'react';
import { Header } from '../../_components/Header';
import '../../_assets/css/charge-coin.css'
import './home.css'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { signInWithCustomToken, authenticationService, auth } from '../../_services';
import { userActions, authenticationActions } from '../../_actions';
import { useDispatch } from 'react-redux';

function HomePage() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname === "/") {
      document.getElementById("homepage").classList.add("homepage");
    }
  }, [])
  const handleHidden = () => {
    setHidden(true);
  }

  const code = new URLSearchParams(useLocation().search).get('code');
  if (code) {
    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('redirect_uri', process.env.REACT_APP_LINE_REDIRECT_URI)
    params.append('client_id', process.env.REACT_APP_LINE_CONSUMER_CHANNEL_ID)
    params.append('client_secret', process.env.REACT_APP_LINE_CONSUMER_SECRET)
    axios.post('https://api.line.me/oauth2/v2.1/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((result) => {
      const { data } = result;
      const dataLogin = {
        access_token: data.access_token,
        type: 'line'
      }
      authenticationService.createCustomToken(dataLogin)
        .then(async (result) => {
          if (result.data.data.token) {
            signInWithCustomToken(result.data.data.token)
              .then(async (userCredential) => {
                auth.currentUser.getIdToken(true).then(async (idToken) => {
                  await dispatch(authenticationActions.loginSocial(idToken, 'line'));
                  await dispatch(userActions.getUserProfilele());
                }).catch((err) => {
                  console.log(err);
                })
              }).catch((err) => {
                console.log(err);
              })
          }
        }).catch((err) => {
          console.log(err);
        })
    })
      .catch((err) => {
        console.log(err);
      })
  }

  return (
    <>
      <Header />
      <section className="intro" id="intro">
        <video id="video1" className="background-movie" src="/movies/RLounge.mp4" muted playsInline></video>
        <video id="video2" className="background-movie" src="/movies/MASHROOM_HIGHTLIGHT.mp4" muted playsInline></video>
        <div className="site">
          <div>
            <img className="site--logo" src='/assets/images/logo.svg' alt="" />
            <h1 className="site--title">音と人、夢とリアルが繋がる場所</h1>
          </div>
          <div className="download-buttons">
            <a id="iOS" href='https://itunes.apple.com/us/app/livedreamer/id1445831749'>
              <img className="button" src="/assets/images/ios.png" alt="" />
            </a>
            <a id="android" href='https://play.google.com/store/apps/details?id=com.live_dreamer.LiveDreamer'>
              <img className="button button-showy" src="/assets/images/android.png" alt="" />
            </a>
          </div>
        </div>
      </section>
    {/* 
      <section className="hide about" id="about">
        <h2 className="heading">ABOUT US</h2>
        <div className="wrapper hide">
          <p className="text"> LIVE DREAMERについて</p>
          <p className="text">
            自分の可能性を信じて、DREAMチケットをあなたの手に <br />
            <br />
            誰もがライブ配信をする事ができ、それを誰もが見れる
            そして、コミュニティの中でしか見れないライブ配信
            グループでのコミュニケーションができ
            ライブイベントなどで繋がれるサービスがある <br />
            <br />
            そこでのファンを見つける事ができたら <br />
            <br />
            個人がサブスプリクション型で報酬を貰うこと <br />
            <br />
            どこのサービスが演者に合うのか、可能性を探すことができる <br />
            <br />
            単純にテレビやYouTubeで有名になれば、多くの人に見られるように <br />
            <br />
            見れる環境があり、見れることを知り、見たいと思うファンが１人でもいれば <br />
            <br />
            それが１０人、１００人、１００００人以上の人に見てもらえる可能性があると思いませんか？ <br />
            <br />
            そんなLive配信時代（動画）に、全く同じサービスは存在しません <br />
            <br />
            弊社｢LIVE DREAMER｣のサービスと全く同じサービスも存在しません <br />
            <br />
            これから近い将来、 いや… もうすでに５Gという世代が来ています <br />
            <br />
            新しくなった世代では、個人と個人のやり取りがさらに加速していきます <br />
            <br />
            何度でも、試し続けてください <br />
            <br />
            あなたの未来を <br />
            <br />
          </p>
          <p className="text right">
            音と人、夢とリアルが繋がる場所 ｢LIVE DREAMER｣
          </p>
        </div>
        <div className='tutorial'>
          <p><strong>LIVE DREAMER</strong>アプリをお使いの方はコチラ</p>
          <div className="modal hide" id="tutorial-modal">
            <div className='modal-content'>
              <video src="/movies/tutorial_full.mp4" controls className="video" preload="metadata" playsInline></video>
            </div>
          </div>
          <a href="https://drive.google.com/open?id=13O4cFRGGUzib7IXfn_VwCsRwbJcMkM7w" className='link' target="_blank"
            rel="noreferrer">
            チュートリアルを見る
            <i className="icon fa fa-external-link-alt"></i>
          </a>
          <p className='link tutorial-movie-andler'>
            チュートリアル動画を見る
            <i className="icon fa fa-external-link-alt"></i>
          </p>
        </div>

        <div className="cm-movies">
          <video src="/movies/LiveDreamer.mp4" className="video" loop autoPlay muted controls playsInline></video>
        </div>
      </section>  */}

      <section className="contents">
     {/*
        <h2 className="heading">CONTENTS</h2>
    */}
        <div className="wrapper">
          <div className="box tree">
            <div className="bg-image tree description">
              <div className="bg-mask">
                <p className="text">
                  SNSメディア<br />
                  <a href="/social.html" className="button button-ghost">
                    READ MORE
                  </a>
                </p>
              </div>
            </div>
      {/*
            <div className="bg-image tree description">
              <div className="bg-mask">
                <p className="text">
                  ニュース<br />
                  <a href="https://news.live-dreamer.com" className="button button-ghost">READ MORE</a>
                </p>
              </div>
            </div>
            <img className="image reverse mobile-hidden" src="/assets/images/tree.jpg" alt="制作事例1" />
      */}
          </div>
          <div className="box sky">
            <div className="bg-image sky description">
              <div className="bg-mask">
                <p className="text">
                  会社概要<br />
                  <a href="/company.html" className="button button-ghost">READ MORE</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/*
      <section className="skills">
        <h2 className="heading">VALUE</h2>
        <div className="wrapper">
          <div className="box">
            <i className="icon fa fa-eye"></i>
            <div className="title">安心感</div>
            <p className="text">
              カスタマーサポートの徹底とユーザーとの近さ。
            </p>
          </div>
          <div className="box">
            <i className="icon fa fa-smile"></i>
            <div className="title">現実性</div>
            <p className="text">
              関係者すべての人の幸福度を最大化する。
            </p>
          </div>
          <div className="box">
            <i className="icon fa fa-music"></i>
            <div className="title">遊び心</div>
            <p className="text">
              自由な発想で運営していきます。
            </p>
          </div>
        </div>
      </section>
    */}
      <section className="contact" id="contact">
        <form className="form">
          <a type="submit"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeM6g83-mEs0bUSSwYXuzISydXMAChXIiRm6ehBKsZIewIMIA/viewform"
            id="pagetop-btn" target="_blank" rel="noreferrer">
            お問い合わせ
          </a>
        </form>
      </section>
      <footer className="footer">
        <ul className="list-container">
          <li><a href='/terms.html'>利用規約</a></li>
          <li><a href='/help.html'>よくある質問</a></li>
          <li><a href='/privacy.html'>プライバシーポリシー</a></li>
          <li><a href='/settlement.html'>資金決済法に基づく表示</a></li>
          <li><a href='/tsh.html'>特定商取引に基づく表示</a></li>
          <li><a href='/company.html'>会社概要</a></li>
        </ul>

        <div className='sns'>
          <a href="https://www.facebook.com/livedreamer2018" target="_blank" rel="noreferrer">
            <i className="icon fab fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/livedreamer_official/" target="_blank" rel="noreferrer">
            <i className="icon fab fa-instagram"></i>
          </a>
          <a href="https://twitter.com/LiveDreamerPer2?lang=ja" target="_blank" rel="noreferrer">
            <i className="icon fab fa-twitter"></i>
          </a>
        </div>
        <p>©2019 dreamer Inc.</p>
      </footer>
      <p className="pagetop pagetop_btn" id="pagetop">
        <a href="#" id="pagetop_btn">ページTOPへ</a>
      </p>
    </>
  );
};

export { HomePage };
