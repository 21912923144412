import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route {...rest} render={props => {
      if (!localStorage.getItem('token') &&
        (props.match.path === '/events/:eventId'
          || props.match.path === '/livestreams/:livestreamId')) {
        localStorage.setItem("callBack", window.location.pathname);
      }

      if (!localStorage.getItem('token') &&
        (window.location.pathname === '/my-events'
          || window.location.pathname === '/my-page'
          || props.match.path === '/user/:userId')) {
        return <Redirect to={{ pathname: '/before-login', state: { from: props.location } }} />
      }

      if (localStorage.getItem('callBack') === "null") {
        localStorage.setItem("callBack", "");
      }

      // if (!localStorage.getItem('token') && window.location.pathname === '/top') {
      //     return <Component {...props} />
      // }

      // logged in so return component
      return <Component {...props} />
    }} />
  );
}

export { PrivateRoute };