import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./event_right.css";
import { ModalBefore15Minus } from "../ModalBefore15Minus";
import { ModalAfter15Minus } from "../ModalAfter15Minus";
import Linkify from 'react-linkify';
import { eventActions } from "../../../_actions";
import { ModalTicketFree } from "./ModalTicketFree";
import { ModalTicketFreeSuccess } from "./ModalTicketFreeSuccess";
import { eventService } from "../../../_services";
import { formatMessage } from "../../../_utils/formatMessage";
import cogoToast from "cogo-toast";
import { ModalJoinCommunity } from "../ModalJoinCommunity";
import { useWindowResize } from "../../LiveStream/LiveStreamDetail/useWindowResize";
import { ModalFreeTickets } from "../ModalFreeTickets";
import { ModalExpireCommunityTickets } from "../ModalExpireCommunityTickets";

export function EventRight(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowResize();
  const buttonTicketFreeRef = useRef();
  const buttonTicketFreeSuccessRef = useRef();
  const eventsDetail = props.eventsDetail && props.eventsDetail.livestream;
  const userLogged = useSelector(state => state.login.user);
  let userLoginId = userLogged ? userLogged.id : 0;
  let communities = useSelector(state => state.events.communities);
  const [hash, setHash] = useState('');
  const [popup, setPopup] = useState(false);
  const [checkInCommunity, setCheckInCommunity] = useState(false);
  let [isBuyTicket, setIsBuyTicket] = useState(false);
  let [boughtTicket, setBoughtTicket] = useState(null);
  let [disableBuyTicket, setDisableBuyTicket] = useState(false);
  const [openPopupAfter15Minus, setOpenPopupAfter15Minus] = useState(false);

  useEffect(() => {
    setCheckInCommunity(false);
  }, []);

  useEffect(() => {
    if (userLoginId) {
      const getCommunities = async () => {
        await dispatch(eventActions.getCommunities());
      }
      getCommunities();
    }

    if (eventsDetail) {
      setIsBuyTicket(eventsDetail.is_buy_ticket);
      setBoughtTicket(eventsDetail.ticket);
    }
  }, [eventsDetail]);

  useEffect(() => {
    setCheckInCommunity(false);
    communities = communities || [];
    if (communities.length && eventsDetail) {
      const community = communities.filter(community => {
        return parseInt(community.id) === parseInt(eventsDetail.href[0]);
      });
      if (community && community.length) {
        setCheckInCommunity(true);
      }
    }

  }, [communities, eventsDetail]);

  const handleBuyTicket = (ticketId) => {
    if (!userLoginId) {
      history.push(`/before-login`);
    } else {
      history.push(`/events/${eventsDetail.id}/buy-ticket/${ticketId}`);
    }

  }

  const handleViewRoom = () => {
    const start = moment(new Date(eventsDetail && eventsDetail.start_time));
    // const start = moment(new Date(1622267880000)); // 29/5/2021 12:58
    const end = moment(new Date());
    const result = start.diff(end, 'minutes');

    if (result > 15) {
      setPopup(true);
    } else if (result > 0 && result <= 15) {
      handleOpenModal(true);
      setPopup(false);
    } else {
      history.push(`/livestreams/${eventsDetail.id}`);
    }
  }

  const handleOpenModal = (data) => {
    setOpenPopupAfter15Minus(data);
  }

  const handleBuyTicketFree = (hash) => {
    if (!disableBuyTicket) {
      setHash(hash);
      buttonTicketFreeRef.current.click();
      setDisableBuyTicket(true);
    }
  }

  const handleTicketFree = () => {
    if (eventsDetail) {
      eventService.getFreeTicket(eventsDetail.id, hash)
        .then(
          res => {
            if (res.data.status === 400) {
              cogoToast.error(formatMessage(res.data.message), {
                heading: 'エラー',
                position: 'top-center',
                hideAfter: 3,
                bar: { size: '10px' }
              });
            } else if (res.data.status === 200) {
              setIsBuyTicket(true);
              const ticket = eventsDetail.tickets.find(ticket => ticket.hash === hash);
              setBoughtTicket(ticket);
              buttonTicketFreeSuccessRef.current.click();
            }
          },
          error => {
            cogoToast.error(formatMessage(error.toString()), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
          }
        );

    }

  }


  const renderTickets = (ticket, i, number, type) => {
    let iEqualEvent, iBiggerEvent;
    switch (type) {
      case "equal":
        iEqualEvent = number === eventsDetail.tickets.length;
        break;

      case "bigger":
        iBiggerEvent = number;
        break;
      default:
        break;
    }

    let bdLineNone = iEqualEvent ? (i === number - 1 ? 'bd_line_none' : '') : (iBiggerEvent ? (i === number - 1 ? 'bd_line_none' : '') : '');

    return <div className={`apply_purchase__ticket ${bdLineNone}`} key={i}>
      <span className="apply_purchase_content" style={{ width: "75%" }} id="detail">
        <span className="content_1 content_1__cus">
          <img src={'/assets/icons/title.png'} alt="" />{ticket.name}
        </span>
        <span className="content_2 added-ticket-description">
          <img src={'/assets/icons/information.png'} alt="" />
          <span className={`${eventsDetail.href.length > 0 ? "ticket_text_span" : ""}`}>
            {ticket.price === 0 && eventsDetail.href.length > 0 &&
              <span className="ticket_text_community">コミュニティ限定</span> || ''}
            <span className="ticket__description"><Linkify
              properties={{ target: '_blank' }}>{ticket.description}</Linkify></span>
          </span>
        </span>
        <span className="ticket__content content_3">
          <span className="content_3__dp content_3_price">
            <img src={'/assets/icons/money.png'} alt="" />￥{ticket.price}
          </span>
          <span className="content_3__dp content_3_calendar">
            <img src={'/assets/icons/calendar.png'}
              alt="" />販売終了: {moment.unix(ticket.expired_date / 1000).format('YYYY/MM/DD HH:mm')}
          </span>
          <span className="content_3__dp content_3_ticket">
            <img src={'/assets/icons/ticket.png'}
              alt="" />残り{ticket.ticket_remain >= 0 ? ticket.ticket_remain.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : parseInt(ticket.quantity).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}枚
          </span>
        </span>
      </span>
      {!props.user
        ? (ticket.ticket_remain > 0 && ticket.expired_date >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
          ? <span className="apply_purchase_buy_ticket">
            <button className="btn apply_purchase_btn w__apply_purchase_btn"
              onClick={() => handleBuyTicket(ticket.id)}>チケット購入</button>
          </span>
          : (
            (ticket.ticket_remain <= 0
              ? <span className="apply_purchase_buy_ticket">
                <button className="btn apply_purchase_btn w__apply_purchase_btn" disabled>完売</button>
              </span>
              : (ticket.expired_date < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
                ? <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn"
                    disabled>販売終了</button>
                </span> : null))
          ))
        :
        (eventsDetail.user_id !== props.user.id && ticket.price !== 0 &&
          (ticket.ticket_remain > 0 && ticket.expired_date >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
            ? <span className="apply_purchase_buy_ticket">
              <button className="btn apply_purchase_btn w__apply_purchase_btn"
                onClick={() => handleBuyTicket(ticket.id)}>チケット購入</button>
            </span>
            : (
              (ticket.ticket_remain <= 0
                ? <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn"
                    disabled>完売</button>
                </span>
                : (ticket.expired_date < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
                  ? <span className="apply_purchase_buy_ticket">
                    <button className="btn apply_purchase_btn w__apply_purchase_btn"
                      disabled>販売終了</button>
                  </span> : null))
            )
          )
        )
      }

      {!props.user
        ? (ticket.ticket_remain !== 0 && ticket.expired_date >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() && eventsDetail.href.length > 0 && checkInCommunity
          ? (
            (ticket.ticket_remain <= 0
              ? <span className="apply_purchase_buy_ticket">
                <button className="btn apply_purchase_btn w__apply_purchase_btn" disabled>完売</button>
              </span>
              : (ticket.expired_date < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
                ? <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn" disabled>販売終了</button>
                </span>
                : <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn"
                    onClick={() => handleBuyTicketFree(ticket.hash)}>チケット購入</button>
                  <button
                    ref={buttonTicketFreeRef}
                    data-toggle="modal"
                    data-target="#modalTicketFree"
                    hidden="hidden"
                  >チケット購入</button>
                  <button
                    ref={buttonTicketFreeSuccessRef}
                    data-toggle="modal"
                    data-target="#modalTicketFreeSuccess"
                    hidden="hidden"
                  >チケット購入</button>
                </span>))
          )
          : null)
        : (eventsDetail.user_id !== props.user.id && ticket.price === 0 &&
          (ticket.ticket_remain !== 0 && ticket.expired_date >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() && eventsDetail.href.length > 0 && checkInCommunity
            ? (
              (ticket.ticket_remain <= 0
                ? <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn"
                    disabled>完売</button>
                </span>
                : (ticket.expired_date < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
                  ? <span className="apply_purchase_buy_ticket">
                    <button className="btn apply_purchase_btn w__apply_purchase_btn"
                      disabled>販売終了</button>
                  </span>
                  : <span className="apply_purchase_buy_ticket">
                    <button className="btn apply_purchase_btn w__apply_purchase_btn"
                      onClick={() => handleBuyTicketFree(ticket.hash)}>チケット購入</button>
                    <button
                      ref={buttonTicketFreeRef}
                      data-toggle="modal"
                      data-target="#modalTicketFree"
                      hidden="hidden"
                    >チケット購入</button>
                    <button
                      ref={buttonTicketFreeSuccessRef}
                      data-toggle="modal"
                      data-target="#modalTicketFreeSuccess"
                      hidden="hidden"
                    >チケット購入</button>
                  </span>))
            )
            : (
              (ticket.ticket_remain <= 0
                ? <span className="apply_purchase_buy_ticket">
                  <button className="btn apply_purchase_btn w__apply_purchase_btn"
                    disabled>完売</button>
                </span>
                : (ticket.expired_date < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()
                  ? <span className="apply_purchase_buy_ticket end_of_sale">
                      <button className="btn apply_purchase_btn w__apply_purchase_btn"
                        disabled>販売終了</button>
                        <a className="click_view_popup_a"
                        data-toggle="modal"
                        data-target={`${eventsDetail.href.length ? "#modalExpireCommunityTickets" : "#modalFreeTickets"}`}
                      >{`${eventsDetail.href.length ? "※本チケットについて" : "※無料チケットについて"}`}</a>
                    </span>
                  : ((eventsDetail.href && eventsDetail.href.length)
                    ? <span className="apply_purchase_buy_ticket">
                      <button
                        className="btn apply_purchase_btn w__apply_purchase_btn"
                        data-toggle="modal"
                        data-target="#modalJoinCommunity"
                      >チケット購入</button>
                    </span> : <span className="apply_purchase_buy_ticket">
                      <button
                        className="btn apply_purchase_btn w__apply_purchase_btn"
                        data-toggle="modal"
                        data-target="#modalFreeTickets"
                      >チケット購入</button>
                    </span>)))
            )
          )
        )
      }
    </div>
  }

  return (
    <>
      {eventsDetail &&
        <>
          {isBuyTicket && boughtTicket ?
            <div className="buy__ticket">
              <div className="apply_purchase">購入したチケット情報</div>
              <div className="apply_purchase__ticket">
                <span className="apply_purchase_content" style={{ width: "75%" }}>
                  <span className="content_1 content_1__cus">
                    <img src={'/assets/icons/title.png'} alt="" />
                    {boughtTicket.name}
                    <img src={'/assets/icons/watch_live.png'} alt="" className="ticket_img_live" />
                  </span>
                  <span className="content_2">
                    <img src={'/assets/icons/information.png'} alt="" /><Linkify
                      properties={{ target: '_blank' }}>{boughtTicket.description}</Linkify>
                  </span>
                  <span className="ticket__content content_3">
                    <span className="content_3__dp content_3_price">
                      <img src={'/assets/icons/money.png'} alt="" />￥{boughtTicket.price}
                    </span>
                    <span className="content_3__dp content_3_calendar">
                      <img src={'/assets/icons/calendar.png'}
                        alt="" />販売終了: {moment.unix(boughtTicket.expired_date / 1000).format('YYYY/MM/DD HH:mm')}
                    </span>
                    <span className="content_3__dp content_3_ticket">

                    </span>
                  </span>
                  <br /><br />
                </span>
                <span className="apply_purchase_buy_ticket">
                  <button
                    className="btn apply_purchase_btn w__apply_purchase_btn not__cursor">購入済</button>
                  <button
                    ref={buttonTicketFreeSuccessRef}
                    data-toggle="modal"
                    data-target="#modalTicketFreeSuccess"
                    hidden="hidden"
                  >チケット購入</button>
                </span>
              </div>
              <div className="click_view_page">視聴ページは
                <a className="click_view_page_a" href="#"
                  onClick={handleViewRoom}
                  data-toggle="modal"
                  data-target={`${popup ? "#modalBefore15Minus" : "#modalAfter15Minus"}`}
                >こちら</a>
              </div>
            </div>
            :
            <div className="buy__ticket">
              <div
                className="apply_purchase">{eventsDetail.tickets.length ? (eventsDetail.user_id === userLoginId ? 'チケット一覧' : '申込/購入はこちらから') : '無料配信'}</div>
              {eventsDetail.tickets.length ? (
                <>
                  {eventsDetail.tickets.filter(x => x.price === 0).sort((a, b) => (a.expired_date > b.expired_date) ? 1 : -1).map((ticket, i) => {
                    return renderTickets(ticket, i, eventsDetail.tickets.filter(x => x.price === 0).length, 'equal');
                  })}
                  {eventsDetail.tickets.filter(x => x.price > 0).sort((a, b) => (a.expired_date > b.expired_date) ? 1 : -1).map((ticket, i) => {
                    return renderTickets(ticket, i, eventsDetail.tickets.filter(x => x.price > 0).length, 'bigger');
                  })}
                </>
              ) : <div className="click_view_page" style={{ textAlign: 'center' }}>視聴ページは
                <a className="click_view_page_a" href="#"
                  onClick={handleViewRoom}
                  data-toggle="modal"
                  data-target={`${popup ? "#modalBefore15Minus" : "#modalAfter15Minus"}`}
                >こちら</a>
              </div>}
            </div>
          }
        </>
      }
      <ModalTicketFree handleTicketFree={handleTicketFree} />
      <ModalTicketFreeSuccess />
      <ModalBefore15Minus />
      <ModalAfter15Minus eventsDetail={eventsDetail} handleOpenModal={handleOpenModal}
        openPopupAfter15Minus={openPopupAfter15Minus} />
      <ModalJoinCommunity eventsDetailHref={eventsDetail && eventsDetail.href} width={width} />
      <ModalFreeTickets />
      <ModalExpireCommunityTickets eventsDetailHref={eventsDetail && eventsDetail.href} />
    </>
  );
}
