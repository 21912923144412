import { authenticationConstants } from '../../_constants';

let token = localStorage.getItem('verify-token');
const initialState = token ? { token } : {};

export function registration(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.REGISTER_REQUEST:
      return {};
    case authenticationConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: true,
        phone_code: action.payload.phone_code,
        phone_number: action.payload.phone_number
      };
    case authenticationConstants.REGISTER_FAILURE:
      return {};
    default:
      return state;
  }
}