import React from "react";

const LoaderCenter = () => {
  return (
    <div className="sprinner_loading-center">
      <span className="spinner-border sprinner-center"></span>
    </div>
  );
};

export default LoaderCenter;
