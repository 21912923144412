import React, { useEffect } from 'react';
import $ from 'jquery';

export function ModalConfirmSuccess() {

  useEffect(() => {
    $(document.body).removeClass("modal-open");
  }, [])

  return (
    <div className="modal fade" id="modalConfirmSuccess" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="false" data-keyboard="false" data-backdrop="static">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">チケットを作成しました。</div>

            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn close__public"
                data-dismiss="modal"
                aria-label="Close"
              >閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
