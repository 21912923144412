import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { setMinutes, setHours, setSeconds } from "date-fns";
import moment from "moment";
import { ModalCommunity } from "../../CreateLivestreamSchedule/ModalCommunity";
import { useSelector } from "react-redux";
import { UserWatched } from '../../CreateLivestreamSchedule/UserWatched';

export function EventEditLeft(props) {
  const livestream = props.tickets && props.tickets.livestream;
  let communities = useSelector(state => state.communities.communities);
  const [valTitle, setValTitle] = useState("");
  const [valDescription, setValDescription] = useState("");
  const [email, setEmail] = useState("");
  const [childSubmited, setChildSubmited] = useState(() => props.submitted);
  const [checkTitle, setCheckTitle] = useState(true);
  const [CheckDescription, setCheckDescription] = useState(true);
  const [titleChar, setTitleChar] = useState(191);
  const [descriptionChar, setDescriptionChar] = useState(250);
  const [date, setDate] = useState(setSeconds(new Date(), 10800));
  const [startDate, setStartDate] = useState(livestream && setSeconds(new Date(livestream.start_time), 0));
  const [minExpired, setMinExpired] = useState(setSeconds(new Date(), 600));
  const [startTime, setStartTime] = useState('');
  const [isLess10Minutes, setIsLess10Minutes] = useState(false);
  const [displayStartTime, setDisplayStartTime] = useState(false);
  const [selectCommunity, setSelectCommunity] = useState('配信するコミュニティを選択');
  const [communityID, setCommunityID] = useState([]);
  const [radioTest, setRadioTest] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [arrayUsersChoised, setarrayUsersChoised] = useState([]);

  useEffect(() => {
    setChildSubmited(props.submitted);

  }, [props.submitted]);

  useEffect(() => {
    if (livestream && checkTitle && CheckDescription) {
      setValTitle(livestream.title);
      setValDescription(livestream.description);
    }
    if (livestream) {
      setStartDate(setSeconds(new Date(livestream.start_time), 0));
      setEmail(livestream.email);
      if (livestream.tickets.length === 0) {
        setDisplayStartTime(true);
      }

      livestream.href.map(comm => {
        const handleCommunities = communities && communities.filter(community => {
          return parseInt(community.id) === parseInt(comm);
        });
        if (handleCommunities) {
          setSelectCommunity(handleCommunities[0].title);
          setCommunityID([handleCommunities[0].id])
        }
      })

      if (livestream.is_test === 0) {
        setRadioTest(false)
      } else {
        setRadioTest(true)
      }

      // if (props.tickets.user_ids && props.tickets.user_ids.length) {
      //   setarrayUsersChoised(props.tickets.user_ids)
      // }
    }
  }, [livestream]);

  useEffect(() => {
    const changeStartDate = `${moment(date).format('YYYY-MM-DD')} ${moment(startDate).format('HH:mm')}`
    setStartTime(moment(changeStartDate).valueOf());
  }, [date, startDate])

  useEffect(() => {
    let minTime = moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm');
    minTime = moment(minTime).valueOf();
    if (minTime > startTime) {
      setIsLess10Minutes(true);
    } else {
      setIsLess10Minutes(false);
    }

    const data = {
      valTitle: valTitle,
      valDescription: valDescription,
      email: email,
      start_time: displayStartTime ? startTime : '',
      href: communityID,
      is_test: radioTest,
      user_ids : userIds
    }

    setTitleChar(191 - valTitle.length);
    setDescriptionChar(250 - valDescription.length);
    props.handleSubmit(data);

  }, [valTitle, valDescription, email, communityID, startTime, radioTest, userIds, props.checkStartTime]);

  const handleSelectCommunity = data => {
    const handleCommunities = communities.find(community => {
      return parseInt(community.id) === parseInt(data);
    });
    if (handleCommunities) {
      setSelectCommunity(handleCommunities.title);
      setCommunityID([handleCommunities.id])
    } else {
      setSelectCommunity(data);
      setCommunityID([])
    }
  }

  const handleChangeTitle = (e) => {
    const { value } = e.target;
    if (191 - value.length < 0) {
      const lengthSub = Math.abs(191 - value.length);
      const newtitle = value.substr(0, value.length - lengthSub);
      setValTitle(newtitle);
      setTitleChar(0);
    } else {
      setTitleChar(191 - value.length);
      setValTitle(value);
    }
    setCheckTitle(false);
  }

  const handleChangeDescription = async (e) => {
    const { value } = e.target;
    if (250 - value.length < 0) {
      const lengthSub = Math.abs(250 - value.length);
      const newDescription = value.substr(0, value.length - lengthSub);

      await setValDescription(newDescription);
      await setDescriptionChar(0)
    } else {
      await setValDescription(value);
      await setDescriptionChar(250 - value.length)
    }
    setCheckDescription(false);
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleChangeRadioTest = (e) => {
    const valuesTest = e.target.value
    if (valuesTest === "true") {
      setRadioTest(true)
    } else {
      setRadioTest(false)
    }
  }

  const handleCheckboxUserWatched = (data) => {
    setUserIds(data)
  }

  return (
    <>
      {livestream &&
        <>
          <div className="form-group schedule-font-size">
            <span className="schedule-label">
              <label htmlFor="community">ライブタイトルを入力</label>
              <span className="characters_left">残り{titleChar}文字</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="title"
              className={`form-control input-schedule ${childSubmited && !valTitle ? ' is-invalid' : ''}`}
              value={valTitle}
              onChange={handleChangeTitle}
            />
          </div>
          {childSubmited && !valTitle &&
            <div className="invalid__feedback">題名は、必ず指定してください。</div>
          }

          <div className="event-infor">
            <ul className="event-edit-meta pl-0">
              <li>
                <img className="resize-icon" src={'/assets/icons/calendar_white.png'} alt="" />
                <span className="pl-1 content_daytime__color">
                  {moment(new Date()).format("ddd, MMM D YYYY")}
                </span>
              </li>
              <li>
                {livestream.categories.map((category, i) => {
                  return <span className="badge badge-dark badge-custom" key={i}>
                    {category.name}
                  </span>
                })}
              </li>
              {!displayStartTime && <li>
                <img className="resize-icon" src={'/assets/icons/hour.png'} alt="" />
                <span className="pl-1 time_start__live">
                  {moment.unix(livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")} ~
                </span>
              </li>}
            </ul>
            <div className="event__infor_user">

            </div>
          </div>

          <div className="form-group schedule-font-size">
            <span className="schedule-label">
              <label htmlFor="description">ライブの説明を入力</label>
              <span className="characters_left">残り{descriptionChar}文字</span>
            </span>
            <textarea
              className={`form-control input-schedule `}
              rows={10}
              name="description"
              value={valDescription}
              onChange={handleChangeDescription}
            />
          </div>

          <div className="form-group schedule-font-size">
            <span className="schedule-label">
              <label htmlFor="community">メールアドレス</label>
            </span>
            <input
              autoComplete="off"
              type="email"
              name="email"
              className={`form-control input-schedule ${childSubmited && !email ? ' is-invalid' : ''}`}
              value={email}
              placeholder="live@dreamer.com"
              onChange={handleChangeEmail}
            />
          </div>
          {childSubmited && !email &&
            <div className="invalid__feedback">メールアドレスは、必ず指定してください。</div>
          }

          {displayStartTime && <div className="form-group schedule-font-size">
            <p className="livestream-schedule-text">コミュニティーをご選択お願いします<br />有料コミュニティー内のみ限定で配信希望の方は選択</p>
            <button
              className={`btn form-control btn-input-schedule`}
              data-toggle="modal"
              data-target="#modalCommunity"
            >{selectCommunity}</button>
          </div>}

          {displayStartTime && <div className="form-group schedule-font-size">
            <label className="schedule-label_custom" htmlFor="">開始時間</label>
            <div className="schedule-date-time event_edit_date_time">
              <span className="schedule-date">
                <DatePicker
                  locale="ja"
                  selected={date}
                  onChange={date => {
                    return setDate(new Date(moment(date).format('YYYY-MM-DD')))
                  }}
                  dateFormat="yyyy-MM-dd"
                  className={`form-control input-schedule ${childSubmited && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                  minDate={new Date()}
                />
                <img src={'/assets/icons/calendar_schedule.png'} className="schedule-date-img" alt="" />
              </span>

              <span className="schedule-time">
                <DatePicker
                  locale="ja"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  minDate={new Date()}
                  minTime={setSeconds(new Date(moment(date).valueOf()), -10800) > new Date() ? setHours(setMinutes(startDate, 0), 0) : minExpired}
                  maxTime={startDate > new Date() ? setHours(setMinutes(startDate, 59), 23) : setHours(setMinutes(new Date(), 59), 23)}
                  timeCaption="時間"
                  dateFormat="HH:mm"
                  className={`form-control input-schedule ${childSubmited && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                />
                <img src={'/assets/icons/hour_schedule.png'} className="schedule-date-img" alt="" />
              </span>
            </div>
          </div>}
          {displayStartTime && childSubmited && startTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() &&
            <div className="invalid__feedback">配信スケジュールの開始時間は必ず、将来時間を指定ください。</div>
          }
          {displayStartTime && childSubmited && isLess10Minutes &&
            <div className="invalid__feedback">配信開始時間は、現在の時間より10分後から設定できます</div>
          }

          {/* <div className="form-group schedule-font-size">
            <label className="livestream-schedule-radio" htmlFor="">配信タイプを選ぶ</label>
            <div className="buy__ticket__radio box-livestream__radio">
              <input
                type="radio"
                value={false}
                name="readioTest"
                id="livestrean"
                // defaultChecked={true}
                defaultChecked={livestream.is_test === 0}
                onChange={handleChangeRadioTest}
              />
              <label className="create-livestream__radio" htmlFor="livestrean">本番</label>
              <input
                type="radio"
                value={true}
                name="readioTest"
                id="test"
                defaultChecked={livestream.is_test === 1}
                onChange={handleChangeRadioTest}
              />
              <label htmlFor="test">リハーサル</label>
            </div>

            { radioTest && <UserWatched handleCheckboxUserWatched={handleCheckboxUserWatched} arrayUsersChoised={arrayUsersChoised} />}
          </div> */}
        </>
      }
      <ModalCommunity selectCommunity={handleSelectCommunity} communityID={communityID} />
    </>
  );
}
