import React, { useEffect } from 'react';
import $ from 'jquery';

export function ModalTicketFreeSuccess() {

  useEffect(() => {
    $(document.body).removeClass("modal-open");
  }, [])

  return (
    <div className="modal fade" id="modalTicketFreeSuccess" tabIndex={-1} role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="false" data-keyboard="false" data-backdrop="static">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">無料チケットを購入しました。</div>
            <div className="give_away_ticket__content">マイページをご確認ください。</div>

            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn close__public"
                data-dismiss="modal"
                aria-label="Close"
              >OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
