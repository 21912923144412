import React from 'react';
import * as moment from 'moment';
import Linkify from 'react-linkify';

function CardUser(props) {
  return (
    <>
      {
        props.listItems.length > 0 && props.listItems.map((listLiveStream, i) => {
          return <div className="live_content_tab1" key={i}>
            <span className="schedule_content_img">
              <img
                src={`${listLiveStream.video && listLiveStream.video.thumbnail_endpoint ? listLiveStream.video.thumbnail_endpoint : "/assets/images/livestream.png"}`}
                alt="" />
            </span>
            <span className="schedule_content_box">
              <span className="schedule_time_day">
                <span className="schedule_content_time">
                  <span
                    className="schedule_day">{listLiveStream.livestream ? moment.unix(listLiveStream.livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm") : null}</span> ~
                </span>
                <span className="schedule_content_live">{listLiveStream.title}</span>
              </span>
              <span className="schedule_comment"><Linkify
                properties={{ target: '_blank' }}>{listLiveStream.description}</Linkify></span>
            </span>
          </div>
        })
      }
    </>
  );
};

export { CardUser };
