export const livestreamConstants = {
  LIST_LIVESTREAM_REQUEST: 'LIST_LIVESTREAM_REQUEST',
  LIST_LIVESTREAM_SUCCESS: 'LIST_LIVESTREAM_SUCCESS',
  LIST_LIVESTREAM_FAILURE: 'LIST_LIVESTREAM_FAILURE',

  LIST_LIVESTREAM_MY_PAGE_REQUEST: 'LIST_LIVESTREAM_MY_PAGE_REQUEST',
  LIST_LIVESTREAM_MY_PAGE_SUCCESS: 'LIST_LIVESTREAM_MY_PAGE_SUCCESS',
  LIST_LIVESTREAM_MY_PAGE_FAILURE: 'LIST_LIVESTREAM_MY_PAGE_FAILURE',

  LIST_SCHEDULE_LIVESTREAM_REQUEST: 'LIST_SCHEDULE_LIVESTREAM_REQUEST',
  LIST_SCHEDULE_LIVESTREAM_SUCCESS: 'LIST_SCHEDULE_LIVESTREAM_SUCCESS',
  LIST_SCHEDULE_LIVESTREAM_FAILURE: 'LIST_SCHEDULE_LIVESTREAM_FAILURE',

  LIST_LIVESTREAM_DETAIL_REQUEST: 'LIST_LIVESTREAM_DETAIL_REQUEST',
  LIST_LIVESTREAM_DETAIL_SUCCESS: 'LIST_LIVESTREAM_DETAIL_SUCCESS',
  LIST_LIVESTREAM_DETAIL_FAILURE: 'LIST_LIVESTREAM_DETAIL_FAILURE',

  LIVESTREAM_DETAIL_REQUEST: 'LIVESTREAM_DETAIL_REQUEST',
  LIVESTREAM_DETAIL_SUCCESS: 'LIVESTREAM_DETAIL_SUCCESS',
  LIVESTREAM_DETAIL_FAILURE: 'LIVESTREAM_DETAIL_FAILURE',

  FOLLOW_REQUEST: 'FOLLOW_REQUEST',
  FOLLOW_SUCCESS: 'FOLLOW_SUCCESS',
  FOLLOW_FAILURE: 'FOLLOW_FAILURE',

  UNFOLLOW_REQUEST: 'UNFOLLOW_REQUEST',
  UNFOLLOW_SUCCESS: 'UNFOLLOW_SUCCESS',
  UNFOLLOW_FAILURE: 'UNFOLLOW_FAILURE',

  LIKE_REQUEST: 'LIKE_REQUEST',
  LIKE_SUCCESS: 'LIKE_SUCCESS',
  LIKE_FAILURE: 'LIKE_FAILURE',

  UNLIKE_REQUEST: 'UNLIKE_REQUEST',
  UNLIKE_SUCCESS: 'UNLIKE_SUCCESS',
  UNLIKE_FAILURE: 'UNLIKE_FAILURE',

  REPORT_REQUEST: 'REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORT_FAILURE',
  POST_REPORT_SUCCESS: 'POST_REPORT_SUCCESS',

  CHARGE_COIN_REQUEST: 'CHARGE_COIN_REQUEST',
  CHARGE_COIN_SUCCESS: 'CHARGE_COIN_SUCCESS',
  CHARGE_COIN_FAILURE: 'CHARGE_COIN_FAILURE',
  CHARGE_COIN_BY_CREDIT_SUCCESS: 'CHARGE_COIN_BY_CREDIT_SUCCESS',

  SUBSCRIBE_LIVESTREAM_REQUEST: 'SUBSCRIBE_LIVESTREAM_REQUEST',
  SUBSCRIBE_LIVESTREAM_SUCCESS: 'SUBSCRIBE_LIVESTREAM_SUCCESS',
  SUBSCRIBE_LIVESTREAM_FAILURE: 'SUBSCRIBE_LIVESTREAM_FAILURE',

  COMMENT_SUCCESS: 'COMMENT_SUCCESS',

  LOAD_SEND_COINS_SUCCESS: 'LOAD_SEND_COINS_SUCCESS',

  POST6_TIME_SUCCES: 'POST6_TIME_SUCCES',
  POST7_TIME_SUCCES: 'POST7_TIME_SUCCES',
  POST8_TIME_SUCCES: 'POST8_TIME_SUCCES',

  GET_COMMUNITIES_REQUEST: 'GET_COMMUNITIES_REQUEST',
  GET_COMMUNITIES_SUCCESS: 'GET_COMMUNITIES_SUCCESS',
  GET_COMMUNITIES_FAILURE: 'GET_COMMUNITIES_FAILURE',

  GET_COMMUNITY_DETAIL_SUCCESS: 'GET_COMMUNITY_DETAIL_SUCCESS',
  GET_COMMUNITY_DETAIL_FAILURE: 'GET_COMMUNITY_DETAIL_FAILURE',

  GET_VIDEO_CATEGORIES_REQUEST: 'GET_VIDEO_CATEGORIES_REQUEST',
  GET_VIDEO_CATEGORIES_SUCCESS: 'GET_VIDEO_CATEGORIES_SUCCESS',
  GET_VIDEO_CATEGORIES_FAILURE: 'GET_VIDEO_CATEGORIES_FAILURE',

  SINGLE_URL_REQUEST: 'SINGLE_URL_REQUEST',
  SINGLE_URL_SUCCESS: 'SINGLE_URL_SUCCESS',
  SINGLE_URL_FAILURE: 'SINGLE_URL_FAILURE',

  CREATE_DRAFT_EVENT_DOING: 'CREATE_DRAFT_EVENT_DOING',
  CREATE_DRAFT_EVENT_SUCCESS: 'CREATE_DRAFT_EVENT_SUCCESS',
  CREATE_DRAFT_EVENT_FAIL: 'CREATE_DRAFT_EVENT_FAIL'
};
