import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from './Banner/Banner';
import { EventLeft } from './EventLeft/index';
import { EventRight } from './EventRight/index';

import "./eventcontent.css";
import cogoToast from "cogo-toast";
import { ModalCreateSchedule } from "./ModalCreateSchedule";
import { ModalCreateScheduleContinue } from "./ModalCreateScheduleContinue";
import { formatMessage } from "../../_utils/formatMessage";
import { ModalDraft } from "./ModalDraft";
import { ModalDraftSuccess } from "./ModalDraftSuccess";
import { livestreamActions } from "../../_actions";
import { livestreamService } from "../../_services";

const textHeader = "チケット一覧";

export function EventDetailAfterCreate(props) {
  // let singleUrl = useSelector(state => state.singleUrl.singleUrl);
  const dispatch = useDispatch();
  const buttonPublicRef = useRef();
  const buttonPublicContinueRef = useRef();
  const buttonDraftRef = useRef();
  const buttonDraftSuccessRef = useRef();
  const [afterCreateSchedule, setAfterCreateSchedule] = useState(props.createSchedule);
  const [saveDraft, setSaveDraft] = useState(true);
  const [listTagUser, setListTagUser] = useState(props.listTagUser);

  useEffect(() => {
    setAfterCreateSchedule({ ...props.createSchedule });
  }, [props.createSchedule, props.imagePreviewUrlBanner]);

  useEffect(() => {
    if (props.listTagUser && props.listTagUser.length) {
      setListTagUser(props.listTagUser)
    }
  }, [props.listTagUser]);

  const handleClickBack = () => {
    props.handleBack();
  }

  const handleClickFreeTickets = () => {
    props.handleClickFreeTickets(afterCreateSchedule);
  }

  const handleSubmitCreateLivestream = async () => {
    await dispatch(livestreamActions.createLivestream(afterCreateSchedule));
    const createScheduleSusscess = localStorage.getItem("CreateScheduleSusscess");
    if (createScheduleSusscess === 'true') {
      buttonPublicRef.current.click();
    } else {
      buttonPublicContinueRef.current.click();
    }
    localStorage.setItem("CreateScheduleSusscess", 'false');
  }

  const handleSubmitFreeTicketsContinue = async () => {
    const afterCreateScheduleCotinue = {...afterCreateSchedule, is_still_create: true}
    await dispatch(livestreamActions.createLivestream(afterCreateScheduleCotinue));
    const createScheduleSusscess = localStorage.getItem("CreateScheduleSusscess");
    if (createScheduleSusscess === 'true') {
      buttonPublicRef.current.click();
    }
  }

  const handleSaveDraft = () => {
    buttonDraftRef.current.click();
  }

  const handleSaveDraftSuccess = async () => {
    if (saveDraft) {
      setSaveDraft(false);
      delete afterCreateSchedule.is_test
      delete afterCreateSchedule.user_ids
      
      await livestreamService.createDraftEvent(afterCreateSchedule)
        .then(
          res => {
            if (res.data.status === 400) {
              cogoToast.error(formatMessage(res.data.message), {
                heading: 'エラー',
                position: 'top-center',
                hideAfter: 3,
                bar: { size: '10px' }
              });
              setSaveDraft(true);
            } else if (res.data.status === 200) {
              buttonDraftSuccessRef.current.click();
            }
          },
          error => {
            cogoToast.error(formatMessage(error.toString()), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
            setSaveDraft(true);
          }
        );
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className='title__create_schedule'>配信情報プレビュー</div>
      </div>

      <Banner src={props.imagePreviewUrlBanner} text="" />

      <div className="container-fluid">
        <section className="event-content">
          <div className="row">
            <div className="col-lg-7 px-3 mb-5">
              <EventLeft createSchedule={afterCreateSchedule} listTagUser={listTagUser} />
            </div>
            <div className="col-lg-5 px-3 mb-5">
              <EventRight createSchedule={afterCreateSchedule} textHeader={textHeader} />
            </div>
          </div>

          <div className="event__btn">
            <button className="btn event__but_return" onClick={handleClickBack}>戻る</button>
            <button className="btn event__but_next" onClick={handleSaveDraft}>保存する</button>
            <button
              ref={buttonDraftRef}
              data-toggle="modal"
              data-target="#modalDraft"
              hidden="hidden"
            >保存する
            </button>
            <button
              ref={buttonDraftSuccessRef}
              data-toggle="modal"
              data-target="#modalDraftSuccess"
              hidden="hidden"
            >保存する
            </button>

            {props.isFreeTicket ?
              <button className="btn event__btn_free_ticket" onClick={handleClickFreeTickets}>無料チケットを配る</button> :
              <button className="btn event__btn_free_ticket"
                // ref={buttonPublicRef}
                data-toggle="modal"
                data-target="#modalCreateSchedule"
                data-dismiss="modal">公開</button>
            }

            <button
              ref={buttonPublicContinueRef}
              data-toggle="modal"
              data-target="#modalCreateScheduleContinue"
              data-dismiss="modal"
              hidden="hidden"
            >公開
            </button>

            <button
              ref={buttonPublicRef}
              data-toggle="modal"
              data-target="#modalCreateScheduleSuccsess"
              data-dismiss="modal"
              hidden="hidden"
            >公開
            </button>

          </div>
        </section>
      </div>
      <ModalCreateSchedule handleSubmitFreeTickets={handleSubmitCreateLivestream} btnClick={1} />
      <ModalCreateScheduleContinue handleSubmitFreeTicketsContinue={handleSubmitFreeTicketsContinue}/>
      <ModalDraft handleSaveDraftSuccess={handleSaveDraftSuccess} />
      <ModalDraftSuccess />
    </>
  );
}
