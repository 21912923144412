import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { VideoDetail } from '../../_components/ViveoDetail/VideoDetail';
import { VideoInfo } from '../../_components/ViveoDetail/VideoInfo';
import { Header } from '../../_components/Header';
import { Footer } from '../../_components/Footer/Footer';

import './videodetailpage.css'

const token = localStorage.getItem('token');
const videoDetail = localStorage.getItem('my-page-video-detail') ? JSON.parse(localStorage.getItem('my-page-video-detail')) : {};

function VideoDetailPage() {
  const history = useHistory();

  useEffect(() => {
    checkLogin();

    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  const checkLogin = () => {
    if (!token) {
      history.push("/before-login");
    }
  }

  return (
    <>
      <Header />
      <div className="home__page_live home__page_video_detail">
        <section className="section section__live_stream">
          <div className="container">
            {token && <VideoDetail videoDetail={videoDetail} />}
            <section className="section__my_livestream div_desktop">
              <VideoInfo videoDetail={videoDetail} />
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export { VideoDetailPage };
