import { userConstants } from '../../_constants';

const initialState = { buyTicketLivestream: [], historyLivestream: [] }

export function buyTicketLivestream(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_BUY_TICKET_LIVESTREAM_REQUEST:
      return {
        ...state,
      };
    case userConstants.GET_BUY_TICKET_LIVESTREAM_SUCCESS:
      {
        if (action.payload.type === 0) {

        }
        switch (action.payload.type) {
          case 0: {
            return {
              ...state,
              buyTicketLivestream: action.payload.data,
            };
          }
          case 1: {
            return {
              ...state,
              historyLivestream: action.payload.data,
            };
          }
        }
      }

    case userConstants.GET_BUY_TICKET_LIVESTREAM_FAILURE:
      return {
        ...state
      };
    default:
      return state
  }
}
