export const eventConstants = {
  LIST_EVENT_REQUEST: 'LIST_EVENT_REQUEST',
  LIST_EVENT_SUCCESS: 'LIST_EVENT_SUCCESS',
  LIST_EVENT_FAILURE: 'LIST_EVENT_FAILURE',

  LIST_EVENT_BY_USER_REQUEST: 'LIST_EVENT_BY_USER_REQUEST',
  LIST_EVENT_BY_USER_SUCCESS: 'LIST_EVENT_BY_USER_SUCCESS',
  LIST_EVENT_BY_USER_FAILURE: 'LIST_EVENT_BY_USER_FAILURE',

  LIST_MY_DRAFT_EVENT_BY_USER_REQUEST: 'LIST_MY_DRAFT_EVENT_BY_USER_REQUEST',
  LIST_MY_DRAFT_EVENT_BY_USER_SUCCESS: 'LIST_MY_DRAFT_EVENT_BY_USER_SUCCESS',
  LIST_MY_DRAFT_EVENT_BY_USER_FAILURE: 'LIST_MY_DRAFT_EVENT_BY_USER_FAILURE',

  SUBSCRIBE_EVENT_REQUEST: 'SUBSCRIBE_EVENT_REQUEST',
  SUBSCRIBE_EVENT_SUCCESS: 'SUBSCRIBE_EVENT_SUCCESS',
  SUBSCRIBE_EVENT_FAILURE: 'SUBSCRIBE_EVENT_FAILURE',

  EVENT_BY_DATE_REQUEST: 'EVENT_BY_DATE_REQUEST',
  EVENT_BY_DATE_SUCCESS: 'EVENT_BY_DATE_SUCCESS',
  EVENT_BY_DATE_FAILURE: 'EVENT_BY_DATE_FAILURE',

  SUBSCRIBE_EVENT_BY_DATE_REQUEST: 'SUBSCRIBE_EVENT_BY_DATE_REQUEST',
  SUBSCRIBE_EVENT_BY_DATE_SUCCESS: 'SUBSCRIBE_EVENT_BY_DATE_SUCCESS',
  SUBSCRIBE_EVENT_BY_DATE_FAILURE: 'SUBSCRIBE_EVENT_BY_DATE_FAILURE',

  EVENT_DETAIL_REQUEST: 'EVENT_DETAIL_REQUEST',
  EVENT_DETAIL_SUCCESS: 'EVENT_DETAIL_SUCCESS',
  EVENT_DETAIL_FAILURE: 'EVENT_DETAIL_FAILURE',

  EDIT_EVENT_DETAIL_REQUEST: 'EDIT_EVENT_DETAIL_REQUEST',
  EDIT_EVENT_DETAIL_SUCCESS: 'EDIT_EVENT_DETAIL_SUCCESS',
  EDIT_EVENT_DETAIL_FAILURE: 'EDIT_EVENT_DETAIL_FAILURE',
  ADD_TICKET_SUCCESS: 'ADD_TICKET_SUCCESS',

  ADD_TICKET_DETAIL_REQUEST: 'ADD_TICKET_DETAIL_REQUEST',
  ADD_TICKET_DETAIL_SUCCESS: 'ADD_TICKET_DETAIL_SUCCESS',
  ADD_TICKET_DETAIL_FAILURE: 'ADD_TICKET_DETAIL_FAILURE',

  USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
  USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
  USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

  RESET_LIST_EVENT_BY_USER: 'RESET_LIST_EVENT_BY_USER',
  RESET_LIST_MY_DRAFT_EVENT_BY_USER: 'RESET_LIST_MY_DRAFT_EVENT_BY_USER',
  DRAFT_EVENT_WITH_CREATE: 'DRAFT_EVENT_WITH_CREATE',

  SEARCH_EVENTS_REQUEST: 'SEARCH_EVENTS_REQUEST',
  SEARCH_EVENTS_SUCCESS: 'SEARCH_EVENTS_SUCCESS',
  SEARCH_EVENTS_FAILURE: 'SEARCH_EVENTS_FAILURE',

  GET_COMMUNITY_REQUEST: 'GET_COMMUNITY_REQUEST',
  GET_COMMUNITY_SUCCESS: 'GET_COMMUNITY_SUCCESS',
  GET_COMMUNITY_FAILURE: 'GET_COMMUNITY_FAILURE',

  DELETE_DRAFT_EVENT_REQUEST: 'DELETE_DRAFT_EVENT_REQUEST',
  DELETE_DRAFT_EVENT_SUCCESS: 'DELETE_DRAFT_EVENT_SUCCESS',
  DELETE_DRAFT_EVENT_FAIL: 'DELETE_DRAFT_EVENT_FAIL'
};
