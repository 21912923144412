import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInfiniteScroll from '../MyPage/useInfiniteScroll';
import { userActions } from '../../_actions/user.actions';
import { eventActions } from '../../_actions';
import { CardEvent } from './CardEvent';

function ScheduleContent(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  let userEvents = useSelector(state => state.myEvents.myEvents);
  const [listItems, setListItems] = useState("");
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    userEvents = [];
    setListItems("");
    const getBuyTicketLivestream = async () => {
      await dispatch(eventActions.getEventsByUser(page, limit, props.userProfileId))
    }
    getBuyTicketLivestream();
  }, []);

  useEffect(() => {
    let timer;
    if (userEvents && userEvents.length > 0) {
      setLoadMore(true);
      timer = setTimeout(() => {
        if (listItems.length > 0 && listItems.length < 10) {
          setLoadMore(false);
        }
        setListItems(prevState => [
          ...prevState,
          ...userEvents
        ]);
        setIsFetching(false);
      }, 500);
    } else if (userEvents && userEvents.length === 0) {
      setLoadMore(false);
      setIsFetching(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [userEvents]);

  function fetchMoreListItems() {
    dispatch(eventActions.getEventsByUser(page + 1, limit, props.userProfileId))
    setPage(page + 1);
  }

  return (
    <>
      {listItems && <CardEvent listItems={listItems} handClick={true} userEvent={1} />}
      {isFetching && loadMore && <span className="spinner-border sprinner-my-page"></span>}
    </>
  );
};

export { ScheduleContent };
