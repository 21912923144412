import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import ja from "date-fns/locale/ja";
import DatePicker, { registerLocale } from "react-datepicker";
import { SvgCalendar } from './SvgCalendar';
import { convert } from '../../_utils/formatTime';
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";

registerLocale("ja", ja);

export function Calendar() {
  const history = useHistory();
  const eventDay = useParams().eventDay;
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (startDate) {
      const date = convert(startDate);
      history.push(`/events-by-date/${date}`);
    }
  }, [startDate]);

  return (
    <>
      <DatePicker
        monthsShown={3}
        locale="ja"
        selected={startDate}
        onChange={date => setStartDate(date)}
        customInput={
          <div className="content__calendar">
            <div className="text__year">
              {eventDay ? new Date(eventDay).getFullYear() : new Date().getFullYear()},
            </div>
            <div className="text__month">
              {
                ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                [eventDay ? new Date(eventDay).getMonth() : new Date().getMonth()]
              }
            </div>
            <div className="text__day text__day_mr">
              {("0" + (eventDay ? new Date(eventDay).getDate() : new Date().getDate())).slice(-2)}
            </div>
            <div className="content__calendar_img">
              <SvgCalendar />
            </div>
          </div>
        }
      />
    </>
  );
}