import { authenticationConstants } from '../_constants';
import { authenticationService } from '../_services';
import { history } from '../_helpers';
import { checkAuthentication, handleErrorMessage, handleReponse } from '../_helpers/api';
import cogoToast from 'cogo-toast';
import { formatMessage } from '../_utils/formatMessage';

export const authenticationActions = {
  login,
  loginSocial,
  verifyRegisterCode,
  register,
  updatePassword,
  requestResetPasswordCode,
  verifyCodeResetPassword,
  updateNewPassword,
  logout
}
function login(phoneCode, phoneNumber, password) {
  let dataLogin = {
    phone_code: phoneCode,
    phone_number: phoneNumber,
    password
  }
  return dispatch => {
    dispatch(request({ dataLogin }));

    authenticationService.login(dataLogin)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, '再度ログインしてください');
          }

          const handleSuccess = () => {
            localStorage.setItem("goBack", "/top");
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
            localStorage.setItem('user_coin', JSON.stringify(res.data.data.user_coin));
            localStorage.setItem('token', res.data.data.token);

            dispatch(success(res));

            const callBack = JSON.parse(JSON.stringify(localStorage.getItem("callBack")));
            if (callBack && callBack !== 'null') {
              localStorage.setItem("callBack", "");
              return window.location = `${document.location.origin}${callBack}`;
            }

            return window.location = `${document.location.origin}/top`;
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(user) { return { type: authenticationConstants.LOGIN_REQUEST, payload: user } }
  function success(user) { return { type: authenticationConstants.LOGIN_SUCCESS, payload: user } }
  function failure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }
}

function verifyRegisterCode(phoneCode, phoneNumber) {
  let dataVerifyCode = {
    phone_code: phoneCode,
    phone_number: phoneNumber
  }
  return async dispatch => {
    dispatch(request({ dataVerifyCode }));

    await authenticationService.verifyRegisterCode(dataVerifyCode)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            localStorage.setItem('verify-token', res.data.data.token);
            dispatch(success(dataVerifyCode));
            cogoToast.success('', {
              heading: '確認コードを送信しました',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: authenticationConstants.VERIFY_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.VERIFY_SUCCESS, payload: data } }
  function failure(error) { return { type: authenticationConstants.VERIFY_FAILURE, error } }
}

function register(phoneCode, phoneNumber, code) {
  let dataRegister = {
    phone_code: phoneCode,
    phone_number: phoneNumber,
    code: Number(code)
  }
  return async dispatch => {
    dispatch(request(dataRegister));

    await authenticationService.register(dataRegister)
      .then(
        res => {
          const handleError = () => {
            dispatch(failure(res.data.message));
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }
          const handleSuccess = () => {
            localStorage.setItem('register-token', res.data.data.token);
            dispatch(success(dataRegister));
          }
          handleReponse(res, handleError, handleSuccess);
        }
      );
  };

  function request(data) { return { type: authenticationConstants.REGISTER_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.REGISTER_SUCCESS, payload: data } }
  function failure(error) { return { type: authenticationConstants.REGISTER_FAILURE, error } }
}

function updatePassword(password) {
  return dispatch => {
    dispatch(request(password));

    authenticationService.updatePassword({ password })
      .then(
        res => {

          const handleError = () => {
            dispatch(failure(res.data.message));
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }
          const handleSuccess = () => {
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
            localStorage.setItem('user_coin', JSON.stringify(res.data.data.user_coin));
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('register-token', res.data.data.token);
            dispatch(success(password));
            return window.location = `${document.location.origin}/top`;
          }
          handleReponse(res, handleError, handleSuccess);
        }
      );
  };

  function request(data) { return { type: authenticationConstants.UPDATE_PASSWORD_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.UPDATE_PASSWORD_SUCCESS, payload: data } }
  function failure(error) { return { type: authenticationConstants.UPDATE_PASSWORD_FAILURE, error } }
}

function requestResetPasswordCode(phoneCode, phoneNumber) {
  let dataVerifyCode = {
    phone_code: phoneCode,
    phone_number: phoneNumber
  }
  return async dispatch => {
    dispatch(request({ dataVerifyCode }));

    await authenticationService.requestResetPasswordCode(dataVerifyCode)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }
          const handleSuccess = () => {
            localStorage.setItem('verify-token', res.data.data.token);
            dispatch(success(dataVerifyCode));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: authenticationConstants.REQUEST_RESET_PASSWORD_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.REQUEST_RESET_PASSWORD_SUCCESS, payload: data } }
  function failure(error) { return { type: authenticationConstants.REQUEST_RESET_PASSWORD_FAILURE, error } }
}

function verifyCodeResetPassword(code) {
  let dataRegister = {
    code: Number(code)
  }
  return dispatch => {
    dispatch(request(dataRegister));

    authenticationService.verifyResetPasswordCode(dataRegister)
      .then(
        res => {
          const handleError = () => {
            dispatch(failure(res.data.message));
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }
          const handleSuccess = () => {
            localStorage.setItem('reset-password-token', res.data.data.token);
            dispatch(success(dataRegister));
          }
          handleReponse(res, handleError, handleSuccess);
        }
      );
  };

  function request(data) { return { type: authenticationConstants.VERIFY_RESET_PASSWORD_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.VERIFY_RESET_PASSWORD_SUCCESS, payload: data } }
  function failure(error) { return { type: authenticationConstants.VERIFY_RESET_PASSWORD_FAILURE, error } }
}

function updateNewPassword(password) {
  return dispatch => {
    dispatch(request(password));

    authenticationService.updatePassword({ password }, true)
      .then(
        res => {
          const handleError = () => {
            dispatch(formatMessage(res.data.message));
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }
          const handleSuccess = () => {
            dispatch(success(password));
            localStorage.setItem('reset-password-token', '');
            localStorage.setItem('verify-token', '');
            history.push('/login');
          }
          handleReponse(res, handleError, handleSuccess);
        }
      );
  };

  function request(data) { return { type: authenticationConstants.RESET_PASSWORD_REQUEST, payload: data } }
  function success(data) { return { type: authenticationConstants.RESET_PASSWORD_SUCCESS, payload: data } }
}

function loginSocial(access_token, type) {
  let dataLogin = {
    access_token,
    type
  }

  return async (dispatch) => {
    await authenticationService.loginSocial(dataLogin)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, '再度ログインしてください');
          }
          const handleSuccess = () => {
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem("goBack", "/top");
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
            res.data.data.user_coin ? localStorage.setItem('user_coin', JSON.stringify(res.data.data.user_coin)) : localStorage.setItem('user_coin', null);
            const callBack = JSON.parse(JSON.stringify(localStorage.getItem("callBack")));
            if (callBack && callBack !== 'null') {
              localStorage.setItem("callBack", "");
              return window.location = `${document.location.origin}${callBack}`;
            }

            return window.location = `${document.location.origin}/top`;
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };
}

function logout() {
  try {
    authenticationService.logout();

    return dispatch => {
      dispatch({ type: authenticationConstants.LOGOUT })
    }
  } catch (err) {
    console.log(err);
  }
}
