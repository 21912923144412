import { authenticationConstants } from '../../_constants';

let token = localStorage.getItem('reset-password-token');
const initialState = token ? { token } : {};

export function resetPassword(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.RESET_PASSWORD_REQUEST:
      return {
        reset_password_next: false
      };
    case authenticationConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password_next: false,
        phone_code: '',
        phone_number: ''
      };
    case authenticationConstants.RESET_PASSWORD_FAILURE:
      return {
        reset_password_next: false
      };
    case authenticationConstants.REQUEST_RESET_PASSWORD_REQUEST:
      return {
        reset_password_next: false
      };
    case authenticationConstants.REQUEST_RESET_PASSWORD_SUCCESS:

      return {
        ...state,
        reset_password_next: false,
        phone_code: action.payload.phone_code,
        phone_number: action.payload.phone_number
      };
    case authenticationConstants.REQUEST_RESET_PASSWORD_FAILURE:
      return {
        reset_password_next: false
      };

    case authenticationConstants.VERIFY_RESET_PASSWORD_REQUEST:
      return {
        reset_password_next: false
      };
    case authenticationConstants.VERIFY_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password_next: true,
        phone_code: action.payload.phone_code,
        phone_number: action.payload.phone_number
      };
    case authenticationConstants.VERIFY_RESET_PASSWORD_FAILURE:
      return {
        reset_password_next: false
      };
    default:
      return state;

  }
}