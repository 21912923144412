import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { eventActions } from '../../_actions/event.actions';
import Linkify from 'react-linkify';
import { ModalConfirmDeleteDraft } from './ModalConfirmDeleteDraft';
import { ModalDeleteDraftSuccess } from './ModalDeleteDraftSuccess';
import Loader from "../Loader/Loader";

const page = 1;
const limit = 10;

export const ConstructionSchedule = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonDeleteDraftRef = useRef();
  let events = useSelector(state => state.myDraftEvents.myDraftEvents);
  let loadingEvents = useSelector(state => state.myDraftEvents.loadingMyDraftEvents);
  let total = useSelector(state => {
    return state.myDraftEvents.total
  });
  let [eventId, setEventId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    events = [];
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }

    const getDraftEventsByUser = async () => {
      await dispatch(eventActions.getDraftEventsByUser(page, limit));
    }
    getDraftEventsByUser();
  }, []);

  useEffect(() => {
    if (loadingEvents) {
      setLoading(true)
    }
  }, [loadingEvents]);

  const handleClickCreateLivestream = async (eventId) => {
    const evt = events.find(event => +event.id === +eventId);
    await dispatch(eventActions.draftEventWithCreate(evt));
    history.push(`/create-livestream-schedule`);
  }

  const handleClickDeleteLivestream = async (eventId) => {
    setEventId(eventId);

  }

  const handleDeleteDraftLivestream = async () => {
    await dispatch(eventActions.deleteDraftEvent(eventId));
    const status = localStorage.getItem('deleteDraft');
    if (status === 'success') {
      buttonDeleteDraftRef.current.click();
      localStorage.removeItem('deleteDraft');
    }

  }

  return (
    <>
      <section className="section">
        <div className="container_list__event">
          <div className="best_event">
            <div className="best_event_head">
              <div className="best_event_title">作成中の配信スケジュール</div>
              <div className="number__event"><span>{total}</span>イベント</div>
            </div>
            {events ? events.sort((a, b) => (a.start_time > b.start_time) ? 1 : -1).map((event, i) => {
              let totalTicketRemain = event.tickets.reduce((total, ticket) => {
                return total += parseInt(ticket.quantity)
              }, 0);
              return (<div className="best_event_box" key={i}>
                <div className="best_event_box_img">
                  <img
                    src={event.thumbnail}
                    alt=""
                  />
                  <span className="best_event_heart_start">
                    <a
                      className={`img_start ${totalTicketRemain.toString().length > 4 ? "img_start_length" : ""}`}>{totalTicketRemain}</a>
                    <span>残り<br />チケット</span>
                  </span>
                </div>
                <div className="schedule_content_day">
                  <div className="schedule_content_daytime">
                    <img src={'/assets/icons/calendar_schedule.png'} className="content_daytime__img" alt="" />
                    <span className="pl-1 pr-1 content_daytime__color">
                      {moment.unix(event.start_time / 1000).format("ddd, D MMM YYYY")}
                    </span>
                    {event.categories.map((category, key) => {
                      return <span key={key} className="badge badge-dark badge-custom">  {category.name}  </span>;
                    })}

                    <div className="best_event_content_title not_cursor_pointer">
                      {event.title}
                    </div>
                    <div className="best_event_copy_right">
                      <Linkify properties={{ target: '_blank' }}>{event.description}</Linkify>
                    </div>
                  </div>
                  <div className="btn_detail">
                    <button onClick={() => {
                      handleClickCreateLivestream(event.id)
                    }} className="btn schedule__but_detail">再開
                    </button>
                    <button onClick={() => {
                      handleClickDeleteLivestream(event.id)
                    }} className="btn schedule__but_detail"
                      data-toggle="modal"
                      data-target="#modalDeleteDraft"
                      data-dismiss="modal"
                      style={{ marginTop: '10px', background: '#9B9B9B' }}
                    >削除
                    </button>

                  </div>
                </div>
              </div>)
            }) : null}
            {events && events.length === 0 && loading && <div className="video_not_fount">
              <span className="video_span_not_fount">現在、作成中の配信スケジュールはございません</span>
            </div>}
            {!loading && <Loader />}
          </div>
        </div>
        <ModalConfirmDeleteDraft handleDeleteDraftLivestream={handleDeleteDraftLivestream} />
        <ModalDeleteDraftSuccess />
      </section>
      <button
        ref={buttonDeleteDraftRef}
        data-toggle="modal"
        data-target="#modalDeleteDraftSuccsess"
        data-dismiss="modal"
        hidden="hidden"
      >公開
      </button>
    </>
  );
}
