import { authenticationConstants } from '../../_constants';

let token = localStorage.getItem('verify-token');
const initialState = token ? { token } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.VERIFY_REQUEST:
      return {
        verifyCode: false
      };
    case authenticationConstants.VERIFY_SUCCESS:
      return {
        ...state,
        verifyCode: true,
        phone_code: action.payload.phone_code,
        phone_number: action.payload.phone_number
      };
    case authenticationConstants.VERIFY_FAILURE:
      return {};
    default:
      return state
  }
}