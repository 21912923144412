import { eventConstants } from '../../_constants';

const initialState = {
  listEvents: [],
  loadingListEvents: false,
  eventsByDate: [],
  loadingEventsByDate: false,
  loadingEventsFavorite: false,
  eventDetail: null,
  loadingEventDetail: false,
  draftEventWithCreate: null,
  searchEvents: [],
  communities: [],
}

export function events(state = initialState, action) {
  switch (action.type) {
    case eventConstants.LIST_EVENT_REQUEST:
      return {
        ...state,
        loadingListEvents: false,
      };
    case eventConstants.LIST_EVENT_SUCCESS:
    case eventConstants.LIST_EVENT_FAILURE:
      return {
        ...state,
        listEvents: action.payload,
        loadingListEvents: true,
      };

    case eventConstants.SUBSCRIBE_EVENT_SUCCESS: {
      const EventList = [...state.listEvents];
      EventList.map(event => {
        if (event.livestream.id === action.payload.id) {
          event.livestream.is_notification = action.payload.is_notification;
        }
        return event;
      })

      return {
        ...state,
        listEvents: EventList
      }
    }

    case eventConstants.EVENT_BY_DATE_REQUEST:
      return {
        ...state,
        loadingEventsByDate: false,
        loadingEventsFavorite: false,
      };
    case eventConstants.EVENT_BY_DATE_SUCCESS:
    case eventConstants.EVENT_BY_DATE_FAILURE:
      return {
        ...state,
        eventsByDate: action.payload,
        loadingEventsByDate: true,
        loadingEventsFavorite: true,
      };

    case eventConstants.SUBSCRIBE_EVENT_BY_DATE_SUCCESS: {
      const EventByDateList = [...state.eventsByDate.events];
      EventByDateList.map(event => {
        if (event.livestream.id === action.payload.id) {
          event.livestream.is_notification = action.payload.is_notification;
        }
        return event;
      })

      const eventByDate = { "events": EventByDateList, "favorite_livestream": state.eventsByDate.favorite_livestream };

      return {
        ...state,
        eventsByDate: eventByDate
      }
    }

    case eventConstants.SUBSCRIBE_EVENT_REQUEST:
    case eventConstants.SUBSCRIBE_EVENT_FAILURE:
    case eventConstants.SUBSCRIBE_EVENT_BY_DATE_REQUEST:
    case eventConstants.SUBSCRIBE_EVENT_BY_DATE_FAILURE: {
      return {
        ...state
      }
    }

    case eventConstants.EVENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingEventDetail: false,
      };
    case eventConstants.EVENT_DETAIL_SUCCESS:
    case eventConstants.EVENT_DETAIL_FAILURE:
      return {
        ...state,
        eventDetail: action.payload,
        loadingEventDetail: true,
      };

    case eventConstants.EDIT_EVENT_DETAIL_SUCCESS:
      const livestream = { "livestream": action.payload };
      return {
        ...state,
        eventDetail: livestream,
      };
    case eventConstants.ADD_TICKET_SUCCESS: {
      const eventDetail = { ...state.eventDetail };
      eventDetail.livestream.tickets.push(action.payload);
      return {
        ...state,
        eventDetail
      }
    }

    case eventConstants.DRAFT_EVENT_WITH_CREATE: {
      return {
        ...state,
        draftEventWithCreate: action.payload
      }
    }

    case eventConstants.SEARCH_EVENTS_REQUEST:
      return {
        ...state,
      };
    case eventConstants.SEARCH_EVENTS_SUCCESS:
    case eventConstants.SEARCH_EVENTS_FAILURE:
      return {
        ...state,
        searchEvents: action.payload,
      };

    case eventConstants.GET_COMMUNITY_REQUEST:
      return {
        ...state,
      };
    case eventConstants.GET_COMMUNITY_SUCCESS:
    case eventConstants.GET_COMMUNITY_FAILURE:
      return {
        ...state,
        communities: action.payload,
      };

    default:
      return state
  }
}