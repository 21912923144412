import axios from 'axios';
import { API_BASE, getHeaders } from "../_helpers/api";

export const authenticationService = {
  login,
  verifyRegisterCode,
  logout,
  register,
  updatePassword,
  requestResetPasswordCode,
  verifyResetPasswordCode,
  loginSocial,
  createCustomToken
}

function login(bodyObject) {
  return axios.post(`${API_BASE}/api/v1/auth/login`, bodyObject, {
    headers: {
      ...getHeaders()
    }
  });
}

function verifyRegisterCode(bodyObject) {
  return axios.post(`${API_BASE}/api/v1/auth/verify-code`, bodyObject, {
    headers: {
      ...getHeaders()
    }
  });
}

function register(bodyObject) {
  let token = localStorage.getItem('verify-token');

  return axios.post(`${API_BASE}/api/v1/auth/register`, bodyObject, {
    headers: {
      ...getHeaders(),
      token
    }
  })
}

function updatePassword(bodyObject, isResetPassword = false) {
  let token = localStorage.getItem('register-token');
  if(!isResetPassword) {
    return axios.put(`${API_BASE}/api/v1/auth/update-password`, bodyObject, {
      headers: {
        ...getHeaders(),
        token
      }
    })
  } 

  token = localStorage.getItem('reset-password-token');

  return axios.put(`${API_BASE}/api/v1/auth/reset-password/update-password`, bodyObject, {
    headers: {
      ...getHeaders(),
      token
    }
  })
}

function requestResetPasswordCode(bodyObject) {
  return axios.post(`${API_BASE}/api/v1/auth/reset-password/request-code`, bodyObject, {
    headers: {
      ...getHeaders()
    }
  });
}

function verifyResetPasswordCode(bodyObject) {
  let token = localStorage.getItem('verify-token');
  return axios.post(`${API_BASE}/api/v1/auth/reset-password/verify-code`, bodyObject, {
    headers: {
      ...getHeaders(),
      token
    }
  });
}

function loginSocial(bodyObject) {
  return axios.post(`${API_BASE}/api/v1/auth/login-firebase`, bodyObject, {
    headers: {
      ...getHeaders()
    }
  });
}

function createCustomToken(bodyObject) {
  return axios.post(`${API_BASE}/api/v1/auth/create-custom-token`, bodyObject, {
    headers: {
      ...getHeaders()
    }
  });
}

function logout() {
  const callBack = localStorage.getItem("callBack");
  localStorage.removeItem('token');
  localStorage.clear();
  localStorage.setItem("callBack", callBack);
}
