import React, { useState, useEffect } from 'react';
import { generate } from 'short-id'
import Emoji from './Emoji'
import EmojiButton from './EmojiButton';
import EmojiBubble from './EmojiBubble';
import AutoExpire from './AutoExpire';
import { useWindowResize } from '../LiveStream/LiveStreamDetail/useWindowResize';

const Board = () => {
  const { width } = useWindowResize();
  const [emojiQueue, setEmojiQueue] = useState([]);
  const [left, setLeft] = useState();
  const [bottom, setBottom] = useState();
  const [bottomBottom, setBottomBottom] = useState();

  useEffect(() => {
    if (width > 768) {
      setLeft(randomNumber(-25, -24));
      setBottom(10);
      setBottomBottom(70);
    } else if (width > 480) {
      setLeft(randomNumber(80, 79));
      setBottom(160);
      setBottomBottom(210);
    } else {
      setLeft(randomNumber(80, 79));

      const heightLTInfor = parseInt(localStorage.getItem('clientHeight'));
      const boxChatHeight = parseInt(localStorage.getItem('boxChatHeight'));

      if (width >= 414) {
        setBottom(131 + heightLTInfor * 100 / boxChatHeight);
        setBottomBottom(173 + heightLTInfor * 100 / boxChatHeight);
      } else if (width >= 411) {
        setBottom(131 + heightLTInfor * 100 / boxChatHeight);
        setBottomBottom(177 + heightLTInfor * 100 / boxChatHeight);
      } else if (width >= 375) {
        setBottom(136 + heightLTInfor * 100 / boxChatHeight);
        setBottomBottom(186 + heightLTInfor * 100 / boxChatHeight);
      } else if (width >= 360) {
        setBottom(132 + heightLTInfor * 100 / boxChatHeight);
        setBottomBottom(176 + heightLTInfor * 100 / boxChatHeight);
      } else {
        setBottom(140 + heightLTInfor * 100 / boxChatHeight);
        setBottomBottom(188 + heightLTInfor * 100 / boxChatHeight);
      }
    }
  }, [width, localStorage.getItem('clientHeight'), localStorage.getItem('boxChatHeight')]);

  const randomNumber = (max, min) => {
    return Math.floor(Math.random() * (max - min)) + min
  }

  const randomPosOrNeg = (max, min) => {
    let randomNumber = Math.floor(Math.random() * (max - min));
    randomNumber *= Math.floor(Math.random() * 2) === 1 ? 1 : -1

    return randomNumber
  }

  const handleEmojiClick = (label) => {
    setEmojiQueue([
      ...emojiQueue,
      {
        label,
        size: randomNumber(2, 1),
        left,
        one: randomPosOrNeg(65, 50),
        two: randomPosOrNeg(65, 50),
        id: generate()
      }
    ])
  }

  const handleEmojiKeydown = (e, label) => {
    if (e.key === 'Enter') {
      setEmojiQueue([
        ...emojiQueue,
        {
          label,
          size: randomNumber(2, 1),
          left,
          one: randomPosOrNeg(65, 50),
          two: randomPosOrNeg(65, 50),
          id: generate()
        }
      ])
    }
  }

  const label = "Thump Up";
  return (
    <>
      <div>
        <EmojiButton onClick={() => handleEmojiClick(label)} onKeyDown={(e) => handleEmojiKeydown(e, label)}>
          <Emoji
            label={label}
            size='4rem'
          />
        </EmojiButton>
      </div>
      <div>
        {emojiQueue.map(({ id, label, size, left, one, two }, i) =>
          <AutoExpire key={i}>
            <EmojiBubble
              key={id}
              label={label}
              size={size}
              left={left}
              bottom={bottom}
              bottomBottom={bottomBottom}
              one={one}
              two={two}
            />
          </AutoExpire>
        )}
      </div>
    </>
  )
}

export default Board;
