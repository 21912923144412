import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { HeaderRight } from './HeaderRight';
import { HeaderMenu } from './HeaderMenu';
import { userActions } from '../../_actions';

function Header() {
  const dispatch = useDispatch();
  const handleLoadHomePage = () => {
    window.location = `${document.location.origin}`;
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(userActions.getCoins());
    }

  }, [dispatch]);

  return (
    <>
      <header>
        <nav id="my__navbar" className="sticky-top navbar navbar-expand-lg navbar__custom">
          <div className="container-fluid">
            <div id="menu" className=" d-flex justify-content-between align-items-center">
              <div className="menu__logo mr-auto">
                <Link className="navbar-brand" onClick={handleLoadHomePage} to="">
                  <img src="/assets/images/logo.png" alt="" />
                </Link>
                <button className="btn navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                  aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fas fa-bars"></i>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="navbarText">
                <div className="menu__right user__actions d-flex justify-content-center align-items-center">
                  {<HeaderMenu />}
                </div>
              </div>

              <div id="menu_user_dropdow" className="dropdown user__actions">
                {<HeaderRight />}
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div id="share"></div>
    </>
  );
}

export { Header };