import { combineReducers } from 'redux';
import { login } from './authentication/login.reducer';
import { coins } from './users/getCoins.reducer';
import { authentication } from './authentication/authentication.reducer';
import { registration } from './authentication/registration.reducer';
import { updatePassword } from './authentication/updatePassword.reducer';
import { resetPassword } from './authentication/resetPassword.reducer';
import { userProfile } from './authentication/userProfile.reducer';
import { listLiveStream } from './livestream/listLiveStream.reducer';
import { liveStreamDetail } from './livestream/liveStreamDetail.reducer';
import { chargeCoin } from './users/chargeCoin.reducer';
import { follow } from './livestream/follow.reducer';
import { comment } from './livestream/comment.reducer';
import { videoCategories } from './livestream/videoCategories.reducer';
import { communities } from './livestream/communities.reducer';
import { followerUsers } from './followerUser.reducer';
import { singleUrl } from './livestream/singleUrl.reducer';
import { events } from './events/events.reducer';
import { myEvents } from './events/myEvents.reducer';
import { myDraftEvents } from './events/myDraftEvents.reducer';
import { draftEvent } from './livestream/draftEvent.reducer';
import { buyTicketLivestream } from './livestream/buyTicketLivestream.reducer';

import { loadSendCoins } from './livestream/loadSendCoins.reducer';
import { alert } from './alert.reducer';
import { report } from './livestream/report.reducer';
import { listUsers } from './users/getUsers';

const rootReducer = combineReducers({
  login,
  authentication,
  registration,
  updatePassword,
  resetPassword,
  listLiveStream,
  liveStreamDetail,
  follow,
  report,
  chargeCoin,
  coins,
  comment,
  loadSendCoins,
  videoCategories,
  communities,
  followerUsers,
  events,
  singleUrl,
  userProfile,
  alert,
  draftEvent,
  myEvents,
  myDraftEvents,
  buyTicketLivestream,
  listUsers,
});

export default rootReducer;
