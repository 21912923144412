import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function ModalCommunity(props) {
  let communities = useSelector(state => state.communities.communities);
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    if (props.communityID) {
      setSelectedOption(props.communityID && props.communityID[0])
    }
  }, [props.communityID])

  const handleSelectCommunity = async (communityId) => {
    if (communityId === selectedOption) {
      setSelectedOption(0);
    } else {
      await setSelectedOption(communityId);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (communities && communities.length) {
      if (selectedOption !== 0) {
        props.selectCommunity(selectedOption);
      } else {
        props.selectCommunity('配信するコミュニティを選択');
      }
    }
  }

  return (
    <div className="modal fade" id="modalCommunity" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <span className="community__title">{communities && communities.length ? '配信するコミュニティを選択' : '参加コミュニティがございません。'}</span>
            <div className={`${(communities && communities.length > 6) ? "community__body community__body_hd" : "community__body"}`}>
              {communities && communities.map((community, i) => {
                return <div key={i}>
                  <span className="buy__ticket__radio community__radio">
                    <input
                      type="radio"
                      id={community.id}
                      name="ticket-distribute"
                      value={community.id}
                      checked={selectedOption === community.id ? 'checked' : ''}
                      onChange={() => handleSelectCommunity(community.id)}
                    />
                    <label htmlFor={community.id}>{community.title}</label>
                  </span>
                </div>
              })}
            </div>

            <div className="community__btn mt-4">
              <button type="button"
                className="btn schedule__but_return"
                style={communities && communities.length ? { display: 'block' } : { display: 'none' }}
                data-dismiss="modal"
                aria-label="Close">キャンセル</button>
              <button type="button" className="btn schedule__but_next"
                onClick={handleSubmit}
                data-dismiss="modal"
                aria-label="Close">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
