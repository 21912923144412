import React from 'react';
import { useWindowResize } from '../LiveStream/LiveStreamDetail/useWindowResize';

const EmojiWrapper = ({ className, label }) => {
  const { width } = useWindowResize();
  return (
    <span
      role='img'
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      className={className}
    >
      <img src="/assets/images/icon_yellow.png" alt="" className={`${width > 768 ? "icon__like" : "icon__like_mobile"}`} />
    </span>
  )
}

export default EmojiWrapper;
