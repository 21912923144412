import React, { useState, useEffect } from 'react';
import { generate } from 'short-id'
import FirstEmojiBubble from './FirstEmojiBubble';
import AutoExpire from './AutoExpire';
import { useWindowResize } from '../LiveStream/LiveStreamDetail/useWindowResize';

const randomNumber = (max, min) => {
  return Math.floor(Math.random() * (max - min)) + min
}

const randomPosOrNeg = (max, min) => {
  let randomNumber = Math.floor(Math.random() * (max - min));
  randomNumber *= Math.floor(Math.random() * 2) === 1 ? 1 : -1

  return randomNumber
}

const Board = ({ displayLike }) => {
  const { width } = useWindowResize();
  const [emojiQueue, setEmojiQueue] = useState([]);
  const [bottom, setBottom] = useState();
  const [bottomBottom, setBottomBottom] = useState();

  useEffect(() => {
    if (displayLike) {
      if (width > 768) {
        setBottom(10);
        setBottomBottom(70);
      } else {
        const hideBoxChat = JSON.parse(localStorage.getItem('hideBoxChat'));
        if (hideBoxChat) {
          if (width < 321) {
            setBottom(76);
            setBottomBottom(92);
          } else if (width >= 321 && width < 414) {
            setBottom(74);
            setBottomBottom(92);
          } else if (width >= 414 && width < 766) {
            setBottom(75);
            setBottomBottom(92);
          } else {
            setBottom(67);
            setBottomBottom(87);
          }
        } else {
          if (width < 321) {
            setBottom(62);
            setBottomBottom(86);
          } else if (width >= 321 && width < 414) {
            setBottom(57);
            setBottomBottom(87);
          } else if (width >= 414 && width < 766) {
            setBottom(55);
            setBottomBottom(85);
          } else {
            setBottom(45);
            setBottomBottom(55);
          }
        }
      }

      setEmojiQueue([
        ...emojiQueue,
        {
          size: randomNumber(2, 1),
          one: randomPosOrNeg(75, 50),
          two: randomPosOrNeg(75, 50),
          id: generate()
        }
      ])
    }
  }, [displayLike]);

  return (
    <>
      <div>
        {emojiQueue.map(({ id, size, one, two }, i) =>
          <AutoExpire key={i}>
            <FirstEmojiBubble
              key={id}
              size={size}
              bottom={bottom}
              bottomBottom={bottomBottom}
              one={one}
              two={two}
            />
          </AutoExpire>
        )}
      </div>
    </>
  )
}

export default Board;
