import { userConstants } from '../../_constants';

const initialStateFollow = { chargeCoins: '', url: '' }

export function chargeCoin(state = initialStateFollow, action) {
  switch (action.type) {
    case userConstants.CHARGE_COIN_REQUEST:
      return {
        ...state,
      };
    case userConstants.CHARGE_COIN_SUCCESS:
      return {
        ...state,
        chargeCoins: action.payload,
      };
    case userConstants.CHARGE_COIN_FAILURE:
      return {
        chargeCoins: '',
      };
    case userConstants.CHARGE_COIN_BY_CREDIT_SUCCESS:
      return {
        ...state,
        url: action.payload,
      };
    default:
      return state
  }
}
