import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListLiveStream, ListScheduleLiveStream } from '../../_components/LiveStream';
import { Ticket } from '../../_components/Ticket/Ticket';
import { EventCategory } from '../../_components/EventCategory/EventCategory';
import { Header } from '../../_components/Header';
import { Footer } from '../../_components/Footer/Footer';
import { livestreamActions } from '../../_actions';

// import './custom-style.css'
import '../../_assets/css/charge-coin.css';
import './home-page.css';

function TopPage() {
  const dispatch = useDispatch();
  const [position, setPosition] = useState(window.pageYOffset)
  const [visible, setVisible] = useState(true)
  const page = 1;
  const limit = 12;
  const limitSchedule = 12;

  useEffect(() => {
    const goBack = localStorage.getItem("goBack");
    if (goBack === "/top") {
      localStorage.setItem("goBack", "");
      return window.location.reload();
    }

    if (window.location.pathname === "/") {
      document.getElementById("homepage").classList.add("homepage");
    }

    const getLivestream = async () => {
      await dispatch(livestreamActions.getScheduleLivestreamList(page, limitSchedule))
      await dispatch(livestreamActions.getListLivestream(page, limit))
    }
    getLivestream();
  }, [])

  useEffect(()=> {
    const handleScroll = () => {
       let moving = window.pageYOffset

       setVisible(position > moving);
       setPosition(moving)
    };
    window.addEventListener("scroll", handleScroll);
    return(() => {
       window.removeEventListener("scroll", handleScroll);
    })
  })

  return (
    <>
      <Header />
      <section className="intro" id="intro">
        <video id="video1" className="background-movie" src="/movies/RLounge.mp4" autoPlay muted playsInline></video>
        <video id="video2" className="background-movie" src="/movies/MASHROOM_HIGHTLIGHT.mp4" autoPlay muted
          playsInline></video>
        <div className="site">
          <div>
            <img className="site--logo" src='/assets/images/logo.svg' alt="" />
            <h1 className="site--title">音と人、夢とリアルが繋がる場所</h1>
          </div>
          <div className="download-buttons">
            <a id="iOS" href='https://itunes.apple.com/us/app/livedreamer/id1445831749'>
              <img className="button" src="/assets/images/ios.png" alt="" />
            </a>
            <a id="android" href='https://play.google.com/store/apps/details?id=com.live_dreamer.LiveDreamer'>
              <img className="button button-showy" src="/assets/images/android.png" alt="" />
            </a>
          </div>
        </div>
      </section>

      <section className="section section__live_stream section__box_live_stream">
        <div className="container-fluid__livesteam">
          <div id="tabs_livesteam"
            className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
            <ul className="nav nav-tabs" id="tab__list_livestream" role="tablist">
              <li className="nav-item nav-item_cus">
                <a
                  className="nav-link active cursor_pointer_default"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >配信中</a>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="video__related">
                <ListLiveStream limit={limit} />
              </div>
            </div>
          </div>
          <div id="tabs_livesteam2"
            className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
            <ul className="nav nav-tabs" id="tab__list_livestream" role="tablist">
              <li className="nav-item nav-item_cus">
                <a
                  className="nav-link active cursor_pointer_default"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="true"
                >配信予定</a>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent2">
            <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div className="video__related">
                <ListScheduleLiveStream limit={limitSchedule} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about about_cus" id="about">
        <div className="cm-movies">
          <video src="/movies/LiveDreamer.mp4" className="video video__custom" loop autoPlay controls muted
            playsInline></video>
        </div>
      </section>

      <section className="home_page__ticket" id="about">
        <Ticket />
      </section>

      <section className="home_page__event_category" id="about">
        <EventCategory />
      </section>

      <Footer />

      <div className="pagetop pagetop_btn" id="pagetop" style={{display: position ? 'block' : 'none'}}>
        {/* <a href="#" id="pagetop_btn">ページTOPへ</a> */}
        <a href="#" id="pagetop_btn"></a>
      </div>
    </>
  );
};

export { TopPage };
