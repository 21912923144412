import React, { useEffect, useRef, useState } from 'react';
import * as moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { livestreamActions } from '../../_actions';
import useInfiniteScroll from './useInfiniteScroll';
import { history } from "../../_helpers";

function LiveContent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const limit = 10;
  let selectListLiveStream = useSelector(state => state.listLiveStream.listLivestreamMyPage);
  let userLoginId = useSelector(state => state.login ? state.login.user.id : '');
  const [listItems, setListItems] = useState("");
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [loadMore, setLoadMore] = useState(true);
  const targetRef = useRef();
  // const [dimensions, setDimensions] = useState({ height: 0 });

  useEffect(() => {
    selectListLiveStream = [];
    setListItems("");
    const listLivestream = async () => {
      await dispatch(livestreamActions.getListLivestreamByUserId(userLoginId, 'livestream', page, limit))
    }
    listLivestream();
  }, []);

  useEffect(() => {
    let timer;
    if (selectListLiveStream && selectListLiveStream.length > 0) {
      setLoadMore(true);
      timer = setTimeout(() => {
        if (listItems.length > 0 && listItems.length < 10) {
          setLoadMore(false);
        }
        setListItems(prevState => [
          ...prevState,
          ...selectListLiveStream
        ]);
        setIsFetching(false);
      }, 500);
    } else if (selectListLiveStream && selectListLiveStream.length === 0) {
      setLoadMore(false);
      setIsFetching(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [selectListLiveStream]);

  function fetchMoreListItems() {
    dispatch(livestreamActions.getListLivestreamByUserId(userLoginId, 'livestream', page + 1, limit));
    setPage(page + 1);
  }

  const handleViewLive = (livedetail) => {
    localStorage.setItem('my-page-video-detail', JSON.stringify(livedetail));
    history.push(`/my-page/video-detail/${livedetail.id}`);
  }

  return (
    <div ref={targetRef}>
      {listItems && listItems.map((listLiveStream, i) => {
        return <div className="live_content_tab2" key={i} onClick={() => handleViewLive(listLiveStream)}>
          <span className="schedule_content_img">
            <img
              src={(listLiveStream && listLiveStream.video && listLiveStream.video.thumbnail_endpoint) ? listLiveStream.video.thumbnail_endpoint : "/assets/images/livestream.png"}
              alt="" />
          </span>
          <span className="schedule_content_box">
            <span className="schedule_time_day">
              <span className="schedule_content_time">
                {listLiveStream.livestream ? <span
                  className="schedule_day">{moment.unix(listLiveStream.livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")}~</span> : null}

              </span>
              <span
                className="schedule_content_live">{listLiveStream.livestream ? listLiveStream.livestream.title : null}</span>
            </span>
            <span
              className="schedule_comment">{listLiveStream.livestream ? listLiveStream.livestream.description : null}</span>
          </span>
        </div>
      })}
      {isFetching && loadMore && <span className="spinner-border sprinner-my-page"></span>}
    </div>
  );
};

export { LiveContent };