import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { GiveAwayTicket } from '../../_components/EventDetailAfterCreate/GiveAwayTicket';
import { eventActions } from '../../_actions';
import LoaderCenter from '../../_components/Loader/LoaderCenter';

function EventEditFreeTicketPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const eventId = useParams().eventId;
  let eventsDetail = useSelector(state => state.events.eventDetail);
  let loadingEventDetail = useSelector(state => state.events.loadingEventDetail);
  const [createSchedule, setCreateSchedule] = useState([]);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
    const getEventsDetail = async () => {
      await dispatch(eventActions.getEventDetail(eventId));
    }
    getEventsDetail();
  }, [dispatch, eventId])

  useEffect(() => {
    if (eventsDetail) {
      const creSchedule = {
        "thumbnail": eventsDetail.livestream.thumbnail,
        "title": eventsDetail.livestream.title,
        "description": eventsDetail.livestream.description,
        "href": eventsDetail.livestream.href,
        "start_time": eventsDetail.livestream.start_time,
        "categories": eventsDetail.livestream.categories.map(category => category.id),
        "tickets": eventsDetail.livestream.tickets,
        "user_tickets": [],
        "draft_id": eventsDetail.livestream.id,
        "email": eventsDetail.livestream.email
      }
      setCreateSchedule(creSchedule);
    }
  }, [eventsDetail]);

  const handleBackEventDetail = () => {
    history.goBack();
  }

  return (
    <>
      <Header />
      <div className="home__page_live">
        {eventsDetail && loadingEventDetail && <GiveAwayTicket
          handleBackEventDetail={handleBackEventDetail}
          createSchedule={createSchedule}
          imagePreviewUrl={eventsDetail.livestream.thumbnail}
          eventId={eventId}
          editEvenst={true}
        />}
        {!loadingEventDetail && <LoaderCenter />}

        <Footer />
      </div>
    </>
  );
};

export { EventEditFreeTicketPage };
