import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../../_helpers';
import { eventActions, livestreamActions, userActions } from '../../../_actions';
import Linkify from 'react-linkify';

export function LivestreamInfo(props) {
  const liveStreamDetail = props.liveStreamDetail;
  const dispatch = useDispatch();
  let selectUserProfile = useSelector(state => state.events.userProfile);
  const commentState = useSelector(state => state.comment.comment);
  const isFollowed = liveStreamDetail ? liveStreamDetail.livestream.user.is_followed : '';
  const [liveStreamDetailFollow, setLiveStreamDetailFollow] = useState(isFollowed);
  const [numberLike, setNumberLike] = useState(0);
  const [numberViews, setNumberViews] = useState(0);
  const [numberComment, setNumberComment] = useState(0);
  const [numberCoin, setNumberCoin] = useState(0);
  let isRendered = useRef(false);


  useEffect(() => {
    localStorage.setItem('follow', '');
  }, []);

  useEffect(() => {
    if (liveStreamDetail) {
      const getUserProfile = async () => {
        await dispatch(userActions.getUserProfile(liveStreamDetail.livestream.user.id));
      }
      getUserProfile();
    }
  }, [liveStreamDetail]);

  useEffect(() => {
    setLiveStreamDetailFollow(isFollowed);
  }, [isFollowed]);

  useEffect(() => {
    if (commentState) {
      let comment;
      if (numberComment !== 0) {
        comment = numberComment + commentState
      } else if (numberComment === 0) {
        const number_comment = liveStreamDetail ? liveStreamDetail.number_comment : 0;
        comment = number_comment + commentState;
      }
      setNumberComment(comment);
      dispatch(livestreamActions.comment(''));
    }
  }, [commentState]);

  useEffect(() => {
    isRendered = true;
    socket.on('userChat', (data) => {
      if (isRendered) {
        handleSocketOn(data.number_comment, data.number_views, data.number_coin, data.number_like);
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    socket.on('userLike', (data) => {
      if (isRendered) {
        handleSocketOn(data.number_comment, data.number_views, data.number_coin, data.number_like);
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    socket.on('userSendCoin', (data) => {
      if (isRendered) {
        handleSocketOn(data.number_comment, data.number_views, data.number_coin, data.number_like);
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    socket.on('userJoinRoom', (data) => {
      if (isRendered) {
        handleSocketOn(data.number_comment, data.number_views, data.number_coin, data.number_like);
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    socket.on('userLeaveRoom', (data) => {
      if (isRendered) {
        handleSocketOn(data.number_comment, data.number_views, data.number_coin, data.number_like);
      }
    });

    return () => {
      isRendered = false;
    };
  });

  const handleSocketOn = (number_comment, number_views, number_coin, number_like) => {
    if (numberComment !== number_comment) {
      setNumberComment(number_comment);
    }
    if (numberViews !== number_views) {
      setNumberViews(number_views);
    }
    if (numberCoin !== number_coin) {
      setNumberCoin(number_coin);
    }
    if (numberLike !== number_like) {
      setNumberLike(number_like);
    }
  }

  const handleFollow = async (userId, follow) => {
    const stateFollow = localStorage.getItem('follow');
    let isFollow = stateFollow ? !follow : !isFollowed;
    localStorage.setItem('follow', 'follow');

    follow ? await dispatch(livestreamActions.unfollow(userId)) : await dispatch(livestreamActions.follow(userId));
    setLiveStreamDetailFollow(isFollow);

    let eventTimeOut = setTimeout(async () => {
      await dispatch(userActions.getUserProfile(liveStreamDetail.livestream.user.id));
      clearTimeout(eventTimeOut);
    }, 500);
  }

  return (
    <>
      {liveStreamDetail &&
        <div ref={props.elRef}>
          <div
            className="section_livestream_info d-flex justify-content-between align-items-center content__margin-top livestream__infor">
            <div className="stream__info">
              <div className="heading_livedream">{liveStreamDetail.livestream.title}</div>
              <div className="meta_info">
                <span><i
                  className="far fa-comment-dots" />{numberComment ? numberComment : liveStreamDetail.number_comment}</span>
                <span><i
                  className="far fa-eye" />{liveStreamDetail.livestream.status === 1 || liveStreamDetail.livestream.status === 2
                    ? (numberViews ? numberViews : liveStreamDetail.number_views)
                    : 0}</span>
                <span><img className="small_img" src="/assets/images/icon.png"
                  alt="" />{numberLike ? numberLike : liveStreamDetail.number_like}</span>
                <span><img className="small_img" src="/assets/images/coins.png"
                  alt="" />{numberCoin ? numberCoin : liveStreamDetail.number_coin}</span>
              </div>
            </div>
            <div className="acction_share">
              <span>
                <a data-toggle="modal" data-target="#modalShare">シェア<i className="far fa-share-square" /></a>
              </span>
              <span>
                <a data-toggle="modal" data-target="#modalReport"> レポート<i className="fas fa-flag" /></a>
              </span>
            </div>
          </div>
          <div className="stream_description d-flex justify-content-between align-items-center content__margin-top">
            <div className="author_info d-flex justify-content-between align-items-center user_infor">
              <img src={liveStreamDetail.livestream.user && liveStreamDetail.livestream.user.avatar} 
                onError={(event) => event.target.src = '/assets/images/avatar.png'}
                alt="" />
              <div className="author__name">
                <h6>{liveStreamDetail.livestream.user.name}</h6>
                <span>{selectUserProfile && selectUserProfile.number_follower}フォロワー</span>
                <div className="author_detail">
                  <Linkify properties={{ target: '_blank' }}>{liveStreamDetail.livestream.description}</Linkify>
                </div>
              </div>
            </div>
            <button className="btn btn__follow_chanel"
              onClick={() => handleFollow(liveStreamDetail.livestream.user.id, liveStreamDetailFollow)}>
              {liveStreamDetailFollow ? 'フォローを解除する' : 'フォローする'}
            </button>
          </div>
        </div>
      }
    </>
  );
}
