import React from 'react';
import { Link } from 'react-router-dom';
import { GroupBeforeLogin } from '../../_utils/Group/GroupBeforeLogin';
import { signInWithGoogle } from "../../_services/firebase";
import { signInWithFacebook } from "../../_services/firebase";
import { signInWithTwitter } from "../../_services/firebase";
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';

function BeforeLoginPage() {
  const clientID = process.env.REACT_APP_LINE_CONSUMER_CHANNEL_ID;
  const redirectURI = process.env.REACT_APP_LINE_REDIRECT_URI;
  const handleSocialClick = (sns) => {
    switch (sns) {
      case "facebook":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "facebook")
        signInWithFacebook();
        break;
      case "google":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "google")
        signInWithGoogle();
        break;
      case "twitter":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "twitter")
        signInWithTwitter();
        break;
      default:
        throw new Error("Unsupported SNS" + sns)

    }
  }

  const signIn = () => {
    return GroupBeforeLogin.map((item, i) => {
      switch (item.id) {
        case 1:
          return <li key={i}>
            <Link className="button" to={item.url}>
              <img src={item.image} alt={item.image} />
              <span>{item.name}</span>
            </Link>
          </li>
        case 2:
          return <li key={i}>
            <Link to="" onClick={() => handleSocialClick("twitter")} className="button">
              <img src={item.image} alt={item.image} />
              <span className="buttonText">{item.name}</span>
            </Link>
          </li>
        case 4:
          return <li key={i}>
            <Link to="" onClick={() => handleSocialClick("google")} className="button">
              <img src={item.image} alt={item.image} />
              <span className="buttonText">{item.name}</span>
            </Link>
          </li>
        case 5:
          return <li key={i}>
            <Link to="" onClick={() => handleSocialClick("facebook")} className="button">
              <img src={item.image} alt={item.image} />
              <span className="buttonText">{item.name}</span>
            </Link>
          </li>
        case 6:
          return <li key={i}>
            <a
              href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&state=12345abcde&scope=profile%20openid`}
              className="button">
              <img src={item.image} alt={item.image} />
              <span className="buttonText">{item.name}</span>
            </a>
          </li>
        default:
          return false;
      }
    })
  }

  return (
    <>
      <Header />
      <div className="home__page">
        <img className="home__image" src="/assets/images/bg.png" alt="" />
        <div className="list__livestream_login d-flex justify-content-center align-items-center">
          <ul>
            <h4 className="heading_livedream">ログインする</h4>
            <div className="sub__content">
              <span>アカウントの管理、通知やコメントの確認などが行えます。</span>
            </div>

            {signIn()}

            <div className="link__login">
              <span className="pr-3">まだアカウントをお持ちでないですか？</span>
              <Link to="/before-register">登録</Link>
            </div>
          </ul>
        </div>

      </div>
      <Footer />
    </>
  );
};

export { BeforeLoginPage };
