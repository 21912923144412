import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as moment from 'moment';
import useInfiniteScroll from './useInfiniteScroll';
import { userActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Linkify from 'react-linkify';

function ScheduleHistoryContent(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTabSub1, setActiveTabSub1] = useState(true);
  const [activeTabSub2, setActiveTabSub2] = useState(false);
  let sbuyTicketLivestream = useSelector(state => state.buyTicketLivestream.buyTicketLivestream);
  let shistoryLivestream = useSelector(state => state.buyTicketLivestream.historyLivestream);
  const [isBuyTicketFetching, setIsBuyTicketFetching] = useInfiniteScroll(fetchMoreBuyTicket);
  const [loadMoreBuyTicket, setLoadMoreBuyTicket] = useState(true);
  const [isHistoryFetching, setIsHistoryFetching] = useInfiniteScroll(fetchMoreHistory);
  const [loadMoreHistory, setLoadMoreHistory] = useState(true);
  const [buyTicketLivestream, setBuyTicketLivestream] = useState("");
  const [historyLivestream, setHistoryLivestream] = useState("");
  const [page1, setPage1] = useState(1);
  const limit1 = 10;
  const [page2, setPage2] = useState(1);
  const limit2 = 10;

  useEffect(() => {
    sbuyTicketLivestream = [];
    shistoryLivestream = [];
    setBuyTicketLivestream("");
    setHistoryLivestream("");
    const getBuyTicketLivestream = async () => {
      await dispatch(userActions.getBuyTicketLivestream(0, page1, limit1))
    }
    getBuyTicketLivestream();

    const getHistoryLivestream = async () => {
      await dispatch(userActions.getBuyTicketLivestream(1, page2, limit2))
    }
    getHistoryLivestream();
  }, []);

  useEffect(() => {
    let timer;
    if (sbuyTicketLivestream && sbuyTicketLivestream.length > 0) {
      setLoadMoreBuyTicket(true);
      timer = setTimeout(() => {
        if (buyTicketLivestream.length > 0 && buyTicketLivestream.length < 10) {
          setLoadMoreBuyTicket(false);
        }
        setBuyTicketLivestream(prevState => [
          ...prevState,
          ...sbuyTicketLivestream
        ]);
        setIsBuyTicketFetching(false);
      }, 500);
    } else if (sbuyTicketLivestream && sbuyTicketLivestream.length === 0) {
      setLoadMoreBuyTicket(false);
      setIsBuyTicketFetching(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [sbuyTicketLivestream]);

  useEffect(() => {
    let timer;
    if (shistoryLivestream && shistoryLivestream.length > 0) {
      setLoadMoreHistory(true);
      timer = setTimeout(() => {
        if (historyLivestream.length > 0 && historyLivestream.length < 10) {
          setLoadMoreHistory(false);
        }
        setHistoryLivestream(prevState => [
          ...prevState,
          ...shistoryLivestream
        ]);
        setIsHistoryFetching(false);
      }, 500);
    } else if (shistoryLivestream && shistoryLivestream.length === 0) {
      setLoadMoreHistory(false);
      setIsHistoryFetching(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [shistoryLivestream]);

  function fetchMoreBuyTicket() {
    dispatch(userActions.getBuyTicketLivestream(0, page1 + 1, limit1))
    setPage1(page1 + 1);
  }

  function fetchMoreHistory() {
    dispatch(userActions.getBuyTicketLivestream(1, page2 + 1, limit1))
    setPage2(page2 + 1);
  }

  const handleActiveTabSub1 = () => {
    setActiveTabSub1(true);
    setActiveTabSub2(false);
  }

  const handleActiveTabSub2 = () => {
    setActiveTabSub1(false);
    setActiveTabSub2(true);
  }

  const handleClickLiveStream = (id) => {
    history.push(`/events/${id}`);
  }

  return (
    <>
      <div id="tab_user_profile_sub"
        className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
        <ul className="nav nav-tabs" id="tab__list_user_profile_schedule" role="tablist">
          <li className="nav-item_user_profile" onClick={handleActiveTabSub1}>
            <a
              className={activeTabSub1 ? `nav-link_user_profile_sub user_profile__active_sub` : `nav-link_user_profile_sub`}
              id="schedule-tab"
              data-toggle="tab"
              href="#schedule"
              role="tab"
              aria-controls="schedule"
              aria-selected="true"
            >予定</a>
          </li>
          <li className="nav-item_user_profile" onClick={handleActiveTabSub2}>
            <a
              className={activeTabSub2 ? `nav-link_user_profile_sub user_profile__active_sub` : `nav-link_user_profile_sub`}
              id="history-tab"
              data-toggle="tab"
              href="#history"
              role="tab"
              aria-controls="history"
              aria-selected="true"
            >履歴</a>
          </li>
        </ul>
      </div>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
          {
            historyLivestream && historyLivestream.map((livestream, i) => {
              if (livestream.livestream) {
                return (
                  <div className="schedule_content" key={i}>
                    <span className="schedule_content_img cursor_pointer"
                      onClick={() => handleClickLiveStream(livestream.livestream.id)}>
                      <img src={livestream.livestream.thumbnail} 
                        onError={(event) => event.target.src = '/assets/images/livestream.png'}
                        alt="" />
                    </span>
                    <span className="schedule_content_box">
                      <span className="schedule_time_day">
                        <span className="schedule_content_time">
                          <span
                            className="schedule_day">{moment.unix(livestream.livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")}</span> ~
                        </span>
                        <span className="schedule_content_live cursor_pointer"
                          onClick={() => handleClickLiveStream(livestream.livestream.id)}>{livestream.livestream.title}</span>
                      </span>
                      <span className="schedule_comment"><Linkify
                        properties={{ target: '_blank' }}>{livestream.livestream.description}</Linkify></span>
                    </span>
                  </div>
                )
              }

            })

          }
          {isHistoryFetching && loadMoreHistory && <span className="spinner-border sprinner-my-page"></span>}
        </div>
        <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">

          {
            buyTicketLivestream && buyTicketLivestream.map((livestream, i) => {
              if (livestream.livestream) {
                return (
                  <div className="schedule_content" key={i}>
                    <span className="schedule_content_img cursor_pointer"
                      onClick={() => handleClickLiveStream(livestream.livestream.id)}>
                      <img src={livestream.livestream.thumbnail} 
                        onError={(event) => event.target.src = '/assets/images/livestream.png'}
                        alt="" />
                    </span>
                    <span className="schedule_content_box">
                      <span className="schedule_time_day">
                        <span className="schedule_content_time">
                          <span
                            className="schedule_day">{moment.unix(livestream.livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")}</span> ~
                        </span>
                        <span className="schedule_content_live cursor_pointer"
                          onClick={() => handleClickLiveStream(livestream.livestream.id)}>{livestream.livestream.title}</span>
                      </span>
                      <span className="schedule_comment"><Linkify
                        properties={{ target: '_blank' }}>{livestream.livestream.description}</Linkify></span>
                    </span>
                  </div>
                )
              }

            })

          }
          {isBuyTicketFetching && loadMoreBuyTicket && <span className="spinner-border sprinner-my-page"></span>}
        </div>
      </div>
    </>
  );
};

export { ScheduleHistoryContent };