import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRouteRedirectLivestreams({ component: Component, roles, ...rest }) {
  return (
    <Route {...rest} render={props => {
      if (localStorage.getItem('token') && window.location.pathname !== '/top') {
        return <Redirect to={{ pathname: '/top', state: { from: props.location } }} />
      }

      return <Component {...props} />
    }} />
  );
}

export { PrivateRouteRedirectLivestreams };
