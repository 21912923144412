import React from 'react';

export function ModalConfirmLive(props) {

  const handleConfirm = () => {
    props.handleClickEventDetail();
  }

  const handleBackConfirm = () => {
    props.handleBackConfirm();
  }

  return (
    <div className="modal fade" id="modalConfirmLive" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">無料視聴時間は30秒です。</div>
            <div className="give_away_ticket__content">続きはチケットの購入後にご視聴頂けます。</div>
            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn"
                onClick={handleBackConfirm}
                data-dismiss="modal"
                aria-label="Close"
              >いいえ
              </button>
              <button className="btn schedule__but_next give_away_ticket__btn"
                onClick={handleConfirm}
                data-dismiss="modal"
                aria-label="Close"
              >チケット購入
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
