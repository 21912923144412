import React, { useState } from "react";
import "./buyticketcontent.css"
import { Step1 } from "./Step/Step1";
import { Step2 } from "./Step/Step2";
import { Step3 } from "./Step/Step3";

export function BuyTicketContent(props) {
  const ticket = props.event.livestream.tickets.find(ticket => ticket.id === +props.ticketId);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleFirstName = (data) => {
    setFirstName(data);
  }

  const handleLastName = (data) => {
    setLastName(data);
  }

  const handleEmail = (data) => {
    setEmail(data)
  }

  return (
    <>
      <div className="container">
        {props.result !== 'success' ?
          <div className="list_progress_event_pm">
            <div className="progress__text d-flex justify-content-between align-items-center content__margin-top">
              <span className="ticket_active">詳細入力</span>
              <span className="progress__text_span ">支払い</span>
              <span className="" >確認</span>
            </div>
            <div className="progress__line_event d-flex justify-content-between align-items-center content__margin-top">
              <div className="cicle_dots cicle_dots1" />
              <span className="line" />
              <div className="cicle_dots" />
              <span className="line " />
              <div className="cicle_dots" />
            </div>
          </div> :
          <div className="list_progress_event_pm">
            <div className="progress__text d-flex justify-content-between align-items-center content__margin-top">
              <span className="ticket_active">詳細入力</span>
              <span className="progress__text_span ticket_active">支払い</span>
              <span className="ticket_active" >確認</span>
            </div>
            <div className="progress__line_event d-flex justify-content-between align-items-center content__margin-top">
              <div className="cicle_dots cicle_dots1" />
              <span className="line line1" />
              <div className="cicle_dots cicle_dots2" />
              <span className="line line2" />
              <div className="cicle_dots cicle_dots3" />
            </div>
          </div>
        }

        <div className="container_buy_ticket">
          {props.result === 'success' ?
            <fieldset>
              <Step3 event={props.event} ticket={ticket ? ticket : null} />
            </fieldset>
            :
            <>
              <fieldset>
                <Step1 event={props.event} ticket={ticket ? ticket : null} handleFirstName={handleFirstName} handleLastName={handleLastName} handleEmail={handleEmail} />
              </fieldset>
              <fieldset>
                <Step2 event={props.event} ticket={ticket ? ticket : null} firstName={firstName} lastName={lastName} email={email} />
              </fieldset>
            </>
          }

        </div>
      </div>
    </>
  );
}
