import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { authenticationActions } from '../../_actions/authentication.actions';
import { logOut } from "../../_services/firebase";

const hiddenMenuArray = ['/before-login', '/login', '/before-register', '/register', '/']

export function HeaderRight() {
  let userLogin = useSelector(state => state.login.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const tokenUserLogin = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const [hiddenMenu, setHiddenMenu] = useState(false);

  useEffect(() => {
    setHiddenMenu(hiddenMenuArray.includes(location.pathname));
  }, [location]);

  const handleLogout = async () => {
    const type = localStorage.getItem('type-social');
    if (type) {
      await logOut();
    }

    dispatch(authenticationActions.logout());
    window.location = `${document.location.origin}/top`;
  }

  const handleMyPage = (e) => {
    e.preventDefault();
    history.push(`/my-page`);
  }

  return (
    <>
      {userLogin && !hiddenMenu &&
        <button className="btn dropdown-toggle user__actions_custom" type="button" id="menu__dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="user-name">{(userLogin) ? userLogin.name : tokenUserLogin.name}</span>
          <img src={userLogin.avatar} 
            onError={(event) => event.target.src = '/assets/images/avatar.png'}
            alt="" className="img-avatar" />
        </button>}
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="menu__dropdown">
        <Link to="" className="dropdown-item" onClick={handleMyPage}>マイページ</Link>
        <Link to={`/livestreams/charge-coin?callback=${window.location.href}`} className="dropdown-item">チャージ</Link>
        <Link to="" className="dropdown-item"> スマホで見る</Link>
        <Link to="" className="dropdown-item" onClick={() => handleLogout()}> ログアウト</Link>
      </div>

      {!token && !hiddenMenu ?
        <Link className="nav-link nav-link__login" style={{ color: '#ffffff' }} to="/before-login">
          <span className="menu__login">ログイン/登録</span>
          <img src={'/assets/icons/name.png'} alt="" />
        </Link>
        : null
      }
    </>
  );
}