import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import { PersistGate } from "redux-persist/integration/react";
// import { history, store, persistor } from './_helpers';
import { history, store } from './_helpers';
import { App } from './App/App';
import ScrollToTop from './scrollToTop';

import './_assets/css/main.css';
import './_assets/css/mobile.css';
import './_assets/css/styles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Router history={history}>
        <ScrollToTop />
        <App />
      </Router>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();



