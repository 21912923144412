import React from 'react';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { MyPageDetail } from '../../_components/MyPage/MyPageDetail';

function MyPage() {

  return (
    <>
      <Header />
      <div className="home__page_live">
        <MyPageDetail />
      </div>
      <Footer />
    </>
  );
};

export { MyPage };
