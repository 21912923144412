import { livestreamConstants } from '../../_constants';

const initialStateFollow = { comment: '' }

export function comment(state = initialStateFollow, action) {
  switch (action.type) {
    case livestreamConstants.COMMENT_SUCCESS:
      return {
        ...state,
        comment: action.payload,
      };
    default:
      return state
  }
}
