import React from "react";
import { DeliverySchedule } from './DeliverySchedule';
import { ConstructionSchedule } from './ConstructionSchedule';
import "./list_schedule.css";

export const ListSchedule = () => {

  return (
    <>
      <div className="schedule-livestream-head">
        <h2>配信スケジュール管理</h2>
      </div>
      <div className="create_delivery_schedule">
        <a className="btn schedule__but_next btn-create-livestream" href="/create-livestream-schedule">配信スケジュールを新規作成</a>
      </div>

      <DeliverySchedule />

      <div className="margin__bottom_1"></div>

      <ConstructionSchedule />

      <div className="margin__bottom_5"></div>
    </>
  );
}
