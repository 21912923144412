import styled, { keyframes } from 'styled-components';
import EmojiWrapper from './EmojiWrapper';

const floatAnimation = (bottom, bottomBottom, one, two) => keyframes`
0% { bottom: ${bottom}%; transform: translateX(0)}
50% {opacity: 0.5; transform: translateX(${one}px)}
100% { opacity: 0.1; bottom: ${bottomBottom}%; transform: translateX(${two}px)}
`

const EmojiBubble = styled(EmojiWrapper)`
  position: absolute;
  bottom: 0;
  left: ${({ left }) => left ? left : '10'}%;
  font-size: ${({ size }) => size ? size : 2}rem;
  animation: ${({ bottom, bottomBottom, one, two }) => floatAnimation(bottom, bottomBottom, one, two)} 2s ease-in-out forwards;
`

export default EmojiBubble;
