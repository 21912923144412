import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "../../_actions";
import { history } from "../../_helpers";
import { Banner } from '../EventDetail/Banner/Banner';
import LoaderCenter from "../Loader/LoaderCenter";
import { BuyTicketContent } from "./BuyTicketContent/BuyTicketContent";

export function BuyTicket(props) {
  const dispatch = useDispatch();
  const [result, setResult] = useState('');
  let selectEventsDetail = useSelector(state => {
    return state.events.eventDetail
  });
  let loadingEventDetail = useSelector(state => state.events.loadingEventDetail);

  const getQuery = () => {
    return new URLSearchParams(window.location.search);
  }

  useEffect(() => {
    const getEventsDetail = async () => {
      await dispatch(eventActions.getEventDetail(props.eventId));
    }
    const query = getQuery();
    setResult(query.get('result'));
    getEventsDetail();
  }, []);
  if (selectEventsDetail && selectEventsDetail.livestream.is_buy_ticket && result !== 'success') {
    cogoToast.error('あなたはこの配信スケジュールのチケットをもっているので、購入できません。', {
      heading: 'エラー',
      position: 'top-center',
      hideAfter: 3,
      bar: { size: '10px' }
    });
    history.push(`/events/${selectEventsDetail.livestream.id}`)
  }
  return (
    <>
      {selectEventsDetail && loadingEventDetail &&
        <>
          <Banner src={selectEventsDetail ? selectEventsDetail.livestream.banner : ""} />

          <BuyTicketContent event={selectEventsDetail} ticketId={props.ticketId} result={result} />
        </>
      }
      {!loadingEventDetail && <LoaderCenter />}
    </>
  );
}
