import React, { useEffect } from 'react';
import { SendVerifyCode } from '../../_components/ForgetPassword/sendVerifyCode';
import { VerifyCode } from '../../_components/ForgetPassword/verifyCode';
import { UpdatePassword } from '../../_components/ForgetPassword/UpdatePassword';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import './forget_password.css'

function ForgetPasswordPage() {
  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])
  return (
    <>
      <Header />
      <div className="home__page">
        <img className="home__image" src="/assets/images/bg.png" alt="" />
        <div className="list__livestream_login d-flex justify-content-center align-items-center">
          <div className="form_register">
            <div className="login__form register__form" style={{ width: '410px' }}>
              <fieldset>
                <h4 className="heading_livedream mb-5">パスワードを再設定</h4>
                <SendVerifyCode />
              </fieldset>
              <fieldset>
                <VerifyCode />
              </fieldset>
              <fieldset>
                <UpdatePassword />
              </fieldset>
            </div>
            <div className="list_progress_login">
              <div className="progress__line d-flex justify-content-between align-items-center content__margin-top">
                <div className="cicle_dots" />
                <span className="line" />
                <div className="cicle_dots" />
                <span className="line" />
                <div className="cicle_dots" />
              </div>
              <div className="progress__text d-flex justify-content-between align-items-center content__margin-top">
                <span className="forget_register__span1">認証コード送信</span>
                <span className="forget_register__span2">認証コード入力</span>
                <span className="forget_register__span3">パスワードを更新する</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export { ForgetPasswordPage };