import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EventEditRight } from "./EventEditRight";
import { EventEditLeft } from "./EventEditLeft";
import { ModalCreateEvent } from "./ModalCreateEvent";
import { Banner } from '../Banner/Banner';
import { eventActions } from "../../../_actions";
import "./event_edit.css";
import { ModalEditEvent } from "./ModalEditEvent";
import { ModalConfirmEdit } from "./ModalConfirmEdit";
import { ModalConfirmSuccess } from "../../EventDetailAfterCreate/ModalConfirmSuccess";
import { ModalEditEventSuccess } from "./ModalEditEventSuccess";
import { eventService } from "../../../_services";
import cogoToast from "cogo-toast";
import { formatMessage } from "../../../_utils/formatMessage";
import { eventConstants } from "../../../_constants";

export function EventEdit(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonConfirmRef = useRef();
  const buttonConfirmSuccessRef = useRef();
  const buttonEditRef = useRef();
  const buttonEditModalRef = useRef();
  let selectEventsDetail = useSelector(state => state.events.eventDetail);
  const [tickets, setTickets] = useState(() => selectEventsDetail);
  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [values, setValues] = useState("");
  const [loadEventsDetail, setLoadEventsDetail] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [arrayHref, setArrayHref] = useState('');
  const [checkStartTime, setCheckStartTime] = useState(false);
  const [radioTest, setRadioTest] = useState(false);
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
    const getEventsDetail = async () => {
      await dispatch(eventActions.getEventDetail(props.eventId));
    }
    getEventsDetail();
  }, [])

  useEffect(() => {
    if (loadEventsDetail) {
      setTickets(selectEventsDetail)
    }
  }, [selectEventsDetail]);

  useEffect(() => {
    setSubmitted(true);
  }, [title, description]);

  const handleClickBack = () => {
    history.goBack();
  }

  const handlCreateTicket = (data) => {
    buttonConfirmRef.current.click();
    setLoadEventsDetail(false);
    setValues(data);
  }

  const handleConfirm = async () => {
    await dispatch(eventActions.addTicket(props.eventId, values));
    buttonConfirmSuccessRef.current.click();
  }

  const handleSubmitEditEvent = async () => {
    if (title) {
      const data = startTime ? {
        "title": title,
        "description": description,
        'email': email,
        'start_time': startTime,
        'href': arrayHref,
        // 'is_test': radioTest,
        // 'user_ids' : userIds
      } : {
        "title": title,
        "description": description,
        'email': email,
        // 'is_test': radioTest,
        // 'user_ids' : userIds
      }

      await eventService.editEventDetail(props.eventId, data)
        .then(
          res => {
            if (res.data.status === 400) {
              cogoToast.error(formatMessage(res.data.message), {
                heading: 'エラー',
                position: 'top-center',
                hideAfter: 3,
                bar: { size: '10px' }
              });
            } else if (res.data.status === 200) {
              // dispatch(success(res.data.data));
              dispatch({ type: eventConstants.EDIT_EVENT_DETAIL_SUCCESS, payload: res.data.data });
              buttonEditRef.current.click();
            }
          },
          error => {
            cogoToast.error(formatMessage(error.toString()), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
          }
        );
    }
  }

  const handleClickBackModal = (e) => {
    history.push(`/my-events`);
  }

  const handleClose = () => {
    history.push(`/my-events`);
  }

  const handleSubmit = (data) => {
    setTitle(data.valTitle);
    setDescription(data.valDescription);
    setEmail(data.email);
    setStartTime(data.start_time);
    setArrayHref(data.href);
    setRadioTest(data.is_test)
    setUserIds(data.user_ids)
  }

  const handleUpdate = () => {
    let minTime = moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm');
    minTime = moment(minTime).valueOf();
    if (startTime) {
      if (minTime > startTime) {
        setCheckStartTime(true);
        return;
      }
      setCheckStartTime(false);
    }

    if (title && email) {
      buttonEditModalRef.current.click();
    }
  }

  return (
    <>
      <Banner src={`${tickets ? tickets.livestream.banner : ''}`} />
      <div className="container-fluid">
        <section className="event-content">
          <div className="row">
            {/* <div className="col-lg-6 offset-lg-3 px-3 mb-5"> */}
            <div className="col-lg-6 px-3 mb-5">
              <EventEditLeft tickets={tickets} submitted={submitted} handleSubmit={handleSubmit}
                checkStartTime={checkStartTime} />
            </div>
            <div className="col-lg-6 px-3 mb-5">
              <EventEditRight tickets={tickets} />
            </div>
          </div>

          <div className="row">
            {/* <div className="col-lg-6 offset-lg-3"> */}
            <div className="col-lg-12">
              <div className="event__btn">
                <button className="btn event__but_return" onClick={handleClickBack}>戻る</button>
                <button className="btn event__but_next"
                  onClick={handleUpdate}
                >更新
                </button>
              </div>
            </div>
          </div>

          <div className="event__btn">
            {/* <button className="btn event__but_return" onClick={handleClickBack}>戻る</button>
            <button className="btn event__but_next"
              onClick={handleUpdate}
            >更新
            </button> */}
            <button
              ref={buttonEditModalRef}
              data-toggle="modal"
              data-target="#modalEditEvent"
              hidden="hidden"
            >更新
            </button>
            <button
              ref={buttonEditRef}
              data-toggle="modal"
              data-target="#modalEditEventSuccess"
              hidden="hidden"
            >更新
            </button>

            <button
              ref={buttonConfirmRef}
              data-toggle="modal"
              data-target="#modalConfirmEdit"
              hidden="hidden"
            >更新
            </button>
            <button
              ref={buttonConfirmSuccessRef}
              data-toggle="modal"
              data-target="#modalConfirmSuccess"
              hidden="hidden"
            >更新
            </button>
          </div>
        </section>

        <ModalEditEvent handleSubmitEditEvent={handleSubmitEditEvent} handleClickBackModal={handleClickBackModal} />
        <ModalEditEventSuccess handleClose={handleClose} />
        <ModalCreateEvent handlCreateTicket={handlCreateTicket} tickets={tickets} eventId={props.eventId}
          startTime={startTime} />
        <ModalConfirmEdit handleConfirm={handleConfirm} />
        <ModalConfirmSuccess />
      </div>
    </>
  );
}
