import socketIOClient from 'socket.io-client';

const ENDPOINT = `${process.env.REACT_APP_APIURL}`;
const token = localStorage.getItem('token');

const socket = socketIOClient(ENDPOINT, {
  query: { token },
  transports: ['websocket'],
  forceNew: true,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});

export { socket };

