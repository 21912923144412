import { authenticationConstants, userConstants } from '../../_constants';

let user = JSON.parse(localStorage.getItem('user'));
let user_coin = JSON.parse(localStorage.getItem('user_coin'));
const initialState = { loggedIn: true, user, user_coin }

export function login(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
    case authenticationConstants.LOGIN_SOCIAL_REQUEST:
      return {
        loggingIn: true,
      };
    case authenticationConstants.LOGIN_SUCCESS:
    case authenticationConstants.LOGIN_SOCIAL_SUCCESS:
      return {
        loggedIn: false,
        user: action.payload.data.data ? action.payload.data.data.user : user,
        user_coin: action.payload.data.data ? action.payload.data.data.userCoin : user_coin
      };
    case authenticationConstants.LOGIN_FAILURE:
    case authenticationConstants.LOGIN_SOCIAL_FAILURE:
    case authenticationConstants.LOGOUT:
      return {};
    default:
      return state
  }
}