import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Linkify from 'react-linkify';

import "./event_left.css"
import { livestreamActions } from "../../../_actions/livestream.actions";

export function EventLeft(props) {
  let videoCategories = useSelector(state => state.videoCategories.videoCategories);
  const eventsDetail = props.createSchedule;
  const dispatch = useDispatch();
  const listTagUsers = props.listTagUser;

  useEffect(() => {
    const getVideoCategories = async () => {
      await dispatch(livestreamActions.getVideoCategories());
    }
    getVideoCategories();
  }, [])

  return (
    <>
      {eventsDetail &&
        <>
          <h3 className="event-content-title mb-3"> {eventsDetail.title} </h3>
          <div className="event-infor">
            <ul className="event-meta-left pl-0">
              <li>
                <img className="resize-icon" src={'/assets/icons/calendar_white.png'} alt="" />
                <span className="pl-1 content_daytime__color">
                  {moment.unix(eventsDetail.start_time / 1000).format("ddd, D MMM YYYY")}
                </span>
              </li>
              <li>
                {eventsDetail.categories && eventsDetail.categories.map((category, i) => {
                  let cate = videoCategories ? videoCategories.filter(videoCategory => {
                    return parseInt(videoCategory.id) === parseInt(category);
                  }) : null;
                  return <span className="badge badge-dark badge-custom" key={i}>
                    {cate ? cate[0].name : ''}
                  </span>
                })}
              </li>
              <li>
                <img className="resize-icon" src={'/assets/icons/hour.png'} alt="" />
                <span className="pl-1 time_start__live">
                  {moment.unix(eventsDetail.start_time / 1000).format("ddd, D MMM YYYY HH:mm")} ~
                </span>
              </li>
            </ul>
          </div>

          <div className="event-desc">
            <Linkify properties={{ target: '_blank', style: { color: 'red' } }}>{eventsDetail.description}</Linkify>
          </div>

          <div className="event-desc">
            {!eventsDetail.is_test && <span>配信タイプ: 本番</span> }
            {eventsDetail.is_test && <span>配信タイプ: リハーサル</span>}
          </div>
          <div className="event-infor display_user_watch">
            {eventsDetail.is_test && listTagUsers && listTagUsers.length
              &&  <div className={`schedule-category box__lists_user`}>
                    {listTagUsers.map((user, idx) =>
                      <span className='tag__lists_user' key={idx}>{user.name}</span>
                    )}
                  </div>
              || ''}
          </div>
        </>
      }

    </>
  );
}
