import React from "react";
import "./banner.css";

export function Banner(props) {
  return (
    <section className="section banner_position">
      <img className="img_baner cursor_pointer" src={props.src} alt="" />
      <span className="banner_title">{props.text}</span>
    </section>
  );
}
