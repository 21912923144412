import React from "react";
import "./banner.css";

export function Banner(props) {

  return (
    <section className="section">
      <img className="img_baner cursor_pointer" src={props.src} alt=""/>
    </section>
  );
}
