import React from 'react';
import { useHistory } from 'react-router';

export function ModalDraft(props) {
  const history = useHistory();

  const handleClickBack = () => {
    // props.setDisplayGiveAwayTicket(false);
  }
  const handleSaveDraftSuccess = (e) => {
    props.handleSaveDraftSuccess();
  }

  return (
    <div className="modal fade" id="modalDraft" tabIndex={-1} role="dialog" aria-labelledby="modalCreateEvent"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">保存します。よろしいですか？</div>
            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn"
                // onClick={handleClickBack}
                data-dismiss="modal"
                aria-label="Close"
              >いいえ
              </button>
              <button className="btn schedule__but_next give_away_ticket__btn"
                onClick={handleSaveDraftSuccess}
                data-dismiss="modal"
                aria-label="Close"
              >はい
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
