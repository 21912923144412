import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cogoToast from 'cogo-toast';
import { eventActions } from '../../../_actions/event.actions';
import { livestreamActions, userActions } from '../../../_actions';
import Linkify from 'react-linkify';
import "./event_left.css"
import { useWindowResize } from "../../LiveStream/LiveStreamDetail/useWindowResize";

export function EventLeft(props) {
  const { width } = useWindowResize();
  const history = useHistory();
  let userLogin = useSelector(state => state.login.user);
  let selectUserProfile = useSelector(state => state.userProfile.userProfile);
  const dispatch = useDispatch();
  const eventsDetail = props.eventsDetail && props.eventsDetail.livestream;
  const isFollowed = eventsDetail ? eventsDetail.user.is_followed : '';
  const [liveStreamDetailFollow, setLiveStreamDetailFollow] = useState(isFollowed);
  const [urlUser, setUrlUser] = useState('');

  useEffect(() => {
    localStorage.setItem('event-follow', '');
  }, []);

  useEffect(() => {
    setLiveStreamDetailFollow(isFollowed);
  }, [isFollowed]);

  useEffect(() => {
    if (eventsDetail) {
      const getUserProfilele = async () => {
        await dispatch(userActions.getUserProfile(eventsDetail.user.id));
      }
      getUserProfilele();
      setUrlUser(`${window.location.origin}/user/${eventsDetail.user.id}`);
    }
  }, [eventsDetail]);

  const handleUserProfile = () => {
    const userId = eventsDetail && eventsDetail.user.id;
    const userLoginId = userLogin && userLogin.id;
    if (userLoginId === userId) {
      history.push(`/my-page`);
    } else {
      history.push(`/user/${userId}`);
    }
  }

  const handleClickFollow = async (userId, follow) => {
    const stateFollow = localStorage.getItem('event-follow');
    let isFollow = stateFollow ? !follow : !isFollowed;
    localStorage.setItem('event-follow', 'follow');

    follow ? await dispatch(livestreamActions.unfollow(userId)) : await dispatch(livestreamActions.follow(userId));

    setLiveStreamDetailFollow(isFollow);

    let eventTimeOut = setTimeout(async () => {
      await dispatch(userActions.getUserProfile(eventsDetail.user.id));
      clearTimeout(eventTimeOut);
    }, 500);
  }

  const handleClickCopy = (e) => {
    e.preventDefault();
    cogoToast.success('', {
      heading: 'コピーしました。',
      position: 'top-center',
      hideAfter: 3,
      bar: { size: '10px' }
    });
  }

  return (
    <>
      {eventsDetail &&
        <>
          <h3 className="event-content-title mb-3"> {eventsDetail.title} </h3>
          <div className="event-infor">
            <ul className="event-meta pl-0">
              <li>
                <img className="resize-icon" src={'/assets/icons/calendar_white.png'} alt="" />
                <span className="pl-1 content_daytime__color">
                  {moment.unix(eventsDetail.start_time / 1000).format("ddd, D MMM YYYY")}
                </span>
              </li>
              <li>
                {eventsDetail.categories.map((category, i) => {
                  return <span className="badge badge-dark badge-custom" key={i}>
                    {category.name}
                  </span>
                })}
              </li>
              <li>
                <img className="resize-icon" src={'/assets/icons/hour.png'} alt="" />
                <span className="pl-1 time_start__live">
                  {moment.unix(eventsDetail.start_time / 1000).format("ddd, D MMM YYYY HH:mm")} ~
                </span>
              </li>
            </ul>
            <div className="event-desc display-xs">
              <Linkify properties={{ target: '_blank' }}>{eventsDetail.description}</Linkify>
            </div>
            <hr className="event__hr display-xs" />
            {userLogin && userLogin.id && userLogin.id !== eventsDetail.user.id && <div className="event__infor_user">
              <div className="event__infor_user_img">
                <span className="event__img"><img src={eventsDetail.user.avatar} onError={(event) => event.target.src = '/assets/images/avatar.png'} alt=""
                  onClick={handleUserProfile} /></span>
                <span className="hide-xs">
                  {userLogin && userLogin.id !== eventsDetail.user.id &&
                    <button className={`btn btn__flow ${liveStreamDetailFollow ? 'active_follow' : ''}`}
                      onClick={() => handleClickFollow(eventsDetail.user.id, liveStreamDetailFollow)}>
                      {liveStreamDetailFollow ? 'フォローを解除する' : 'フォローする'}
                    </button>
                    ||
                    <span className="disable__follow"></span>
                  }
                  <CopyToClipboard text={urlUser}>
                    <button className="btn btn__copy_url" onClick={handleClickCopy}>URLコピー</button>
                  </CopyToClipboard>

                </span>

                <span className="display-xs">
                  <h6>{eventsDetail.user.name}</h6>
                </span>
              </div>
              {selectUserProfile && <>
                <div className="author__name">
                  <span className="display-xs">
                    {userLogin && userLogin.id !== eventsDetail.user.id &&
                      <button className={`btn btn__flow ${liveStreamDetailFollow ? 'active_follow' : ''}`}
                        onClick={() => handleClickFollow(eventsDetail.user.id, liveStreamDetailFollow)}>
                        {liveStreamDetailFollow ? 'フォローを解除する' : 'フォローする'}
                      </button>
                      ||
                      <span className="disable__follow"></span>
                    }
                    <CopyToClipboard text={urlUser}>
                      <button className="btn btn__copy_url" onClick={handleClickCopy}>URLコピー</button>
                    </CopyToClipboard>
                  </span>
                  <span className={`${width > 414 ? "" : "hidden-username-xs"}`}>
                    <h6>{eventsDetail.user.name}</h6>
                  </span>
                  <div className="event_author_detail">
                    <Linkify properties={{ target: '_blank' }}>{selectUserProfile.about}</Linkify>
                  </div>
                </div>
                <div className="event_follows hide-xs">
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.number_followed}</span> フォロー中</span>
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.number_follower}</span> フォロワー</span>
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.likes}</span> いいね</span>
                  <span><span style={{ fontWeight: 'bold' }}>{selectUserProfile.community_joined}</span> 参加コミュニティー</span>
                </div>
                <div className="event_follows display-xs">
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.number_followed}</span> フォロー中</span>
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.number_follower}</span> フォロワー</span>
                  <span className="event_pd_r"><span
                    style={{ fontWeight: 'bold' }}>{selectUserProfile.likes}</span> いいね</span>
                  <span><span style={{ fontWeight: 'bold' }}>{selectUserProfile.community_joined}</span> 参加コミュニティー</span>
                </div>
              </>}
            </div>}
          </div>

          <div className="event-desc hide-xs">
            <Linkify properties={{ target: '_blank' }}>{eventsDetail.description}</Linkify>
          </div>
        </>
      }
    </>
  );
}
