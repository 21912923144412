import { eventConstants } from '../../_constants';

const initialState = { myDraftEvents: [], total: 0, loadingMyDraftEvents: false }

export function myDraftEvents(state = initialState, action) {
  switch (action.type) {
    case eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_REQUEST:
      return {
        ...state,
        loadingMyDraftEvents: false
      };
    case eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_SUCCESS:
    case eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_FAILURE:
      {
        return {
          ...state,
          myDraftEvents: action.payload && action.payload.events,
          total: action.payload && action.payload.total || 0,
          loadingMyDraftEvents: true
        }
      }
    case eventConstants.DELETE_DRAFT_EVENT_REQUEST: {
      return {
        ...state,
        loadingMyDraftEvents: false
      }
    }

    case eventConstants.DELETE_DRAFT_EVENT_SUCCESS: {
      let myDraftEvents = [...state.myDraftEvents];
      myDraftEvents = myDraftEvents.filter(event => +event.id !== +action.payload);
      let total = state.total - 1;
      return {
        ...state,
        myDraftEvents,
        total,
        loadingMyDraftEvents: true
      }
    }
    case eventConstants.DELETE_DRAFT_EVENT_FAIL: {
      return {
        ...state,
        loadingMyDraftEvents: true
      }
    }

    default:
      return state
  }
}