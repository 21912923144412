import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Ticket } from '../../_components/Ticket/Ticket';
import { EventCategory } from '../../_components/EventCategory/EventCategory';
import { Footer } from '../../_components/Footer/Footer';
import { BuyTicket } from '../../_components/BuyTicket/BuyTicket';
import { Header } from '../../_components/Header';

function EventPaymentPage() {
  const eventId = useParams().eventId;
  const ticketId = useParams().ticketId;

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  return (
    <>
      <Header />
      <div className="home__page_live">

        <BuyTicket eventId={eventId} ticketId={ticketId} />

        <Ticket />

        <EventCategory />

        <Footer />
      </div>
    </>
  );
};

export { EventPaymentPage };