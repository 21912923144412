import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../_components';
import { BeforeRegisterPage } from "../Pages/RegisterPage";
import { RegisterPage } from '../Pages/RegisterPage';
import { BeforeLoginPage } from "../Pages/LoginPage";
import { LoginPage } from "../Pages/LoginPage";
import { ForgetPasswordPage } from "../Pages/ForgetPasswordPage";
import { LiveStreamDetailPage } from "../Pages/LiveStreamPage";
import { ChargeCoinPage } from "../Pages/LiveStreamPage/ChargeCoinPage";
import { AuthProvider } from '../_components/LoginSocial/AuthProvider';
import { PrivateRouteRedirectLivestreams } from '../_components/PrivateRouteRedirectLivestreams';
import { EventDetailPage } from '../Pages/EventDetailPage';
import { VideoDetailPage } from '../Pages/VideoDetailPage';
import {
  ListEventPage,
  EventPaymentPage,
  MyPage,
  EventEditPage,
  UserPage,
  EventTicketPage,
  TopPage,
  HomePage,
  EventEditFreeTicketPage
} from '../Pages';
import { CreateLivestreamSchedulePage } from '../Pages/CreateLivestreamSchedulePage';
import { ListSchedulePage } from '../Pages/ListSchedulePage/ListSchedulePage';

function App() {
  return (
    <>
      <AuthProvider>
        <div>
          <Switch> 
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute exact path="/top" component={TopPage} />
            <PrivateRoute exact path="/livestreams/charge-coin" component={ChargeCoinPage} />
            <PrivateRoute exact path="/livestreams/:livestreamId" component={LiveStreamDetailPage} />
            <Route exact path="/videos/:videoId" component={TopPage} />
            <Route exact path="/communities/:communityID" component={TopPage} />
            <Route exact path="/users/:hash" component={TopPage} />
            <PrivateRouteRedirectLivestreams path="/before-login" component={BeforeLoginPage} />
            <Route path="/login" component={LoginPage} />
            <PrivateRouteRedirectLivestreams path="/before-register" component={BeforeRegisterPage} />
            <PrivateRouteRedirectLivestreams path="/register" component={RegisterPage} />
            <Route path="/reset-password" component={ForgetPasswordPage} />
            <PrivateRoute exact path="/events-by-date/:eventDay" component={ListEventPage} />
            <PrivateRoute exact path="/events/:eventId" component={EventDetailPage} />
            <PrivateRoute exact path="/schedule-livestreams/:eventId" component={EventDetailPage} />
            <PrivateRoute exact path="/event-edit/:eventId" component={EventEditPage} />
            <PrivateRoute exact path="/event-edit/:eventId/free-ticket" component={EventEditFreeTicketPage} />
            <PrivateRoute exact path="/events/:eventId/buy-ticket/:ticketId" component={EventPaymentPage} />
            <PrivateRoute exact path="/my-page" component={MyPage} />
            <PrivateRoute exact path="/my-page/video-detail/:id" component={VideoDetailPage} />
            <PrivateRoute exact path="/user/:userId" component={UserPage} />
            <PrivateRoute exact path="/create-livestream-schedule" component={CreateLivestreamSchedulePage} />
            <PrivateRoute exact path="/my-events" component={ListSchedulePage} />
            <PrivateRoute exact path="/events/ticket/:hash" component={EventTicketPage} />
            <Redirect from="*" to="/top" />
          </Switch>
        </div>
      </AuthProvider>
    </>
  );
}

export { App };