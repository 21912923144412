import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from './Banner/Banner';
import { EventContent } from "./EventContent/EventContent";
import { eventActions } from '../../_actions/event.actions';
import { Ticket } from '../../_components/Ticket/Ticket';
import { EventCategory } from '../../_components/EventCategory/EventCategory';
import LoaderCenter from "../Loader/LoaderCenter";
import './event_detail.css';

export function EventDetail(props) {
  const dispatch = useDispatch();
  let selectEventsDetail = useSelector(state => {
    return state.events.eventDetail
  });
  let loadingEventDetail = useSelector(state => state.events.loadingEventDetail);
  const [loading, setLoading] = useState(false);
  const [eventsDetail, setEventsDetail] = useState("");

  useEffect(() => {
    if (props.eventId) {
      setEventsDetail("")
      setLoading(false)
      const getEventsDetail = async () => {
        await dispatch(eventActions.getEventDetail(props.eventId));
      }
      getEventsDetail();
    }
  }, [props.eventId]);

  useEffect(() => {
    if (loadingEventDetail) {
      setLoading(true);
    }
  }, [loadingEventDetail]);

  useEffect(() => {
    if (selectEventsDetail) {
      setEventsDetail(selectEventsDetail);
    }
  }, [selectEventsDetail]);

  return (
    <>
      {eventsDetail && loading &&
        <>
          <Banner src={`${eventsDetail ? eventsDetail.livestream.banner : ''}`} />

          <EventContent eventsDetail={eventsDetail} user={props.user} />
        </>
      }
      {!loading && <LoaderCenter />}

      {/* {eventsDetail && (!props.user || (props.user && eventsDetail.livestream.user.id !== props.user.id)) ?
        <>
          <Ticket />

          <EventCategory />
        </> : null} */}

    </>
  );
}
