import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { socket } from '../../../_helpers';
import { Chat } from './Chat';
import VideoPlayer from './video';
import { LivestreamInfo } from './LivestreamInfo';
import { useWindowResize } from './useWindowResize';
import FirstEmojiBoard from '../../Emoji/FirstEmojiBoard';
import "./live_stream_display.css"
import { useHistory } from 'react-router';
import { ModalConfirmLive } from './ModalConfirmLive';
import { Countdown } from './Countdown/Countdown';
import LoaderCenter from '../../Loader/LoaderCenter';

const ContentToast = ({ avatar, name, img_coin, coin }) => {
  return (
    <div>
      <img className="avatar__coins_notifi" src={avatar} alt="" /> {name}
      <img className="img_coin__coins_notifi" src={img_coin} alt="" /> <span
        className="span__coins_notifi">+ {coin}</span>
    </div>
  );
};

export function LiveSteamDetail(props) {
  const history = useHistory();
  const liveStreamDetail = props.liveStreamDetail;
  const resJoinRoom = props.resJoinRoom;
  let userLogin = useSelector(state => state.login.user);
  let loading_livestream_detail = useSelector(state => state.liveStreamDetail.loading_livestream_detail);
  const [screen, setScreen] = useState(false);
  const { width } = useWindowResize();
  const [isExpire, setIsExpire] = useState(false);
  const [isExpire2, setIsExpire2] = useState(false);
  const [isExpire3, setIsExpire3] = useState(false);
  const [displayImage6, setDisplayImage6] = useState(0);
  const [imageCoins6, setImageCoins6] = useState('');
  const [displayImage7, setDisplayImage7] = useState(0);
  const [imageCoins7, setImageCoins7] = useState('');
  const [displayImage8, setDisplayImage8] = useState(0);
  const [imageCoins8, setImageCoins8] = useState('');
  let isRendered = useRef(false);
  const el = useRef();
  const [displayLike, setDisplayLike] = useState(false);
  const [hideBoxChatMobile, setHideBoxChatMobile] = useState(true);
  const [hideBoxChatDestop, setHideBoxChatDestop] = useState(true);
  const [userLikeId, setUserLikeId] = useState(0);
  const [displayHidden, setDisplayHidden] = useState("");
  const [displayHiddenMobile, setDisplayHiddenMobile] = useState("");
  const [stopLiveStream, setStopLiveStream] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(liveStreamDetail ? liveStreamDetail.livestream.status : 4);
  const [url, setUrl] = useState('');
  let timerDisplay;
  const buttonEvenstBuyTicket = useRef();
  const [stopLiveBuyTicket, setStopLiveBuyTicket] = useState(false);
  const [number, setNumber] = useState(true);
  const [unset, setUnset] = useState(true);
  const [onCheckCountDown, setOnCheckCountDown] = useState(false);
  const [checkErrorJoinRoom, setCheckErrorJoinRoom] = useState(true);

  useEffect(() => {
    localStorage.setItem('hideBoxChat', hideBoxChatMobile);
    setUrl('');
  }, []);

  useEffect(() => {
    isRendered = true;
    socket.on('userSendCoin', (data) => {
      if (isRendered) {
        if (userLogin.id !== data.user.id && liveStreamDetail && liveStreamDetail.livestream.status === 1) {
          let name = data.user.name;
          let avatar = data.user.avatar;

          switch (data.coin) {
            case 10:
              const img_coin1 = "/assets/images/10.png";
              getToastCoins(avatar, name, img_coin1, data.coin);
              break;
            case 100:
              const img_coin2 = "/assets/images/100.png";
              getToastCoins(avatar, name, img_coin2, data.coin);
              break;
            case 1000:
              const img_coin3 = "/assets/images/1000.png";
              getToastCoins(avatar, name, img_coin3, data.coin);
              break;
            case 10000:
              const img_coin4 = "/assets/images/10000.png";
              getToastCoins(avatar, name, img_coin4, data.coin);
              break;
            case 100000:
              const img_coin5 = "/assets/images/100000.png";
              getToastCoins(avatar, name, img_coin5, data.coin);
              break;
            case 200:
              const img_coin6 = "/assets/images/gif_clap_live_stream.gif";
              localStorage.setItem('displayImage6', parseInt(localStorage.getItem('displayImage6')) + 1);
              const localImage6 = parseInt(localStorage.getItem('displayImage6'));
              setDisplayImage6(localImage6);
              setImageCoins6(img_coin6);
              break;
            case 400:
              const img_coin7 = "/assets/images/gif_tequila.gif";
              localStorage.setItem('displayImage7', parseInt(localStorage.getItem('displayImage7')) + 1);
              const localImage7 = parseInt(localStorage.getItem('displayImage7'));
              setDisplayImage7(localImage7);
              setImageCoins7(img_coin7);
              break;
            case 600:
              const img_coin8 = "/assets/images/gif_money_gun.gif";
              localStorage.setItem('displayImage8', parseInt(localStorage.getItem('displayImage8')) + 1);
              const localImage8 = parseInt(localStorage.getItem('displayImage8'));
              setDisplayImage8(localImage8);
              setImageCoins8(img_coin8);
              break;
            default:
              break;
          }
        }
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    if (liveStreamDetail) {
      let status = localStorage.getItem("statusLive") ? localStorage.getItem("statusLive") : '';
      if(status) {
        setCurrentStatus(1);
        localStorage.setItem("statusLive", "");
      } else {
        setCurrentStatus(liveStreamDetail.livestream.status);
      }
    }
  }, [liveStreamDetail]);

  useEffect(() => {
    if (currentStatus === 1) {
      const url = liveStreamDetail ? `http://${liveStreamDetail.android.livestream_url}/${liveStreamDetail.android.livestream_application}/${liveStreamDetail.android.livestream_stream_name}/playlist.m3u8` : '';
      setUrl(url);
    }
  }, [currentStatus]);

  useEffect(() => {
    if (onCheckCountDown) {
      localStorage.setItem("statusLive", 1);

      let reloadTimeOut = setTimeout(async () => {
        clearTimeout(reloadTimeOut);
        window.location.reload();
      }, 5000);
    }
  }, [onCheckCountDown])

  useEffect(() => {
    isRendered = true;
    if (width <= 768) {
      socket.on('userLike', (data) => {
        if (isRendered) {
          if (userLogin.id !== userLikeId && liveStreamDetail && liveStreamDetail.livestream.status === 1) {
            setDisplayLike(true);
          } else {
            setUserLikeId(0);
          }
        }
      });
    }

    return () => {
      isRendered = false;
      setDisplayLike(false);
    };
  });

  useEffect(() => {
    const dom = el.current ? el.current.clientHeight : 0;
    if (dom) {
      localStorage.setItem('clientHeight', dom);
    }
  });

  useEffect(() => {
    isRendered = true;
    socket.on('stopLivestream', (data) => {
      if (isRendered && liveStreamDetail && liveStreamDetail.livestream.status !== 5) {
        setStopLiveStream(true);
        setUnset(false);
        setCurrentStatus(2);
      }
    });

    return () => isRendered = false;
  });

  const handleOnCheckCountDown = (data) => {
    if (data) {
      setOnCheckCountDown(data);
    }
  }

  const getToastCoins = async (avatar, name, img_coin, coin) => {
    toast(<ContentToast avatar={avatar} name={name} img_coin={img_coin} coin={coin} />);
  }

  const ImageGif6 = (img_coin6) => {
    let timeout = 3700;
    let img6_time_out;

    if (displayImage6 === 0) {
      setDisplayImage6(0);
      clearTimeout(img6_time_out);
    }

    if (isExpire) {
      return null
    }

    img6_time_out = setTimeout(() => {
      setIsExpire(true);
      localStorage.setItem('displayImage6', displayImage6 - 1);
      let timeoutExpire = setTimeout(() => {
        setIsExpire(false);
        clearTimeout(timeoutExpire);
      }, timeout + 1)
      setDisplayImage6(displayImage6 - 1);
      clearTimeout(img6_time_out);
    }, timeout);

    return <div className="animate__clap_live_stream1">
      <img src={img_coin6} alt="" />
    </div>
  }

  const ImageGif7 = (img_coin7) => {
    let timeout = 3800;
    let img7_time_out;

    if (displayImage7 === 0) {
      setDisplayImage7(0);
      clearTimeout(img7_time_out);
    }

    if (isExpire2) {
      return null
    }

    img7_time_out = setTimeout(() => {
      setIsExpire2(true);
      localStorage.setItem('displayImage7', displayImage7 - 1);
      let timeoutExpire2 = setTimeout(() => {
        setIsExpire2(false);
        clearTimeout(timeoutExpire2);
      }, timeout + 1);
      setDisplayImage7(displayImage7 - 1);
      clearTimeout(img7_time_out);
    }, timeout);

    return <div className="animate__tequila1">
      <img src={img_coin7} alt="" />
    </div>
  }

  const ImageGif8 = (img_coin8) => {
    let timeout = 4500;
    let img8_time_out;

    if (displayImage8 === 0) {
      setDisplayImage8(0);
      clearTimeout(img8_time_out);
    }

    if (isExpire3) {
      return null
    }

    img8_time_out = setTimeout(() => {
      setIsExpire3(true);
      localStorage.setItem('displayImage8', displayImage8 - 1);
      let timeoutExpire3 = setTimeout(() => {
        setIsExpire3(false);
        clearTimeout(timeoutExpire3);
      }, timeout + 1);
      setDisplayImage8(displayImage8 - 1);
      clearTimeout(img8_time_out);
    }, timeout);

    return <div className="animate__money_gun1">
      <img src={img_coin8} alt="" />
    </div>
  }

  const handdleDefaultScreen = (e) => {
    e.preventDefault();
    setHideBoxChatDestop(true);
    setScreen(false);
  }

  const handleScreen = (e, screen) => {
    e.preventDefault();
    setHideBoxChatDestop(false);
    setScreen(screen);
  }

  const handleHideBoxChat = (e, hideBoxChatMobile) => {
    e.preventDefault();
    setHideBoxChatMobile(hideBoxChatMobile);
    localStorage.setItem('hideBoxChat', hideBoxChatMobile);
  }

  const handleClickEventDetail = () => {
    const id = liveStreamDetail.livestream && liveStreamDetail.livestream.id;
    history.push(`/events/${id}`);
  }

  const handleBackConfirm = () => {
    setUnset(false);
  }

  const handleDisplayHidden = () => {
    setDisplayHidden(true);
    setStopLiveBuyTicket(true);
    buttonEvenstBuyTicket.current && buttonEvenstBuyTicket.current.click();
  }

  const handleDisplayHiddenMobile = () => {
    setDisplayHiddenMobile(true);
    setStopLiveBuyTicket(true);
    buttonEvenstBuyTicket.current && buttonEvenstBuyTicket.current.click();
  }

  const renderCountdown = () => {
    if (currentStatus === 5) {
      return <Countdown remainingCountdown={liveStreamDetail && liveStreamDetail.livestream.start_time}
        onCheckCountDown={handleOnCheckCountDown} />
    }
  }

  const DisplayDestopByStatus = () => {
    if (liveStreamDetail) {
      switch (currentStatus) {
        case 1:
          if (resJoinRoom && parseInt(resJoinRoom.error) === 99 && resJoinRoom.message === 'user_is_denied_join_livestream' && checkErrorJoinRoom) {
            setCheckErrorJoinRoom(false);
            handleDisplayHidden();
          } else if (!liveStreamDetail.livestream.is_buy_ticket && liveStreamDetail.livestream.is_event === 1 && userLogin.id !== liveStreamDetail.livestream.user.id) {
            if (number) {
              setNumber(false)
              let timeOut = 30000;
              let time = 0;
              let getLeftTime = localStorage.getItem(liveStreamDetail.livestream.stream_name);
              getLeftTime = getLeftTime ? JSON.parse(getLeftTime) : null;
              if (getLeftTime && getLeftTime.user_id === userLogin.id) {
                timeOut = 30000 - getLeftTime.time * 1000;
                time = getLeftTime.time;
              }
              const interval = setInterval(() => {
                time++;
                localStorage.setItem(liveStreamDetail.livestream.stream_name, JSON.stringify({
                  user_id: userLogin.id,
                  time
                }));
                if (time === 30) {
                  clearInterval(interval);
                }
              }, 1000);
              timerDisplay = setTimeout(() => {
                handleDisplayHidden();
              }, timeOut);

            }
          }

          if (displayHidden) {
            clearTimeout(timerDisplay);
            return <div className="stop_box__video">
              <span className="stop_display_text"></span>
            </div>
          }

          break;

        case 2:
          return renderLiveStreamEnded();
        case 3:
          return renderLiveStreamBlocked();
        case 5:
          return renderCountdown();

        default:
          break;
      }
    }
  }

  const DisplayMobilByStatus = () => {
    if (liveStreamDetail) {
      switch (currentStatus) {
        case 1:
          if (resJoinRoom && parseInt(resJoinRoom.error) === 99 && resJoinRoom.message === 'user_is_denied_join_livestream' && checkErrorJoinRoom) {
            handleDisplayHiddenMobile();
            setCheckErrorJoinRoom(false);
          } else if (!liveStreamDetail.livestream.is_buy_ticket && liveStreamDetail.livestream.is_event === 1 && userLogin.id !== liveStreamDetail.livestream.user.id) {
            if (number) {
              setNumber(false);
              let timeOut = 30000;
              let time = 0;
              let getLeftTime = localStorage.getItem(liveStreamDetail.livestream.stream_name);
              getLeftTime = getLeftTime ? JSON.parse(getLeftTime) : null;
              if (getLeftTime && getLeftTime.user_id === userLogin.id) {
                timeOut = 30000 - getLeftTime.time * 1000;
                time = getLeftTime.time;
              }
              const interval = setInterval(() => {
                time++;
                localStorage.setItem(liveStreamDetail.livestream.strean_name, JSON.stringify({
                  user_id: userLogin.id,
                  time
                }));
                if (time === 30) {
                  clearInterval(interval);
                }
              }, 1000);
              timerDisplay = setTimeout(() => {
                handleDisplayHiddenMobile();
              }, timeOut);

            }
          }

          if (displayHiddenMobile) {
            clearTimeout(timerDisplay);
            return <div className={hideBoxChatMobile ? "stop_box__chat1" : "stop_box__chat2"}>
              <div className="box__buy__ticket_text"></div>
            </div>
          }

          break;

        case 2:
          return renderLiveStreamEndedMobile();
        case 3:
          return renderLiveStreamBlockedMobile();
        case 5:
          return renderCountdown();

        default:
          break;
      }
    }
  }

  const renderLiveStreamEnded = () => {
    return showTextVideo('配信は終了しました。');
  }

  const renderLiveStreamBlocked = () => {
    return showTextVideo('配信はブロックされました。');
  }

  const renderLiveStreamEndedMobile = () => {
    return showTextVideoMobile('配信は終了しました。');
  }

  const renderLiveStreamBlockedMobile = () => {
    return showTextVideoMobile('配信はブロックされました。');
  }

  const renderCountDownMobile = () => {
    return showTextVideoMobile('');
  }

  const showTextVideo = (text) => {
    return <div className="stop_box__video">
      <span className="stop_display_text">{text}</span>
    </div>
  }

  const showTextVideoMobile = (text) => {
    return <div className={hideBoxChatMobile ? "stop_box__chat1" : "stop_box__chat2"}>
      <div className="box__chat_text">{text}</div>
    </div>
  }

  return (
    <>
      {liveStreamDetail && loading_livestream_detail &&
        <div className="box__live_streams d-flex justify-content-between">
          {stopLiveStream && (width > 768) && renderLiveStreamEnded()}
          {width > 768 && DisplayDestopByStatus()}
          <button
            ref={buttonEvenstBuyTicket}
            data-toggle="modal"
            data-target="#modalConfirmLive"
            hidden="hidden"
          >戻る
          </button>
          <div className={`box__video box__livestram_pad ${unset ? "unset_box" : "set_box"}`}
            style={screen ? { flex: '0 0 100%' } : { flex: '0 0 74%' }}>
            <VideoPlayer url={url}
              displayMobilByStatus={width <= 768 ? DisplayMobilByStatus() : ""}
              stopLiveStreamEnded={stopLiveStream}
              stopLiveBuyTicket={stopLiveBuyTicket}
              currentStatus={currentStatus} />

            <button className="btn" id="showBoxChat" style={screen ? { display: 'unset' } : { display: 'none' }}
              onClick={handdleDefaultScreen}>
              <img src="/assets/images/right-arrow2.png" alt=""></img>
            </button>
            <button className="btn btn__double_right" id="fullScreen" onClick={(e) => handleScreen(e, true)}
              style={!screen ? { display: 'unset' } : { display: 'none' }}>
              <img src="/assets/images/right-arrow.png" alt=""></img>
            </button>
            {/* {width <= 768 && <FirstEmojiBoard displayLike={displayLike} />} */}
            <ToastContainer
              position="top-left"
              autoClose={1000}
              limit={3}
              closeButton={false}
            />
            {displayImage6 > 0 && ImageGif6(imageCoins6)}
            {displayImage7 > 0 && ImageGif7(imageCoins7)}
            {displayImage8 > 0 && ImageGif8(imageCoins8)}
          </div>
          {width <= 768 && <section className="section__my_livestream div_mobile">
            <FirstEmojiBoard displayLike={displayLike} />
            <LivestreamInfo elRef={el} liveStreamDetail={liveStreamDetail} />
          </section>}

          <div className={`par_box_chat ${hideBoxChatMobile ? "show_par_box_chat" : "hide_par_box_chat"}`}>
            {stopLiveStream && (width <= 768) && renderLiveStreamEndedMobile()}
            {width <= 768 && (currentStatus === 5 ? renderCountDownMobile() : DisplayMobilByStatus())}

            {width > 768 && <span className={`${hideBoxChatDestop ? 'displayBlockBoxChat' : 'displayNoneBoxChat'}`}>
              <Chat setUserLikeId={setUserLikeId} />
            </span>}
            {hideBoxChatMobile && (width <= 768) && <Chat setUserLikeId={setUserLikeId} />}

            {width <= 768 && <button className={`btn ${hideBoxChatMobile ? "border_show_chat" : ""}`} id="hideBoxChat"
              onClick={(e) => handleHideBoxChat(e, !hideBoxChatMobile)}>
              <span>{hideBoxChatMobile ? 'チャット非表示' : 'チャット表示'}</span> <img className="small_img"
                src="/assets/images/triangular-up-arrow.png" alt=""
                style={hideBoxChatMobile ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}></img>
            </button>}
          </div>
        </div>
      }

      {!liveStreamDetail && loading_livestream_detail &&
        <div className="box__live_streams d-flex justify-content-between">
          <div className={`box__video box__livestram_pad ${unset ? "unset_box" : "set_box"}`} style={screen ? { flex: '0 0 100%' } : { flex: '0 0 74%' }}>

          </div>
          <div className={`par_box_chat hidden__box_chat`}>

          </div>
        </div>
      }

      {!loading_livestream_detail && <LoaderCenter />}
      <ModalConfirmLive handleClickEventDetail={handleClickEventDetail} handleBackConfirm={handleBackConfirm} />
    </>
  );
}
