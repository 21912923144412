import React from 'react';
import { useSelector } from 'react-redux';
import Linkify from 'react-linkify';

export function VideoInfo(props) {
  const videoDetail = props.videoDetail;
  let selectMeProfile = useSelector(state => state.userProfile.userProfile);

  const handleClickBack = () => {
    return window.location = `${document.location.origin}/my-page`;
  }

  return (
    <>
      {videoDetail &&
        <div>
          <div
            className="section_livestream_info d-flex justify-content-between align-items-center content__margin-top livestream__infor">
            <div className="stream__info">
              <div className="heading_livedream">{videoDetail.livestream && videoDetail.livestream.title}</div>
              <div className="meta_info">
                <span><i className="far fa-comment-dots" />{videoDetail.livestream && videoDetail.livestream.number_comments}</span>
                <span><i className="far fa-eye" />{videoDetail.livestream && videoDetail.livestream.number_views}</span>
                <span><img className="small_img" src="/assets/images/icon.png" alt="" />{videoDetail.livestream && videoDetail.livestream.number_likes}</span>
                <span><img className="small_img" src="/assets/images/coins.png" alt="" />{videoDetail.livestream && videoDetail.livestream.number_coins}</span>
              </div>
            </div>
          </div>

          <div className="stream_description d-flex justify-content-between align-items-center content__margin-top">
            <div className="author_info d-flex justify-content-between align-items-center user_infor">
              <img src={videoDetail.livestream && videoDetail.livestream.user && videoDetail.livestream.user.avatar}
                onError={(event) => event.target.src = '/assets/images/avatar.png'}
                alt="" />
              <div className="author__name">
                <h6>{videoDetail.livestream && videoDetail.livestream.user && videoDetail.livestream.user.name}</h6>
                <span>{selectMeProfile && selectMeProfile.number_follower}フォロワー</span>
                <div className="author_detail">
                  <Linkify properties={{ target: '_blank' }}>{videoDetail.livestream && videoDetail.livestream.description}</Linkify>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-back-my-page">
            <div className="col-lg-6 offset-lg-3">
              <div className="event__btn">
                <button className="btn event__but_return" onClick={handleClickBack}>戻る</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
