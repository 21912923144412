import { livestreamConstants } from '../../_constants';

const initialStateFollow = { loadSendCoins: '' }

export function loadSendCoins(state = initialStateFollow, action) {
  switch (action.type) {
    case livestreamConstants.LOAD_SEND_COINS_SUCCESS:
      return {
        ...state,
        loadSendCoins: action.payload,
      };
    default:
      return state
  }
}
