import { livestreamConstants } from '../../_constants';

const initialState = { draftEvent: {} }

export function draftEvent(state = initialState, action) {
  switch (action.type) {
    case livestreamConstants.CREATE_DRAFT_EVENT_DOING:
      return {
        ...state,
      };
    case livestreamConstants.CREATE_DRAFT_EVENT_SUCCESS:
      return {
        ...state,
        draftEvent: action.payload,
      };
    case livestreamConstants.CREATE_DRAFT_EVENT_FAIL:
      return {
        draftEvent: action.payload,
      };
    default:
      return state
  }
}
