import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Moment from 'react-moment';
import * as moment from 'moment';
import { useWindowResize } from '../LiveStreamDetail/useWindowResize';
import { livestreamActions } from '../../../_actions';
import Loader from '../../Loader/Loader';

const token = localStorage.getItem('token');

export function ListScheduleLiveStream(props) {
  const history = useHistory();
  let listScheduleLoad = useSelector(state => state.listLiveStream.list_schedule_livestream);
  let loading_list_schedule_livestream = useSelector(state => state.listLiveStream.loading_list_schedule_livestream);
  let userLogin = useSelector(state => state.login.user);
  let userLoginId = userLogin ? userLogin.id : 0;
  const limit = props.limit;
  const scheduleRef = useRef([]);
  const [listScheduleLivestream, setListScheduleLivestream] = useState('');
  const [pageSchedule, setPageSchedule] = useState(1);
  const [scribeNotification, setScribeNotification] = useState(true);
  const [eventId, setEventId] = useState('');
  const [isNotification, setIsNotification] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { width } = useWindowResize();
  let height = 350;
  if (width <= 480) {
    height = width * 16 / 19;
  }

  useEffect(() => {
    if (loading_list_schedule_livestream) {
      setLoading(true)
    }
  }, [loading_list_schedule_livestream]);

  useEffect(() => {
    if (listScheduleLoad && listScheduleLoad.length > 0) {
      if (scribeNotification) {
        if (pageSchedule === 1) {
          setListScheduleLivestream(listScheduleLoad);
        } else {
          const curData = [...listScheduleLivestream, ...listScheduleLoad];
          setListScheduleLivestream(curData);
        }
      } else {
        listScheduleLivestream.map(event => {
          if (event.livestream.id === eventId) {
            event.livestream.is_notification = isNotification;
          }
          return event;
        })
        setEventId('');
      }
    } else {
      if (pageSchedule === 1) {
        setListScheduleLivestream([]);
      }
      if (!scribeNotification) {
        listScheduleLivestream.map(event => {
          if (event.livestream.id === eventId) {
            event.livestream.is_notification = isNotification;
          }
          return event;
        })
        setEventId('');
      }
    }
    setScribeNotification(true);
  }, [listScheduleLoad]);

  const handleSubscribeNotification = (id) => {
    checkLogin();
    setScribeNotification(false);
    setEventId(id);
    setIsNotification(true);
    dispatch(livestreamActions.subscribeLivestream(id));
  }

  const handleUnsubscribeNotification = (id) => {
    checkLogin();
    setScribeNotification(false);
    setEventId(id);
    setIsNotification(false);
    dispatch(livestreamActions.unsubscribeLivestream(id));
  }

  const checkLogin = () => {
    if (!token) {
      history.push("/before-login");
    }
  }

  const handleLoadMore = async (e) => {
    const currentScollWidth =
      scheduleRef.current.scrollLeft + scheduleRef.current.clientWidth;
    const triggerScollWidth = scheduleRef.current.scrollWidth;
    if (triggerScollWidth - 100 <= currentScollWidth + 1) {
      await dispatch(livestreamActions.getScheduleLivestreamList(pageSchedule + 1, limit));
      setPageSchedule(pageSchedule + 1);
    }
  }

  const handleEventDetail = async (id) => {
    localStorage.setItem("goBack", window.location.pathname);
    history.push(`/events/${id}`);
  }

  return (
    <>
      <div onScroll={(e) => handleLoadMore(e)} ref={scheduleRef} id="livesteam_scroll"
        className={((listScheduleLivestream && listScheduleLivestream.length > 6)
          || (width <= 480 && listScheduleLivestream && listScheduleLivestream.length > 1)
          || (width > 480 && width <= 812 && listScheduleLivestream && listScheduleLivestream.length > 4))
          ? "list__video_livesteam_schedule_object video_livesteam_scroll"
          : "list__video_livesteam"}>
        {listScheduleLivestream && listScheduleLivestream.sort((a, b) => (a.livestream.start_time > b.livestream.start_time) ? 1 : -1).map((item, i) => {
          return <div key={i} className={`iteam__schedule_livestream
                                ${(i === listScheduleLivestream.length - 1)
              ? "item__video_livesteam_schedule2 item__video_livesteam_mgr"
              : "item__video_livesteam_schedule2"}`}>
            <div className="video_livesteam cursor_pointer image_schedule_livestream" style={{ height: height }}
              onClick={() => handleEventDetail(item.livestream.id)}>
              <img className="user"
                src={item.livestream.thumbnail ? item.livestream.thumbnail : "/assets/images/livestream.png"}
                alt="" />
            </div>
            <h5 className="video__title cursor_pointer text_video_hidden"
              onClick={() => handleEventDetail(item.livestream.id)}>{item.livestream.title}</h5>
            <div className="meta__video d-flex justify-content-between">
              <span>
                {item.livestream.number_views} views  ·  <Moment
                  fromNow>{item.livestream.created_at}</Moment>
              </span>
            </div>
            <span
              className="timeLivestream">{moment.unix(item.livestream.start_time / 1000).format('MM/DD HH:mm')} ~</span>
            {item.livestream.user_id !== userLoginId && <button
              onClick={item.livestream.is_notification ? () => handleUnsubscribeNotification(item.livestream.id) : () => handleSubscribeNotification(item.livestream.id)}
              className={item.livestream.is_notification ? "live notification" : "live un_notification"}>{item.livestream.is_notification ? "予約する" : "予約する"}</button>}
            <div className="mobile_video_info d-flex justify-content-between align-items-center content__margin-top">
              <div className="avatar">
                <img src={item.livestream.user && item.livestream.user.avatar}
                  onError={(event) => event.target.src = '/assets/images/avatar.png'} 
                  alt="" />
                <span>{item.livestream.user.name}</span>
              </div>
              <div className="video_title video_title_cus"> {item.livestream.title}</div>
            </div>
          </div>
        })}
      </div>

      {listScheduleLivestream.length === 0 && loading && <div className="video_not_fount">
        <span className="video_span_not_fount">現在、配信予約はございません。</span>
      </div>}

      {listScheduleLivestream.length === 0 && !loading && <Loader />}
    </>
  );
}
