import React from 'react';

export function ModalFreeTickets() {

  return (
    <div className="modal fade" id="modalFreeTickets" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community modal__free_ticket">
          <div className="modal-body modal-body__free_ticket">
            <div className="content__free_ticket mt-2">
              <pre className="pre__free_ticket">    本イベントは、チケット作成者からスペシャルオファーとして</pre>
              <pre className="pre__free_ticket">    無料チケットを配布され受け取った方(イベントへの招待を受けた方)</pre>
              <pre className="pre__free_ticket">    のみが視聴できるイベントになります。</pre>
            </div>
            <div className="content__free_ticket mt-2">
              <pre className="pre__free_ticket">※チケット作成者から招待を受ける(無料チケットを受け取る)には、</pre>
              <pre className="pre__free_ticket">    事前にチケット作成者アカウントをフォローしている、もしくは</pre>
              <pre className="pre__free_ticket">    チケット作成者アカウントからフォローされている必要がございます</pre>
            </div>

            <div className="community__btn mt-4 mb-2">
              <button className="btn schedule__but_next"
                data-dismiss="modal"
                aria-label="Close"
              >OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
