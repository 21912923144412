import { livestreamConstants } from '../../_constants';

const initialState = {
  list_livestream: [],
  list_schedule_livestream: [],
  listLivestreamMyPage: [],
  loading_list_livestream: false,
  loading_list_schedule_livestream: false,
};

export function listLiveStream(state = initialState, action) {
  switch (action.type) {
    case livestreamConstants.LIST_LIVESTREAM_REQUEST:
      return {
        ...state,
        loading_list_livestream: false,
      };
    case livestreamConstants.LIST_LIVESTREAM_SUCCESS: {
      let livestreamList = [...state.list_livestream];
      action.payload.map(data => {
        let isExist = false;
        livestreamList.map(livestream => {
          if (livestream.livestream.id === data.livestream.id && data.livestream.status === 5) {
            isExist = true;
          }
        })

        if (!isExist) {
          livestreamList.push(data);
        }
      })

      livestreamList = livestreamList.filter(livestream => livestream.status === 1);

      return {
        ...state,
        list_livestream: action.payload,
        loading_list_livestream: true,
      };
    }
    case livestreamConstants.LIST_LIVESTREAM_FAILURE:
      return {
        ...state,
        list_livestream: [],
        loading_list_livestream: true,
      };

    case livestreamConstants.LIST_LIVESTREAM_MY_PAGE_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.LIST_LIVESTREAM_MY_PAGE_SUCCESS: {
      return {
        ...state,
        listLivestreamMyPage: action.payload,
      };
    }
    case livestreamConstants.LIST_LIVESTREAM_MY_PAGE_FAILURE:
      return {
        ...state,
        listLivestreamMyPage: []
      };

    case livestreamConstants.LIST_SCHEDULE_LIVESTREAM_REQUEST:
      return {
        ...state,
        loading_list_schedule_livestream: false,
      };
    case livestreamConstants.LIST_SCHEDULE_LIVESTREAM_SUCCESS: {
      let livestreamList = [...state.list_schedule_livestream];
      action.payload.map(data => {
        let isExist = false;
        livestreamList.map(livestream => {
          if (livestream.livestream.id === data.livestream.id && data.livestream.status === 1) {
            isExist = true;
          }
        })

        if (!isExist) {
          livestreamList.push(data);
        }
      })

      livestreamList = livestreamList.filter(livestream => livestream.status === 5);
      return {
        ...state,
        list_schedule_livestream: action.payload,
        loading_list_schedule_livestream: true,
      };
    }
    case livestreamConstants.LIST_SCHEDULE_LIVESTREAM_FAILURE:
      return {
        ...state,
        list_schedule_livestream: [],
        loading_list_schedule_livestream: true,
      };
    case livestreamConstants.SUBSCRIBE_LIVESTREAM_REQUEST: {
      return {
        ...state
      }
    }

    case livestreamConstants.SUBSCRIBE_LIVESTREAM_SUCCESS: {
      const livestreamList = [...state.list_schedule_livestream];
      livestreamList.map(livestream => {
        if (livestream.livestream.id === action.payload.id) {
          livestream.livestream.is_notification = action.payload.is_notification;
        }
        return livestream;
      })

      return {
        ...state,
        list_schedule_livestream: livestreamList
      }
    }

    case livestreamConstants.SUBSCRIBE_LIVESTREAM_FAILURE: {
      return {
        ...state
      }
    }
    default:
      return state
  }
}
