import React from "react";

import "./footer.css";

export function Footer() {

  return (
    <section
      className="event__category"
    >
      <div className="footer_box">
        <div className="footer_box_item1">
          <ul className="list-container">
            <li><a href='/terms.html'>利用規約</a></li>
            <li><a href='/help.html'>よくある質問</a></li>
            <li><a href='/privacy.html'>プライバシーポリシー</a></li>
            <li><a href='/settlement.html'>資金決済法に基づく表示</a></li>
            <li><a href='/tsh.html'>特定商取引に基づく表示</a></li>
            <li><a href='/company.html'>会社概要</a></li>
          </ul>
        </div>
        <div className="footer_box_item2">
          <div className="contact contact__cus">
            <form className="form">
              <a type="submit"
                className="btn btn-outline-light btn__footer_box_item2"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeM6g83-mEs0bUSSwYXuzISydXMAChXIiRm6ehBKsZIewIMIA/viewform"
                id="pagetop-btn" target="_blank" rel="noreferrer">
                お問い合わせ
              </a>
            </form>
          </div>
        </div>
        <div className="footer_box_item3">
          <div className='footer_box_sns'>
            <a href="https://www.facebook.com/livedreamer2018" target="_blank" rel="noreferrer">
              <i className="icon fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/livedreamer_official/" target="_blank" rel="noreferrer">
              <i className="icon fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/LiveDreamerPer2?lang=ja" target="_blank" rel="noreferrer">
              <i className="icon fab fa-twitter"></i>
            </a>
          </div>
          <div className="logo__footer">
            <img src='/assets/images/logo_footer.png' alt="" />
          </div>
          <div className="copyright">©2019 dreamer Inc.</div>
        </div>
      </div>
    </section>
  );
}