import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { eventActions } from "../../_actions";
import "./ticket.css";

export function Ticket() {
  const dispatch = useDispatch();
  const history = useHistory();
  let selectListEvents = useSelector(state => state.events ? state.events.searchEvents : []);
  const [listEvents, setListEvents] = useState(selectListEvents);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);
  const page = 1;
  const limit = -1;

  // click away listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => document.removeEventListener("mousedown", handleClick, false);
  }, []);

  useEffect(() => {
    if (searchValue) {
      const getEvents = async () => {
        await dispatch(eventActions.searchEvents(page, limit, searchValue.trim()));
      }
      getEvents();
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue) {
      setListEvents(selectListEvents);
    } else {
      setListEvents([]);
    }
  }, [selectListEvents, searchValue]);

  const handleClick = (e) => {
    if (dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (!visible) {
      setVisible(true);
    }
  };

  const selectItem = (item) => {
    history.push(`/events/${item.id}`);
  };

  const handlePurchasedTicket = () => {
    localStorage.setItem('active_tab', 2);
    localStorage.setItem('my-page', 'my-page');
    // history.push(`/my-page`);
  }

  return (
    <div className="container_ticket">
      <section className="banner-home" style={{ background: `url("/assets/images/bg.png") center / cover` }}>
        <div className="pt-4">
          <div className="container__search">
            <div tabIndex="0" className="input_container">
              <input
                className="input__search"
                type="text"
                placeholder="ライブ配信、イベントで検索"
                value={searchValue}
                onChange={handleChange}
                onFocus={() => {
                  setVisible(true);
                }}
              />
              <button className="btn button__search"><i className='fas fa-search'></i></button>
            </div>
            <div
              ref={dropdownRef}
              className={`dropdown__search dropdown_position ${visible ? "v" : ""}`}
            >
              <ul className="dropdown__search__ul">
                {listEvents && listEvents.length &&
                  listEvents.map((event) => (
                    <li
                      key={event.livestream.id}
                      onClick={() => selectItem(event.livestream)}
                      className="dropdown_item dispay__dropdown"
                    >
                      <span className="dispay__dropdown_image">
                        <img src={`${event.livestream.thumbnail}`} />
                      </span>
                      <span>
                        <div className="item_text1">{event.livestream.title}</div>
                        <div className="item_text2">{event.livestream.user.name}</div>
                      </span>
                    </li>
                  )) ||
                  <li key="zxc" className="dropdown_item cl__no_result">検索結果はございません。</li>
                }
              </ul>
            </div>
          </div>

          <div className="btn__ticket btn__ticket_pb">
            <a className="btn btn-default-ticket mb-2 btn-my-ticket" href="/my-page" onClick={handlePurchasedTicket}>
              <img src={'/assets/icons/ticket_btn.png'} alt="" />購入済みチケット
            </a>
            <a className="btn btn-outline-light-event mb-2 ml-2" href="/create-livestream-schedule">イベントを作成</a>
          </div>
        </div>
      </section>
    </div>

  );
}
