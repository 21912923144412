import { eventConstants } from '../_constants';
import { eventService } from '../_services/event.service';
import { history } from '../_helpers';
import { checkAuthentication, handleErrorMessage, handleReponse } from '../_helpers/api';

export const eventActions = {
  getEvents,
  getListEventsByDate,
  getEventDetail,
  editEvent,
  addTicket,
  getEventsByUser,
  getDraftEventsByUser,
  draftEventWithCreate,
  searchEvents,
  getEventTicketByHash,
  getCommunities,
  deleteDraftEvent
};

function getEventTicketByHash(hash) {

  return dispatch => {
    dispatch(request(''));
    eventService.getEventTicket(hash)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.EVENT_DETAIL_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.EVENT_DETAIL_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.EVENT_DETAIL_FAILURE, error } }
}

function getEvents(page, limit, arrCategories) {
  let data = {
    page,
    limit,
    arrCategories
  }

  return dispatch => {
    dispatch(request(''));

    eventService.listEvent(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.LIST_EVENT_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.LIST_EVENT_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.LIST_EVENT_FAILURE, error } }
}

function getEventsByUser(page, limit, userId) {
  let data = {
    page,
    limit,
    userId
  }

  return dispatch => {
    dispatch(request(''));

    eventService.getEventsByUser(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.LIST_EVENT_BY_USER_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.LIST_EVENT_BY_USER_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.LIST_EVENT_BY_USER_FAILURE, error } }
}

function getDraftEventsByUser(page, limit) {
  let data = {
    page,
    limit
  }

  return dispatch => {
    dispatch(request(''));

    eventService.getDraftEventsByUser(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.LIST_MY_DRAFT_EVENT_BY_USER_FAILURE, error } }
}

function draftEventWithCreate(event) {
  return dispatch => {
    dispatch(save(event));
  }
  function save(event) { return { type: eventConstants.DRAFT_EVENT_WITH_CREATE, payload: event } };
}

function getListEventsByDate(eventDay, page, limit) {
  return dispatch => {
    dispatch(request(''));
    eventService.listEventsByDate(eventDay, page, limit)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.EVENT_BY_DATE_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.EVENT_BY_DATE_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.EVENT_BY_DATE_FAILURE, error } }
}

function getEventDetail(eventId) {
  return async dispatch => {
    dispatch(request(''));
    await eventService.eventDetail(eventId)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.EVENT_DETAIL_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.EVENT_DETAIL_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.EVENT_DETAIL_FAILURE, error } }
}

function editEvent(eventId, data) {
  return dispatch => {
    eventService.editEventDetail(eventId, data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: eventConstants.EDIT_EVENT_DETAIL_SUCCESS, payload: data } }
}

function addTicket(eventId, data) {
  return async dispatch => {
    await eventService.addTicket(eventId, data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: eventConstants.ADD_TICKET_SUCCESS, payload: data } }
}

function searchEvents(page, limit, keyword) {
  return dispatch => {
    dispatch(request(''));
    eventService.searchEvents(page, limit, keyword)
      .then(
        res => {
          
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.SEARCH_EVENTS_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.SEARCH_EVENTS_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.SEARCH_EVENTS_FAILURE, error } }
}

function getCommunities() {
  return dispatch => {
    dispatch(request(''));
    eventService.getCommunities()
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: eventConstants.GET_COMMUNITY_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.GET_COMMUNITY_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.GET_COMMUNITY_FAILURE, error } }
}

function deleteDraftEvent(id) {
  return async dispatch => {
    dispatch(request(''));
    await eventService.deleteDraftEvent(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(id));
            localStorage.setItem('deleteDraft', 'success');
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
          localStorage.setItem('deleteDraft', 'fail');
        }
      );
  };

  function request(data) { return { type: eventConstants.DELETE_DRAFT_EVENT_REQUEST, payload: data } }
  function success(data) { return { type: eventConstants.DELETE_DRAFT_EVENT_SUCCESS, payload: data } }
  function failure(error) { return { type: eventConstants.DELETE_DRAFT_EVENT_FAIL, error } }
}
