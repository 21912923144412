import { livestreamConstants } from '../../_constants';

const initialState = { videoCategories: '', loadingVideoCategories: false }

export function videoCategories(state = initialState, action) {
  switch (action.type) {
    case livestreamConstants.GET_VIDEO_CATEGORIES_REQUEST:
      return {
        ...state,
        loadingVideoCategories: false
      };
    case livestreamConstants.GET_VIDEO_CATEGORIES_SUCCESS:
      return {
        ...state,
        videoCategories: action.payload,
        loadingVideoCategories: true
      };
    case livestreamConstants.GET_VIDEO_CATEGORIES_FAILURE:
      return {
        videoCategories: action.payload,
        loadingVideoCategories: true
      };
    default:
      return state
  }
}
