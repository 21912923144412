import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SendVerifycationCode } from '../../_components/Register/SendVerifycationCode';
import { VerifyCode } from '../../_components/Register/VerifyCode';
import { EnterPassword } from '../../_components/Register/EnterPassword';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { authenticationActions } from '../../_actions/authentication.actions';

function RegisterPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
    dispatch(authenticationActions.logout());
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="home__page">
        <img className="home__image" src="/assets/images/bg.png" alt="" />
        <div className="list__livestream_login d-flex justify-content-center align-items-center">
          <div className="form_register">
            <div className="login__form register__form" style={{ width: '410px' }}>
              <fieldset>
                <SendVerifycationCode />
              </fieldset>
              <fieldset>
                <VerifyCode />
              </fieldset>
              <fieldset>
                <EnterPassword />
              </fieldset>
            </div>
            <div className="list_progress_login">
              <div className="progress__line d-flex justify-content-between align-items-center content__margin-top">
                <div className="cicle_dots" />
                <span className="line" />
                <div className="cicle_dots" />
                <span className="line" />
                <div className="cicle_dots" />
              </div>
              <div className="progress__text d-flex justify-content-between align-items-center content__margin-top">
                <span style={{ paddingLeft: '30px' }}>認証コード送信</span>
                <span style={{ marginLeft: '-35px' }}>認証コード入力</span>
                <span style={{ marginRight: '42px' }}>登録</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export { RegisterPage };