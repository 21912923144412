import firebase from "../_helpers/firebase";
export const auth = firebase.auth();

export const signInWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider()
  await auth
    .signInWithPopup(googleProvider)
    .catch(handleAuthError);
}

export const signInWithFacebook = async () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider()
  await auth
    .signInWithPopup(facebookProvider)
    .catch(handleAuthError);
}

export const signInWithTwitter = async () => {
  const twitterProvider = new firebase.auth.TwitterAuthProvider()
  await auth
    .signInWithPopup(twitterProvider)
    .catch(handleAuthError);
}

export const signInWithCustomToken = async (token) => {
  return firebase.auth().signInWithCustomToken(token);
}

export const logOut = async () => {
    localStorage.setItem('type-social', '')
    localStorage.clear();
    await auth.signOut();
}

const handleAuthError = (error) => {
  console.log(error)
}
