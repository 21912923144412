import React, { useEffect } from 'react';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { ListSchedule } from '../../_components/ListSchedule/index';

function ListSchedulePage() {

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  return (
    <>
      <Header />
      <div className="home__page_live">
        <ListSchedule />
      </div>
      <Footer />
    </>
  );
};

export { ListSchedulePage };
