import axios from 'axios';
import { API_BASE, getHeaders } from "../_helpers/api";

export const eventService = {
  listEvent,
  subscribeLivestream,
  unsubscribeLivestream,
  listEventsByDate,
  eventDetail,
  editEventDetail,
  addTicket,
  userProfile,
  getEventsByUser,
  getDraftEventsByUser,
  buyTicket,
  searchEvents,
  getEventTicket,
  getCommunities,
  getFreeTicket,
  deleteDraftEvent,
  getUserboughtTicket
};

function getEventTicket(hash) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/hash/${hash}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function listEvent(data) {
  const categories = data.arrCategories.join();
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/events?page=${data.page}&limit=${data.limit}&categories=${categories}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getEventsByUser(data) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/my-events?page=${data.page}&limit=${data.limit}&user=${data.userId || 0}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getDraftEventsByUser(data) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/my-draft-events?page=${data.page}&limit=${data.limit}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function subscribeLivestream(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${id}/subscribe-notification`, {}, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function unsubscribeLivestream(id) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${id}/unsubscribe-notification`, {}, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function listEventsByDate(eventDay, page, limit) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/events-by-date/${eventDay}?page=${page}&limit=${limit}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function eventDetail(eventId) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/events/${eventId}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function getUserboughtTicket(eventId) {
  const token = localStorage.getItem('token');

  return axios.get(`${API_BASE}/api/v1/livestreams/${eventId}/bought-tickets`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function editEventDetail(eventId, data) {
  const token = localStorage.getItem('token');
  return axios.put(`${API_BASE}/api/v1/livestreams/${eventId}`, data, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function addTicket(eventId, data) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${eventId}/add-ticket`, data, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function userProfile(eventId) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/${eventId}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function buyTicket(livestreamId, data) {
  const token = localStorage.getItem('token');
  return axios.post(`${API_BASE}/api/v1/livestreams/${livestreamId}/buy-ticket`, data, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function searchEvents(page, limit, keyword) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/events?page=${page}&limit=${limit}&categories=&key=${keyword}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function getCommunities() {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/me/join-communities?key=&typeCommunity=2&page=1&limit=-1&sort=total_member:desc,fee:desc,created_at:desc`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function getFreeTicket(id, hash) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/livestreams/${id}/get-free-ticket/${hash}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}

function deleteDraftEvent(id) {
  const token = localStorage.getItem('token');
  return axios.delete(`${API_BASE}/api/v1/livestreams/draft-livestreams/${id}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}
