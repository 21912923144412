import React from 'react';
import { useLocation } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, LineShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LineIcon } from "react-share";

export function ModalShare() {
  const location = useLocation();
  const urlShare = `https://s-live-deramer.com${location.pathname}`;

  return (
    <div className="modal fade" id="modalShare" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">共有</h5>
            <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <ul className="share_modal d-flex justify-content-center align-items-center">
                <li>
                  <FacebookShareButton
                    url={urlShare}
                    description={"aiueo"}
                    className="Demo__some-network__share-button btn"
                  >
                    <FacebookIcon size={48} round={true} />
                  </FacebookShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    url={urlShare}
                    className="btn"
                  >
                    <TwitterIcon size={48} round={true} />
                  </TwitterShareButton>
                </li>
                <li>
                  <LineShareButton
                    url={urlShare}
                    className="btn"
                  >
                    <LineIcon size={48} round={true} />
                  </LineShareButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
