import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';

import '../../_assets/css/charge-coin.css'
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';

function ChargeCoinPage() {
  const dispatch = useDispatch();
  const linkRef = useRef(null);
  const chargeCoins = useSelector(state => state.chargeCoin.chargeCoins);
  const coinsUser = useSelector(state => state.coins.coins);
  const [urlChargeCoinCredit, setUrlChargeCoinCredit] = useState('');
  const [userCoinTotal, setUserCoinTotal] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const callback = urlParams.get('callback') || window.location.hostname;

  useEffect(() => {
    dispatch(userActions.chargeCoin());
    if (coinsUser) {
      setUserCoinTotal(coinsUser);
    }

  }, [dispatch, coinsUser]);

  const handleChargeCoinByCredit = async (packageName) => {
    await dispatch(userActions.chargeCoinByCredit(packageName, callback))
    setUrlChargeCoinCredit(localStorage.getItem('url-chage-coin'))
    linkRef.current.click()
  }

  return (
    <>
      <Header />
      <div className="home__page_live">

        <div className="section section__charge_coins bg_black">
          <div className="container">
            <div className="tabs_charge_coin">
              <div className="your__coins">
                <h5>保有コイン<img src="/assets/images/ic_coin_livestream.png" alt="" />
                  <strong>{userCoinTotal ? userCoinTotal : 0}</strong></h5>
                <div className="note">
                  <span>コインチャージモードをご利用する際に、 <br />
                    次の事を注意してください：</span>
                </div>
              </div>
              <ul className="list_charge__coin">
                {chargeCoins && chargeCoins.map((chargeCoin, i) => (
                  <li key={i}>
                    <div className="coin">
                      <img src="/assets/images/coin_button.png" alt="" />
                      <span>{chargeCoin.coin}</span>
                    </div>
                    <div className="money">
                      <button className="btn" onClick={() => handleChargeCoinByCredit(chargeCoin.package_name)}>
                        <span>¥{chargeCoin.price ? `${chargeCoin.price}` : `000`}</span>
                      </button>
                      {urlChargeCoinCredit &&
                        <a href={urlChargeCoinCredit} ref={linkRef} rel="noopener noreferrer"></a>}
                    </div>
                  </li>
                ))}
              </ul>
              <span className="remind-notification">返品・キャンセルについては<a href="/tsh.html">こちら</a></span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { ChargeCoinPage };
