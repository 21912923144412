import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { User } from '../../_components/User/User';

function UserPage() {
  const userId = useParams().userId;

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  return (
    <>
      <Header />
      <div className="home__page_live">
        <User userId={userId} />
      </div>
      <Footer />
    </>
  );
};

export { UserPage };
