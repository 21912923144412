import { userConstants } from '../../_constants';

const initialState = {
  listUsersWatched: [],
}

export function listUsers(state = initialState, action) {
  switch (action.type) {
    case userConstants.LIST_USER_REQUEST:
      return {
        ...state,
      };
    case userConstants.LIST_USER_SUCCESS:
    case userConstants.LIST_USER_FAILURE:
      return {
        ...state,
        listUsersWatched: action.payload,
      };

    default:
      return state
  }
}