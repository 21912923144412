import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { isIOS } from "react-device-detect";
import { socket } from '../../../_helpers';
import { Coins } from './Coins';
import EmojiBoard from '../../Emoji/EmojiBoard';
import FirstEmojiBoard from '../../Emoji/FirstEmojiBoard';
import './live_steam_detail.css';
import { livestreamActions } from '../../../_actions';
import { useWindowResize } from './useWindowResize';

export function Chat(props) {
  const dispatch = useDispatch();
  const elBoxChat = useRef();
  const userLogin = useSelector(state => state.login.user);
  const liveStreamDetail = useSelector(state => state.liveStreamDetail);
  const [content, setContent] = useState('');
  const [listChat, setListChat] = useState([]);
  let isRendered = useRef(false);
  const [displayLike, setDisplayLike] = useState(false);
  const [userLike, setUserLike] = useState(0);
  const messagesEndRef = useRef(null);
  const { width } = useWindowResize();
  const [userSendCoin, setUserSendCoin] = useState("");
  const [userChat, setUserChat] = useState("");

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end"
    });
  };
  useEffect(scrollToBottom, [listChat]);

  useEffect(() => {
    if (userSendCoin && userLogin.id !== userSendCoin.user.id) {
      setListChat([...listChat, userSendCoin]);
    }
  }, [userSendCoin]);

  useEffect(() => {
    if (userChat) {
      setListChat([...listChat, userChat]);
    }
  }, [userChat]);

  useEffect(() => {
    isRendered = true;
    socket.on('userSendCoin', (data) => {
      if (isRendered) {
        if (userLogin.id !== data.user.id) {
          if (data.coin) {
            let img_coin = '';
            switch (data.coin) {
              case 10:
                img_coin = "/assets/images/10.png";
                break;
              case 100:
                img_coin = "/assets/images/100.png";
                break;
              case 1000:
                img_coin = "/assets/images/1000.png";
                break;
              case 10000:
                img_coin = "/assets/images/10000.png";
                break;
              case 100000:
                img_coin = "/assets/images/100000.png";
                break;
              case 200:
                img_coin = "/assets/images/200.png";
                break;
              case 400:
                img_coin = "/assets/images/400.png";
                break;
              case 600:
                img_coin = "/assets/images/600.png";
                break;
              default:
                break;
            }
            const message = {
              coin: data.coin,
              content: data.coin + 'コインを送りました。',
              user: data.user,
              image: img_coin
            }
            setUserSendCoin(message);
          }
        }
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    socket.on('userChat', (data) => {
      if (isRendered) {
        if (data.coin) {
          if (userLogin.id === data.user.id) {
            let img_coin = '';
            switch (data.coin) {
              case 10:
                img_coin = "/assets/images/10.png";
                break;
              case 100:
                img_coin = "/assets/images/100.png";
                break;
              case 1000:
                img_coin = "/assets/images/1000.png";
                break;
              case 10000:
                img_coin = "/assets/images/10000.png";
                break;
              case 100000:
                img_coin = "/assets/images/100000.png";
                break;
              case 200:
                img_coin = "/assets/images/200.png";
                break;
              case 400:
                img_coin = "/assets/images/400.png";
                break;
              case 600:
                img_coin = "/assets/images/600.png";
                break;
              default:
                break;
            }

            const message = {
              coin: data.coin,
              content: data.coin + 'コインを送りました。',
              user: data.user,
              image: img_coin
            }
            setUserChat(message);
          }
        } else {
          setUserChat(data.message);
        }
      }
    });

    return () => {
      isRendered = false;
    };
  });

  useEffect(() => {
    isRendered = true;
    if (width > 768) {
      socket.on('userLike', (data) => {
        if (isRendered) {
          if (userLogin.id !== userLike) {
            setDisplayLike(true);
          } else {
            setUserLike(0);
          }
        }
      });
    }

    return () => {
      isRendered = false;
      setDisplayLike(false);
    };
  });

  useEffect(() => {
    const dom = elBoxChat.current ? elBoxChat.current.clientHeight : 0;
    if (dom) {
      localStorage.setItem('boxChatHeight', dom);
    }
  });

  const onMessageSubmit = async (e) => {
    e.preventDefault()
    if (!content) return false;
    await dispatch(livestreamActions.comment(1));
    const streamName = liveStreamDetail ? liveStreamDetail.livestream_detail.livestream.stream_name : '';
    const message = {
      content,
      user: {
        id: userLogin.id,
        name: userLogin.name,
        avatar: userLogin.avatar
      }
    }
    const objData = {
      streamName,
      message,
      type: 1
    }
    socket.emit('chat', objData);
    setContent('');
    setListChat([...listChat, message])
  }

  const onTextChange = e => {
    const { value } = e.target;
    setContent(value);
  }

  const renderChat = () => {
    return listChat.map((item, index) =>
      item &&
      <li className="d-flex justify-content-start" key={index}>
        <img className="user user__avatar" src={item.user.avatar} 
          onError={(event) => event.target.src = '/assets/images/avatar.png'} 
          alt="" />
        <div className="user_info">
          <span className="name">{item ? item.user.name : ''}</span>
          <div className="comment">
            {item.coin && <img src={item.image} className="comment__coin" alt="" />}
            <span>{item ? item.content : ''}</span>
          </div>
        </div>
      </li>
    )
  }

  const handleLike = () => {
    props.setUserLikeId(userLogin.id);
    const streamName = liveStreamDetail ? liveStreamDetail.livestream_detail.livestream.stream_name : '';
    socket.emit('like', { streamName, number_like: 1 });
    setUserLike(userLogin.id);
  }

  return (
    <div className="box__chat">
      <ul className="nav nav-tabs d-flex justify-content-around align-items-center" id="convertionTabs" role="tablist">
        <li className="nav-item">
          <a className="nav-link active tab__box_chat" id="home-tab" data-toggle="tab" href="#comment" role="tab"
            aria-controls="home" aria-selected="true">
            <img className="comment-img" src="/assets/images/comment.png" alt="" />
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link tab__box_chat" id="profile-tab" data-toggle="tab" href="#donate" role="tab"
            aria-controls="profile" aria-selected="false">
            <img className="donate-img" src="/assets/images/coins.png" alt="" />
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="comment" role="tabpanel" aria-labelledby="home-tab">
          <div ref={elBoxChat} className="content__box_chat">
            <div className="box__lis_chat">
              <ul className="list_chat">
                {renderChat()}
                <div ref={messagesEndRef} />
              </ul>
            </div>

            <div className="box_type_chat d-flex justify-content-between align-items-center content__margin-top">
              <div className="type__comment">
                <form className="comment-form" onSubmit={onMessageSubmit}>
                  <img src="/assets/images/comment.png" alt="" className="type__comment_img" />
                  <input
                    name="content"
                    onChange={e => onTextChange(e)}
                    value={content}
                    placeholder="コメントする"
                    className="type__comment_input"
                    autoComplete="off"
                  />
                  <button id="sentMess" className="btn btn__sentMess">
                    <i className="far fa-paper-plane"></i>
                  </button>
                </form>
              </div>
              <div className="button__icon">
                <div>
                  <button id="show_share" className="btn show_emoji" data-id="show_share" onClick={handleLike}>
                    <EmojiBoard />
                    {width > 768 && <FirstEmojiBoard displayLike={displayLike} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="donate" role="tabpanel" aria-labelledby="profile-tab">
          <Coins liveStreamDetail={liveStreamDetail} />
        </div>
      </div>
    </div>
  );
}
