import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import moment from "moment";
import { EventLeft } from '../EventLeft/index';
import { EventRight } from '../EventRight/index';
import { getWeekDay } from '../../../_utils/formatTime';
import "./eventcontent.css";

export function EventContent(props) {
  const history = useHistory();
  const [getDay, setGetDay] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [freeTicket, setFreeTicket] = useState(false);

  useEffect(() => {
    setFreeTicket(false);
    if (props.eventsDetail) {
      const date = props.eventsDetail.livestream.start_time;
      setGetDay(getWeekDay(date));
      setCurrMonth(new Date(date).getMonth());
      setCurrDate(new Date(date).getDate());

      props.eventsDetail.livestream.tickets.filter((ticket) => {
        if (ticket.price === 0 && ticket.ticket_remain > 0) {
          return setFreeTicket(true);
        }
      })
    }
  }, [props.eventsDetail]);

  const handleClickBack = () => {
    const goBack = localStorage.getItem("goBack")
    if (goBack) {
      localStorage.setItem("goBack", ``);
      if (goBack === '/top') {
        return window.location = `${document.location.origin}/top`;
      }
      return history.push(goBack)
    }
    localStorage.setItem("goBack", ``);
    history.goBack();
  }

  const handleClickEdit = (eventId) => {
    history.push(`/event-edit/${eventId}`);
  }

  const handleClickBackCalendar = () => {
    localStorage.setItem("goBack", "");
    history.push(`/events-by-date/${moment.unix(props.eventsDetail.livestream.start_time / 1000).format("YYYY-MM-DD")}`);
  }

  const handleClickEditFreeTickets = (eventId) => {
    history.push(`/event-edit/${eventId}/free-ticket`);
  }

  return (
    <>
      <div className="container-fluid">
        <section className="event-content">
          <div className="row">
            <div className="col-lg-7 px-3 mb-5">
              <EventLeft eventsDetail={props.eventsDetail} />
            </div>
            <div className="col-lg-5 px-3 mb-5">
              <EventRight eventsDetail={props.eventsDetail} user={props.user} />
            </div>
          </div>

          {props.eventsDetail && props.user && props.eventsDetail.livestream.user.id === props.user.id &&
            <div className="event__btn">
              <button className="btn event__but_return event__but_cus" onClick={handleClickBack}>戻る</button>
              <button className="btn event__but_next event__but_cus"
                onClick={() => handleClickEdit(props.eventsDetail.livestream.id)}>編集する
              </button>
              {freeTicket && <button className="btn event__but_next event__but_cus"
                onClick={() => handleClickEditFreeTickets(props.eventsDetail.livestream.id)}>残りの無料チケットを配る</button>}
            </div>
          }

          {/* {props.eventsDetail && (props.user || (props.user && props.eventsDetail.livestream.user.id !== props.user.id)) &&
            <>
              <hr className="event__hr" />
              <div className="event__back">
                <button className="btn btn__back" style={{ fontSize: '14px' }} onClick={handleClickBackCalendar}>
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  <span>{`${(currMonth + 1)}`}</span>月<span>{`${currDate}`}</span>日（{`${getDay}`}）のイベントへ戻る
                </button>
              </div>
            </>
          } */}
        </section>
      </div>
    </>
  );
}
