import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { roundNumber } from '../../_utils/formatNumber';
import { Calendar } from './Calendar';

export function HeaderMenu() {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const userCoin = useSelector(state => state.coins.coins);
  const loginRole = useSelector(state => state.login && state.login.user && state.login.user.role);
  const [coinOfUser, setCoinOfUser] = useState('');
  const history = useHistory();
  const [hiddenMenu, setHiddenMenu] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      setHiddenMenu(true)
    } else {
      setHiddenMenu(false)
    }
  }, [location]);

  useEffect(() => {
    if (userCoin >= 0) {
      if (userCoin.toString().length < 4) {
        setCoinOfUser(userCoin);
      } else if ((userCoin.toString().length === 4) || (userCoin.toString().length > 4 && userCoin.toString().length < 7)) {
        const number = userCoin / 1000;
        setCoinOfUser(`${roundNumber(number, 2)}K`);
      } else if ((userCoin.toString().length === 7) || (userCoin.toString().length > 7 && userCoin.toString().length < 10)) {
        const number = userCoin / 1000000;
        setCoinOfUser(`${roundNumber(number, 2)}M`);
      } else {
        const number = userCoin / 1000000000;
        setCoinOfUser(`${roundNumber(number, 2)}B`);
      }

      localStorage.setItem('user-send-coin', userCoin);
    }
  }, [userCoin]);

  const onClickHref = (href) => {
    history.push(href);
  }

  return (
    <>
      {token ? <>
        <ul className="menu__center navbar-nav mr-auto mt-2 mt-lg-0">
          {hiddenMenu ?
            <>
              <li className="nav-item small-calendar active livestream__box">
                <a className="nav-link nav-link__menu livestream__menu" href="/top">LIVESTREAM</a>
              </li>
            </> : <>
              <li className="nav-item small-calendar active">
                <div className="top__calendar">
                  <Calendar />
                </div>
              </li>
              <li className="nav-item nav-item__menu small-calendar" onClick={() => onClickHref('/top')}>
                <a className="nav-link nav-link__menu top__menu" href="/top">TOP</a>
              </li>

              <li className="nav-item nav-item__menu">
                <a className="nav-link nav-link__menu" href="/">HOME PAGE</a>
              </li>
              {loginRole && <li className="nav-item nav-item__menu item__menu_margin" onClick={() => onClickHref('/my-events')}>
                <a className="nav-link nav-link__menu" onClick={() => onClickHref('/my-events')}>配信スケジュール管理</a>
              </li> || ''}
            </>}
        </ul>
        {!hiddenMenu && <ul className="navbar-nav user__coin">
          <li className="nav-item d-flex align-items-center">
            <div className="ic__coin_ld">
              <img src="/assets/images/ic_coin_livestream.png" alt="" />
              <span>{coinOfUser ? coinOfUser : 0} DPoint</span>
            </div>
          </li>
        </ul>}
      </>
        : <ul className="menu__center navbar-nav mr-auto mt-lg-0">
          {hiddenMenu ?
            <>
              <li className="nav-item small-calendar active livestream__box">
                {/* <a className="nav-link nav-link__menu livestream__menu" href="/top">LIVESTREAM</a> */}
              </li>
            </> : <>
              <li className="nav-item small-calendar active">
                <div className="top__calendar">
                  <Calendar />
                </div>
              </li>
              <li className="nav-item nav-item__menu small-calendar">
                <a className="nav-link nav-link__menu top__menu" href="/top">TOP</a>
              </li>
              <li className="nav-item nav-item__menu">
                <a className="nav-link nav-link__menu" href="/">HOME PAGE</a>
              </li>
            </>}
        </ul>
      }
    </>

  );
}