import { livestreamConstants } from '../../_constants';

const initialState = { communities: '', getCommunityId: '' }

export function communities(state = initialState, action) {
  switch (action.type) {
    case livestreamConstants.GET_COMMUNITIES_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: action.payload,
      };
    case livestreamConstants.GET_COMMUNITIES_FAILURE:
      return {
        communities: action.payload,
      };
    case livestreamConstants.GET_COMMUNITY_DETAIL_SUCCESS:
      return {
        ...state,
        getCommunityId: action.payload,
      };
    default:
      return state
  }
}
