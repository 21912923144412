import React from 'react';
import * as moment from 'moment';
import Linkify from 'react-linkify';
import { useHistory } from 'react-router';

function CardEvent(props) {
  const history = useHistory();

  const handleClickLiveStream = (id) => {
    props.handClick && history.push(`/events/${id}`);
  }
  return (
    <>
      {props.listItems.length > 0 && props.listItems.map((listLiveStream, i) => {
        return <div className={`live_content_tab1 ${props.handClick ? 'cursor_pointer' : ''}`} key={i}
          onClick={() => handleClickLiveStream(listLiveStream.id)}>
          <span className="schedule_content_img">
            <img
              src={`${listLiveStream.thumbnail ? listLiveStream.thumbnail : "/assets/images/livestream.png"}`}
              alt="" />
          </span>
          <span className="schedule_content_box">
            <span className="schedule_time_day">
              <span className="schedule_content_time">
                <span
                  className="schedule_day">{moment.unix(listLiveStream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")}</span> ~
              </span>
              <span className="schedule_content_live">{listLiveStream.title}</span>
            </span>
            <span className="schedule_comment"><Linkify
              properties={{ target: '_blank' }}>{listLiveStream.description}</Linkify></span>
          </span>
        </div>
      })}
    </>
  );
};

export { CardEvent };
