import { userConstants } from '../../_constants';

const initialState = { userProfile: "", loadingUserProfile: false }

export function userProfile(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_PROFILE_REQUEST:
      return {
        ...state,
        loadingUserProfile: false
      };
    case userConstants.USER_PROFILE_SUCCESS:
    case userConstants.USER_PROFILE_FAILURE:
      return {
        ...state,
        userProfile: action.payload,
        loadingUserProfile: true
      };

    default:
      return state
  }
}