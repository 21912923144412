import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cogoToast from 'cogo-toast';
import { formatDate, getWeekDay } from '../../_utils/formatTime';
import { eventActions } from '../../_actions/event.actions';
import Linkify from 'react-linkify';

import "./listevent.css";
import Loader from "../Loader/Loader";

function addDays(date) {
  const result = new Date(date)
  return result.setDate(result.getDate() + 1);
}

function minisDays(date) {
  const result = new Date(date)
  return result.setDate(result.getDate() - 1);
}

function LastDayOfMonth(Year, Month) {
  return new Date((new Date(Year, Month, 1)) - 1).getDate();
}

function getMonthUrl(month) {
  return ("0" + (month + 1)).slice(-2);
}

function getDayUrl(day) {
  return ("0" + day).slice(-2);
}

export function ListEvent(props) {
  let eventsByDate = useSelector(state => state.events.eventsByDate);
  let loadingEventsFavorite = useSelector(state => state.events.loadingEventsFavorite);
  let loadingEventsByDate = useSelector(state => state.events.loadingEventsByDate);
  let userLogin = useSelector(state => state.login.user);
  let userLoginId = userLogin ? userLogin.id : 0;
  const history = useHistory();
  const dispatch = useDispatch();
  const [getDay, setGetDay] = useState("");
  const [prevDay, setPrevDay] = useState("");
  const [nextDay, setNextDay] = useState("");
  const [currentDate, setCurrentDate] = useState(() => new Date(props.eventDay));
  const [page, setPage] = useState(1);
  const limit = 8;
  const [loadMoreEvents, setLoadMoreEvents] = useState(false);
  const [eventsFavorite, setEventsFavorite] = useState("");
  const [listEventsByDate, setListEventsByDate] = useState("");
  const [numberEventsByDate, setNumberEventsByDate] = useState(0);
  const [eventsDetailEmpty, setEventsDetailEmpty] = useState(false);
  const [hiddenBtnTicket, setHiddenBtnTicket] = useState(0);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  useEffect(() => {
    setCurrentDate(new Date(props.eventDay));
  }, [props.eventDay])

  useEffect(() => {
    const dateUrl = moment(currentDate).format("YYYY-MM-DD");
    setGetDay(getWeekDay(currentDate));
    if (currentDate.getDate() === 1) {
      let monthEndDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      setPrevDay(monthEndDay.getDate());
      setNextDay(currentDate.getDate() + 1);
    } else if (currentDate.getDate() === LastDayOfMonth(currentDate.getFullYear(), currentDate.getMonth() + 1)) {
      let monthStartDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      setPrevDay(currentDate.getDate() - 1);
      setNextDay(monthStartDay.getDate());
    } else {
      setPrevDay(currentDate.getDate() - 1);
      setNextDay(currentDate.getDate() + 1);
    }

    setListEventsByDate([]);
    setEventsFavorite("");
    const getEventsByDate = async () => {
      await dispatch(eventActions.getListEventsByDate(dateUrl, page, limit));
    }
    getEventsByDate();
  }, [currentDate]);

  useEffect(() => {
    let time_out;
    if (eventsByDate && eventsByDate.events && eventsByDate.events.length > 0) {
      if (page === 1) {
        setNumberEventsByDate(eventsByDate.events.length);
        setListEventsByDate(eventsByDate.events);
      } else {
        const curData = [...listEventsByDate, ...eventsByDate.events];
        const numberLengthEvents = numberEventsByDate + eventsByDate.events.length;
        setNumberEventsByDate(numberLengthEvents);
        setListEventsByDate(curData);
      }

      const getUnit = eventsByDate.events.length % limit;
      if (getUnit === 0) {
        setLoadMoreEvents(true);
      } else {
        setLoadMoreEvents(false);
      }
    } else {
      setLoadMoreEvents(false);
      setNumberEventsByDate(0);
      time_out = setTimeout(() => {
        setEventsDetailEmpty(true);
      }, 500);
    }

    if (eventsByDate && eventsByDate.favorite_livestream) {
      setEventsFavorite(eventsByDate.favorite_livestream)
    }

    return () => clearTimeout(time_out);
  }, [eventsByDate]);

  const handlePrevDay = () => {
    const yesterday = minisDays(currentDate);
    const forYesterday = formatDate(new Date(yesterday));

    eventsByDate = [];
    setListEventsByDate([]);
    setEventsFavorite("");
    setNumberEventsByDate(0);
    setPage(1);

    if (forYesterday) {
      setCurrentDate(new Date(yesterday));
      history.push(`/events-by-date/${forYesterday}`);
    }
  }

  const handleNextDay = () => {
    const tomorrow = addDays(currentDate);
    const forTomorrow = formatDate(new Date(tomorrow));

    eventsByDate = [];
    setListEventsByDate([]);
    setEventsFavorite("");
    setNumberEventsByDate(0);
    setPage(1);

    if (forTomorrow) {
      setCurrentDate(new Date(tomorrow));
      history.push(`/events-by-date/${forTomorrow}`);
    }
  }

  const handleClickEventDetail = (eventId, startTime) => {
    let start = moment(new Date(startTime));
    let end = moment(new Date());
    let result = start.diff(end, 'minutes');
    if (result >= -15) {
      history.push(`/events/${eventId}`);
    } else {
      setHiddenBtnTicket(eventId);
      // cogoToast.error('het thoi gian', {
      //     heading: 'エラー',
      //     position: 'top-center',
      //     hideAfter: 3,
      //     bar: { size: '10px' }
      // });
    }
  }

  const handleLoadMoreEventsByDate = async (e) => {
    e.preventDefault();
    const dateUrl = moment(currentDate).format("YYYY-MM-DD");
    await dispatch(eventActions.getListEventsByDate(dateUrl, page + 1, limit));
    setPage(page + 1);
  }

  return (
    <>
      <section className="section">
        <div className="container_list__event">
          <h3
            className="event__detail_title">{getMonthUrl(currentDate.getMonth())}月{getDayUrl(currentDate.getDate())}日（{`${getDay}`}）のイベント</h3>

          <div className="best_event">
            <div className="best_event_head">
              <div className="best_event_title">ベストイベント</div>
              <div
                className="number__event">{eventsByDate && eventsFavorite && eventsFavorite.livestream && eventsFavorite.livestream.is_event || 0}イベント
              </div>
            </div>
            {eventsFavorite &&
              <div className="best_event_box">
                <div className="best_event_box_img">
                  <img
                    className="cursor_pointer"
                    src={`${eventsFavorite.livestream.thumbnail}`}
                    alt=""
                    onClick={() => handleClickEventDetail(eventsFavorite.livestream.id, eventsFavorite.livestream.start_time)}
                  />
                  <span className="best_event_heart_start">
                    <a
                      className={`img_start ${eventsFavorite.livestream.tickets[0] && eventsFavorite.livestream.tickets[0].ticket_remain.toString().length > 4 ? "img_start_length" : ""}`}>
                      {eventsFavorite.livestream.tickets[0] && eventsFavorite.livestream.tickets[0].ticket_remain || 0}
                    </a>
                    <span>残り<br />チケット</span>
                  </span>
                </div>
                <div className="best_event_content_day">
                  <div className="best_event_content_daytime">
                    <img src={'/assets/icons/calendar_schedule.png'} className="content_daytime__img" alt="" />
                    <span className="pl-1 pr-1 content_daytime__color">
                      {moment.unix(eventsFavorite.livestream.start_time / 1000).format("ddd, D MMM YYYY")}
                    </span>
                    {eventsFavorite.livestream.categories && eventsFavorite.livestream.categories.map((category, i) => {
                      return <span className="badge badge-dark badge-custom" key={i}>  {category.name}  </span>
                    })}

                    <div className="best_event_content_title best_event_content_title_cus"
                      onClick={() => handleClickEventDetail(eventsFavorite.livestream.id, eventsFavorite.livestream.start_time)}>
                      {eventsFavorite.livestream.title}
                    </div>
                    <p className="best_event_copy_right best_event_content_title_cus">
                      <Linkify properties={{ target: '_blank' }}>{eventsFavorite.livestream.description}</Linkify>
                    </p>
                    <div className="best_event_content_content">
                      {eventsFavorite.livestream.user.name}
                    </div>
                  </div>
                  {moment(new Date(eventsFavorite.livestream.start_time)).diff(moment(new Date()), 'minutes') >= -15 &&
                    <div
                      className="coupon__tag"
                      style={eventsFavorite.livestream.id === hiddenBtnTicket ? { display: 'none' } : { display: 'block' }}
                      onClick={() => handleClickEventDetail(eventsFavorite.livestream.id, eventsFavorite.livestream.start_time)}>
                      チケット購入
                    </div>}

                </div>
              </div>
              || null}
            {!eventsByDate.favorite_livestream && eventsDetailEmpty && loadingEventsFavorite &&
              <div className="video_not_fount">
                <span className="video_span_not_fount">選択日のベストイベントはございません。</span>
              </div>}
            {!loadingEventsFavorite && <Loader />}
          </div>
        </div>
      </section>
      <section className="section section__live_stream">
        <div className="container_list__event">
          <div className="best_event">
            <div className="best_event_head">
              <div className="best_event_title">イベント一覧</div>
              <div className="number__event"><span className="">{numberEventsByDate}</span>イベント</div>
            </div>
            <div className="container_list_event">
              {(listEventsByDate && listEventsByDate.length) ? listEventsByDate.sort((a, b) => (a.livestream.start_time > b.livestream.start_time) ? 1 : -1).map((event, i) => {
                let start = moment(new Date(event.livestream.start_time));
                let end = moment(new Date());
                let result = start.diff(end, 'minutes');
                let quantityTicket = event.livestream.tickets.reduce((total, ticket) => {
                  return total += ticket.ticket_remain
                }, 0);
                return <div className="list_event" key={i}>
                  <div className="list_event_img" onClick={() => handleClickEventDetail(event.livestream.id, start)}>
                    <img className="cursor_pointer" src={`${event.livestream.thumbnail}`} alt="" />
                    <span className="list_event_img_start">
                      <span className="list_event_content">
                        {event.livestream.user.name}
                      </span>
                    </span>
                  </div>
                  <h5 className="list_event_title" onClick={() => handleClickEventDetail(event.livestream.id, start)}>
                    {event.livestream.title}
                  </h5>
                  <span
                    className="list_event_time">{moment.unix(event.livestream.start_time / 1000).format('HH:mm')} ~</span>

                  {event.livestream.user_id !== userLoginId && result >= -15 && <button
                    onClick={() => handleClickEventDetail(event.livestream.id, start)}
                    className="list_event_notification"
                    style={event.livestream.id === hiddenBtnTicket ? { display: 'none' } : { display: 'block' }}
                  >
                    チケット購入
                  </button>}

                  <div className="list_event_badge">
                    {event.livestream.categories.map((category, i) => {
                      return <span className="badge badge-dark badge__mg_top badge-custom"
                        key={i}>  {category.name}  </span>
                    })}

                    <span className="list_event_btn">
                      残りチケット{quantityTicket}枚
                    </span>
                  </div>
                </div>
              }) : ''}
              {!listEventsByDate.length && eventsDetailEmpty && loadingEventsByDate &&
                <div className="video_not_fount">
                  <span className="video_span_not_fount"> 選択日のイベントはございません。</span>
                </div>
              }
              {!loadingEventsByDate && <Loader />}
            </div>
            <div className="pl-4 pr-4 event__category_load_more">
              {loadMoreEvents &&
                <button className="btn btn_list_event_load_more" onClick={(e) => handleLoadMoreEventsByDate(e)}>
                  もっと見る<i className="fas fa-chevron-right"></i>
                </button>}
            </div>

            <div className="btn_prev_next_day">
              <button className="btn_prev_day">
                <i className="fas fa-chevron-left" onClick={handlePrevDay}></i>
                <span onClick={handlePrevDay}>{`${prevDay}日（${getWeekDay(minisDays(currentDate))}）`}</span>
              </button>
              <button className="btn_next_day">
                <span onClick={handleNextDay}>{`${nextDay}日（${getWeekDay(addDays(currentDate))}）`}</span>
                <i className="fas fa-chevron-right" onClick={handleNextDay}></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
