import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { groupSelectCodeNation } from '../../_utils/Group/GroupSelectCodeNation';
import { clickNext } from '../../_utils/clickNext';
import './register.css';
import { authenticationActions } from '../../_actions/authentication.actions';

export function SendVerifycationCode() {
  const [inputs, setInputs] = useState({
    phone_code: '+81',
    phone_number: '',
  });
  const [submitted, setSubmitted] = useState(false);
  // const verifyCode = useSelector(state => state.authentication.verifyCode);
  const dispatch = useDispatch();

  const handleSelectCode = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }))
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (e.target.validity.valid) setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  const handleSubmitSendVerifycationCode = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (inputs.phone_code && inputs.phone_number) {
      await dispatch(authenticationActions.verifyRegisterCode(inputs.phone_code, inputs.phone_number));
      const verifyCode = localStorage.getItem('verify-token');
      if (verifyCode) {
        clickNext("nextRegister1");
      }
    }
  }

  const handleMaxLengthPhoneNumber = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  return (
    <form name="form" onSubmit={handleSubmitSendVerifycationCode}>
      <div className="locate__input d-flex justify-content-between">
        <div className="input__select input__select__phone">
          <select id="inputGroupSelect04" onChange={handleSelectCode} value={inputs.phone_code} name="phone_code"
            className="select__phone">
            {groupSelectCodeNation.map((item, i) => {
              return <option key={i} value={item.code}>{item.name}</option>
            })}
          </select>
          <span className="caret" />
        </div>
        <div className="input__phone input__phone__number">
          <input
            type="tel"
            name="phone_number"
            pattern="[0-9]*"
            autoFocus
            value={inputs.phone_number}
            onChange={handleChange}
            maxLength={20}
            placeholder="電話番号"
            onInput={handleMaxLengthPhoneNumber}
            className={(submitted && !inputs.phone_number ? ' is-invalid' : '')}
          />
        </div>

      </div>
      {submitted && !inputs.phone_number &&
        <div className="invalid-feedback">電話番号が必要です</div>
      }
      <div className="sub__content text-left mt-4 mb-0">
        <span>※ 電話番号の最初の「0」は打たずに <br /></span>
        <span>ご入力ください。</span>
      </div>
      <div className="sub__note mt-2">
        登録・ログインすることで、当社の <a href="https://live-dreamer.com/terms.html">利用規約</a>・<br />
        <a href="https://live-dreamer.com/privacy.html">プライバシーポリシー</a>に同意したものとみなされます。<br />
        ※登録する際に、ご確認のSMSが届きますので <br />
        SMS料金が発生する場合がございます。<br />
      </div>
      <div className="input-login input__login mt-3">
        <input className="nextRegister1" type="submit" value="次へ" />
      </div>
    </form>
  );
}
