export const authenticationConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  VERIFY_REQUEST: 'VERIFY_REQUEST',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  VERIFY_FAILURE: 'VERIFY_FAILURE',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  REQUEST_RESET_PASSWORD_REQUEST: 'REQUEST_RESET_PASSWORD_REQUEST',
  REQUEST_RESET_PASSWORD_SUCCESS: 'REQUEST_RESET_PASSWORD_SUCCESS',
  REQUEST_RESET_PASSWORD_FAILURE: 'REQUEST_RESET_PASSWORD_FAILURE',

  VERIFY_RESET_PASSWORD_REQUEST: 'VERIFY_RESET_PASSWORD_REQUEST',
  VERIFY_RESET_PASSWORD_SUCCESS: 'VERIFY_RESET_PASSWORD_SUCCESS',
  VERIFY_RESET_PASSWORD_FAILURE: 'VERIFY_RESET_PASSWORD_FAILURE',

  LOGIN_SOCIAL_REQUEST: 'LOGIN_SOCIAL_REQUEST',
  LOGIN_SOCIAL_SUCCESS: 'LOGIN_SOCIAL_SUCCESS',
  LOGIN_SOCIAL_FAILURE: 'LOGIN_SOCIAL_FAILURE',

  LOGOUT: 'LOGOUT',
}
