import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { EventTicket } from '../../_components/EventTicket/EventTicket';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';

function EventTicketPage() {
  const hash = useParams().hash;

  let user = useSelector(state => {
    return state.login.user;
  })

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [])

  return (
    <>
      <Header />
      <div className="home__page_live">
        <EventTicket hash={hash} user={user} />
      </div>
      <Footer />
    </>
  );
};

export { EventTicketPage };
