import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { livestreamActions } from '../../_actions';
import { CardUser } from "./CardUser";
import useInfiniteScroll from '../MyPage/useInfiniteScroll';

function UserLivestream(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  let selectListLiveStream = useSelector(state => state.listLiveStream.listLivestreamMyPage);
  const [listItems, setListItems] = useState("");
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [loadMore, setLoadMore] = useState(true);
  const targetRef = useRef();

  useEffect(() => {
    selectListLiveStream = [];
    setListItems("");
    const listLivestream = async () => {
      await dispatch(livestreamActions.getListLivestreamByUserId(props.userProfileId, 'livestream', page, limit))
    }
    listLivestream();

  }, []);

  useEffect(() => {
    let timer;
    if (selectListLiveStream && selectListLiveStream.length > 0) {
      setLoadMore(true);
      timer = setTimeout(() => {
        if (listItems.length > 0 && listItems.length < 10) {
          setLoadMore(false);
        }
        setListItems(prevState => [
          ...prevState,
          ...selectListLiveStream
        ]);
        setIsFetching(false);
      }, 500);
    } else if (selectListLiveStream && selectListLiveStream.length === 0) {
      setLoadMore(false);
      setIsFetching(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [selectListLiveStream]);

  function fetchMoreListItems() {
    dispatch(livestreamActions.getListLivestreamByUserId(props.userProfileId, 'livestream', page + 1, limit));
    setPage(page + 1);
  }

  return (
    <div ref={targetRef}>
      {listItems && <CardUser listItems={listItems} handClick={false} />}
      {isFetching && loadMore && <span className="spinner-border sprinner-my-page"></span>}
    </div>
  );
};

export { UserLivestream };
