import React from 'react';
import videojs from 'video.js';
import "@videojs/http-streaming";
import '../../../_assets/css/video-js.css';
import './live_steam_detail.css';

const videoOptions = {
  preload: 'auto',
  autoplay: true,
  controls: true,
  muted: true,
  fluid: true,
  liveui: true,
  playsinline: true,
  height: 641,
  techOrder: ["html5"],
  html5: {
    nativeCaptions: false,
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    vhs: {
      overrideNative: true,
    },
  },
  controlBar: {
    timeDivider: false,
    durationDisplay: false,
    currentTimeDisplay: false,
    progressControl: false,
    liveDisplay: true,
    pictureInPictureToggle: false,
    fullscreenToggle: true
  },
}
export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.setWindowWidth);
  }

  componentDidUpdate() {
    const _videoOptions = {
      ...videoOptions,
      sources: [
        {
          src: this.props.url,
          type: 'application/x-mpegURL',
        },
      ],
    }

    if (this.videoNode && this.props.url && this.props.currentStatus !== 5) {
      this.player = videojs(this.videoNode, _videoOptions, function onPlayerReady() {
        var player = this;

        player.on("pause", function () {
          player.one('loadstart', function () {
            player.play();
          });
        });

        player.on('ended', function () {
          if (player.isFullscreen()) {
            player.exitFullscreen();
          }
          player.reset();
          player.dispose();
        })

        player.ready(function() {
          player.volume(0);
        });
      });
    }

    if (this.player && (this.props.stopLiveStreamEnded || this.props.stopLiveBuyTicket)) {
      this.player.reset();
      this.player.dispose();
    }
  }

  setWindowWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWindowWidth);
  }

  render() {
    const { width } = this.state;
    const { displayMobilByStatus, stopLiveStreamEnded } = this.props;

    return (
      <>
        <div className="embed__source" data-vjs-player>
          <video ref={node => (this.videoNode = node)} className="video-js vjs-default-skin" />
        </div>

        {stopLiveStreamEnded && (width <= 768) && <div className="stop_box__livestream">
          <div className="video__stop_livestream">配信は終了しました。</div>
        </div>}

        {!stopLiveStreamEnded && (width <= 768) && <div className="stop_box__livestream">
          <div
            className={typeof displayMobilByStatus === 'object' ? "video__stop_livestream_mobile" : ""}>{displayMobilByStatus}</div>
        </div>}
      </>
    );
  }
}
