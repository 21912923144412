import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Banner } from './Banner/Banner';
import { EventLeft } from './EventLeft/index';
import { TicketDistribute } from './TicketDistribute/index';
import { ModalGiveAwayTicket } from "./ModalGiveAwayTicket";
import { ModalGiveAwayTicketContinue } from "./ModalGiveAwayTicketContinue";
import { eventActions, livestreamActions, userActions } from "../../_actions";
import cogoToast from "cogo-toast";
import { eventService, livestreamService } from "../../_services";
import { ModalDraftGetAway } from "./ModalDraftGetAway";
import { ModalDraftGetAwaySuccess } from "./ModalDraftGetAwaySuccess";
import { formatMessage } from "../../_utils/formatMessage";
import { ModalEditEvent } from "../EventDetail/EventEdit/ModalEditEvent";
import { ModalEditEventSuccess } from "../EventDetail/EventEdit/ModalEditEventSuccess";
import { eventConstants } from "../../_constants";

export function GiveAwayTicket(props) {
  const history = useHistory();
  const eventId = useParams().eventId;
  let userLogin = useSelector(state => state.login.user);
  let userId = userLogin ? userLogin.id : 0;
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const buttonDraftRef = useRef();
  const buttonDraftSuccessRef = useRef();
  const buttonPublicContinueRef = useRef();
  const buttonEditRef = useRef();
  let selectUserProfile = useSelector(state => state.events.userProfile);
  const [freeTickets, setFreeTickets] = useState(() => props.createSchedule);
  const [btnClick, setBtnClick] = useState('');
  const [saveDraft, setSaveDraft] = useState(true);

  useEffect(() => {
    setFreeTickets({ ...props.createSchedule });
    const getUserProfilele = async () => {
      await dispatch(userActions.getUserProfile(userId));
    }
    getUserProfilele();
  }, [props.createSchedule]);

  const handleClickBack = () => {
    if (freeTickets && freeTickets.user_tickets && freeTickets.user_tickets.length) {
      props.saveUserTickets(freeTickets.user_tickets);
    }
    props.handleBackEventDetail();
  }

  const handleSaveDraft = () => {
    buttonDraftRef.current.click();
  }

  const handleSaveDraftSuccess = async () => {
    if (saveDraft) {
      setSaveDraft(false);
      livestreamService.createDraftEvent(freeTickets)
        .then(
          res => {
            if (res.data.status === 400) {
              cogoToast.error(formatMessage(res.data.message), {
                heading: 'エラー',
                position: 'top-center',
                hideAfter: 3,
                bar: { size: '10px' }
              });
              setSaveDraft(true);
            } else if (res.data.status === 200) {
              buttonDraftSuccessRef.current.click()
            }
          },
          error => {
            cogoToast.error(formatMessage(error.toString()), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
            setSaveDraft(true);
          }
        );
    }
  }

  const handleSubmitFreeTickets = async () => {
    if (btnClick === 1) {
      freeTickets.user_tickets = [];
      await dispatch(livestreamActions.createLivestream(freeTickets));
      const createScheduleSusscess = localStorage.getItem("CreateScheduleSusscess");
      if (createScheduleSusscess === 'true') {
        buttonRef.current.click();
      } else {
        buttonPublicContinueRef.current.click();
      }
      localStorage.setItem("CreateScheduleSusscess", 'false');
    } else {
      await dispatch(livestreamActions.createLivestream(freeTickets));
      const createScheduleSusscess = localStorage.getItem("CreateScheduleSusscess");
      if (createScheduleSusscess === 'true') {
        buttonRef.current.click();
      } else {
        buttonPublicContinueRef.current.click();
      }
      localStorage.setItem("CreateScheduleSusscess", 'false');
    }
  }

  const handleSubmitFreeTicketsContinue = async () => {
    const afterCreateScheduleCotinue = {...freeTickets, is_still_create: true}
    await dispatch(livestreamActions.createLivestream(afterCreateScheduleCotinue));
    const createScheduleSusscess = localStorage.getItem("CreateScheduleSusscess");
    if (createScheduleSusscess === 'true') {
      buttonRef.current.click();
    }
    localStorage.setItem("CreateScheduleSusscess", 'false');
  }

  const handleChangeFreeTickets = tickets => {
    if (tickets.selected) {
      setFreeTickets({ ...freeTickets, "user_tickets": tickets.userTickets });
    }
  }

  const handleSubmitEditEvent = async () => {
    const data = {
      'user_tickets': freeTickets.user_tickets
    }
    await eventService.editEventDetail(eventId, data)
      .then(
        res => {
          if (res.data.status === 400) {
            cogoToast.error(formatMessage(res.data.message), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
          } else if (res.data.status === 200) {
            dispatch({ type: eventConstants.EDIT_EVENT_DETAIL_SUCCESS, payload: res.data.data });
            buttonEditRef.current.click();
          }
        },
        error => {
          cogoToast.error(formatMessage(error.toString()), {
            heading: 'エラー',
            position: 'top-center',
            hideAfter: 3,
            bar: { size: '10px' }
          });
        }
      );
  }

  const handleClickBackModal = (e) => {
    history.push(`/events/${eventId}`);
  }

  const handleClose = () => {
    history.push(`/events/${eventId}`);
  }

  return (
    <>
      <div className="container-fluid">
        <div className='title__create_schedule'>無料チケットを配る</div>
      </div>

      <Banner src={props.imagePreviewUrlBanner} text="" />

      <div className="container-fluid">
        <section className="event-content">
          <div className="row">
            <div className="col-lg-7 px-3 mb-4">
              <EventLeft createSchedule={props.createSchedule} />
            </div>
            <div className="col-lg-5 px-3 mb-4"></div>
          </div>

          {/* Ticket Distribute */}
          <TicketDistribute
            tickets={props.createSchedule.tickets}
            user={selectUserProfile}
            handleChangeFreeTickets={handleChangeFreeTickets}
            community={props.createSchedule.href && props.createSchedule.href.length}
            editEvenst={props.editEvenst}
            eventId={props.eventId}
          />

          <div className="event__btn">
            <button className="btn event__but_return" onClick={handleClickBack}>戻る</button>
            {!props.editEvenst && <button className="btn event__but_next"
              onClick={handleSaveDraft}
              className="btn event__btn_free_ticket"
            >保存する</button>}
            <button
              ref={buttonDraftRef}
              data-toggle="modal"
              data-target="#modalDraftGetAway"
              hidden="hidden"
            >保存する</button>
            <button
              ref={buttonDraftSuccessRef}
              data-toggle="modal"
              data-target="#modalDraftGetAwaySuccess"
              hidden="hidden"
            >保存する</button>

            {!props.editEvenst && <button
              onClick={() => setBtnClick(2)}
              className="btn event__btn_free_ticket"
              data-toggle="modal"
              data-target="#modalGiveAwayTicket"
            >公開</button>}

            <button
              ref={buttonPublicContinueRef}
              data-toggle="modal"
              data-target="#modalGiveAwayTicketContinue"
              data-dismiss="modal"
              hidden="hidden"
            >公開
            </button>

            <button
              ref={buttonPublicContinueRef}
              data-toggle="modal"
              data-target="#modalGiveAwayTicketContinue"
              data-dismiss="modal"
              hidden="hidden"
            >公開
            </button>
            <button
              ref={buttonRef}
              data-toggle="modal"
              data-target="#modalCreateScheduleSuccsess"
              data-dismiss="modal"
              hidden="hidden"
            >公開</button>

            {props.editEvenst && <button
              className="btn event__btn_free_ticket"
              data-toggle="modal"
              data-target="#modalEditEvent"
            >変更する</button>}
            <button
              ref={buttonEditRef}
              data-toggle="modal"
              data-target="#modalEditEventSuccess"
              hidden="hidden"
            >変更する</button>
          </div>
        </section>
        <ModalGiveAwayTicket handleSubmitFreeTickets={handleSubmitFreeTickets} btnClick={btnClick} />
        <ModalGiveAwayTicketContinue handleSubmitFreeTicketsContinue={handleSubmitFreeTicketsContinue} />
        <ModalDraftGetAway handleSaveDraftSuccess={handleSaveDraftSuccess} />
        <ModalDraftGetAwaySuccess />
        <ModalEditEvent handleSubmitEditEvent={handleSubmitEditEvent} handleClickBackModal={handleClickBackModal} />
        <ModalEditEventSuccess handleClose={handleClose} />
      </div>
    </>
  );
}
