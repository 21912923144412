import React, { useEffect } from 'react';
import { Footer } from '../../_components/Footer/Footer';
import { CreateLivestreamSchedule } from '../../_components/CreateLivestreamSchedule/CreateLivestreamSchedule';
import { Header } from '../../_components/Header';
import { useHistory } from 'react-router';

const token = localStorage.getItem('token');

function CreateLivestreamSchedulePage() {
  const history = useHistory();

  useEffect(() => {
    const checkLogin = () => {
      if (!token) {
        return history.push("/before-login");
      }
    }
    checkLogin();
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
  }, [history])

  return (
    <>
      <Header />
      <div className="home__page_live">
        {token && <CreateLivestreamSchedule />}

        <Footer />
      </div>
    </>
  );
};

export { CreateLivestreamSchedulePage };
