import React from "react";
import moment from "moment";
import Linkify from 'react-linkify';
import "./event_right.css"

export function EventRight(props) {
  const eventsDetail = props.createSchedule;

  return (
    <>
      <div className="buy__ticket">
        <div
          className="apply_purchase">{eventsDetail && eventsDetail.tickets && eventsDetail.tickets.length ? props.textHeader : '無料配信'}</div>
        {eventsDetail && eventsDetail.tickets && eventsDetail.tickets.length ? eventsDetail.tickets.map((ticket, i) => {
          return <div
            className={`apply_purchase__ticket ${i === eventsDetail.tickets.length - 1 ? "bd_line_none" : ""}`} key={i}>
            <span className="apply_purchase_content" style={{ width: "100%" }}>
              <span className="content_1 content_1__cus">
                <img src={'/assets/icons/title.png'} alt="" />{ticket.name}
              </span>
              <span className="content_2 added-ticket-description">
                <img src={'/assets/icons/information.png'} alt="" />
                <span
                  className={`${ticket.price === 0 && eventsDetail.href.length > 0 ? "ticket_text_span" : ""}`}>
                  {ticket.price === 0 && eventsDetail.href.length > 0 &&
                    <span className="ticket_text_community">コミュニティ限定</span> || ''}
                  <span className="ticket__description"><Linkify
                    properties={{ target: '_blank' }}>{ticket.description}</Linkify></span>
                </span>
              </span>
              <span className="ticket__content content_3">
                <span className="content_3__dp content_3_price">
                  <img src={'/assets/icons/money.png'} alt="" />￥{ticket.price}
                </span>
                <span className="content_3__dp content_3_calendar">
                  <img src={'/assets/icons/calendar.png'}
                    alt="" />販売終了: {moment.unix(ticket.expired_date / 1000).format('YYYY/MM/DD HH:mm')}
                </span>
                <span className="content_3__dp content_3_ticket">
                  <img src={'/assets/icons/ticket.png'}
                    alt="" />残り{ticket.quantity && ticket.quantity.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}枚
                </span>
              </span>
            </span>
          </div>
        }) :
          <span style={{ textAlign: 'center', margin: '10px auto;', display: 'inline-block', width: '100%' }}>視聴ページは<span
            style={{ color: '#0030FF' }}>こちら</span></span>}
      </div>
    </>
  );
}
