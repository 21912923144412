import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../_helpers';
import { livestreamActions } from '../../_actions';
import {
  ListLiveStreamInDetail,
  LiveSteamDetail,
  LivestreamInfo
} from '../../_components/LiveStream';
import { ModalShare } from '../../_components/LiveStream/LiveStreamDetail/ModalShare';
import { ModalReport } from '../../_components/LiveStream/LiveStreamDetail/ModalReport';
import { useWindowResize } from '../../_components/LiveStream/LiveStreamDetail/useWindowResize';
import { Header } from '../../_components/Header';
import { Footer } from '../../_components/Footer/Footer';

import './liveStreamdetailpage.css'

const token = localStorage.getItem('token');

function LiveStreamDetailPage() {
  const { livestreamId } = useParams();
  const history = useHistory();
  const liveStreamDetail = useSelector(state => state.liveStreamDetail.livestream_detail);
  const userLogin = useSelector(state => state.login.user);
  const limit = 10;
  const dispatch = useDispatch();
  const { width } = useWindowResize();
  const el = useRef();
  const [resJoinRoom, setResJoinRoom] = useState('');

  useEffect(() => {
    checkLogin();
    const backLivestream = localStorage.getItem("backLivestream");
    if (backLivestream === "true") {
      localStorage.setItem("backLivestream", "");
      return window.location.reload();
    }

    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }

    localStorage.setItem('displayImage6', 0);
    localStorage.setItem('displayImage7', 0);
    localStorage.setItem('displayImage8', 0);
  }, [])

  useEffect(() => {
    if (token) {
      const getLive = async () => {
        await dispatch(livestreamActions.getLivestreamDetail(livestreamId))
        await dispatch(livestreamActions.getListLivestream(1, 10))
      }
      getLive();
    }
  }, [dispatch, livestreamId]);

  useEffect(() => {
    if (userLogin && liveStreamDetail) {
      const objData = {
        streamName: liveStreamDetail ? liveStreamDetail.livestream.stream_name : '',
        user: {
          id: userLogin.id,
          name: userLogin.name,
          avatar: userLogin.avatar
        },
        type: 1
      }
      socket.emit('joinRoom', objData, (data) => {
        if (data && parseInt(data.error) === 99 && data.message === 'user_is_denied_join_livestream') {
          setResJoinRoom(data);
        }
      });
    }
  }, [userLogin, liveStreamDetail]);

  const checkLogin = () => {
    if (!token) {
      history.push("/before-login");
    }
  }

  return (
    <>
      <Header />
      <div className="home__page_live">
        <section className="section section__live_stream">
          <div className="container">
            {token && <LiveSteamDetail liveStreamDetail={liveStreamDetail} resJoinRoom={resJoinRoom} />}
            {width > 768 && <section className="section__my_livestream div_desktop">
              <LivestreamInfo elRef={el} liveStreamDetail={liveStreamDetail} />
            </section>}
            <div className="video__related">
              <ListLiveStreamInDetail limit={limit} />
            </div>
          </div>
        </section>
        <ModalShare />
        <ModalReport />
      </div>
      <Footer />
    </>
  );
};

export { LiveStreamDetailPage };
