export const groupSelectCodeNation = [
  {id: 1, name: 'Afghanistan', code: '+93'},
  {id: 2, name: 'Albania', code: '+355'},
  {id: 3, name: 'Algeria', code: '+213'},
  {id: 4, name: 'Andorra', code: '+376'},
  {id: 5, name: 'Angola', code: '+244'},
  {id: 6, name: 'Anguilla', code: '+1264'},
  {id: 7, name: 'Antarctica', code: '+1264'},
  {id: 8, name: 'Antigua and Barbuda', code: '+1268'},
  {id: 9, name: 'Argentina', code: '+54'},
  {id: 10, name: 'Armenia', code: '+374'},
  {id: 11, name: 'Aruba', code: '+297'},
  {id: 12, name: 'Australia', code: '+61'},
  {id: 13, name: 'Austria', code: '+43'},
  {id: 14, name: 'Azerbaijan', code: '+994'},
  {id: 15, name: 'Bahamas', code: '+1242'},
  {id: 16, name: 'Bahrain', code: '+973'},
  {id: 17, name: 'Bangladesh', code: '+880'},
  {id: 18, name: 'Barbados', code: '+1246'},
  {id: 19, name: 'Belarus', code: '+375'},
  {id: 20, name: 'Belgium', code: '+32'},
  {id: 21, name: 'Belize', code: '+501'},
  {id: 22, name: 'Benin', code: '+229'},
  {id: 23, name: 'Bermuda', code: '+1441'},
  {id: 24, name: 'Bhutan', code: '+975'},
  {id: 25, name: 'Bolivia', code: '+591'},
  {id: 26, name: 'Bosnia and Herzegovina', code: '+387'},
  {id: 27, name: 'Botswana', code: '+267'},
  {id: 28, name: 'Brazil', code: '+55'},
  {id: 29, name: 'British Indian Ocean Territory', code: '+246'},
  {id: 30, name: 'British Virgin Islands', code: '+1284'},
  {id: 31, name: 'Brunei Darussalam', code: '+673'},
  {id: 32, name: 'Bulgaria', code: '+359'},
  {id: 33, name: 'Burkina Faso', code: '+226'},
  {id: 34, name: 'Burundi', code: '+257'},
  {id: 35, name: 'Cambodia', code: '+855'},
  {id: 36, name: 'Cameroon', code: '+237'},
  {id: 37, name: 'Canada', code: '+1'},
  {id: 38, name: 'Cape Verde', code: '+238'},
  {id: 39, name: 'Cayman Islands', code: '+1345'},
  {id: 40, name: 'Central African Republic', code: '+236'},
  {id: 41, name: 'Chad', code: '+235'},
  {id: 42, name: 'Chile', code: '+56'},
  {id: 43, name: 'China', code: '+86'},
  {id: 44, name: 'Christmas Island', code: '+61'},
  {id: 45, name: 'Cocos Islands', code: '+61'},
  {id: 46, name: 'Colombia', code: '+57'},
  {id: 47, name: 'Comoros', code: '+269'},
  {id: 48, name: 'Republic of the Congo', code: '+242'},
  {id: 49, name: 'Democratic Republic of the Congo', code: '+243'},
  {id: 50, name: 'Cook Islands', code: '+682'},
  {id: 51, name: 'Costa Rica', code: '+506'},
  {id: 52, name: "Cote d'Ivoire", code: '+225'},
  {id: 53, name: 'Croatia', code: '+385'},
  {id: 54, name: 'Cuba', code: '+53'},
  {id: 55, name: 'Cyprus', code: '+357'},
  {id: 56, name: 'Czech Republic', code: '+420'},
  {id: 57, name: 'Denmark', code: '+45'},
  {id: 58, name: 'Djibouti', code: '+253'},
  {id: 59, name: 'Dominica', code: '+1767'},
  {id: 60, name: 'Dominican Republic', code: '+1849'},
  {id: 61, name: 'East Timor', code: '+670'},
  {id: 62, name: 'Ecuador', code: '+593'},
  {id: 63, name: 'Egypt', code: '+20'},
  {id: 64, name: 'El Salvador', code: '+503'},
  {id: 65, name: 'Equatorial Guinea', code: '+240'},
  {id: 66, name: 'Estonia', code: '+372'},
  {id: 67, name: 'Ethiopia', code: '+251'},
  {id: 68, name: 'Falkland Islands', code: '+500'},
  {id: 69, name: 'Faroe Islands', code: '+298'},
  {id: 70, name: 'Fiji', code: '+679'},
  {id: 71, name: 'Finland', code: '+358'},
  {id: 72, name: 'France', code: '+33'},
  {id: 73, name: 'French Guiana', code: '+594'},
  {id: 74, name: 'French Polynesia', code: '+689'},
  {id: 75, name: 'Gabon', code: '+241'},
  {id: 76, name: 'Gambia', code: '+220'},
  {id: 77, name: 'Georgia', code: '+995'},
  {id: 78, name: 'Germany', code: '+49'},
  {id: 79, name: 'Ghana', code: '+233'},
  {id: 80, name: 'Gibraltar', code: '+350'},
  {id: 81, name: 'Greece', code: '+30'},
  {id: 82, name: 'Greenland', code: '+299'},
  {id: 83, name: 'Grenada', code: '+1473'},
  {id: 84, name: 'Guadeloupe', code: '+590'},
  {id: 85, name: 'Guam', code: '+1671'},
  {id: 86, name: 'Guatemala', code: '+502'},
  {id: 87, name: 'Guinea', code: '+224'},
  {id: 88, name: 'Guinea-Bissau', code: '+245'},
  {id: 89, name: 'Guyana', code: '+592'},
  {id: 90, name: 'Haiti', code: '+509'},
  {id: 91, name: 'Honduras', code: '+504'},
  {id: 92, name: 'Hong Kong', code: '+852'},
  {id: 93, name: 'Hungary', code: '+36'},
  {id: 94, name: 'Iceland', code: '+354'},
  {id: 95, name: 'India', code: '+91'},
  {id: 96, name: 'Indonesia', code: '+62'},
  {id: 97, name: 'Iran', code: '+98'},
  {id: 98, name: 'Iraq', code: '+964'},
  {id: 99, name: 'Ireland', code: '+353'},
  {id: 100, name: 'Isle of Man', code: '+44'},
  {id: 101, name: 'Israel', code: '+972'},
  {id: 102, name: 'Italy', code: '+39'},
  {id: 103, name: 'Ivory Coast', code: '+225'},
  {id: 104, name: 'Jamaica', code: '+1876'},
  {id: 105, name: 'Japan', code: '+81'},
  {id: 106, name: 'Jordan', code: '+962'},
  {id: 107, name: 'Kazakhstan', code: '+7'},
  {id: 108, name: 'Kenya', code: '+254'},
  {id: 109, name: 'Kiribati', code: '+686'},
  {id: 110, name: "North Korea", code: '+850'},
  {id: 111, name: "South Korea", code: '+82'},
  {id: 112, name: 'Kuwait', code: '+965'},
  {id: 113, name: 'Kyrgyzstan', code: '+996'},
  {id: 114, name: "Lao People's Democratic Republic", code: '+856'},
  {id: 115, name: 'Latvia', code: '+371'},
  {id: 116, name: 'Lebanon', code: '+961'},
  {id: 117, name: 'Lesotho', code: '+266'},
  {id: 118, name: 'Liberia', code: '+231'},
  {id: 119, name: 'Libyan Arab Jamahiriya', code: '+218'},
  {id: 120, name: 'Liechtenstein', code: '+423'},
  {id: 121, name: 'Lithuania', code: '+370'},
  {id: 122, name: 'Luxembourg', code: '+352'},
  {id: 123, name: 'Macao', code: '+853'},
  {id: 124, name: 'Macedonia', code: '+389'},
  {id: 125, name: 'Madagascar', code: '+261'},
  {id: 126, name: 'Malawi', code: '+265'},
  {id: 127, name: 'Malaysia', code: '+60'},
  {id: 128, name: 'Maldives', code: '+960'},
  {id: 129, name: 'Mali', code: '+223'},
  {id: 130, name: 'Malta', code: '+356'},
  {id: 131, name: 'Marshall Islands', code: '+692'},
  {id: 132, name: 'Martinique', code: '+596'},
  {id: 133, name: 'Mauritania', code: '+222'},
  {id: 134, name: 'Mauritius', code: '+230'},
  {id: 135, name: 'Mayolte', code: '+262'},
  {id: 136, name: 'Mexico', code: '+52'},
  {id: 137, name: 'Micronesia', code: '+691'},
  {id: 138, name: 'Moldova', code: '+373'},
  {id: 139, name: 'Monaco', code: '+377'},
  {id: 140, name: 'Mongolia', code: '+976'},
  {id: 141, name: 'Montenegro', code: '+382'},
  {id: 142, name: 'Montserrat', code: '+1664'},
  {id: 143, name: 'Morocco', code: '+212'},
  {id: 144, name: 'Mozambique', code: '+258'},
  {id: 145, name: 'Myanmar', code: '+95'},
  {id: 146, name: 'Namibia', code: '+264'},
  {id: 147, name: 'Nauru', code: '+674'},
  {id: 148, name: 'Nepal', code: '+977'},
  {id: 149, name: 'Netherlands', code: '+31'},
  {id: 150, name: 'New Caledonia', code: '+687'},
  {id: 151, name: 'New Zealand', code: '+64'},
  {id: 152, name: 'Nicaragua', code: '+505'},
  {id: 153, name: 'Niger', code: '+227'},
  {id: 154, name: 'Nigeria', code: '+234'},
  {id: 155, name: 'Niue', code: '+683'},
  {id: 156, name: 'Norfolk Island', code: '+6723'},
  {id: 157, name: 'Northern Mariana Islands', code: '+1670'},
  {id: 158, name: 'Norway', code: '+47'},
  {id: 159, name: 'Oman', code: '+968'},
  {id: 160, name: 'Pakistan', code: '+92'},
  {id: 161, name: 'Palau', code: '+680'},
  {id: 162, name: 'Palestine', code: '+970'},
  {id: 163, name: 'Panama', code: '+507'},
  {id: 164, name: 'Papua New Guinea', code: '+675'},
  {id: 165, name: 'Paraguay', code: '+595'},
  {id: 166, name: 'Peru', code: '+51'},
  {id: 167, name: 'Philippines', code: '+63'},
  {id: 168, name: 'Pitcairn', code: '+872'},
  {id: 169, name: 'Poland', code: '+48'},
  {id: 170, name: 'Portugal', code: '+351'},
  {id: 171, name: 'Puerto Rico', code: '+1787'},
  {id: 172, name: 'Qatar', code: '+974'},
  {id: 173, name: 'Reunion', code: '+262'},
  {id: 174, name: 'Romania', code: '+40'},
  {id: 175, name: 'Rwanda', code: '+250'},
  {id: 176, name: 'Samoa', code: '+685'},
  {id: 177, name: 'San Marino', code: '+378'},
  {id: 178, name: 'Sao Tome and Principe', code: '+239'},
  {id: 179, name: 'Saudi Arabia', code: '+966'},
  {id: 180, name: 'Senegal', code: '+221'},
  {id: 181, name: 'Serbia', code: '+381'},
  {id: 182, name: 'Seychelles', code: '+248'},
  {id: 183, name: 'Sierra Leone', code: '+232'},
  {id: 184, name: 'Singapore', code: '+65'},
  {id: 185, name: 'Slovakia', code: '+421'},
  {id: 186, name: 'Slovenia', code: '+386'},
  {id: 187, name: 'Solomon Islands', code: '+677'},
  {id: 188, name: 'Somalia', code: '+252'},
  {id: 189, name: 'South Africa', code: '+27'},
  {id: 190, name: 'South Georgia and the South Sandwich Islands', code: '+500'},
  {id: 191, name: 'Spain', code: '+34'},
  {id: 192, name: 'Sri Lanka', code: '+94'},
  {id: 193, name: 'Saint Helena', code: '+290'},
  {id: 194, name: 'Saint Kitts and Nevis', code: '+1869'},
  {id: 195, name: 'Saint Lucia', code: '+1758'},
  {id: 196, name: 'Saint Pierre and Miquelon', code: '+508'},
  {id: 197, name: 'Saint Vincent and the Grenadines', code: '+1784'},
  {id: 198, name: 'Sudan', code: '+249'},
  {id: 199, name: 'Suriname', code: '+597'},
  {id: 200, name: 'Svalbard and Jan Mayen', code: '+47'},
  {id: 201, name: 'Swaziland', code: '+268'},
  {id: 202, name: 'Sweden', code: '+46'},
  {id: 203, name: 'Switzerland', code: '+41'},
  {id: 204, name: 'Syrian Arab Republic', code: '+963'},
  {id: 205, name: 'Taiwan', code: '+886'},
  {id: 206, name: 'Tajikistan', code: '+992'},
  {id: 207, name: 'Tanzania', code: '+255'},
  {id: 208, name: 'Thailand', code: '+66'},
  {id: 209, name: 'Togo', code: '+228'},
  {id: 210, name: 'Tokelau', code: '+690'},
  {id: 211, name: 'Tonga', code: '+676'},
  {id: 212, name: 'Trinidad and Tobago', code: '+1868'},
  {id: 213, name: 'Tunisia', code: '+216'},
  {id: 214, name: 'Turkey', code: '+90'},
  {id: 215, name: 'Turkmenistan', code: '+993'},
  {id: 216, name: 'Turks and Caicos Islands', code: '+1649'},
  {id: 217, name: 'Tuvalu', code: '+688'},
  {id: 218, name: 'Uganda', code: '+256'},
  {id: 219, name: 'Ukraine', code: '+380'},
  {id: 220, name: 'United Arab Emirates', code: '+971'},
  {id: 221, name: 'United Kingdom', code: '+44'},
  {id: 222, name: 'United States', code: '+1'},
  {id: 223, name: 'Uruguay', code: '+598'},
  {id: 224, name: 'Uzbekistan', code: '+7'},
  {id: 225, name: 'Vanuatu', code: '+678'},
  {id: 226, name: 'Vatican City State', code: '+379'},
  {id: 227, name: 'Venezuela', code: '+58'},
  {id: 228, name: 'Viet Nam', code: '+84'},
  {id: 229, name: 'Virgin Islands', code: '+1340'},
  {id: 230, name: 'Wallis and Futuna Islands', code: '+681'},
  {id: 231, name: 'Western Sahara', code: '+212'},
  {id: 232, name: 'Yemen', code: '+967'},
  {id: 233, name: 'Zambia', code: '+260'},
  {id: 234, name: 'Zimbabwe', code: '+263'},
];
