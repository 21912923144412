import { userConstants } from "../_constants";

const initialState = { followUsers: '', followerOfUsers: '', }

export function followerUsers(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_FOLLOWS_USER_REQUEST:
      return {
        ...state,
      };
    case userConstants.GET_FOLLOWS_USER_SUCCESS:
      return {
        ...state,
        followUsers: action.payload,
      };
    case userConstants.GET_FOLLOWS_USER_FAILURE:
      return {
        ...state,
        followUsers: action.payload,
      };
    case userConstants.GET_FOLLOWER_USER_REQUEST:
      return {
        ...state,
      };
    case userConstants.GET_FOLLOWER_USER_SUCCESS:
      return {
        ...state,
        followerOfUsers: action.payload,
      };
    case userConstants.GET_FOLLOWER_USER_FAILURE:
      return {
        ...state,
        followerOfUsers: action.payload,
      };
    default:
      return state
  }
}
