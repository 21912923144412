import { authenticationConstants, livestreamConstants, userConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';
import cogoToast from 'cogo-toast';
import { formatMessage } from '../_utils/formatMessage';
import { checkAuthentication, handleErrorMessage, handleReponse } from '../_helpers/api';

export const userActions = {
  getUserProfile,
  getCoins,
  getSendCoins,
  getBuyTicketLivestream,
  chargeCoin,
  chargeCoinByCredit,
  getFollowerOfUser,
  getFollowsOfUser,
  getListUserWatched
}

function getListUserWatched(page, limit, keyword) {
  return dispatch => {
    dispatch(request(''));
    userService.searchListUsers(page, limit, keyword)
      .then(
        res => {

          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: userConstants.LIST_USER_REQUEST, payload: data } }
  function success(data) { return { type: userConstants.LIST_USER_SUCCESS, payload: data } }
  function failure(error) { return { type: userConstants.LIST_USER_FAILURE, error } }
}

function getBuyTicketLivestream(type, page, limit) {
  const data = {
    type,
    page,
    limit
  }

  return dispatch => {
    dispatch(request());

    userService.getBuyTicketLivestream(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, '再度ログインしてください');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data, type));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: userConstants.GET_BUY_TICKET_LIVESTREAM_REQUEST } }
  function success(data, type) { return { type: userConstants.GET_BUY_TICKET_LIVESTREAM_SUCCESS, payload: { data, type } } }
  function failure(error) { return { type: userConstants.GET_BUY_TICKET_LIVESTREAM_FAILURE, error } }
}

function getCoins() {
  return async (dispatch) => {
    await userService.getCoins()
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            localStorage.setItem('coinCurrent', res.data.data.coin);
            dispatch(success(res.data.data.coin));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(user) { return { type: userConstants.COINS_SUCCESS, payload: user } }
  function failure(error) { return { type: userConstants.COINS_FAILURE, error } }
}

function getSendCoins(coin) {
  const userSendCoins = localStorage.getItem('user-send-coin');
  const coins = parseInt(userSendCoins) - parseInt(coin);

  return dispatch => {
    localStorage.setItem('coinCurrent', coins);
    dispatch(success(coins));
  };

  function success(coins) { return { type: userConstants.COINS_SUCCESS, payload: coins } }
}

function getUserProfile(id) {
  return async (dispatch) => {
    dispatch(request(''));

    await userService.getUserProfile(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, '再度ログインしてください');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(user) { return { type: userConstants.USER_PROFILE_REQUEST, payload: user } }
  function success(user) { return { type: userConstants.USER_PROFILE_SUCCESS, payload: user } }
  function failure(error) { return { type: userConstants.USER_PROFILE_FAILURE, error } }
}

function chargeCoin() {
  return dispatch => {
    dispatch(request(''));
    userService.chargeCoin()
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: userConstants.CHARGE_COIN_REQUEST, payload: data } }
  function success(data) { return { type: userConstants.CHARGE_COIN_SUCCESS, payload: data } }
  function failure(error) { return { type: userConstants.CHARGE_COIN_FAILURE, error } }
}

function chargeCoinByCredit(packageName, callback) {
  let data = {
    package_name: packageName,
    callback
  }

  return async (dispatch) => {
    await userService.chargeCoinByCredit(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            localStorage.setItem('url-chage-coin', res.data.data.url);
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: userConstants.CHARGE_COIN_BY_CREDIT_SUCCESS, payload: data } }
}

function getFollowsOfUser(id) {

  return dispatch => {
    dispatch(request());

    userService.getFollowsOfUser(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: userConstants.GET_FOLLOWS_USER_REQUEST } }
  function success(data) { return { type: userConstants.GET_FOLLOWS_USER_SUCCESS, payload: data } }
  function failure(error) { return { type: userConstants.GET_FOLLOWS_USER_FAILURE, error } }
}

function getFollowerOfUser(id) {

  return dispatch => {
    dispatch(request());

    userService.getFollowerOfUser(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: userConstants.GET_FOLLOWER_USER_REQUEST } }
  function success(data) { return { type: userConstants.GET_FOLLOWER_USER_SUCCESS, payload: data } }
  function failure(error) { return { type: userConstants.GET_FOLLOWER_USER_FAILURE, error } }
}
