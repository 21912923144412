import { authenticationConstants, userConstants } from "../../_constants";

let token = localStorage.getItem('register-token');
const initialState = token ? { token } : {};

export function updatePassword(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.UPDATE_PASSWORD_REQUEST:
      return { updating: true }
    case authenticationConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case authenticationConstants.UPDATE_PASSWORD_FAILURE:
      return {};
    default:
      return state;
  }
}