import React, { useEffect, useRef } from 'react';
import videojs from "video.js";
import './video_detail.css'

const videoOptions = {
  preload: 'auto',
  autoplay: true,
  controls: true,
  muted: true,
  playsinline: true,
  height: 641,
  techOrder: ["html5"],
  controlBar: {
    pictureInPictureToggle: false,
  },
}

export function VideoDetail(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const playerOptions = {
      sources: [
        {
          src: props.videoDetail.video && props.videoDetail.video.url_endpoint
          // src: "http://dotfrdnu22x6a.cloudfront.net/f2279d23-c900-4337-8c50-1bcc263d274d-1650705506503.mp4"
        }
      ]
    };

    if (containerRef.current) {
      const videoEl = containerRef.current;
      const player = videojs(videoEl, {
        ...videoOptions,
        ...playerOptions
      });

      return () => {
        player.dispose();
      };
    }

  }, [props.videoDetail]);

  return (
    <>
      <div className="box__live_streams d-flex justify-content-between">
        <div className={`box__video box__video_detail box__livestram_pad`}>
          <div className="embed__source" data-vjs-player>
            <video ref={containerRef} className="video-js vjs-default-skin" />
          </div>
        </div>
      </div>
    </>
  );
}
