export const formatMessage = (key) => {
  switch (key) {
    case 'bad_request': {
      return '無効なリクエスト';
    }
    case 'email_or_phone_is_existed': {
      return 'メールアドレスまたは電話番号が存在しません';
    }
    case 'user_not_found': {
      return 'ユーザーが見つかりません';
    }
    case 'password_incorrect': {
      return 'パスワードが正しくありません。';
    }
    case 'not_authentication': {
      return 'ユーザーが認証されていません';
    }
    case 'livestream_not_exist': {
      return 'このライブ配信は存在しません';
    }
    case 'join_room_not_success': {
      return 'このライブ配信ルームに参加できません';
    }
    case 'leave_room_not_success': {
      return 'ライブ配信ルームからの退出に失敗しました';
    }
    case 'user_not_permission': {
      return 'このアクションは許可されていません';
    }
    case 'livestream_not_start': {
      return 'このライブ配信は開始されません';
    }
    case 'not_follow_yourself': {
      return '自分自身はフォローできません';
    }
    case 'not_unfollow_yourself': {
      return '自分自身のフォローを解除できません';
    }
    case 'user_is_followed': {
      return 'フォローしました';
    }
    case 'user_is_unfollowed': {
      return 'フォローを解除しました';
    }
    case 'invalid_token': {
      return 'トークンが無効です';
    }
    case 'code_or_phone_number_invalid': {
      return 'コードまたは電話番号が無効です';
    }
    case 'phone_is_registered': {
      return 'この電話番号は登録済みです';
    }
    case 'coin_package_not_exist': {
      return 'コインパッケージが存在しません';
    }
    case 'charge_coin_not_success': {
      return 'コインのチャージに失敗しました';
    }
    case 'coin_not_enough': {
      return 'コインが不足しています';
    }
    case 'user_is_blocked': {
      return 'ユーザーがブロックされています';
    }
    case 'not_send_coin_yourself': {
      return 'コインを自分自身に送ることはできません';
    }
    case 'category_not_exist': {
      return 'ライブ配信のカテゴリが存在しません';
    }
    case 'login_not_success': {
      return 'ログインに失敗しました';
    }
    case 'verify_code_invalid': {
      return '認証コードが無効です';
    }
    case 'code_has_expired': {
      return 'コードの有効期限が切れています';
    }
    case 'email_or_phone_number_is_required': {
      return '電子メールまたは電話番号が必要です';
    }
    case 'system_is_maintained': {
      return 'システムメンテナンス中です';
    }
    case 'phone_number_not_exist': {
      return 'この電話番号は存在しません';
    }
    case 'user_not_in_community': {
      return 'コミュニティに参加していません';
    }
    case 'cannot_edit_stream': {
      return 'このイベントは編集できません';
    }
    case 'ticket_not_exist': {
      return 'このチケットは存在しません';
    }
    case 'user_not_buy_ticket': {
      return 'このライブ配信のチケットを購入していません';
    }
    case 'user_buyed_this_ticket': {
      return 'このチケットは購入済みです';
    }
    case 'cant_create_schedule_livestream_in_this_time': {
      return '配信スケジュールの開始時間は別の配信スケジュールの開始時間と重複されています。';
      // return '配信スケジュールの開始時間は別の配信スケジュールの開始時間と重複されているので、すでに設定された配信スケジュールの開始時間を45分後に設定する必要です。';
    }
    case 'ticket_is_expired': {
      return 'チケットが販売終了しましたので、購入ができません。'
    }
    case 'only_create_10_events': {
      return '配信スケジュールは10件しか作成できません。'
    }
    case 'only_create_10_draft_events': {
      return '未公開の配信スケジュールは10件しか保存できません。'
    }
    case 'reach_ticket_quantity': {
      return 'この無料チケットの全ては配られました。'
    }
    case 'event_not_exist': {
      return 'イベントが存在しません'
    }
    case 'already_got_this_free_ticket': {
      return 'あなたはすでにこの無料チケットを手に入れました。'
    }
    case 'this_ticket_not_free': {
      return 'このチケットは無料チケットではありません。'
    }
    case 'ticket_not_enough': {
      return 'チケットの枚数が足りません。'
    }
    default: {
      return '無効なリクエスト'
    }
  }
}