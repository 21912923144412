import React, { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { useHistory } from 'react-router';
import $ from 'jquery';

export function ModalAfter15Minus(props) {
  const history = useHistory();
  const buttonToLivestream = useRef();
  const [countdownTime, setCountdownTime] = useState('');

  useEffect(() => {
    $(document.body).removeClass("modal-open");
  }, [])

  useEffect(() => {
    if (props.openPopupAfter15Minus) {
      const start = moment(new Date(props.eventsDetail.start_time));
      const end = moment(new Date());
      setCountdownTime(start.diff(end, 'minutes'));
    }

  }, [props.openPopupAfter15Minus])

  useEffect(() => {
    let time_out;
    if (props.openPopupAfter15Minus) {
      props.handleOpenModal(false);
      if (countdownTime > 0 && countdownTime <= 15) {
        time_out = setTimeout(() => {
          buttonToLivestream.current.click();
          history.push(`/livestreams/${props.eventsDetail.id}`);
        }, 2000);
      }
    }

    return () => clearTimeout(time_out);
  }, [countdownTime])

  return (
    <div className="modal fade" id="modalAfter15Minus" tabIndex={-1} role="dialog" aria-hidden="false"
      data-keyboard="false" data-backdrop="static">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content mt-2 view__room_f">
              視聴ルームを開いています。
            </div>
            <div className="community__btn mt-4 mb-2">
              <button className="btn view__room_btn"
                ref={buttonToLivestream}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="spinner-border text-muted"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
