import { eventConstants } from '../../_constants';

const initialState = { myEvents: [], total: 0, loadingMyEvents: false }

export function myEvents(state = initialState, action) {
  switch (action.type) {
    case eventConstants.LIST_EVENT_BY_USER_REQUEST:
      return {
        ...state,
        loadingMyEvents: false
      };
    case eventConstants.LIST_EVENT_BY_USER_SUCCESS:
    case eventConstants.LIST_EVENT_BY_USER_FAILURE:
      {
        return {
          ...state,
          myEvents: action.payload && action.payload.events,
          total: action.payload && action.payload.total || 0,
          loadingMyEvents: true
        };
      }

    default:
      return state
  }
}