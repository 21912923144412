import { userConstants } from '../../_constants';

const initialState = { coins: '' }

export function coins(state = initialState, action) {
  switch (action.type) {
    case userConstants.COINS_SUCCESS:
      if (action.payload === 0) {
        return {
          coins: 0
        };
      }
      return {
        coins: action.payload
      };
    case userConstants.COINS_FAILURE:
      return {};
    default:
      return state
  }
}
