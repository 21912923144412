import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { socket } from '../../../_helpers';
import { GroupCoins } from '../../../_utils/Group/GroupCoins';
import { userActions, livestreamActions } from '../../../_actions';
import 'react-toastify/dist/ReactToastify.css';
import './coins.css';
import { ModalChargeCoin } from './ModalChargeCoin';

const ContentToast = ({ avatar, name, img_coin, coin }) => {
  return (
    <div>
      <img className="avatar__coins_notifi" src={avatar} alt="" /> {name}
      <img className="img_coin__coins_notifi" src={img_coin} alt="" /> <span
        className="span__coins_notifi">+ {coin}</span>
    </div>
  );
};

export function Coins(props) {
  const dispatch = useDispatch();
  const [isExpire, setIsExpire] = useState(false);
  const [isExpire2, setIsExpire2] = useState(false);
  const [isExpire3, setIsExpire3] = useState(false);
  const liveStrDetail = props.liveStreamDetail;
  const userLogin = useSelector(state => state.login.user);
  const [displayImage6, setDisplayImage6] = useState(0);
  const [imageCoins6, setImageCoins6] = useState('');
  const [displayImage7, setDisplayImage7] = useState(0);
  const [imageCoins7, setImageCoins7] = useState('');
  const [displayImage8, setDisplayImage8] = useState(0);
  const [imageCoins8, setImageCoins8] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);

  const handleCoins = (coin, coinType) => {
    setDisplayToast(true);
    const id = userLogin.id;
    const name = userLogin.name;
    const avatar = userLogin.avatar;
    const streamName = liveStrDetail ? liveStrDetail.livestream_detail.livestream.stream_name : '';
    const to = liveStrDetail ? liveStrDetail.livestream_detail.livestream.user_id : '';

    if (parseInt(localStorage.getItem('coinCurrent')) < 0) {
      setOpenModal(true);
      return false;
    }
    if (parseInt(coin) > parseInt(localStorage.getItem('coinCurrent'))) {
      setOpenModal(true);
      return false;
    }

    dispatch(livestreamActions.loadSendCoins(1));

    const user = {
      id,
      name,
      avatar
    }
    const objData = {
      streamName,
      user,
      coin,
      to,
      coinType
    }
    socket.emit('sendCoin', objData, (data) => {

    });

    switch (coinType) {
      case 1:
        const img_coin1 = "/assets/images/10.png";
        return toastCoins(avatar, name, img_coin1, coin);
      case 2:
        const img_coin2 = "/assets/images/100.png";
        return toastCoins(avatar, name, img_coin2, coin);
      case 3:
        const img_coin3 = "/assets/images/1000.png";
        return toastCoins(avatar, name, img_coin3, coin);
      case 4:
        const img_coin4 = "/assets/images/10000.png";
        return toastCoins(avatar, name, img_coin4, coin);
      case 5:
        const img_coin5 = "/assets/images/100000.png";
        return toastCoins(avatar, name, img_coin5, coin);
      case 6:
        const img_coin6 = "/assets/images/gif_clap_live_stream.gif";
        setDisplayImage6(displayImage6 + 1);
        dispatch(userActions.getSendCoins(coin));
        return setImageCoins6(img_coin6);
      case 7:
        const img_coin7 = "/assets/images/gif_tequila.gif";
        setDisplayImage7(displayImage7 + 1);
        dispatch(userActions.getSendCoins(coin));
        return setImageCoins7(img_coin7);
      case 8:
        const img_coin8 = "/assets/images/gif_money_gun.gif";
        setDisplayImage8(displayImage8 + 1);
        dispatch(userActions.getSendCoins(coin));
        return setImageCoins8(img_coin8);
      default:
        return false;
    }
  }

  const toastCoins = async (avatar, name, img_coin, coin) => {
    toast(<ContentToast avatar={avatar} name={name} img_coin={img_coin} coin={coin} />);
    dispatch(userActions.getSendCoins(coin));
    setDisplayToast(false);
  }

  // const randomNumber = (max, min) => {
  //     return Math.floor(Math.random() * (max - min)) + min
  // }
  const ImageGif6 = (img_coin6) => {
    let timeout = 3700;
    let img6_time_out;
    // let left = randomNumber(400, 200);
    // let top = randomNumber(600, 750);

    if (displayImage6 === 0) {
      setDisplayImage6(0);
      clearTimeout(img6_time_out);
    }

    if (isExpire) {
      return null
    }

    img6_time_out = setTimeout(() => {
      setIsExpire(true);
      let timeoutExpire = setTimeout(() => {
        setIsExpire(false);
        clearTimeout(timeoutExpire);
      }, timeout + 1)
      setDisplayImage6(displayImage6 - 1);
      clearTimeout(img6_time_out);
    }, timeout);

    return <div className="animate__clap_live_stream">
      <img src={img_coin6} alt="" />
    </div>
  }

  const ImageGif7 = (img_coin7) => {
    let timeout = 3800;
    let img7_time_out;

    if (displayImage7 === 0) {
      setDisplayImage7(0);
      clearTimeout(img7_time_out);
    }

    if (isExpire2) {
      return null
    }

    img7_time_out = setTimeout(() => {
      setIsExpire2(true);
      let timeoutExpire2 = setTimeout(() => {
        setIsExpire2(false);
        clearTimeout(timeoutExpire2);
      }, timeout + 1);
      setDisplayImage7(displayImage7 - 1);
      clearTimeout(img7_time_out);
    }, timeout);

    return <div className="animate__tequila">
      <img src={img_coin7} alt="" />
    </div>
  }

  const ImageGif8 = (img_coin8) => {
    let timeout = 4500;
    let img8_time_out;

    if (displayImage8 === 0) {
      setDisplayImage8(0);
      clearTimeout(img8_time_out);
    }

    if (isExpire3) {
      return null
    }

    img8_time_out = setTimeout(() => {
      setIsExpire3(true);
      let timeoutExpire3 = setTimeout(() => {
        setIsExpire3(false);
        clearTimeout(timeoutExpire3);
      }, timeout + 1)
      setDisplayImage8(displayImage8 - 1);
      clearTimeout(img8_time_out);
    }, timeout);

    return <div className="animate__money_gun">
      <img src={img_coin8} alt="" />
    </div>
  }

  const handleOpenModal = (bool) => {
    setOpenModal(bool);
  }

  return (
    <>
      <div id="tabsCoins" className="popup__show show_coin">
        <ul>
          {GroupCoins &&
            GroupCoins.map((coin, i) =>
              <li key={i}>
                <button className="btn bonus_item" onClick={() => handleCoins(coin.price, coin.type)} data-toggle="modal"
                  data-target={openModal ? "#modalChargeCoins" : ''}>
                  <img src={coin.image} alt="" />
                  <span className="bonus_item_span">{coin.price}</span>
                </button>
              </li>
            )
          }

        </ul>
        <ul className="add_new_coins">
          <li>
            <Link to={`/livestreams/charge-coin?callback=${window.location.href}`}>
              <button className="btn bonus_item">
                <img src="/assets/images/add.png" className="btn__buy_coin" alt="" />
                <span />
              </button>
            </Link>
          </li>
        </ul>
      </div>
      {displayToast && <ToastContainer
        position="top-left"
        autoClose={1000}
        limit={3}
        closeButton={false}
      />}
      {displayImage6 > 0 && ImageGif6(imageCoins6)}
      {displayImage7 > 0 && ImageGif7(imageCoins7)}
      {displayImage8 > 0 && ImageGif8(imageCoins8)}
      <ModalChargeCoin openModal={handleOpenModal} />
    </>
  );
}
