import $ from 'jquery';

export function clickNext(next) {
  var current_fs, next_fs;
  var opacity;
  current_fs = $("." + next).parents('fieldset');
  next_fs = $("." + next).parents('fieldset').next();
  var newID = ($("fieldset").index(next_fs));
  //Add Class Active
  $(".cicle_dots").eq(newID - 1).addClass("active");
  $(".line").eq(newID - 1).addClass("active");
  $(".progress__text span").eq(newID - 1).addClass("active");
  next_fs.show();
  current_fs.animate({
    opacity: 0
  }, {
    step: function (now) {
      // for making fielset appear animation
      opacity = 1 - now;

      current_fs.css({
        'display': 'none',
        'position': 'relative'
      });
      next_fs.css({
        'opacity': opacity
      });
      $('.input1').focus();
    },
    duration: 500
  });
}

export function clickNextBuyTicket(next) {
  var current_fs, next_fs;
  var opacity;
  current_fs = $("." + next).parents('fieldset');
  next_fs = $("." + next).parents('fieldset').next();
  var newID = ($("fieldset").index(next_fs));

  //Add Class Active
  if (newID === 1) {
    $(".cicle_dots").eq(newID).addClass("cicle_dots2");
    $(".line").eq(newID - 1).addClass("line1");
    $(".progress__text span").eq(newID).addClass("ticket_active");
  } else if (newID === 2) {
    $(".cicle_dots").eq(newID).addClass("cicle_dots3");
    $(".line").eq(newID - 1).addClass("line2");
    $(".progress__text span").eq(newID).addClass("ticket_active");
  }
  $(".progress__text span").eq(newID - 1).addClass("ticket_active");

  next_fs.show();
  current_fs.animate({
    opacity: 0
  }, {
    step: function (now) {
      // for making fielset appear animation
      opacity = 1 - now;

      current_fs.css({
        'display': 'none',
        'position': 'relative'
      });
      next_fs.css({
        'opacity': opacity
      });
    },
    duration: 500
  });
}

export function clickPretBuyTicket(prev) {
  var current_fs, prev_fs;
  var opacity;
  current_fs = $("." + prev).parents('fieldset');
  prev_fs = $("." + prev).parents('fieldset').prev();
  var newID = ($("fieldset").index(prev_fs));

  //Add Class Active
  if (newID === 0) {
    $(".cicle_dots").eq(newID + 1).removeClass("cicle_dots2");
    $(".line").eq(newID).removeClass("line1");
    $(".progress__text span").eq(newID + 1).removeClass("ticket_active");
  }
  $(".progress__text span").eq(newID - 1).removeClass("ticket_active");
  prev_fs.show();
  current_fs.animate({
    opacity: 0
  }, {
    step: function (now) {
      // for making fielset appear animation
      opacity = 1 - now;

      current_fs.css({
        'display': 'none',
        'position': 'relative'
      });
      prev_fs.css({
        'opacity': opacity
      });
    },
    duration: 500
  });
}
