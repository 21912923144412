import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { groupSelectCodeNation } from '../../_utils/Group/GroupSelectCodeNation';
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';
import { authenticationActions } from '../../_actions/authentication.actions';

function LoginPage() {
  const history = useHistory();
  const [inputs, setInputs] = useState({
    phoneCode: "+81",
    phoneNumber: "",
    password: ""
  });
  const [submitted, setSubmitted] = useState(false);
  const { phoneCode, phoneNumber, password } = inputs;
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (window.location.pathname !== "/") {
      document.getElementById("homepage").classList.remove("homepage");
    }
    if (window.location.pathname === "/login") {
      dispatch(authenticationActions.logout());
      history.push("/login");
    }
  }, [])

  function handleChange(e) {
    const { name, value } = e.target;
    if (e.target.validity.valid) setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (phoneNumber && phoneCode && password) {
      const { from } = location.state || { from: { pathname: "/" } };
      dispatch(authenticationActions.login(phoneCode, phoneNumber, password, from));
    }
  }

  const handleSelectCode = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }))
  }

  const handleMaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  return (
    <>
      <Header />
      <div className="home__page">
        <img className="home__image" src="/assets/images/bg.png" alt="" />
        <div className="list__livestream_login d-flex justify-content-center align-items-center">
          <div className="login__form register__form">
            <h4 className="heading_livedream mb-5">電話番号でログインする</h4>
            <form name="form" onSubmit={handleSubmit}>
              <div className="locate__input d-flex justify-content-between">
                <div className="input__select input__select__phone">
                  <select id="inputGroupSelect04" onChange={handleSelectCode} value={phoneCode} name="phoneCode"
                    className="select__phone select-options">
                    {groupSelectCodeNation.map((item, i) => {
                      return <option key={i} value={item.code}
                        className="select-dropdown__list-item">{item.name} </option>
                    })}
                  </select>
                  <span className="caret" />
                </div>
                <div className="input__phone input__phone__number">
                  <input
                    autoComplete="off"
                    type="tel"
                    name="phoneNumber"
                    pattern="[0-9]*"
                    value={phoneNumber}
                    onChange={handleChange}
                    maxLength={20}
                    onInput={handleMaxLength}
                    placeholder="電話番号"
                    className={(submitted && !phoneNumber ? ' is-invalid' : '')} />
                </div>
              </div>
              {submitted && !phoneNumber &&
                <div className="invalid-feedback">電話番号が必要です</div>
              }
              <div className="input-group input__password mt-3">
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  maxLength={20}
                  onInput={handleMaxLength}
                  placeholder="パスワード"
                  className={(submitted && !password ? ' is-invalid' : '')} />
                {submitted && !password &&
                  <div className="invalid-feedback">パスワードが必要です</div>
                }
              </div>
              <div className="link__login">
                <span>パスワードをお忘れですか？</span>
                <Link to="/reset-password">再設定</Link>
              </div>
              <div className="input-login input__login mt-4">
                {loggingIn && <span className="spinner-border spinner-border-sm spinner-margin-right"></span>}
                <input type="submit" value="ログイン" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { LoginPage };
