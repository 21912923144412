import React from 'react';
import { Link } from 'react-router-dom'
import { signInWithGoogle, signInWithFacebook, signInWithTwitter } from "../../_services/firebase";
import { Footer } from '../../_components/Footer/Footer';
import { Header } from '../../_components/Header';

function BeforeRegisterPage() {
  const clientID = process.env.REACT_APP_LINE_CONSUMER_CHANNEL_ID;
  const redirectURI = process.env.REACT_APP_LINE_REDIRECT_URI;

  const handleSocialClick = (sns) => {
    switch (sns) {
      case "facebook":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "facebook")
        signInWithFacebook();
        break;
      case "google":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "google")
        signInWithGoogle();
        break;
      case "twitter":
        localStorage.setItem('login-social', true)
        localStorage.setItem('type-social', "twitter")
        signInWithTwitter();
        break;
      default:
        throw new Error("Unsupported SNS" + sns)

    }
  }

  return (
    <>
      <Header />
      <div className="home__page">
        <img className="home__image" src="/assets/images/bg.png" alt="" />
        <div className="list__livestream_login d-flex justify-content-center align-items-center">
          <ul>
            <h4 className="heading_livedream">新規登録</h4>
            <div className="sub__content">
            </div>

            <li style={{ margin: "0 auto 16px" }}>
              <Link to="/register" className="button">
                <img src="/assets/images/profile.png" alt="/assets/images/profile.png" />
                <span>電話番号で登録する</span>
              </Link>
            </li>
            <div className="loginGroup">
              <li style={{ marginRight: '19px' }}>
                <Link to="" onClick={() => handleSocialClick("twitter")} className="button">
                  <img src="/assets/images/twitter.png" alt="/assets/images/twitter.png" />
                  <span>Twitterで続ける</span>
                </Link>
              </li>
              <li>
                <Link to="" onClick={() => handleSocialClick("google")} className="button">
                  <img src="/assets/images/google.png" alt="/assets/images/google.png" />
                  <span>Googleで続ける</span>
                </Link>
              </li>
            </div>
            <div className="loginGroup">
              <li style={{ marginRight: '19px' }}>
                <Link to="" onClick={() => handleSocialClick("facebook")} className="button">
                  <img src="/assets/images/facebook.png" alt="/assets/images/facebook.png" />
                  <span>Facebookで続ける</span>
                </Link>
              </li>
              <li>
                <a
                  href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&state=12345abcde&scope=profile%20openid`}
                  className="button">
                  <img src="/assets/images/line.png" alt="/assets/images/line.png" />
                  <span>Lineで続ける</span>
                </a>
              </li>
            </div>

            <div className="link__login">
              <span className="pr-3">すでにアカウントをお持ちですか？</span>
              <Link to="/before-login">ログイン</Link>
            </div>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { BeforeRegisterPage };
