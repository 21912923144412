import React from 'react';

export function ModalGiveAwayTicketContinue(props) {
  const handleSubmitFreeTicketsContinue = (e) => {
    e.preventDefault();
    props.handleSubmitFreeTicketsContinue();
  }

  return (
    <div className="modal fade" id="modalGiveAwayTicketContinue" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">既に作成した配信スケジュールが存在します。</div>
            <div className="give_away_ticket__released mt-3">
              スケジュールの作成を続けてますか。  
            </div>
            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn"
                // onClick={handleClickBack}
                data-dismiss="modal"
                aria-label="Close"
              >キャンセル
              </button>
              <button className="btn schedule__but_next give_away_ticket__btn"
                onClick={handleSubmitFreeTicketsContinue}
                data-dismiss="modal"
                aria-label="Close"
              >続ける
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
