import React, { useEffect, useState, createContext } from "react";
import { useDispatch } from 'react-redux';
import { auth } from "../../_services/firebase"
import { authenticationActions } from "../../_actions/authentication.actions";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      const loginSocial = JSON.parse(localStorage.getItem('login-social'));
      const type = localStorage.getItem('type-social');
      if (loginSocial) {
        setUser(userAuth);
        if (userAuth) {
          userAuth.getIdToken().then(async (idToken) => {
            await dispatch(authenticationActions.loginSocial(idToken, type));
          });
        }
        localStorage.setItem('login-social', false)
      }
      setLoadingAuthState(false);
    });

    return () => {
      unsubscribe && unsubscribe();
    }
  }, [dispatch, auth.onAuthStateChanged]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingAuthState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
