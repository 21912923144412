import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from '../EventDetail/Banner/Banner';
import { EventContent } from "../EventDetail/EventContent/EventContent";
import { eventActions } from '../../_actions/event.actions';
import { Ticket } from '../../_components/Ticket/Ticket';
import { EventCategory } from '../../_components/EventCategory/EventCategory';
import "./event_ticket.css";
import LoaderCenter from "../Loader/LoaderCenter";

export function EventTicket(props) {
  const dispatch = useDispatch();
  let selectEventsDetail = useSelector(state => {
    return state.events.eventDetail
  });
  let loadingEventDetail = useSelector(state => state.events.loadingEventDetail);
  const [eventsDetail, setEventsDetail] = useState(() => selectEventsDetail);

  useEffect(() => {
    const getEnentTicket = async () => {
      await dispatch(eventActions.getEventTicketByHash(props.hash));
    }
    getEnentTicket();
  }, []);

  useEffect(() => {
    if (selectEventsDetail) {
      setEventsDetail(selectEventsDetail);
    }
  }, [selectEventsDetail]);

  return (
    <>
      {!loadingEventDetail && <LoaderCenter />}
      {eventsDetail && loadingEventDetail &&
        <>
          <Banner src={`${eventsDetail ? eventsDetail.livestream.banner : ''}`} />

          <EventContent eventsDetail={eventsDetail} user={props.user} />
        </>
      }

      {eventsDetail && eventsDetail.livestream.user.id !== props.user.id ?
        <>
          <Ticket />

          <EventCategory />
        </> : null}

    </>
  );
}
