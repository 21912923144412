import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import moment from "moment";
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { livestreamActions } from '../../../_actions';
import { dateDiffInDays } from '../../../_utils/formatTime';
import { useWindowResize } from '../LiveStreamDetail/useWindowResize';
import Loader from '../../Loader/Loader';
import './livestream.css'

const token = localStorage.getItem('token');

export function ListLiveStream(props) {
  let listLiveStreamLoad = useSelector(state => state.listLiveStream.list_livestream);
  let loading_list_livestream = useSelector(state => state.listLiveStream.loading_list_livestream);
  let userLogin = useSelector(state => state.login.user);
  let userLoginId = userLogin ? userLogin.id : 0;
  const history = useHistory();
  const livestreamRef = useRef([]);
  const [listLiveStream, setListLiveStream] = useState('');
  const [pageLivestream, setPageLivestream] = useState(1);
  const limit = props.limit;
  const dispatch = useDispatch();
  const arrayLivestreamId = [];
  const [loading, setLoading] = useState(false);
  const { width } = useWindowResize();
  const [hiddenBtnTicket, setHiddenBtnTicket] = useState(0);
  let height = 300;
  if (width <= 480) {
    height = width * 16 / 18;
  }

  useEffect(() => {
    if (loading_list_livestream) {
      setLoading(true)
    }
  }, [loading_list_livestream]);

  useEffect(() => {
    if (listLiveStreamLoad && listLiveStreamLoad.length > 0) {
      if (pageLivestream === 1) {
        setListLiveStream(listLiveStreamLoad);
        listLiveStreamLoad.map((item) => {
          arrayLivestreamId.push(item.livestream.id);
        });
        localStorage.setItem('arrayLivestreamId', arrayLivestreamId);
      } else {
        const curData = [...listLiveStream, ...listLiveStreamLoad];
        setListLiveStream(curData);
        curData.map((item) => {
          arrayLivestreamId.push(item.livestream.id);
        });
        localStorage.setItem('arrayLivestreamId', arrayLivestreamId);
      }
    } else {
      localStorage.setItem('arrayLivestreamId', '');
      if (pageLivestream === 1) {
        setListLiveStream([]);
      }
    }
  }, [listLiveStreamLoad]);

  const handleLiveStreamDetail = (livestreamId) => {
    const urlLiveStreamDetail = `/livestreams/${livestreamId}`;

    if (!token) {
      localStorage.setItem('urlLiveStreamDetail', urlLiveStreamDetail);
    }
    localStorage.setItem("goBack", window.location.pathname);
    window.location = `${document.location.origin}/livestreams/${livestreamId}`;
    // history.push(urlLiveStreamDetail);
  }

  const handleLoadMore = async (e) => {
    const currentScollWidth =
      livestreamRef.current.scrollLeft + livestreamRef.current.clientWidth;

    const triggerScollWidth = livestreamRef.current.scrollWidth;
    if (triggerScollWidth - 100 <= currentScollWidth + 1) {
      await dispatch(livestreamActions.getListLivestream(pageLivestream + 1, limit));
      setPageLivestream(pageLivestream + 1);
    }
  }

  const handleToEvent = (id, quantityTicket) => {
    let handleLiveStream = listLiveStream && listLiveStream.filter(item => {
      return parseInt(item.livestream.id) === parseInt(id);
    });
    if (handleLiveStream) {
      let start = moment(new Date(handleLiveStream[0].livestream.start_time));
      let end = moment(new Date());
      let result = start.diff(end, 'minutes');
      if (result > -15 && result <= 0 && quantityTicket) {
        localStorage.setItem("goBack", window.location.pathname);
        history.push(`/events/${id}`);
      } else {
        // cogoToast.error("Het thoi gian", {
        //     heading: 'エラー',
        //     position: 'top-center',
        //     hideAfter: 3,
        //     bar: { size: '10px' }
        // });
        setHiddenBtnTicket(id);
      }
    }
  }

  return (
    <>
      <div onScroll={(e) => handleLoadMore(e)} ref={livestreamRef}
        className={((listLiveStream && listLiveStream.length > 6)
          || (width <= 480 && listLiveStream && listLiveStream.length > 1)
          || (width > 480 && width <= 812 && listLiveStream && listLiveStream.length > 4))
          ? "list__video_livesteam_object video_livesteam_scroll"
          : "list__video_livesteam"}>
        {listLiveStream && listLiveStream.sort((a, b) => (a.livestream.start_time > b.livestream.start_time) ? 1 : -1).map((item, i) => {
          let start = moment(new Date(item.livestream.start_time));
          let end = moment(new Date());
          let result = start.diff(end, 'minutes');

          let quantityTicket = item.livestream.tickets.reduce((total, ticket) => {
            return total += ticket.ticket_remain
          }, 0);
          return <div key={i} className={`iteam__schedule_livestream
                                ${(i === listLiveStream.length - 1)
              ? "item__video_livesteam2 item__video_livesteam_mgr"
              : "item__video_livesteam2"}`}>
            <div className="video_livesteam cursor_pointer" onClick={() => handleLiveStreamDetail(item.livestream.id)}>
              <img className="user"
                src={item.livestream.thumbnail ? item.livestream.thumbnail : "/assets/images/livestream.png"}
                alt="" />
              <time>{dateDiffInDays(moment(item.livestream.start_time).format(), moment().format())}</time>
            </div>
            <h5 className="video__title cursor_pointer text_video_hidden"
              onClick={() => handleLiveStreamDetail(item.livestream.id)}>
              {item.livestream.title}
            </h5>
            <div className="meta__video d-flex justify-content-between">
              <span>
                {item.livestream.number_views} views  ·  <Moment
                  fromNow>{item.livestream.created_at}</Moment>
              </span>
            </div>
            <span className="live live_cus">Live</span>
            {userLoginId !== item.livestream.user.id && result > -15 && result <= 0 && quantityTicket && <button
              onClick={() => handleToEvent(item.livestream.id, quantityTicket)}
              className="live notification notification_cus"
              style={item.livestream.id === hiddenBtnTicket ? { display: 'none' } : { display: 'block' }}
            >
              チケット購入
            </button> || ''}

            <div
              className="mobile_video_info d-flex justify-content-between align-items-center content__margin-top cursor_pointer"
              onClick={() => handleLiveStreamDetail(item.livestream.id)}>
              <div className="avatar">
                <img src={item.livestream.user && item.livestream.user.avatar}
                  onError={(event) => event.target.src = '/assets/images/avatar.png'}
                  alt="" />
                <span>{item.livestream.user.name}</span>
              </div>
              <div className="video_title video_title_cus"
                onClick={() => handleLiveStreamDetail(item.livestream.id)}> {item.livestream.title}</div>
            </div>
          </div>
        })}
      </div>

      {listLiveStream && listLiveStream.length === 0 && loading && <div className="video_not_fount">
        <span className="video_span_not_fount">現在、配信中のライブ配信はございません。</span>
      </div>}

      {listLiveStream && listLiveStream.length === 0 && !loading && <Loader />}

      <div className="mg__button_1"></div>
    </>
  );
}
