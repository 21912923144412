import { livestreamConstants } from '../../_constants';

const initialStateFollow = {
  report: '',
  submitted: false
}

export function report(state = initialStateFollow, action) {
  switch (action.type) {
    case livestreamConstants.REPORT_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
      };
    case livestreamConstants.REPORT_FAILURE:
      return {
        ...state,
        report: action.error,
      };
    case livestreamConstants.POST_REPORT_SUCCESS:
      return {
        ...state,
        submitted: action.payload,
      };
    default:
      return state
  }
}
