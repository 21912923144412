import React from 'react';
import styled from 'styled-components';

const AriaDiv = ({ className, children, onClick, OnKeyDown }) => {
  return (
    <div
      role='button'
      aria-pressed='false'
      tabIndex='0'
      className="btn btn-custom-like"
      onClick={onClick}
      onKeyDown={OnKeyDown}
    >
      {/* {children} */}
      {/* <img src="/assets/images/icon.png" alt="" className="icon__like" /> */}
      <img src="/assets/images/icon.png" alt="" />
    </div>
  )
}

const EmojiButton = styled(AriaDiv)`

`

export default EmojiButton;
