import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router';
import { clickNextBuyTicket } from "../../../../_utils/clickNext";
import * as moment from "moment";
import { isValidEmail } from "../../../../_utils/validate";
import { getWeekDay } from '../../../../_utils/formatTime';
import { API_BASE } from "../../../../_helpers/api";

export function Step1(props) {
  const history = useHistory();
  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [getDay, setGetDay] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [valEmail, setValEmail] = useState(true);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (props.event) {
      const date = props.event.livestream.start_time;
      setGetDay(getWeekDay(date));
      setCurrMonth(new Date(date).getMonth());
      setCurrDate(new Date(date).getDate());
    }
  }, [props.event]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleClickBackCalendar = () => {
    localStorage.setItem("goBack", "");
    history.push(`/events-by-date/${moment.unix(props.event.livestream.start_time / 1000).format("YYYY-MM-DD")}`);
  }

  const handleChangeFirstName = (e) => {
    const { value } = e.target;
    setFirstName(value);
    props.handleFirstName(value);
  }

  const handleChangeLastName = (e) => {
    const { value } = e.target;
    setLastName(value);
    props.handleLastName(value);
  }

  const handleChangeEmail = (e) => {
    const { value } = e.target;
    const formatEmail = isValidEmail(value);
    setValEmail(formatEmail)
    setEmail(value);
    props.handleEmail(value);
  }

  const handleGotoDetailEvent = (eventId) => {
    history.push(`/events/${eventId}`);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!email || !valEmail) {
      emailRef.current.focus()
    }
    if (!firstName) {
      firstNameRef.current.focus()
    }
    if (!lastName) {
      lastNameRef.current.focus()
    }
    if (email && firstName && lastName && valEmail && agree) {
      scrollToTop();
      clickNextBuyTicket("nextBuyTicket1");
    }

  }

  const handleChangeCheckbox = () => {
    setAgree(!agree);
  }

  return (
    <section className="event-content">
      <div className="row">
        <div className="col-lg-7 px-3 mb-5">
          <form name="form">
            <div className="event-payment-infor">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="first-name">姓</label>
                    <input
                      ref={firstNameRef}
                      autoComplete="off"
                      type="text"
                      name="first-name"
                      className={`form-control input-event ${(submitted && !firstName) ? ' is-invalid' : ''}`}
                      placeholder="姓"
                      value={firstName}
                      onChange={handleChangeFirstName}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="last-name">名</label>
                    <input
                      ref={lastNameRef}
                      autoComplete="off"
                      type="text"
                      name="last-name"
                      className={`form-control input-event ${(submitted && !lastName) ? ' is-invalid' : ''}`}
                      placeholder="名"
                      value={lastName}
                      onChange={handleChangeLastName}
                    />
                  </div>
                </div>
                {((submitted && !firstName) || (submitted && !lastName)) &&
                  <div className="invalid__feedback invalid__feedback_pd">お名前が入力されてません</div>
                }
              </div>
              <div className="form-group">
                <label htmlFor="ticket">メールアドレス（必須）</label>
                <input
                  ref={emailRef}
                  autoComplete="off"
                  type="text"
                  name="email"
                  className={`form-control input-event ${((submitted && !email) || (submitted && !valEmail)) ? ' is-invalid' : ''}`}
                  value={email}
                  onChange={handleChangeEmail}
                />
              </div>
              {submitted && !email &&
                <div className="invalid__feedback">メールアドレスは、必ず指定してください。</div>
              }
              {submitted && !valEmail && email !== "" &&
                <div className="invalid__feedback">メールアドレスは間違っています。</div>
              }

              <div className="form-group">
                <label htmlFor="ticket">チケット</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="ticket"
                  className={`form-control input-event`}
                  value={`1人 - ${props.ticket.price}円`}
                  disabled={true}
                  style={{ background: "#9B9B9B" }}
                />
              </div>
            </div>

            <iframe className="event-payment-desc" style={{ width: "100%", height: "680px" }} src={`${API_BASE}/term-of-use/`} />
            <div className="agree" >
              <label className="container-checkbox" onChange={handleChangeCheckbox}>
                <input
                  type="checkbox"
                  className="schedule-checkbox"
                  name='agree'
                  value='1'
                  defaultChecked={agree}
                />
                <span className="checkmark"></span>
              </label><span style={{ paddingLeft: '10px' }}>利用規約に同意します。</span>
            </div>
            {submitted && !agree &&
              <div className="invalid__feedback">利用規約に同意してください。</div>
            }

            <div className="event-payment-desc__but">
              <button className="btn event-payment-desc__but_return" onClick={() => handleGotoDetailEvent(props.event.livestream.id)}>戻る</button>
              <button className="btn event-payment-desc__but_next nextBuyTicket1" onClick={(e) => handleSubmit(e)}>次へ</button>
            </div>
          </form>
        </div>
        <div className="col-lg-5 px-3 mb-5">
          <div className="buy__ticket buy__ticket_payment buy__ticket_payment_cus">
            <div className="apply_purchase">申込/購入はこちらから</div>
            <div className="apply_purchase__buy__ticket">
              <div className="buy__ticket_header">
                <span className="buy__ticket_img">
                  <img src={props.event.livestream.thumbnail} alt="" />
                </span>
                <span className="buy__ticket_content">
                  <span className="buy__ticket_content_tit">
                    {props.event.livestream.title}
                  </span>
                  <span className="buy__ticket_content_cal">
                    <img src={'/assets/icons/calendar_ticket.png'} alt="" />{moment.unix(props.event.livestream.start_time / 1000).format("ddd, MMM D YYYY HH:mm")} ~
                  </span>
                </span>
              </div>
              <button className="btn btn-event__details" onClick={() => handleGotoDetailEvent(props.event.livestream.id)}>イベント詳細</button>
              <span className="buy__ticket_sp">
                <span>チケット x1</span>
                <span>￥{props.ticket.fee_type === 0 ? props.ticket.price : props.ticket.price - parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
              <span className="buy__ticket_sp margin-bottom-1rem">
                <span>購入手数料({props.ticket.fee}%)</span>
                <span>￥{parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
            </div>
            <div className="buy__ticket_total">
              <span>支払金額</span>
              <span style={{ float: "right" }}>￥{props.ticket.fee_type === 0 ? props.ticket.price + parseInt(props.ticket.price * props.ticket.fee / 100) : props.ticket.price}</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="event__hr" />
      <div className="event__back">
        <button className="btn btn__back" onClick={handleClickBackCalendar} >
          <i className="fa fa-chevron-left fa-2x" aria-hidden="true"></i>
          <span>{`${(currMonth + 1)}`}</span>月<span>{`${currDate}`}</span>日（{`${getDay}`}）のイベントへ戻る
        </button>
      </div>
    </section>
  );
}