import React, { useEffect, useRef, useState } from 'react';
import { setMinutes, setHours, setSeconds } from "date-fns";
import DatePicker from "react-datepicker";
import * as JWT from 'json-web-token';
import moment from "moment";

const handleMaxLength = (e) => {
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
}

export function ModalCreateEvent(props) {
  const buttonRef = useRef();
  const [endTime, setEndTime] = useState(new Date());
  const [name, setName] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [price, setPrice] = useState('');
  const [feeType, setFeeType] = useState(0);
  const [expiredDate, setExpiredDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [submitTicket, setSubmitTicket] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [checkQuantity, setCheckQuantity] = useState(false);
  const [maxExpired, setMaxExpired] = useState('');
  const [checkNumberTicket, setCheckNumberTicket] = useState(false);

  useEffect(() => {
    setExpiredDate(moment(endTime).valueOf());
  }, [endTime])

  useEffect(() => {
    if (props.tickets) {
      if (props.tickets.livestream.tickets.length !== 0) {
        const changeStartDate = `${moment(props.tickets.livestream.start_time).format('YYYY-MM-DD HH:mm:ss')}`
        setEndTime(setSeconds(moment(changeStartDate).valueOf(), 600))
        setMaxExpired(setSeconds(moment(changeStartDate).valueOf(), 600));
      }
    }

  }, [props.tickets])

  useEffect(() => {
    if (props.startTime && props.tickets.livestream && props.tickets.livestream.tickets.length === 0) {
      const changeStartDate = `${moment(props.startTime).format('YYYY-MM-DD HH:mm:ss')}`;
      setEndTime(setSeconds(moment(changeStartDate).valueOf(), 600));
      setMaxExpired(setSeconds(moment(changeStartDate).valueOf(), 600));
    }
  }, [props.startTime])

  useEffect(() => {
    if (disableModal) {
      buttonRef.current.click();

    }
  }, [disableModal])

  const handleSubmitTicket = async (e) => {
    e.preventDefault();
    await setSubmitTicket(true);
    if (name && price >= 700 && quantity && endTime >= moment(moment().format('YYYY-MM-DD HH:mm')).valueOf()) {
      await setDisableModal(true);
      const hash = JWT.encode('n6Vk31A5hRKKBajCDPaV', {
        timestamp: new Date().getTime(),
        expiredDate
      });

      const ticket = {
        "name": name,
        "price": price,
        "description": ticketDescription,
        "expired_date": expiredDate,
        "fee": 15,
        "fee_type": feeType,
        "quantity": quantity,
        "hash": hash.value
      }

      props.handlCreateTicket(ticket);
      setSubmitTicket(false);
      setName('');
      setTicketDescription('');
      setPrice('');
      setFeeType(0);
      setQuantity('');
      setEndTime(new Date());
      setDisableModal(false);
    }
  }

  const handleChangePrice = (e) => {
    const { value } = e.target;
    if (parseInt(value) < 700) {
      setCheckQuantity(true);
    } else {
      setCheckQuantity(false);
    }
    if (e.target.validity.valid) {
      if (value.length === 2) {
        const arrayNumber = Array.from(value);
        if (parseInt(arrayNumber[0]) === 0) {
          const valueSub = value.substring(1);
          return setPrice(valueSub);
        }
      }
      setPrice(value);
    }
  }

  const handleChangeQuantity = (e) => {
    const { value } = e.target;
    if (parseInt(value) > 10000) {
      setCheckNumberTicket(true);
    } else {
      setCheckNumberTicket(false);
    }
    if (e.target.validity.valid) {
      if (value.length === 1) {
        const arrayNumber = Array.from(value);
        if (parseInt(arrayNumber[0]) === 0) {
          return;
        }
      }
      setQuantity(value);
    }
  }

  return (
    <div className="modal fade" id="modalCreateEvent" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog create-event-modal" role="document">
        <div className="modal-content modal-content__event">
          <div className="modal-body">
            <span className="create_event__title">チケット追加</span>

            <div className="create_event__content">
              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="name">チケット名</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  value={name}
                  className={`form-control input-schedule ${submitTicket && !name ? ' is-invalid' : ''}`}
                  placeholder="VIP"
                  onChange={e => setName(e.target.value)}
                />
              </div>
              {submitTicket && !name &&
                <div className="invalid__feedback">チケット名は、必ず指定してください。</div>
              }

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="ticketDescription">説明</label>
                <textarea
                  autoComplete="off"
                  name="ticketDescription"
                  className={`form-control input-schedule`}
                  value={ticketDescription}
                  rows={5}
                  placeholder="説明"
                  onChange={e => setTicketDescription(e.target.value)}
                />
              </div>

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="price">価格 (JPY)</label>
                <input
                  autoComplete="off"
                  type="tel"
                  name="price"
                  className={`form-control input-schedule ${((submitTicket && price === "") || checkQuantity) ? ' is-invalid' : ''}`}
                  value={price}
                  placeholder="700"
                  onChange={handleChangePrice}
                  pattern="[0-9]*"
                  maxLength={15}
                  onInput={handleMaxLength}
                />
              </div>
              {submitTicket && price === "" &&
                <div className="invalid__feedback">価格は、必ず指定してください。</div>
              }

              {checkQuantity &&
                <div className="invalid__feedback">価格は700円以上で設定してください</div>
              }

              <div className="schedule-font-size input__select schedule_input__select">
                <label className="schedule-label_custom" htmlFor="fee_type">手数料(15%)負担</label>
                <select
                  id="schedule-input-select"
                  name="fee"
                  className="schedule-select-options"
                  value={feeType}
                  onChange={e => setFeeType(e.target.value)}>
                  <option className="schedule-options_list-item" value="0">購入者</option>
                  <option className="schedule-options_list-item" value="1">販売者</option>
                </select>
                <span className="schedule_caret" />
              </div>

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="quantity">枚数 (枚)</label>
                <input
                  autoComplete="off"
                  type="tel"
                  name="quantity"
                  className={`form-control input-schedule ${submitTicket && !quantity ? ' is-invalid' : ''}`}
                  value={quantity}
                  placeholder="10"
                  onChange={handleChangeQuantity}
                  pattern="[0-9]*"
                  maxLength={5}
                  onInput={handleMaxLength}
                />
              </div>
              {submitTicket && !quantity &&
                <div className="invalid__feedback">枚数は、必ず指定してください。</div>
              }
              {checkNumberTicket &&
                <div className="invalid__feedback">チケットの枚数は10000枚以下に設定してください</div>
              }

              <div className="form-group schedule-font-size">
                <label className="schedule-label_custom" htmlFor="expired_date">販売終了</label>
                <div className="schedule-date-time schedule-date__sale modal__input__datime">
                  <DatePicker
                    locale="ja"
                    selected={endTime}
                    onChange={date => setEndTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    // minDate={new Date()}
                    maxDate={setSeconds(new Date(moment(maxExpired).valueOf()), -600) > new Date() ? new Date(moment(maxExpired).valueOf()) : new Date()}
                    timeIntervals={1}
                    timeCaption="時間"
                    dateFormat="yyyy/MM/d HH:mm"
                    minTime={setHours(setMinutes(endTime, 0), 0)}
                    maxTime={endTime < new Date()
                      ? setHours(setMinutes(endTime, 59), 23)
                      : (endTime < maxExpired
                        ? (new Date(moment(maxExpired).subtract(1, 'days').endOf('days').valueOf()) < endTime
                          ? setSeconds(new Date(maxExpired), 0)
                          : setHours(setMinutes(endTime, 59), 23))
                        : setSeconds(new Date(maxExpired), 0))}
                    className={`form-control input-schedule input__datepicker ${submitTicket && endTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() ? ' is-invalid' : ''}`}
                  />
                  <img src={'/assets/icons/calendar_schedule.png'}
                    className="schedule-date-img modal__schedule-date-img" alt="" />
                </div>
              </div>

              {submitTicket && endTime < moment(moment().format('YYYY-MM-DD HH:mm')).valueOf() &&
                <div className="invalid__feedback">チケットの販売終了日時は必ず将来の時間を指定してください。</div>
              }

              <div className="community__btn mt-4">
                <button
                  ref={buttonRef}
                  className="btn schedule__but_next give_away_ticket__btn"
                  onClick={handleSubmitTicket}
                  data-dismiss={disableModal ? "modal" : ''}
                  aria-label={disableModal ? "Close" : ''}
                >チケットを追加
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};