export function dateDiffInDays(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);

  let res = Math.abs(dt2 - dt1) / 1000;
  let hours = ("0" + Math.floor(res / 3600) % 24).slice(-2);
  let minutes = ("0" + Math.floor(res / 60) % 60).slice(-2);
  let seconds = ("0" + res % 60).slice(-2);

  if (hours !== "00") {
      return hours + ":" + minutes + ":" + seconds;
  } else {
      return minutes + ":" + seconds;
  }
}

export function formatDate(str) {
  let date = new Date(str),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function convert(date) {
  const getDay = new Date(date);
  const mnth = ("0" + (getDay.getMonth() + 1)).slice(-2);
  const day = ("0" + getDay.getDate()).slice(-2);
  return [getDay.getFullYear(), mnth, day].join("-");
}

export function getWeekDay(date) {
  return ["日", "月", "火", "水", "木", "金", "土"][new Date(date).getDay()];
}
