import { livestreamConstants } from '../../_constants';
// let followToken = localStorage.getItem('follow') ? JSON.parse(localStorage.getItem('follow')) : '';
const initialStateFollow = { follow: '' }

export function follow(state = initialStateFollow, action) {
  switch (action.type) {
    case livestreamConstants.FOLLOW_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.FOLLOW_SUCCESS:
      return {
        ...state,
        follow: action.payload,
      };
    case livestreamConstants.FOLLOW_FAILURE:
      return {
        follow: '',
      };
    case livestreamConstants.UNFOLLOW_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.UNFOLLOW_SUCCESS:
      return {
        ...state,
        follow: action.payload,
      };
    case livestreamConstants.UNFOLLOW_FAILURE:
      return {
        follow: '',
      };
    default:
      return state
  }
}
