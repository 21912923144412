import React, { useRef } from "react";
import cogoToast from "cogo-toast";
import { eventService } from "../../../../_services";
import { clickPretBuyTicket } from "../../../../_utils/clickNext";
import * as moment from 'moment';
import { formatMessage } from '../../../../_utils/formatMessage';
import { ModalErrorEmail } from "./ModalErrorEmail";

export function Step2(props) {
  const buttonErrorEmailRef = useRef();

  const handleClickNextPayment = async () => {
    const data = {
      firstname: props.firstName,
      lastname: props.lastName,
      email: props.email,
      ticket_id: props.ticket.id,
      callback: `${window.location.href}?result=success`
    }
    if (props.email) {
      eventService.buyTicket(props.ticket.livestream_id, data)
        .then(
          res => {
            console.log('RES', res.data.error);
            if (res.data.status === 400) {
              if (res.data.error === 100) {
                buttonErrorEmailRef.current.click();
              } else {
                cogoToast.error(formatMessage(res.data.message), {
                  heading: 'エラー',
                  position: 'top-center',
                  hideAfter: 3,
                  bar: { size: '10px' }
                });
                // localStorage.setItem("CreateScheduleSusscess", false);
                return true;
              }
            } else if (res.data.status === 200) {
              // history.replace(res.data.data);
              window.location = res.data.data
            }
          },
          error => {
            cogoToast.error(formatMessage(error.toString()), {
              heading: 'エラー',
              position: 'top-center',
              hideAfter: 3,
              bar: { size: '10px' }
            });
          }
        );
    }
  }

  const handleClickPrevPayment = async (e) => {
    e.preventDefault();
    clickPretBuyTicket("nextBuyTicket2");
  }

  return (
    <section className="event-content">
      <div className="row">
        <div className="col-lg-8 col-md-8 offset-lg-2 px-3 mb-5">
          <div className="buy__ticket_payment">
            <div className="apply_purchase buy__ticket_width_60 apply_purchase_custom">支払い</div>
            <div className="apply_purchase__buy__ticket buy__ticket_width_60" style={{ fontSize: '16px' }}>
              <span className="buy__ticket_sp">
                <span style={{ fontWeight: 'bold' }}>商品名</span>
                <span style={{ fontWeight: 'bold' }}>お支払い内容</span>
              </span>
              <span className="buy__ticket_sp">
                <span>{moment.unix(props.event.livestream.start_time / 1000).format('YYYY-MM-DD HH:mm')} ~ {props.ticket.name}</span>
                <span>チケット料金: ￥{props.ticket.fee_type === 0 ? props.ticket.price : props.ticket.price - parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
              <span className="buy__ticket_sp">
                <span>チケットNo: {props.ticket.id}</span>
                <span>手数料: ￥{parseInt(props.ticket.price * props.ticket.fee / 100)}</span>
              </span>
              <span className="buy__ticket_sp margin-bottom-1rem">
                <span>配信者: {props.event.livestream.user.name}</span>
                <span></span>
              </span>
            </div>
            <div className="buy__ticket_total buy__ticket_width_60">
              <span className="buy__ticket_sp margin-bottom-1rem">
                <span style={{ fontWeight: 'bold' }}>合計価格（税込）</span>
                <span style={{ fontWeight: 'bold' }}>￥{props.ticket.fee_type === 0 ? props.ticket.price + parseInt(props.ticket.price * props.ticket.fee / 100) : props.ticket.price}</span>
              </span>
            </div>
            <div className="box__pad_bottom"></div>
          </div>
          <div className="event-payment-desc__but">
            <button className="btn event-payment-desc__but_return" onClick={handleClickPrevPayment}>戻る</button>
            <button className="btn event-payment-desc__but_next nextBuyTicket2" onClick={handleClickNextPayment} >
              支払い
            </button>
          </div>
        </div>
      </div>

      <button
        ref={buttonErrorEmailRef}
        data-toggle="modal"
        data-target="#modalErrorEmail"
        data-dismiss="modal"
        hidden="hidden"
      >公開
      </button>

      <ModalErrorEmail handleClose={''} />
    </section>
  );
}