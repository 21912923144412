import { livestreamConstants } from '../../_constants';

const initialState = { singleUrl: '' }

export function singleUrl(state = initialState, action) {
  switch (action.type) {
    case livestreamConstants.SINGLE_URL_REQUEST:
      return {
        ...state,
      };
    case livestreamConstants.SINGLE_URL_SUCCESS:
      return {
        ...state,
        singleUrl: action.payload,
      };
    case livestreamConstants.SINGLE_URL_FAILURE:
      return {
        singleUrl: action.payload,
      };
    default:
      return state
  }
}
