import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../_actions/authentication.actions';
import { clickNext } from '../../_utils/clickNext';

export function VerifyCode() {
  const verifyInput1 = useRef(null);
  const verifyInput2 = useRef(null);
  const verifyInput3 = useRef(null);
  const verifyInput4 = useRef(null);
  const [verifyCode1, setVerifyCode1] = useState('');
  const [verifyCode2, setVerifyCode2] = useState('');
  const [verifyCode3, setVerifyCode3] = useState('');
  const [verifyCode4, setVerifyCode4] = useState('');
  const [submittedVerifyCode, setSubmittedVerifyCode] = useState(false);
  const resetPasswordNext = useSelector(state => state.resetPassword.reset_password_next);
  const phoneCode = useSelector(state => state.resetPassword.phone_code);
  const phoneNumber = useSelector(state => state.resetPassword.phone_number);
  const [countdownTime, setCountdownTime] = useState(60);
  const [isResend, setResend] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetPasswordNext) clickNext("next2");
  }, [resetPasswordNext]);

  useEffect(() => {
    if (phoneCode && phoneNumber) {
      // verifyInput1.current.focus();
      if (!countdownTime) {
        setResend(true);
        return;
      }
      const interval = setInterval(() => {
        setCountdownTime(countdownTime => countdownTime - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [phoneNumber, phoneCode, countdownTime])

  function handleSubmitVerifyCode(e) {
    e.preventDefault();

    setSubmittedVerifyCode(true);
    if (verifyCode1 && verifyCode2 && verifyCode3 && verifyCode4) {
      const code = verifyCode1 + verifyCode2 + verifyCode3 + verifyCode4;
      dispatch(authenticationActions.verifyCodeResetPassword(code));
    }
  }

  function handleChangeVerifyCode(e) {
    const { name, value } = e.target;
    if (e.target.validity.valid) {
      switch (name) {
        case 'verify_code1':
          return setVerifyCode1(value);
        case 'verify_code2':
          return setVerifyCode2(value);
        case 'verify_code3':
          return setVerifyCode3(value);
        case 'verify_code4':
          return setVerifyCode4(value);
        default:
          return false;
      }
    }
  }

  const handleMaxLengthCheck = (e, input) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength)
    } else if (e.target.value.length === 1) {
      switch (input) {
        case 'input1':
          return verifyInput2.current.focus();
        case 'input2':
          return verifyInput3.current.focus();
        case 'input3':
          return verifyInput4.current.focus();
        default:
          return false;
      }
    }
  }

  const handleKeyPress = (e, input) => {
    if (e.keyCode === 8) {
      if (input === "input4" && e.target.value === '') {
        verifyInput3.current.focus()
      } else if (input === "input3" && e.target.value === '') {
        verifyInput2.current.focus()
      } else if (input === "input2" && e.target.value === '') {
        verifyInput1.current.focus()
      }
    }
  }

  const handleSendVerifycationCode = async () => {
    await dispatch(authenticationActions.requestResetPasswordCode(phoneCode, phoneNumber));
    setCountdownTime(60);
    setResend(false);
  }

  return (
    <form name="form" onSubmit={handleSubmitVerifyCode}>
      <div className=" text-left mt-4 mb-0 d-flex justify-content-center">
        <span className="heading_livedream">パスワードを再設定<br /></span>
      </div>
      <div className="sub__content_reset mt-2 d-flex justify-content-center">
        <span className="text-left">
          確認コードが記載されたSMSが届きます。<br />
        </span>
      </div>
      <div className="sub__content mt-2 d-flex justify-content-center">
        <span className="text-left">
          認証コードの有効期限は {countdownTime}秒で切れます。{isResend ?
            <span className="resend-button" onClick={handleSendVerifycationCode}>再送信</span> : null}<br />
        </span>
      </div>
      <div className="locate__input d-flex justify-content-center mb-3">
        <div className="input__verify_code margin_verify">
          <input
            type="tel"
            name="verify_code1"
            pattern="[0-9]*"
            maxLength={1}
            value={verifyCode1}
            onInput={e => handleMaxLengthCheck(e, "input1")}
            onChange={handleChangeVerifyCode}
            className={(submittedVerifyCode && !verifyCode1 ? ' is-invalid' : '')}
            ref={verifyInput1}
            onKeyDown={(e) => handleKeyPress(e, "input1")}
          />
          <input
            type="tel"
            name="verify_code2"
            pattern="[0-9]*"
            maxLength={1}
            value={verifyCode2}
            onInput={e => handleMaxLengthCheck(e, "input2")}
            onChange={handleChangeVerifyCode}
            className={(submittedVerifyCode && !verifyCode2 ? ' is-invalid' : '')}
            ref={verifyInput2}
            onKeyDown={(e) => handleKeyPress(e, "input2")}
          />
          <input
            type="tel"
            name="verify_code3"
            pattern="[0-9]*"
            maxLength={1}
            value={verifyCode3}
            onInput={e => handleMaxLengthCheck(e, "input3")}
            onChange={handleChangeVerifyCode}
            className={(submittedVerifyCode && !verifyCode3 ? ' is-invalid' : '')}
            ref={verifyInput3}
            onKeyDown={(e) => handleKeyPress(e, "input3")}
          />
          <input
            type="tel"
            name="verify_code4"
            pattern="[0-9]*"
            maxLength={1}
            value={verifyCode4}
            onInput={e => handleMaxLengthCheck(e, "input4")}
            onChange={handleChangeVerifyCode}
            className={(submittedVerifyCode && !verifyCode4 ? ' is-invalid' : '')}
            ref={verifyInput4}
            onKeyDown={(e) => handleKeyPress(e, "input4")}
          />
        </div>
      </div>

      {submittedVerifyCode && (!verifyCode1 || !verifyCode2 || !verifyCode3 || !verifyCode4) &&
        <div className="invalid-feedback">認証コードを入力してください。</div>
      }
      <div className="input-login input__login mt-3">
        <input className="next2" type="submit" value="次へ" />
      </div>
    </form>
  );
}
