import React from "react";
import moment from "moment";
import Linkify from 'react-linkify';

export function EventEditRight(props) {
  const tickets = props.tickets && props.tickets.livestream;

  const renderTickets = (ticket, i, number, type) => {
    let iEqualEvent, iBiggerEvent;
    switch (type) {
      case "equal":
        iEqualEvent = number === tickets.tickets.length;
        break;

      case "bigger":
        iBiggerEvent = number;
        break;
      default:
        break;
    }

    let bdLineNone = iEqualEvent ? (i === number - 1 ? 'bd_line_none' : '') : (iBiggerEvent ? (i === number - 1 ? 'bd_line_none' : '') : '');

    return <div className={`apply_purchase__ticket ${bdLineNone}`} key={i}>
      <span className="apply_purchase_content">
        <span className="content_1 content_1__cus">
          <img src={'/assets/icons/title.png'} alt="" />{ticket.name}
        </span>
        <span className="content_2 added-ticket-description">
          <img src={'/assets/icons/information.png'} alt="" />
          <span
            className={`ticket-value_event_edit ${ticket.price === 0 && tickets.href.length > 0 ? "ticket_text_span" : ""}`}>
            {ticket.price === 0 && tickets.href.length > 0 &&
              <span className="ticket_text_community">コミュニティ限定</span> || ''}
            <span className="ticket__description"><Linkify
              properties={{ target: '_blank' }}>{ticket.description}</Linkify></span>

          </span>
        </span>
        <span className="ticket__content content_3">
          <span className="content_3__dp content_3_price">
            <img src={'/assets/icons/money.png'} alt="" />￥{ticket.price}
          </span>
          <span className="content_3__dp content_3_calendar">
            <img src={'/assets/icons/calendar.png'}
              alt="" />販売終了: {moment.unix(ticket.expired_date / 1000).format('YYYY/MM/DD HH:mm')}
          </span>
          <span className="content_3__dp content_3_ticket">
            <img src={'/assets/icons/ticket.png'}
              alt="" />残り{ticket.ticket_remain >= 0 ? ticket.ticket_remain.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : parseInt(ticket.quantity).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}枚
          </span>
        </span>
      </span>
    </div>
  }

  return (
    <>
      {tickets && tickets.tickets.length && <div className="buy__ticket">
        <div className="apply_purchase">チケット一覧</div>
        {tickets.tickets.filter(x => x.price === 0).sort((a, b) => (a.expired_date > b.expired_date) ? 1 : -1).map((ticket, i) => {
          return renderTickets(ticket, i, tickets.tickets.filter(x => x.price === 0).length, 'equal');
        })}
        {tickets.tickets.filter(x => x.price > 0).sort((a, b) => (a.expired_date > b.expired_date) ? 1 : -1).map((ticket, i) => {
          return renderTickets(ticket, i, tickets.tickets.filter(x => x.price > 0).length, 'bigger');
        })}
      </div> || ''}

      <div className="event_btn_add_ticket">
        <button
          className="btn btn_add_ticket"
          data-toggle="modal"
          data-target="#modalCreateEvent"
        >チケット追加
        </button>
      </div>
    </>
  );
}
