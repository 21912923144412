import { livestreamConstants } from '../../_constants';


const initialState = {
  livestream_detail: null,
  loading_livestream_detail: false,
  livestreamList: [],
  loadingLivestreamList: false
};

export function liveStreamDetail(state = initialState, action) {

  switch (action.type) {
    case livestreamConstants.LIVESTREAM_DETAIL_REQUEST:
      return {
        ...state,
        loading_livestream_detail: false,
      };
    case livestreamConstants.LIVESTREAM_DETAIL_SUCCESS:
      return {
        ...state,
        livestream_detail: action.payload,
        loading_livestream_detail: true,
      };
    case livestreamConstants.LIVESTREAM_DETAIL_FAILURE:
      return {
        ...state,
        livestream_detail: '',
        loading_livestream_detail: true,
      };
    case livestreamConstants.LIST_LIVESTREAM_DETAIL_REQUEST: {
      return {
        ...state,
        loadingLivestreamList: false
      };
    }
    case livestreamConstants.LIST_LIVESTREAM_DETAIL_SUCCESS: {
      let livestreamList = action.payload;
      return {
        ...state,
        livestreamList,
        loadingLivestreamList: true
      };
    }

    case livestreamConstants.LIST_LIVESTREAM_DETAIL_FAILURE: {
      return {
        ...state,
        livestreamList: [],
        loadingLivestreamList: true
      };
    }

    case livestreamConstants.SUBSCRIBE_LIVESTREAM_REQUEST: {
      return {
        ...state
      }
    }

    case livestreamConstants.SUBSCRIBE_LIVESTREAM_SUCCESS: {
      const livestreamList = [...state.livestreamList];
      livestreamList.map(livestream => {
        if (livestream.livestream.id === action.payload.id) {
          livestream.livestream.is_notification = action.payload.is_notification;
        }

        return livestream;
      })

      return {
        ...state,
        livestreamList: livestreamList
      }
    }

    case livestreamConstants.SUBSCRIBE_LIVESTREAM_FAILURE: {
      return {
        ...state
      }
    }
    default:
      return state
  }
}
