import { isAndroid, isIOS } from "react-device-detect";

export function openApp(idCommunity) {
  const APP_LINK = {
    android:
      "https://play.google.com/store/apps/details?id=com.live_dreamer.LiveDreamer",
    ios: "itms-apps://itunes.apple.com/app/id1445831749"
  };

  if (isAndroid) {
    var now = new Date().valueOf();
    let androidTimeOut = setTimeout(function () {
      if (new Date().valueOf() - now > 100) return window.location = window.location = APP_LINK.android;
      window.location = generateOpenAppButton(idCommunity);
      clearTimeout(androidTimeOut);
    }, 25);
    window.location = "live-dreamer.com://";
  } else if (isIOS) {
    var now = new Date().valueOf();
    let iosTimeOut = setTimeout(function () {
      if (new Date().valueOf() - now > 100) return window.location = window.location = APP_LINK.ios;
      window.location = generateOpenAppButton(idCommunity);
      clearTimeout(iosTimeOut);
    }, 25);
    window.location = "live-dreamer.com://";
  } else {
    window.location = generateOpenAppButton(idCommunity);
  }
}

function generateOpenAppButton(idCommunity) {
  return `https://s-live-deramer.com/communities/${idCommunity}`;
}
