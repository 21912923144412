import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cogoToast from 'cogo-toast';
import Linkify from 'react-linkify';
import { livestreamActions, userActions } from "../../_actions";
import { UserLivestream } from "./UserLivestream";
import { ScheduleContent } from "./ScheduleContent";
import LoaderCenter from "../Loader/LoaderCenter";
import "./user.css";

export function User(props) {
  const dispatch = useDispatch();
  let selectUserProfile = useSelector(state => state.userProfile.userProfile);
  let loadingUserProfile = useSelector(state => state.userProfile.loadingUserProfile);
  const [activeTab1, setActiveTab1] = useState(true);
  const [activeTab2, setActiveTab2] = useState(false);
  const isFollowed = selectUserProfile ? selectUserProfile.is_followed : '';
  const [liveStreamDetailFollow, setLiveStreamDetailFollow] = useState(isFollowed);
  const [urlUser, setUrlUser] = useState('');

  useEffect(() => {
    localStorage.setItem('event-follow', '');
  }, []);

  useEffect(() => {
    setLiveStreamDetailFollow(isFollowed);
  }, [isFollowed]);

  useEffect(() => {
    if (props.userId) {
      const getUserProfile = async () => {
        await dispatch(userActions.getUserProfile(props.userId));
        setUrlUser(`${window.location.origin}/user/${props.userId}`);
      }
      getUserProfile();
    }
  }, [props.userId]);

  const handleActiveTab1 = () => {
    setActiveTab1(true);
    setActiveTab2(false);
  }

  const handleActiveTab2 = () => {
    setActiveTab1(false);
    setActiveTab2(true);
  }

  const handleFollow = async (userId, follow) => {
    const stateFollow = localStorage.getItem('event-follow');
    let isFollow = stateFollow ? !follow : !isFollowed;
    localStorage.setItem('event-follow', 'follow');

    follow ? await dispatch(livestreamActions.unfollow(userId)) : await dispatch(livestreamActions.follow(userId));

    setLiveStreamDetailFollow(isFollow);

    let getUserTimeOut = setTimeout(async () => {
      await dispatch(userActions.getUserProfile(userId));
      clearTimeout(getUserTimeOut);
    }, 500);
  }

  const handleClickCopy = (e) => {
    e.preventDefault();
    cogoToast.success('', {
      heading: 'コピーしました。',
      position: 'top-center',
      hideAfter: 3,
      bar: { size: '10px' },
    });
  }

  return (
    <>
      <section className="my-page-content">
        {selectUserProfile && loadingUserProfile &&
          <div className="row my_page_infor_user">
            <div className="my_page_infor_user_header">
              <span className="my_page_infor_user_img">
                <img src={`${selectUserProfile.avatar}`}
                  onError={(event) => event.target.src = '/assets/images/avatar.png'}
                  alt="" />
              </span>
              <span>
                <button
                  className={`btn btn_user_profile btn_user__custom ${liveStreamDetailFollow ? 'active_follow' : ''}`}
                  onClick={() => handleFollow(selectUserProfile.id, liveStreamDetailFollow)}>
                  {selectUserProfile.is_followed ? 'フォローを解除する' : 'フォローする'}
                </button>
                <CopyToClipboard text={urlUser}>
                  <button className="btn btn_user_profile btn_user__custom"
                    onClick={handleClickCopy}>URLコピー</button>
                </CopyToClipboard>
              </span>
            </div>
            <h6 className="user_profile__name">
              {selectUserProfile.name}
              {selectUserProfile.is_public && <img src={'/assets/icons/ic_official.png'} className="tick_user_profile" alt="" />  || ''}
            </h6>

            <div className="user_profile_content">
              <Linkify properties={{ target: '_blank' }}>{selectUserProfile.about}</Linkify>
            </div>
            <div className="user_profile_follows">
              <span>{selectUserProfile.number_followed} フォロー中</span>
              <span>{selectUserProfile.number_follower} フォロワー</span>
              <span>{selectUserProfile.likes} いいね</span>
              <span>{selectUserProfile.community_joined} 参加コミュニティー</span>
            </div>

            <div className="padding__top_3"></div>

            <div id="tab_user_profile"
              className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
              <ul className="nav nav-tabs" id="tab__list_user_profile" role="tablist">
                <li className="nav-item_user_profile" onClick={handleActiveTab1}>
                  <a
                    className={activeTab1 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                    id="live-tab"
                    data-toggle="tab"
                    href="#live"
                    role="tab"
                    aria-controls="live"
                    aria-selected="true"
                  >ライブ</a>
                </li>
                <li className="nav-item_user_profile" onClick={handleActiveTab2}>
                  <a
                    className={activeTab2 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                    id="schedule_history-tab"
                    data-toggle="tab"
                    href="#schedule_history"
                    role="tab"
                    aria-controls="schedule_history"
                    aria-selected="true"
                  >配信予定</a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="live" role="tabpanel" aria-labelledby="live-tab">
                <div className="live_content">
                  <UserLivestream userProfileId={selectUserProfile.id} />
                </div>
              </div>
              <div className="tab-pane fade" id="schedule_history" role="tabpanel" aria-labelledby="schedule_history-tab">
                <div className="schedule_history_content">
                  <ScheduleContent userProfileId={selectUserProfile.id} />
                </div>
              </div>
            </div>
          </div>
        }
      </section>
      {!loadingUserProfile && <LoaderCenter />}
    </>
  );
}
