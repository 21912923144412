import React, { Fragment, useState, useEffect } from 'react';

const AutoExpire = ({ children }) => {
  const [isExpire, setIsExpire] = useState(false);

  useEffect(() => {
    let likeTimeOut = setTimeout(() => {
      setIsExpire(true);
      clearTimeout(likeTimeOut);
    }, 2000)
  }, []);

  if (isExpire) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

export default AutoExpire;
