import React from 'react';

export function ModalChargeCoin({ openModal }) {
  const handleRemoveModalOpen = () => {
    window.location = `${document.location.origin}/livestreams/charge-coin?callback=${window.location.href}`;
  }

  return (
    <>
      <div className="modal fade" id="modalChargeCoins" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog reveal-modal" role="document">
          <div className="modal-content modal-content__custom">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">エラー</h5>
              <button type="button" className="close btn" data-dismiss="modal" aria-label="Close"
                onClick={() => openModal(false)}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <span className="modal-content-custom">コインが不足です！</span>
              <div className="inputform-body">
                <div className="input-login input__login mt-4">
                  <input type="submit" className="submit-margin-right" value="キャンセル" data-dismiss="modal"
                    aria-label="Close" onClick={() => openModal(false)} />
                </div>

                <div className="input-login input__login mt-4">
                  <input type="submit" className="submit-margin-right" value="OK"
                    onClick={() => handleRemoveModalOpen()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
