import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./ticket_distribute.css"
import cogoToast from "cogo-toast";
import { formatMessage } from "../../../_utils/formatMessage";
import Linkify from 'react-linkify';
import { eventService } from "../../../_services";
import { userActions } from "../../../_actions";

export function TicketDistribute(props) {
  const refRadio = useRef(null);
  const refRadio1 = useRef(null);
  let userLogin = useSelector(state => state.login.user);
  let userId = userLogin ? userLogin.id : 0;
  let followsUsers = useSelector(state => state.followerUsers.followUsers);
  let followerOfUsers = useSelector(state => state.followerUsers.followerOfUsers);
  const dispatch = useDispatch();
  const [activeTab1, setActiveTab1] = useState(true);
  const [activeTab2, setActiveTab2] = useState(false);
  const [selected, setSelected] = useState("");
  const [userTickets, setUserTickets] = useState([]);
  const [freeTickets, setFreetickets] = useState([]);
  const [checkFreeTickets, setCheckFreetickets] = useState([]);

  useEffect(() => {
    const value = refRadio.current ? refRadio.current.value : '';
    if (value) {
      setSelected(value);
    }

  }, [refRadio.current])

  useEffect(() => {
    const getFollowsUsers = async () => {
      await dispatch(userActions.getFollowsOfUser(userId));
      await dispatch(userActions.getFollowerOfUser(userId));
    }

    getFollowsUsers();
    if (props.tickets) {
      let freeTickets = [];
      if (props.editEvenst) {
        freeTickets = props.tickets.filter(ticket => {
          return ticket.price === 0 && parseInt(ticket.ticket_remain) !== 0;
        });
      } else {
        freeTickets = props.tickets.filter(ticket => {
          return ticket.price === 0;
        });
      }
      setFreetickets(freeTickets);
    }
  }, [props.tickets])

  useEffect(() => {
    if (props.eventId) {
      const getUserboughtTicket = async () => {
        await eventService.getUserboughtTicket(props.eventId)
          .then(
            res => {
              if (res.data.status === 400) {
                cogoToast.error(formatMessage(res.data.message), {
                  heading: 'エラー',
                  position: 'top-center',
                  hideAfter: 3,
                  bar: { size: '10px' }
                });
              } else if (res.data.status === 200) {
                setCheckFreetickets(res.data.data);
              }
            },
            error => {
              cogoToast.error(formatMessage(error.toString()), {
                heading: 'エラー',
                position: 'top-center',
                hideAfter: 3,
                bar: { size: '10px' }
              });
            }
          );
      }
      getUserboughtTicket();
    }

  }, [props.eventId])

  useEffect(() => {
    if (userTickets) {
      const checkFreeTicket = {
        "userTickets": userTickets,
        "selected": selected
      }
      props.handleChangeFreeTickets(checkFreeTicket);
    }
  }, [userTickets]);

  const handleActiveTab1 = () => {
    setActiveTab1(true);
    setActiveTab2(false);
  }

  const handleActiveTab2 = () => {
    setActiveTab1(false);
    setActiveTab2(true);
  }

  const handleChangeFollowChecked = (e) => {
    const { value } = e.target;
    if (e.target.checked) {
      const selectedTicket = freeTickets.find(ticket => ticket.hash === selected);
      const userTicketsLength = userTickets.filter(userTicket => userTicket.hash === selected);
      if (props.editEvenst) {
        if (selectedTicket && selectedTicket.ticket_remain <= userTicketsLength.length) {
          e.target.checked = false;
          cogoToast.error(formatMessage('reach_ticket_quantity'), {
            heading: 'エラー',
            position: 'top-center',
            hideAfter: 3,
            bar: { size: '10px' }
          });
          return;
        }
      } else {
        if (selectedTicket && selectedTicket.quantity <= userTicketsLength.length) {
          e.target.checked = false;
          cogoToast.error(formatMessage('reach_ticket_quantity'), {
            heading: 'エラー',
            position: 'top-center',
            hideAfter: 3,
            bar: { size: '10px' }
          });
          return;
        }
      }

      setUserTickets([...userTickets, {
        "user_id": value,
        "hash": selected
      }]);
    } else {
      const results = userTickets.filter(ticket => {
        return parseInt(ticket.user_id) !== parseInt(value);
      });
      setUserTickets(results);
    }

  }

  const handleChangeSelected = (e, hash) => {
    const { value } = e.target;
    setSelected(value);
  }

  return (
    <div className="row event__ticket_distribute">
      <div className="col-lg-5">
        <div className="buy__ticket buy__ticket_custom">
          <div className="apply_purchase">配布するチケットを選択</div>
          {freeTickets && freeTickets.map((ticket, i) => {
            return parseInt(ticket.price) === 0 && <div className={`apply_purchase__ticket_finish ${i !== freeTickets.length - 1 ? "bd_line" : ""}`} key={i}>
              <span className="apply_purchase_content">
                <span className="content_1 content_1__cus">
                  <img src={'/assets/icons/title.png'} alt="" />{ticket.name}
                </span>
                <span className="content_2">
                  <img src={'/assets/icons/information.png'} alt="" />
                  <span className={`ticket-value ticket-value_cus ${ticket.price === 0 && props.community ? "ticket_text_span" : ""}`}>
                    {ticket.price === 0 && props.community && <span className="ticket_text_community">コミュニティ限定</span> || ''}
                    <span className="ticket__description"><Linkify properties={{ target: '_blank' }} >{ticket.description}</Linkify></span>
                  </span>
                </span>
                <span className="ticket__content content_3">
                  <span className="content_3__dp content_3_price">
                    <img src={'/assets/icons/money.png'} alt="" />￥{ticket.price}
                  </span>
                  <span className="content_3__dp content_3_calendar">
                    <img src={'/assets/icons/calendar.png'} alt="" />販売終了: {moment.unix(ticket.expired_date / 1000).format('YYYY/MM/DD HH:mm')}
                  </span>
                  <span className="content_3__dp content_3_ticket">
                    <img src={'/assets/icons/ticket.png'} alt="" />残りチケット{props.editEvenst ? ticket.ticket_remain : ticket.quantity}枚
                  </span>
                </span>
              </span>
              <span className="buy__ticket__radio">
                <input
                  type="radio"
                  id={`${ticket.price}${i}`}
                  name="ticket"
                  defaultChecked={i === 0}
                  // onChange={e => setSelected(e.target.value)}
                  onChange={e => handleChangeSelected(e, ticket.hash)}
                  value={ticket.hash}
                  ref={i === 0 ? refRadio : refRadio1}
                />
                <label htmlFor={`${ticket.price}${i}`}></label>
              </span>
            </div>
          })}
        </div>
      </div>

      <div className="col-lg-5 offset-lg-2 event__account_distribute">
        <div className="account_distribute">
          <div className="apply_purchase">配布するアカウントを選択</div>
          <div id="tab_user_profile" className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
            <ul className="nav nav-tabs" id="tab__list_user_profile" role="tablist">
              <li className="nav-item_user_profile" onClick={handleActiveTab1}>
                <a
                  className={activeTab1 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                  id="live-tab"
                  data-toggle="tab"
                  href="#live"
                  role="tab"
                  aria-controls="live"
                  aria-selected="true"
                >{props.user && props.user.number_followed} フォロー中</a>
              </li>
              <li className="nav-item_user_profile" onClick={handleActiveTab2}>
                <a
                  className={activeTab2 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                  id="schedule_history-tab"
                  data-toggle="tab"
                  href="#schedule_history"
                  role="tab"
                  aria-controls="schedule_history"
                  aria-selected="true"
                >{props.user && props.user.number_follower} フォロワー</a>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="live" role="tabpanel" aria-labelledby="live-tab">
              <div className={`live_content ${followsUsers && followsUsers.length > 5 ? "list_follower_scroll" : ""}`}>
                {followsUsers && followsUsers.map((followsUser, i) => {
                  let followsUserId = checkFreeTickets.some(item => followsUser.id === parseInt(item));
                  return <div className="account_distribute_item" key={i}>
                    <span className="account_distribute__checkbox">
                      <label className="container-checkbox account_distribute__label">
                        <input
                          type="checkbox"
                          className="schedule-checkbox"
                          id="rock"
                          name={followsUser.name}
                          onChange={handleChangeFollowChecked}
                          value={followsUser.id}
                          disabled={followsUserId}
                        />
                        {followsUserId && <span className="checkmark__delivered">配布済み</span> || <span className="checkmark checkmark_cus"></span>}
                      </label>
                    </span>

                    <span className="account_distribute__img"><img src={followsUser.avatar} alt="" /></span>
                    <span className="account_distribute__name">{followsUser.name}</span>
                  </div>
                })}

                {followsUsers && followsUsers.length === 0 && followerOfUsers && followerOfUsers.length === 0 && <div className="live_content_text">フォロー中/フォロワーがいないのでチケットを配布できません。</div>}
              </div>
            </div>

            <div className="tab-pane fade" id="schedule_history" role="tabpanel" aria-labelledby="schedule_history-tab">
              <div className={`schedule_history_content ${followerOfUsers && followerOfUsers.length > 5 ? "list_follower_scroll" : ""}`}>
                {followerOfUsers && followerOfUsers.map((followerOfUser, i) => {
                  let followerOfUserId = checkFreeTickets.some(item => followerOfUser.id === parseInt(item));
                  return <div className="account_distribute_item" key={i}>
                    <span className="account_distribute__checkbox">
                      <label className="container-checkbox account_distribute__label">
                        <input type="checkbox"
                          value={followerOfUser.id}
                          onChange={handleChangeFollowChecked}
                          className="schedule-checkbox"
                          id="followerUser"
                          name="followerUser"
                          disabled={followerOfUserId}
                        />
                        {followerOfUserId && <span className="checkmark__delivered">配布済み</span> || <span className="checkmark checkmark_cus"></span>}
                      </label>
                    </span>

                    <span className="account_distribute__img"><img src={followerOfUser.avatar} alt="" /></span>
                    <span className="account_distribute__name">{followerOfUser.name}</span>
                  </div>
                })}

                {followerOfUsers && followerOfUsers.length === 0 && followsUsers && followsUsers.length === 0 && <div className="live_content_text">フォロー中/フォロワーがいないのでチケットを配布できません。</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
