import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import { livestreamActions } from '../../../_actions';
import { dateDiffInDays } from '../../../_utils/formatTime';
import * as moment from "moment";
import { socket } from '../../../_helpers';
import Loader from '../../Loader/Loader';

export function ListLiveStreamInDetail(props) {
  const limit = props.limit;
  const listInitLiveStream = useSelector(state => state.listLiveStream.list_livestream);
  const loadingListLivestream = useSelector(state => state.listLiveStream.loading_list_livestream);
  const [listLiveStream, setListLiveStream] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentImageIdx, setCurrentImagIdx] = useState(0);
  const [displayLoadData, setDisplayLoadData] = useState(false);
  const [page, setPage] = useState(1);
  const [stopLivestreamId, setStopLivestreamId] = useState('');

  useEffect(() => {
    if (listInitLiveStream && listInitLiveStream.length > 0) {
      if (page === 1) {
        setListLiveStream(listInitLiveStream);
      } else {
        const curData = [...listLiveStream, ...listInitLiveStream];
        setListLiveStream(curData);
      }

      const getUnit = listInitLiveStream.length % 10;
      if (getUnit === 0) {
        setDisplayLoadData(true);
      } else {
        setDisplayLoadData(false);
      }
    }
  }, [listInitLiveStream]);

  useEffect(() => {
    socket.on('stopLivestream', (data) => {
      setStopLivestreamId(data.livestream ? data.livestream.video.livestream_id : '');
    });
  });


  const handleClickPrev = (e) => {
    const resetToVeryBack = currentImageIdx === 0;
    const index = resetToVeryBack ? 0 : currentImageIdx - 1;
    setCurrentImagIdx(index);
  }

  const handleClickNext = async (e) => {
    if (listLiveStream.length - currentImageIdx === 7 && displayLoadData) {
      await dispatch(livestreamActions.getListLivestream(page + 1, limit));
      setPage(page + 1);
      setCurrentImagIdx(currentImageIdx + 1);
    } else {
      if (listLiveStream.length - currentImageIdx < 7) return;
      setCurrentImagIdx(currentImageIdx + 1);
    }
  }

  const activeImageSourcesFromState = listLiveStream.slice(
    currentImageIdx,
    currentImageIdx + 6
  );

  const handleLivestreamId = (livestreamId) => {
    localStorage.setItem('follow', '')
    if (stopLivestreamId === livestreamId) {
      history.push(`/livestreams/${livestreamId}`);
    } else if (stopLivestreamId !== livestreamId) {
      window.location = `${document.location.origin}/livestreams/${livestreamId}`;
    }
  }

  const handleEventDetail = async (id) => {
    localStorage.setItem("goBack", window.location.pathname);
    localStorage.setItem("backLivestream", "true");
    history.push(`/events/${id}`);
  }

  const handleSubscribeNotification = (id) => {
    dispatch(livestreamActions.subscribeLivestream(id));
  }

  const handleUnsubscribeNotification = (id) => {
    dispatch(livestreamActions.unsubscribeLivestream(id));
  }

  return (
    <>
      <div className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
        <div className="heading_livedream text-left">配信中・配信予定</div>
        <div className="group__button">
          <button className="btn" onClick={() => handleClickPrev()}>
            <img src="/assets/images/Left.png" alt="" />
          </button>
          <button className="btn" onClick={() => handleClickNext()}>
            <img src="/assets/images/Right.png" alt="" />
          </button>
        </div>
      </div>
      <div className="list__video_related" id="list_livestream_detail">
        {activeImageSourcesFromState.length > 0 && activeImageSourcesFromState.map((item, i) =>
          <div className="item__video" key={i}>
            {item.livestream.status === 1 ?
              <div>
                <div className="video video_livesteam  cursor_pointer"
                  onClick={() => handleLivestreamId(item.livestream.id)}>
                  <img className="user"
                    src={item.livestream.thumbnail ? item.livestream.thumbnail : "/assets/images/livestream.png"}
                    alt="" />
                  <time>{dateDiffInDays(moment(item.livestream.start_time).format(), moment().format())}</time>
                </div>
                <h5 className="video__title cursor_pointer"
                  onClick={() => handleLivestreamId(item.livestream.id)}>{item.livestream.title}</h5>
                <div className="meta__video d-flex justify-content-between">
                  <span>
                    {item.livestream.number_views} views  ·  <Moment
                      fromNow>{item.livestream.created_at}</Moment>
                  </span>
                </div>
                <span className="live">Live</span>
                <div
                  className="mobile_video_info d-flex justify-content-between align-items-center content__margin-top cursor_pointer"
                  onClick={() => handleLivestreamId(item.livestream.id)}>
                  <div className="avatar">
                    <img src={item.livestream.user && item.livestream.user.avatar}
                      onError={(event) => event.target.src = '/assets/images/avatar.png'}
                      alt="" />
                    <span>{item.livestream.user.name}</span>
                  </div>
                  <div className="video_title video_title_cus"> {item.livestream.title}</div>
                </div>
              </div>
              : null}
            {item.livestream.status === 5 ?
              <div className="iteam__schedule_livestream" onClick={() => handleEventDetail(item.livestream.id)}>
                <div className="video video_livesteam  cursor_pointer">
                  <img className="user"
                    src={item.livestream.thumbnail ? item.livestream.thumbnail : "/assets/images/livestream.png"}
                    alt="" />
                </div>
                <h5 className="video__title cursor_pointer"
                  onClick={() => handleEventDetail(item.livestream.id)}>{item.livestream.title}</h5>
                <div className="meta__video d-flex justify-content-between">
                  <span>
                    {item.livestream.number_views} views  ·  <Moment
                      fromNow>{item.livestream.created_at}</Moment>
                  </span>
                </div>
                <span
                  className="timeLivestream">{moment.unix(item.livestream.start_time / 1000).format('MM/DD HH:mm')} ~</span>
                <button
                  onClick={item.livestream.is_notification ? () => handleUnsubscribeNotification(item.livestream.id) : () => handleSubscribeNotification(item.livestream.id)}
                  className={item.livestream.is_notification ? "live notification" : "live un_notification"}>{item.livestream.is_notification ? "予約する" : "予約する"}</button>
                <div
                  className="mobile_video_info d-flex justify-content-between align-items-center content__margin-top cursor_pointer">
                  <div className="avatar">
                    <img src={item.livestream.user && item.livestream.user.avatar}
                      onError={(event) => event.target.src = '/assets/images/avatar.png'} 
                      alt="" />
                    <span>{item.livestream.user.name}</span>
                  </div>
                  <div className="video_title video_title_cus"> {item.livestream.title}</div>
                </div>
              </div>
              : null}
          </div>
        )}
        {activeImageSourcesFromState.length === 0 && !loadingListLivestream && <Loader />}
      </div>
    </>
  );
}
