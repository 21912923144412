import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Linkify from 'react-linkify';
import { userActions } from "../../_actions";
import './my_page.css';
import { ScheduleHistoryContent } from './ScheduleHistoryContent';
import { LiveContent } from './LiveContent';
import { roundNumber } from "../../_utils/formatNumber";
import LoaderCenter from "../Loader/LoaderCenter";

function MyPageDetail() {
  const dispatch = useDispatch();
  let selectMeProfile = useSelector(state => state.userProfile.userProfile);
  let loadingMeProfile = useSelector(state => state.userProfile.loadingUserProfile);
  const userCoin = useSelector(state => state.login.user_coin);
  const [activeTab1, setActiveTab1] = useState(true);
  const [activeTab2, setActiveTab2] = useState(false);

  useEffect(() => {
    const getMeProfile = async () => {
      await dispatch(userActions.getUserProfile('me'));
    }
    getMeProfile();

    const activeTab = localStorage.getItem("active_tab");
    const myPage = localStorage.getItem("my-page");
    if (parseInt(activeTab) && myPage === 'my-page') {
      setActiveTab1(false);
      setActiveTab2(true);
      localStorage.setItem('my-page', '');
    }
  }, []);

  const handleActiveTab1 = () => {
    setActiveTab1(true);
    setActiveTab2(false);
  }

  const handleActiveTab2 = () => {
    setActiveTab1(false);
    setActiveTab2(true);
  }

  const handleProfileEdit = () => {

  }

  function formatCoin(coin) {
    if (coin >= 0) {
      if (coin.toString().length < 4) {
        return coin;
      } else if ((coin.toString().length === 4) || (coin.toString().length > 4 && coin.toString().length < 7)) {
        const number = coin / 1000;
        return `${roundNumber(number, 2)}K`;
      } else if ((coin.toString().length === 7) || (coin.toString().length > 7 && coin.toString().length < 10)) {
        const number = coin / 1000000;
        return `${roundNumber(number, 2)}M`;
      } else {
        const number = coin / 1000000000;
        return `${roundNumber(number, 2)}B`;
      }
    }

    return coin;
  }

  return (
    <>
      <section className="my-page-content">
        {selectMeProfile && loadingMeProfile &&
          <div className="row my_page_infor_user">
            <div className="my_page_infor_user_header">
              <span className="my_page_infor_user_img">
                <img src={`${selectMeProfile.avatar}`}
                  onError={(event) => event.target.src = '/assets/images/avatar.png'}
                  alt="" />
              </span>
              <span>
                <button className="btn btn_user_profile" onClick={() => handleProfileEdit}
                  disabled>プロフィール編集・設定</button>
              </span>
            </div>
            <h6 className="user_profile__name">
              {selectMeProfile.name}
              {selectMeProfile.is_public && <img src={'/assets/icons/ic_official.png'} className="tick_user_profile" alt="" />  || ''}
            </h6>

            <div className="user_profile_content">
              <Linkify properties={{ target: '_blank' }}>{selectMeProfile.about}</Linkify>
            </div>
            <div className="user_profile_follows">
              <span>{selectMeProfile.number_followed} フォロー中</span>
              <span>{selectMeProfile.number_follower} フォロワー</span>
              <span>{selectMeProfile.likes} いいね</span>
              <span>{selectMeProfile.community_joined} 参加コミュニティー</span>
            </div>
            <div className="user_profile_follows">
              <span><b>{userCoin ? formatCoin(userCoin.coin_community_received + userCoin.coin_livestream_received) : 0}</b> ゲットDコイン</span>
              <span><b>{userCoin ? formatCoin(userCoin.coin_used) : 0}</b> チップDコイン</span>
              <span><b>{userCoin ? formatCoin(userCoin.coin) : 0}</b> 所持Dポイント</span>
            </div>


            <div id="tab_user_profile"
              className="top__action_video d-flex justify-content-between align-items-center content__margin-top">
              <ul className="nav nav-tabs" id="tab__list_user_profile" role="tablist">
                <li className="nav-item_user_profile" onClick={handleActiveTab1}>
                  <a
                    className={activeTab1 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                    id="live-tab"
                    data-toggle="tab"
                    href="#live"
                    role="tab"
                    aria-controls="live"
                    aria-selected="true"
                  >ライブ</a>
                </li>
                <li className="nav-item_user_profile" onClick={handleActiveTab2}>
                  <a
                    className={activeTab2 ? `nav-link_user_profile user_profile__active` : `nav-link_user_profile`}
                    id="schedule_history-tab"
                    data-toggle="tab"
                    href="#schedule_history"
                    role="tab"
                    aria-controls="schedule_history"
                    aria-selected="true"
                  >視聴予定/履歴</a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className={activeTab1 ? `tab-pane fade show active` : `tab-pane fade`} id="live" role="tabpanel"
                aria-labelledby="live-tab">
                <div className="live_content">
                  <LiveContent />
                </div>
              </div>
              <div className={activeTab2 ? `tab-pane fade show active` : `tab-pane fade`} id="schedule_history"
                role="tabpanel" aria-labelledby="schedule_history-tab">
                <div className="schedule_history_content">
                  <ScheduleHistoryContent />
                </div>
              </div>
            </div>
          </div>
        }
      </section>
      {!loadingMeProfile && <LoaderCenter />}
    </>
  );
};

export { MyPageDetail };