import React, { useEffect } from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router';

export function ModalDraftSuccess() {
  const history = useHistory();

  useEffect(() => {
    $(document.body).removeClass("modal-open");
  }, []);

  const handleClose = () => {
    history.push(`/my-events`);
  }

  return (
    <div className="modal fade" id="modalDraftSuccess" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="false" data-keyboard="false" data-backdrop="static">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content">保存しました。</div>

            <div className="community__btn mt-4">
              <button className="btn schedule__but_return give_away_ticket__btn close__public"
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
              >閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
