import React from 'react';
import moment from 'moment';
import "./countdown.css"

const SVGCircle = ({ radius }) => {
  return <svg className='countdown-svg'>
    {window.innerWidth > 480
      ? <path fill="none" stroke="#FFF765" strokeWidth="4" d={describeArc(50, 50, 48, 0, radius)} />
      : <path fill="none" stroke="#FFF765" strokeWidth="4" d={describeArc(32.7, 32.7, 31, 0, radius)} />}
  </svg>
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");

  return d;
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export class Countdown extends React.Component {
  state = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    startCountdown: false
  }

  componentDidMount() {
    if (moment(new Date(this.props.remainingCountdown), "MM DD YYYY, h:mm a") >= moment()) {
      this.setState({ startCountdown: true });
    }
  }

  componentDidUpdate() {
    const then = moment(new Date(this.props.remainingCountdown), "MM DD YYYY, h:mm a");
    if (then >= moment()) {
      this.interval = setInterval(() => {
        const now = moment();
        const countdown = moment.duration(then.diff(now))

        const days = countdown.days();
        const hours = countdown.hours();
        const minutes = countdown.minutes();
        const seconds = countdown.seconds();

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
          return this.props.onCheckCountDown(true);
        }
        this.setState({ days, hours, minutes, seconds });
        clearInterval(this.interval);
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes, seconds, startCountdown } = this.state;
    const daysRadius = mapNumber(days, 30, 0, 0, 360);
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    return (
      <>
        {startCountdown && <div className="box_countdown_timer">
          <h5 className="text_countdown_timer">配信開始まで少々おまちください</h5>
          <div className='countdown-wrapper'>
            {days >= 0 && (
              <div className='countdown-item'>
                <SVGCircle radius={daysRadius} />
                {days}
                <span>日</span>
              </div>
            )}
            {hours >= 0 && (
              <div className='countdown-item'>
                <SVGCircle radius={hoursRadius} />
                {hours}
                <span>時</span>
              </div>
            )}
            {minutes >= 0 && (
              <div className='countdown-item'>
                <SVGCircle radius={minutesRadius} />
                {minutes}
                <span>分</span>
              </div>
            )}
            {seconds >= 0 && (
              <div className='countdown-item'>
                <SVGCircle radius={secondsRadius} />
                {seconds}
                <span>秒</span>
              </div>
            )}
          </div>
        </div>
        ||
        <div className="stop_box__video">
          <span className="stop_display_text">
            配信は終了しました。
          </span>
        </div>}
      </>
    );
  }
}
