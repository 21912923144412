import { livestreamConstants } from '../_constants';
import { livestreamService } from '../_services';
import { history } from '../_helpers';
import { checkAuthentication, handleErrorMessage, handleReponse } from '../_helpers/api';

export const livestreamActions = {
  getListLivestream,
  getLivestreamDetail,
  follow,
  unfollow,
  report,
  postReport,
  subscribeLivestream,
  unsubscribeLivestream,
  getScheduleLivestreamList,
  comment,
  loadSendCoins,
  getListLivestreamByUserId,
  getCommunities,
  getVideoCategories,
  createLivestream,
  singedUrl,
  uploadImageS3,
  createDraftevent,
  getCommunityById
};

function getListLivestreamByUserId(id = 'me', type, page, limit) {
  return dispatch => {
    dispatch(request(''));

    livestreamService.getVideosOfUser(id, type, page, limit)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.LIST_LIVESTREAM_MY_PAGE_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.LIST_LIVESTREAM_MY_PAGE_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.LIST_LIVESTREAM_MY_PAGE_FAILURE, error } }
}

function getListLivestream(page, limit) {

  let data = {
    page,
    limit
  }
  return dispatch => {
    dispatch(request({ data }));

    livestreamService.getLivestreamByStatus(page, limit, 1, 1)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.LIST_LIVESTREAM_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.LIST_LIVESTREAM_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.LIST_LIVESTREAM_FAILURE, error } }
}

function getScheduleLivestreamList(page, limmit) {

  let data = {
    page,
    limmit
  }

  return dispatch => {
    dispatch(request({ data }));

    livestreamService.getLivestreamByStatus(page, limmit, 5)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.LIST_SCHEDULE_LIVESTREAM_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.LIST_SCHEDULE_LIVESTREAM_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.LIST_SCHEDULE_LIVESTREAM_FAILURE, error } }
}

function getLivestreamDetail(id) {
  let data = {
    id
  }
  return dispatch => {
    dispatch(request({ data }));

    livestreamService.getLivestreamDetail(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
            dispatch(failure(res.data.message));
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.LIVESTREAM_DETAIL_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.LIVESTREAM_DETAIL_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.LIVESTREAM_DETAIL_FAILURE, error } }
}

function follow(id) {
  return dispatch => {
    livestreamService.follow(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };
}

function subscribeLivestream(id) {
  return dispatch => {
    dispatch(request(''));
    livestreamService.subscribeLivestream(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success({ id, is_notification: true }));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_FAILURE, error } }
}

function unsubscribeLivestream(id) {
  return dispatch => {
    dispatch(request(''));
    livestreamService.unsubscribeLivestream(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー');
          }

          const handleSuccess = () => {
            dispatch(success({ id, is_notification: false }));
          }
          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.SUBSCRIBE_LIVESTREAM_REQUEST, error } }
}

function unfollow(id) {
  return dispatch => {

    livestreamService.unfollow(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {

          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };
}

function report() {
  return async dispatch => {
    dispatch(request(''));
    await livestreamService.report()
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request(data) { return { type: livestreamConstants.REPORT_REQUEST, payload: data } }
  function success(data) { return { type: livestreamConstants.REPORT_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.REPORT_FAILURE, error } }
}

function postReport(reportable_id, type, reasonId, content) {
  let data = {
    reportable_id,
    type,
    reasonId,
    content
  }

  return async (dispatch) => {
    await livestreamService.postReport(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {

          }

          handleReponse(res, handleError, handleSuccess);
          dispatch(success(false));
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: livestreamConstants.POST_REPORT_SUCCESS, payload: data } }
}

function comment(number) {
  return async (dispatch) => {
    await dispatch(success(number));
  };

  function success(data) { return { type: livestreamConstants.COMMENT_SUCCESS, payload: data } }
}

function loadSendCoins(number) {
  return async (dispatch) => {
    await dispatch(success(number));
  };

  function success(data) { return { type: livestreamConstants.LOAD_SEND_COINS_SUCCESS, payload: data } }
}

function getCommunities(keySearch, page, limmit) {
  return dispatch => {
    livestreamService.getCommunities(keySearch, page, limmit)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: livestreamConstants.GET_COMMUNITIES_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.GET_COMMUNITIES_FAILURE, error } }
}

function getCommunityById(id) {
  return dispatch => {
    livestreamService.getCommunityById(id)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function success(data) { return { type: livestreamConstants.GET_COMMUNITY_DETAIL_SUCCESS, payload: data } }
}

function getVideoCategories() {

  return dispatch => {
    dispatch(request());

    livestreamService.getVideoCategories()
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: livestreamConstants.GET_VIDEO_CATEGORIES_REQUEST } }
  function success(data) { return { type: livestreamConstants.GET_VIDEO_CATEGORIES_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.GET_VIDEO_CATEGORIES_FAILURE, error } }
}

function createLivestream(data) {
  return async dispatch => {
    await livestreamService.createLivestream(data)
      .then(
        res => {
          if (res.data.status === 400 && res.data.message === "cant_create_schedule_livestream_in_this_time") {
            return localStorage.setItem("CreateScheduleSusscess", 'false');
          }

          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            localStorage.setItem("CreateScheduleSusscess", 'true');
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };
}

function singedUrl() {
  const data = {
    "extension": "png",
    "type": 2
  }
  return dispatch => {
    dispatch(request());
    livestreamService.singedUrl(data)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: livestreamConstants.SINGLE_URL_REQUEST } }
  function success(data) { return { type: livestreamConstants.SINGLE_URL_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.SINGLE_URL_FAILURE, error } }
}

function createDraftevent(draftEvent) {
  delete draftEvent.is_upload;
  return dispatch => {
    dispatch(request());
    livestreamService.createDraftEvent(draftEvent)
      .then(
        res => {
          const handleError = () => {
            checkAuthentication(res, history);
            handleErrorMessage(res.data.message, 'エラー')
          }

          const handleSuccess = () => {
            dispatch(success(res.data.data));
          }

          handleReponse(res, handleError, handleSuccess);
        },
        error => {
          dispatch(failure(error.toString()));
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };

  function request() { return { type: livestreamConstants.CREATE_DRAFT_EVENT_DOING } }
  function success(data) { return { type: livestreamConstants.CREATE_DRAFT_EVENT_SUCCESS, payload: data } }
  function failure(error) { return { type: livestreamConstants.CREATE_DRAFT_EVENT_FAIL, error } }
}

function uploadImageS3(urlUploadS3, thumbnail) {
  const data = {
    'urlUploadS3': urlUploadS3,
    'thumbnail': thumbnail,
  }

  return dispatch => {

    livestreamService.uploadImageS3(data)
      .then(
        res => {
        },
        error => {
          handleErrorMessage(error.toString(), 'エラー');
        }
      );
  };
}
