export const GroupCoins = [
  {
    id: 1,
    image: "/assets/images/200.png",
    price: 200,
    type: 6
  },
  {
    id: 2,
    image: "/assets/images/400.png",
    price: 400,
    type: 7
  },
  {
    id: 3,
    image: "/assets/images/600.png",
    price: 600,
    type: 8
  },
  {
    id: 4,
    image: "/assets/images/10.png",
    price: 10,
    type: 1
  },
  {
    id: 5,
    image: "/assets/images/100.png",
    price: 100,
    type: 2
  },
  {
    id: 6,
    image: "/assets/images/1000.png",
    price: 1000,
    type: 3
  },
  {
    id: 7,
    image: "/assets/images/10000.png",
    price: 10000,
    type: 4
  },
  {
    id: 8,
    image: "/assets/images/100000.png",
    price: 100000,
    type: 5
  },
];
