import cogoToast from "cogo-toast"
import { formatMessage } from "../_utils/formatMessage"

export const API_BASE = process.env.REACT_APP_APIURL
export const getHeaders = (token = '') => {
  if (token) {
    return {
      'Content-Type': 'application/json',
      'platform': 3,
      'Authorization': token
    }
  }

  return {
    'Content-Type': 'application/json',
    'platform': 3
  }
}

export const checkAuthentication = (response, history) => {
  if (response.data.message === 'not_authentication') {
    history.push('/before-login');
  }
}

export const handleReponse = (res, handleError, handleSuccess) => {
  switch (res.data.status) {
    case 200:
    case 201: {
      return handleSuccess()
    }
    case 400:
    case 404:
    case 500: {
      return handleError()
    }
    default: {
      return handleSuccess()
    }
  }
}

export const handleErrorMessage = (errorCode, heading = 'エラー') => {
  return cogoToast.error(formatMessage(errorCode), {
    heading,
    position: 'top-center',
    hideAfter: 3,
    bar: { size: '10px' }
  });
}
