import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { livestreamActions } from '../../_actions';

export function ModalExpireCommunityTickets(props) {
  let community = useSelector(state => state.communities.getCommunityId);
  const dispatch = useDispatch();
  const [communityModal, setCommunityModal] = useState('');

  useEffect(() => {
    if (props.eventsDetailHref && props.eventsDetailHref.length) {
      const getCommunities = async () => {
        await dispatch(livestreamActions.getCommunityById(props.eventsDetailHref[0]));
      }

      getCommunities();
    }
  }, [props.eventsDetailHref]);

  useEffect(() => {
    if (community) {
      setCommunityModal(community.title)
    }
  }, [community]);

  return (
    <div className="modal fade" id="modalExpireCommunityTickets" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community modal__free_ticket">
          <div className="modal-body modal-body__free_ticket">
            <div className="give_away_ticket__content mt-2 font__size_expire_community">
                本イベントのチケットを取得するには<br />
                以下①②のいずれかが必要になります。
            </div>
            <div className="content__free_ticket mt-2">
              <div className="expire_community__free_ticket_title">①チケット作成者から無料チケットを受け取る</div>
              <div className="expire_community_tickets">チケット作成者からスペシャルオファーとして</div>
              <div className="expire_community_tickets">無料チケットを配布され、受け取った方(イベントへの招待を受けた方)</div>
              <div className="expire_community_tickets">がご視聴いただけるイベントになります。</div>
              <div className="expire_community_tickets">※チケット作成者から招待を受ける(無料チケットを受け取る)には、</div>
              <div className="expire_community_tickets_2">事前にチケット作成者アカウントをフォローしている、もしくは</div>
              <div className="expire_community_tickets_2">チケット作成者アカウントからフォローされている必要がございます</div>
            </div>
            <div className="content__free_ticket mt-2">
              <div className="expire_community__free_ticket_title">②事前にチケット作成者のコミュニティに参加する</div>
              <div className="expire_community_tickets">イベントの対象となるコミュニティに事前にご参加いただくことで</div>
              <div className="expire_community_tickets">チケットを購入することができるイベントになります。</div>
              <div className="expire_community_tickets">本イベントの場合、「{communityModal}」コミュニティへのご参加が必要となり</div>
              <div className="expire_community_tickets">今後、コミュニティ参加者限定のチケット取得ご希望の方は</div>
              <div className="expire_community_tickets">Live Dreamer スマホ版アプリの "コミュニティ" にて</div>
              <div className="expire_community_tickets">対象のコミュニティへ事前にご参加くださいませ。</div>
            </div>
            <div className="community__btn mt-4 mb-2">
              <button className="btn schedule__but_next"
                data-dismiss="modal"
                aria-label="Close"
              >OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
