import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { livestreamActions } from '../../../_actions';
import { SelectBox } from '../../SelectBox/Select';
import '../../SelectBox/select.css';

export function ModalReport() {
  const dispatch = useDispatch();
  const reportable_id = useParams().livestreamId;
  const type = 1;
  const reportResult = useSelector(state => state.report.report);
  const [spinner, setSpinner] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [reasonId, setReasonId] = useState(1);
  const [content, setContent] = useState('');

  useEffect(() => {
    dispatch(livestreamActions.report());
  }, [dispatch]);

  const handleSelectReasonId = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setReasonId(value);
  }

  const handleChangeContent = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setContent(value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (reasonId && content) {
      setSpinner(true);
      setContent('');
      setSubmitted(false);

      await dispatch(livestreamActions.postReport(parseInt(reportable_id), type, reasonId, content));
      setSpinner(false);
    }
  }

  return (
    <>
      <div className="modal fade" id="modalReport" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog reveal-modal" role="document">
          <div className="modal-content modal-content__custom">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">レポート</h5>
              <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="form" onSubmit={handleSubmit}>
                <div className="container">
                  <div className="form-group">
                    <select id="modalSelect" onChange={handleSelectReasonId} value={reasonId}
                      className="form-control modal-select">
                      {reportResult && reportResult.length && reportResult.map((item, i) => {
                        switch (item.content) {
                          case "videos_often_spam_others":
                            return <option key={i} value={item.id} className="modal-option">ユーザーは不快な情報がある投稿をする</option>
                          case "videos_with_violent_and_incited_content":
                            return <option key={i} value={item.id}
                              className="modal-option">ユーザーは暴力的で扇動された情報がある投稿をする</option>
                          case "videos_has_distorting_information":
                            return <option key={i} value={item.id} className="modal-option">ユーザーは歪んだ情報がある投稿をする</option>
                          case "videos_with_pornography_content":
                            return <option key={i} value={item.id} className="modal-option">ユーザーは他のユーザーを迷惑する</option>
                          case "videos_priracy":
                            return <option key={i} value={item.id} className="modal-option">ユーザーは法律違反がある投稿をするる</option>
                          default:
                            return false;
                        }
                      })}
                    </select>
                    {reportResult && SelectBox()}
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows="5"
                      id="comment"
                      value={content}
                      onChange={handleChangeContent}
                    ></textarea>
                    {submitted && !content &&
                      <div className="invalid-feedback-modal">この内容は必須である。</div>
                    }
                  </div>
                </div>

                <div className="input-login input__login mt-4">
                  {spinner && <span className="spinner-border spinner-border-sm spinner-margin-right"></span>}
                  <input type="submit" className="submit-margin-right" value="送信" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
