import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../_actions/authentication.actions';
import './resetPassword.css';

export function UpdatePassword() {
    const [password, setPassword] = useState('');
    const [prePassword, setPrePassword] = useState('');
    const [submittedUpdatePassword, setSubmittedUpdatePassword] = useState(false);
    const updating = useSelector(state => state.updatePassword.updating);
    const dispatch = useDispatch();

    function handleSubmitUpdatePassword(e) {
        e.preventDefault();

        setSubmittedUpdatePassword(true);
        if (password && prePassword && password === prePassword && password.length >= 8) {
            dispatch(authenticationActions.updateNewPassword(password));
        }
    }

    function handleChangeUpdatePassword(e) {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                return setPassword(value);
            case 'prePassword':
                return setPrePassword(value);
            default:
                return false;
        }
    }

    const handleMaxLengthUpdatePassword = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
    }

    return (
        <form name="form" onSubmit={handleSubmitUpdatePassword}>
            <div className=" text-left mt-4 mb-3 d-flex justify-content-center">
                <span className="heading_livedream">パスワードを再設定<br /></span>
            </div>
            <div className="locate__input d-flex justify-content-between">
                <div className="step_register input__phone input__password">
                    <input
                        type="password"
                        name="password"
                        maxLength={20}
                        value={password}
                        onInput={handleMaxLengthUpdatePassword}
                        onChange={handleChangeUpdatePassword}
                        placeholder="パスワード（半角8文字以上）"
                        className={(submittedUpdatePassword && (!password || (password && password.length < 8)) ? ' is-invalid' : '')} />
                    {submittedUpdatePassword && !password &&
                        <div className="invalid-feedback">パスワードが必要</div>
                    }

                    {submittedUpdatePassword && password && password.length < 8 &&
                        <div className="invalid-feedback">パスワードは必ず、８文字以上で指定ください</div>
                    }

                    <input
                        type="password"
                        name="prePassword"
                        maxLength={20}
                        value={prePassword}
                        onInput={handleMaxLengthUpdatePassword}
                        onChange={handleChangeUpdatePassword}
                        placeholder="パスワードを確認"
                        className={(submittedUpdatePassword && (!prePassword || (prePassword !== password)) ? ' is-invalid' : '')} />
                    {submittedUpdatePassword && !prePassword &&
                        <div className="invalid-feedback">パスワードの再入力が必要です</div>
                    }

                    {submittedUpdatePassword && prePassword !== password &&
                        <div className="invalid-feedback">確認パスワードは正しくありません。</div>
                    }
                </div>
            </div>
            <div className="input-login input__login mt-3">
                {updating && <span className="spinner-border spinner-border-sm mr-1 sprinner-register"></span>}
                <input className="next3" type="submit" value="更新" />
            </div>
        </form>
    );
}
