import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { livestreamActions } from '../../_actions';
import { openApp } from '../../_utils/openApp';

export function ModalJoinCommunity(props) {
  let community = useSelector(state => state.communities.getCommunityId);
  const dispatch = useDispatch();
  const [communityModal, setCommunityModal] = useState('');

  useEffect(() => {
    if (props.eventsDetailHref && props.eventsDetailHref.length) {
      const getCommunities = async () => {
        await dispatch(livestreamActions.getCommunityById(props.eventsDetailHref[0]));
      }

      getCommunities();
    }
  }, [props.eventsDetailHref]);

  useEffect(() => {
    if (community) {
      setCommunityModal(community.title)
    }
  }, [community]);

  const handleClickChange = async () => {
    await openApp(`${community.id}`);
  }

  return (
    <div className="modal fade" id="modalJoinCommunity" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community modal-content_cus">
          <div className="modal-body">
            <div className="give_away_ticket__content mt-2 font__size_community">
              本イベントのチケットを取得するには<br />
              「{communityModal}」コミュニティへのご参加が必要です。<br />
              チケット取得ご希望の方は
            </div>
            <div className="give_away_ticket__content mt-2 font__size_community">
              Live Dreamer スマホ版アプリの "コミュニティ" にて<br />
              「{communityModal}」コミュニティへご参加くださいませ
            </div>

            <div className="community__btn mt-4 mb-2">
              <button className="btn schedule__but_return join_community__btn"
                data-dismiss="modal"
                aria-label="Close"
              >キャンセル
              </button>
              {props.width < 768 && <button className="btn schedule__but_next join_community__btn"
                onClick={handleClickChange}
                data-dismiss="modal"
                aria-label="Close"
              >コミュニティへ移動する</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
