import axios from 'axios';
import { API_BASE, getHeaders } from "../_helpers/api";

export const userService = {
  getUserProfile,
  getCoins,
  getBuyTicketLivestream,
  syncData,
  chargeCoin,
  chargeCoinByCredit,
  getFollowsOfUser,
  getFollowerOfUser,
  searchListUsers
};

function syncData() {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/sync-data`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getCoins() {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/me/coins`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getUserProfile(id) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/${id}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function getBuyTicketLivestream(data) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/me/buy-ticket-livestream?type=${data.type}&page=${data.page}&limit=${data.limit}`, {
    headers: {
      ...getHeaders(token)
    }
  })
}

function chargeCoin() {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/coins/packages`, {
    headers: {
      'Content-Type': 'application/json',
      'platform': '3',
      'Authorization': token
    }
  })
}

function chargeCoinByCredit(data) {
  const token = localStorage.getItem('token');

  return axios.post(`${API_BASE}/api/v1/coins/charge-coin-by-credit`, data, {
    headers: {
      'Content-Type': 'application/json',
      'platform': '3',
      'Authorization': token,
    }
  });
}

function getFollowsOfUser(id) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/${id}/follows`, {
    headers: {
      'Content-Type': 'application/json',
      'platform': '3',
      'Authorization': token
    }
  })
}

function getFollowerOfUser(id) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users/${id}/followers`, {
    headers: {
      'Content-Type': 'application/json',
      'platform': '3',
      'Authorization': token
    }
  })
}

function searchListUsers(page, limit, keyword) {
  const token = localStorage.getItem('token');
  return axios.get(`${API_BASE}/api/v1/users?page=${page}&limit=${limit}&key=${keyword}`, {
    headers: {
      ...getHeaders(token)
    }
  });
}
