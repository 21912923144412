import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../_actions/authentication.actions';

export function EnterPassword() {
  const [password, setPassword] = useState('');
  const [prePassword, setPrePassword] = useState('');
  const [submittedEnterPassword, setSubmittedEnterPassword] = useState(false);
  const updating = useSelector(state => state.updatePassword.updating);
  const dispatch = useDispatch();

  function handleSubmitEnterPassword(e) {
    e.preventDefault();

    setSubmittedEnterPassword(true);
    if (password && prePassword && password === prePassword && password.length >= 8) {
      dispatch(authenticationActions.updatePassword(password));
    }

  }

  function handleChangeEnterPassword(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'password':
        return setPassword(value);
      case 'prePassword':
        return setPrePassword(value);
      default:
        return false;
    }
  }

  const handleMaxLengthEnterPassword = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  return (
    <form name="form" onSubmit={handleSubmitEnterPassword}>
      <div className=" text-left mt-4 mb-3 d-flex justify-content-center">
        <span className="heading_livedream">パスワードの設定<br /></span>
      </div>
      <div className="locate__input d-flex justify-content-between">
        <div className="step_register input__phone input__password">
          <input
            type="password"
            name="password"
            maxLength={20}
            value={password}
            onInput={handleMaxLengthEnterPassword}
            onChange={handleChangeEnterPassword}
            placeholder="パスワード（半角8文字以上）"
            className={(submittedEnterPassword && (!password || (password && password.length < 8)) ? ' is-invalid' : '')} />
          {submittedEnterPassword && !password &&
            <div className="invalid-feedback">パスワードが必要</div>
          }

          {submittedEnterPassword && password && password.length < 8 &&
            <div className="invalid-feedback">パスワードは必ず、８文字以上で指定ください</div>
          }
          <input
            type="password"
            name="prePassword"
            maxLength={20}
            value={prePassword}
            onInput={handleMaxLengthEnterPassword}
            onChange={handleChangeEnterPassword}
            placeholder="確認用"
            className={(submittedEnterPassword && (!prePassword || (prePassword !== password)) ? ' is-invalid' : '')} />
          {submittedEnterPassword && !prePassword &&
            <div className="invalid-feedback">パスワードの再入力が必要です</div>
          }
          {submittedEnterPassword && prePassword !== password &&
            <div className="invalid-feedback">確認パスワードは正しくありません。</div>
          }
        </div>
      </div>
      <div className="input-login input__login mt-3">
        {updating && <span className="spinner-border spinner-border-sm mr-1 sprinner-register"></span>}
        <input className="nextRegister3" type="submit" defaultValue="Register" value="登録" />
      </div>
    </form>
  );
}
