import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { groupSelectCodeNation } from '../../_utils/Group/GroupSelectCodeNation';
import { clickNext } from '../../_utils/clickNext';
import { authenticationActions } from '../../_actions/authentication.actions';

export function SendVerifyCode() {
  const [inputs, setInputs] = useState({
    phoneCode: '+81',
    phoneNumber: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const verifyCode = useSelector(state => state.authentication.verifyCode);
  const dispatch = useDispatch();

  useEffect(() => {
    if (verifyCode) clickNext("next1");
  }, [verifyCode]);


  const handleSelectCode = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }))
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (e.target.validity.valid) setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  const handleSubmitRequestCode = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (inputs.phoneCode && inputs.phoneNumber) {
      await dispatch(authenticationActions.requestResetPasswordCode(inputs.phoneCode, inputs.phoneNumber));
      const verifyCode = localStorage.getItem('verify-token');
      if (verifyCode) {
        clickNext("next1");
      }
    }
  }

  const handleMaxLengthPhoneNumber = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  return (
    <form name="form" onSubmit={handleSubmitRequestCode}>
      <div className="locate__input d-flex justify-content-between">
        <div className="input__select input__select__phone">
          <select id="inputGroupSelect04" onChange={handleSelectCode} value={inputs.phoneCode} name="phoneCode"
            className="select__phone">
            {groupSelectCodeNation.map((item, i) => {
              return <option key={i} value={item.code}>{item.name}</option>
            })}
          </select>
          <span className="caret" />
        </div>
        <div className="input__phone input__phone__number">
          <input
            type="tel"
            name="phoneNumber"
            pattern="[0-9]*"
            value={inputs.phoneNumber}
            onChange={handleChange}
            maxLength={20}
            onInput={handleMaxLengthPhoneNumber}
            className={(submitted && !inputs.phoneNumber ? ' is-invalid' : '')}
          />
        </div>
      </div>
      {submitted && !inputs.phoneNumber &&
        <div className="invalid-feedback">電話番号が必要です</div>
      }
      <div className="input-login input__login mt-3">
        <input className="next1" type="submit" value="次へ" />
      </div>
    </form>
  );
}
