export const userConstants = {
  COINS_SUCCESS: 'COINS_SUCCESS',
  COINS_FAILURE: 'COINS_FAILURE',

  USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
  USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
  USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

  GET_BUY_TICKET_LIVESTREAM_REQUEST: 'GET_BUY_TICKET_LIVESTREAM_REQUEST',
  GET_BUY_TICKET_LIVESTREAM_SUCCESS: 'GET_BUY_TICKET_LIVESTREAM_SUCCESS',
  GET_BUY_TICKET_LIVESTREAM_FAILURE: 'GET_BUY_TICKET_LIVESTREAM_FAILURE',
  GET_FOLLOWS_USER_REQUEST: 'GET_FOLLOWS_USER_REQUEST',
  GET_FOLLOWS_USER_SUCCESS: 'GET_FOLLOWS_USER_SUCCESS',
  GET_FOLLOWS_USER_FAILURE: 'GET_FOLLOWS_USER_FAILURE',

  GET_FOLLOWER_USER_REQUEST: 'GET_FOLLOWER_USER_REQUEST',
  GET_FOLLOWER_USER_SUCCESS: 'GET_FOLLOWER_USER_SUCCESS',
  GET_FOLLOWER_USER_FAILURE: 'GET_FOLLOWER_USER_FAILURE',
  CHARGE_COIN_REQUEST: 'CHARGE_COIN_REQUEST',
  CHARGE_COIN_SUCCESS: 'CHARGE_COIN_SUCCESS',
  CHARGE_COIN_FAILURE: 'CHARGE_COIN_FAILURE',
  CHARGE_COIN_BY_CREDIT_SUCCESS: 'CHARGE_COIN_BY_CREDIT_SUCCESS',

  LIST_USER_REQUEST: 'LIST_USER_REQUEST',
  LIST_USER_SUCCESS: 'LIST_USER_SUCCESS',
  LIST_USER_FAILURE: 'LIST_USER_FAILURE',
};
