import React from 'react';

export function ModalBefore15Minus() {

  return (
    <div className="modal fade" id="modalBefore15Minus" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog reveal-modal" role="document">
        <div className="modal-content modal-content__community">
          <div className="modal-body">
            <div className="give_away_ticket__content mt-2 view__room_f">
              LIVE開始時刻15分前から視聴ルームに入れます。
            </div>
            <div className="give_away_ticket__content mt-2 view__room_f">
              配信をお楽しみに！
            </div>
            <div className="community__btn mt-4 mb-2">
              <button className="btn schedule__but_next give_away_ticket__btn"
                data-dismiss="modal"
                aria-label="Close"
              >閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
