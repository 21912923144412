export const GroupBeforeLogin = [
  {
    id: 1,
    image: "/assets/images/profile.png",
    name: "電話番号でログインする",
    url: "/login"
  },
  {
    id: 2,
    image: "/assets/images/twitter.png",
    name: "Twitterで続ける",
    url: "/login-twitter"
  },
  {
    id: 4,
    image: "/assets/images/google.png",
    name: "Googleで続ける",
    url: "/login-google"
  },
  {
    id: 5,
    image: "/assets/images/facebook.png",
    name: "Facebookで続ける",
    url: "/login-facebook"
  },
  {
    id: 6,
    image: "/assets/images/line.png",
    name: "LINEで続ける",
    url: "/login-line"
  },
];